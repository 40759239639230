import { Fragment, useEffect, useState } from 'react';
import { useHolidayCapacity } from '../../../hooks';
import { HolidayCapacitiesResponseType } from '../../../services/HolidayCapacitiesService';
import { SvgIconComponent } from '../../Icons';
import { ProgressBar } from '../../ProgressBar';
import {
  CapacityInfo,
  CapacitySubtitle,
  CapacityTitle,
  MainPercent,
  TextPercent,
  TribeWrapper,
} from './styled';
import { CapacityHolidayOnDayEmployeeProps } from './types';

export const CapacityHolidayOnDayEmployee = ({
  capacityParams,
  singleEmployeeId,
}: CapacityHolidayOnDayEmployeeProps) => {
  const { capacity } = useHolidayCapacity({ capacityParams });
  const [holidayCapacities, setHolidayCapacities] = useState<HolidayCapacitiesResponseType>();

  useEffect(() => {
    setHolidayCapacities(capacity);
  }, [capacity]);

  return (
    <>
      {singleEmployeeId && (
        <>
          <CapacityTitle>Capacity</CapacityTitle>
          <MainPercent>
            {holidayCapacities &&
              holidayCapacities.departments
                ?.filter((dep) => dep.id === singleEmployeeId)
                ?.map((holidayPercentage) => (
                  <Fragment key={holidayPercentage.id}>
                    <CapacitySubtitle>Tribe occupancy on vacation leave</CapacitySubtitle>
                    <CapacityInfo>
                      <ProgressBar showValue={holidayPercentage.occupancy_percentage} />
                      <TextPercent value={holidayPercentage.occupancy_percentage}>
                        {`${holidayPercentage.occupancy_percentage.toFixed(0)}%`}
                      </TextPercent>
                      <SvgIconComponent icon="UserIcon" width={16} />
                    </CapacityInfo>
                  </Fragment>
                ))}
          </MainPercent>
        </>
      )}
      <TribeWrapper>
        {holidayCapacities &&
          holidayCapacities.departments?.map((holidayPercentage) => (
            <Fragment key={holidayPercentage.id}>
              <CapacitySubtitle>{holidayPercentage.name}</CapacitySubtitle>
              <CapacityInfo>
                <ProgressBar showValue={holidayPercentage.occupancy_percentage} />
                <TextPercent value={holidayPercentage.occupancy_percentage}>
                  {`${holidayPercentage.occupancy_percentage.toFixed(0)}%`}
                </TextPercent>
                <SvgIconComponent icon="UserIcon" width={16} />
              </CapacityInfo>
            </Fragment>
          ))}
      </TribeWrapper>
    </>
  );
};
