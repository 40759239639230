import { StyledSwitch } from './styled';
import { SwitchProps } from './types';

export const Switch = ({ value, onChange }: SwitchProps) => {
  return (
    <StyledSwitch data-cy={`kids-toggle${value ? '-checked' : ''}`}>
      <div className="form-group">
        <input type="checkbox" id="checkbox" checked={value} onChange={onChange} />
        <label htmlFor="checkbox" className="label-switch">
          <span className="switch"></span>
        </label>
      </div>
    </StyledSwitch>
  );
};
