import React, { useMemo } from 'react';
import { DonutChart } from '../../../components/DonutChart';
import Text from '../../../components/Text';
import { useThemeContext } from '../../../components/ThemeWrapper/ThemeWrapper';
import { TileWrapper } from '../../../components/TileWrapper';
import { colors } from '../../../styles/colors';
import { HolidayBalanceTileProps } from '../types';
import { HolidayBalanceWrapper, Row, HolidayBalanceList } from './styled';

export const HolidayBalanceTile = ({ holiday }: HolidayBalanceTileProps) => {
  const { getColorByTheme } = useThemeContext();

  const holidayConfig = useMemo(() => {
    const config = [
      {
        name: 'Total Holiday Balance',
        amount: `${holiday.used}/${holiday.total} days`,
        color: getColorByTheme(colors.gray[700], colors.gray[500]),
      },
      {
        name: 'Requested',
        amount: `${holiday.requested} days`,
        color: getColorByTheme(colors.dark.tertiary, colors.tertiary),
      },
      {
        name: 'Left',
        amount: `${holiday.left} days`,
        color: getColorByTheme(colors.gray[900], colors.gray[300]),
      },
    ];

    if (!!holiday.onDemandTotal) {
      config.splice(1, 0, {
        name: 'On demand left',
        amount: `${holiday.onDemandTotal - holiday.onDemand}/${holiday.onDemandTotal} days`,
        color: getColorByTheme(colors.gray[700], colors.gray[500]),
      });
    }

    return config;
  }, [holiday, getColorByTheme]);

  return (
    <TileWrapper title="Holiday Balance">
      <HolidayBalanceWrapper>
        <HolidayBalanceList>
          {holidayConfig.map((element) => (
            <Row key={element.name}>
              <Text.Paragraphs type="xSmall" color={element.color}>
                {element.name}
              </Text.Paragraphs>
              <Text.Paragraphs type="xSmall" color={element.color}>
                {element.amount}
              </Text.Paragraphs>
            </Row>
          ))}
        </HolidayBalanceList>

        <DonutChart
          requested={holiday.requested}
          avaiable={holiday.available}
          total={holiday.total}
        />
      </HolidayBalanceWrapper>
    </TileWrapper>
  );
};
