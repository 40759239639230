import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { countWorkingDays } from 'helpers/CountWorkoutDays';
import { Employee } from 'services/EmployeesService';
import { colors } from 'styles/colors';
import { HolidaySummaryContainer } from '../styled';

interface HolidaySummaryProps {
  employee: Employee;
  startDate: Date;
  endDate: Date;
}

export const HolidaySummary = ({ employee, startDate, endDate }: HolidaySummaryProps) => {
  const { getColorByTheme } = useThemeContext();

  const total = employee.holiday?.total || 0;

  const used = employee.holiday?.used || 0;
  const requesting = countWorkingDays(startDate, endDate) || 0;
  const onDemand = employee.holiday?.onDemand || 0;
  const onDemandTotal = employee.holiday?.onDemandTotal || 0;
  const left = employee.holiday?.left || 0;

  return (
    <HolidaySummaryContainer>
      <HolidaySummaryRow
        left="Total Holiday Balance"
        right={`${used}/${total}`}
        color={getColorByTheme(colors.gray[700], colors.gray[500])}
      />

      {!!onDemandTotal && (
        <HolidaySummaryRow
          left="On demand left"
          right={`${onDemandTotal - onDemand}/${onDemandTotal}`}
          color={getColorByTheme(colors.gray[700], colors.gray[500])}
        />
      )}

      <HolidaySummaryRow
        left="Requesting"
        right={requesting}
        color={getColorByTheme(colors.dark.tertiary, colors.tertiary)}
      />

      <HolidaySummaryRow
        left="Left"
        right={left}
        color={getColorByTheme(colors.gray[900], colors.gray[300])}
      />
    </HolidaySummaryContainer>
  );
};
