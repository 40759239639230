import { Fragment } from 'react';
import { Button, SvgIconComponent } from 'components';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { remCalc } from 'helpers';
import { AdditionalHolidaUpdateTypes } from 'services/EmployeesService';
import { colors } from 'styles/colors';
import {
  AdditionalHolidaysButtonsWrapper,
  AdditionalHolidaysContainer,
  AdditionalHolidaysIconWrapper,
  AdditionalHolidaysInfoWrapper,
  DividerModalBorder,
} from './styled';

interface Props {
  toggle: VoidFunction;
  isVisible: boolean;
  dataForUpdate: AdditionalHolidaUpdateTypes[];
  employeeFirstName: string;
  employeeLastName: string;
  employeeId: number;
  submitEmploymentUpdate: () => Promise<void>;
}

export const ChangingEmploymentTypeModal = ({
  toggle,
  isVisible,
  employeeFirstName,
  employeeLastName,
  dataForUpdate,
  employeeId,
  submitEmploymentUpdate,
}: Props) => {
  const { getColorByTheme } = useThemeContext();

  const showAdditionalHolidayElement = !!dataForUpdate.length;

  return (
    <Modal
      label="Changing employment type"
      visible={isVisible}
      onClose={toggle}
      crossMarkIcon
      dataCY="changing-employment-type-modal"
      hideButton>
      <Text.Paragraphs style={{ marginBottom: 25, marginTop: 11 }} type="xSmall">
        Are you sure you want to change the employment type?
        <br></br>
        {showAdditionalHolidayElement && 'There are some additional holidays that will be cleared.'}
      </Text.Paragraphs>
      <AdditionalHolidaysInfoWrapper>
        <DividerModalBorder />
        <AdditionalHolidaysContainer showAdditionalHolidayElement={showAdditionalHolidayElement}>
          <Text.Paragraphs type="small" color={colors.gray[600]} weight={700}>
            <AdditionalHolidaysIconWrapper>
              <SvgIconComponent icon={'UserGreyIcon'} width={12} height={12} />
            </AdditionalHolidaysIconWrapper>
            Employee
          </Text.Paragraphs>
          <Text.Paragraphs
            type="small"
            color={getColorByTheme(colors.black, colors.white)}
            margin={`0 0 ${remCalc(24)}`}
            weight={700}>
            {employeeLastName} {employeeFirstName}
          </Text.Paragraphs>
          {showAdditionalHolidayElement &&
            dataForUpdate.map((item: AdditionalHolidaUpdateTypes) => {
              return (
                <Fragment key={`${item.id}-${item.localId}`}>
                  <Text.Paragraphs type="small" color={colors.gray[600]} weight={700}>
                    <AdditionalHolidaysIconWrapper>
                      <SvgIconComponent icon={'SuitcaseIcon'} width={12} height={12} />
                    </AdditionalHolidaysIconWrapper>
                    Holiday type
                  </Text.Paragraphs>
                  <Text.Paragraphs
                    type="small"
                    color={getColorByTheme(colors.black, colors.white)}
                    margin={`0 0 ${remCalc(24)}`}
                    weight={700}>
                    {item.name} - {item.days} days for {item.year} year
                  </Text.Paragraphs>
                </Fragment>
              );
            })}
        </AdditionalHolidaysContainer>

        <DividerModalBorder />
      </AdditionalHolidaysInfoWrapper>
      <AdditionalHolidaysButtonsWrapper>
        <Button label="Don't change" variant="basic" size="regular" onClick={toggle} />
        <Button
          label="Change employment type"
          variant="strongFail"
          size="regular"
          onClick={submitEmploymentUpdate}
        />
      </AdditionalHolidaysButtonsWrapper>
    </Modal>
  );
};
