import styled from 'styled-components';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const CapacityWrapper = styled.div`
  margin-top: 2.5rem;
  padding: 1em;
  background: ${({ theme }) => theme.holidayForm.capacity.background};
  border: 1px solid ${({ theme }) => theme.holidayForm.capacity.borderColor};
  border-radius: ${borderRadius.xs};
`;

export const CapacityTitle = styled.div`
  ${typography.heading[4]};
  color: ${({ theme }) => theme.text[900]};
`;

export const CapacitySubtitle = styled.div`
  ${typography.paragraphs.caption};
  color: ${({ theme }) => theme.text[700]};
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

export const TribeWrapper = styled.div`
  margin: 2rem 0;
`;

export const MainPercent = styled.div`
  padding-bottom: 1.625rem;
  border-bottom: 1px solid ${({ theme }) => theme.holidayForm.capacity.borderColor};
`;

export const CapacityInfo = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1rem 1rem;
  column-gap: 1rem;
`;

export const TextPercent = styled.div<{ value: number }>`
  ${typography.paragraphs.caption};
  color: ${({ theme, value }) => (value <= 20 ? theme.text[700] : theme.text.fail)};
`;
