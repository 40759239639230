export const remCalc = (pixels: number): string => {
  const rem = pixels * 0.0625;
  return `${rem}rem`;
};

export const generateArrayOfYears = (): number[] => {
  const max = new Date().getFullYear();
  const min = max - 4;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};
