import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: absolute;
  text-align: center;
`;

export const StyledSvg = styled.svg`
  transform: scale(1.25);
  min-width: 11.25rem;
`;
