import styled, { createGlobalStyle } from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const DatePickerStyles = createGlobalStyle<{ variant?: 'outline' }>`
  .react-datepicker__input-container {
    > div:first-child {
      background-color: ${({ theme }) => theme.input.background};
    }

    div {
      background-color: ${({ theme }) => theme.input.background};
      border-color: ${({ theme, variant }) => {
        return variant ? theme.button.basic.borderColor : theme.input.border;
      }};
      color:${({ theme }) => theme.input.datePickerPlaceholder};
      box-shadow: 0 0 1px ${({ theme }) => theme.input.background};
    }

    svg {
      color:${({ theme }) => theme.input.datePickerPlaceholder};
    }

    input {
      background-color: ${({ theme }) => theme.input.background};
      color:${({ theme }) => theme.input.datePickerPlaceholder};

      ::placeholder {
        color:${({ theme }) => theme.input.datePickerPlaceholder};
      }
    }
  }
  
  .datepicker_calendar {
    ${typography.heading[6]};
    background-color: ${({ theme }) => theme.backgroundElement};
    border-radius: ${borderRadius.sm};
    border: 1px solid ${({ theme }) => theme.smallCalendar.border};;
    box-shadow:  ${({ theme }) => theme.smallCalendar.boxShadow};
   
    .react-datepicker__header, .react-datepicker__header--custom {
      background-color: transparent;
      border-bottom:none;
      padding: 0;
      width: 15.5rem;
      height: 4.25rem;

      > div:first-child {
        background-color: ${({ theme }) => theme.smallCalendar.backgroundHeader};
        color: ${({ theme }) => theme.smallCalendar.title};
        margin: 0 !important;
        padding: 1rem;
        border-radius: ${borderRadius.sm} ${borderRadius.sm} 0 0;

        div { 
          button {
          margin: 0;
          padding: 0;
          border: none;
          background-color: transparent;
              
              &:first-child {
                  padding-right: 0.5rem;
              }
          
          }
        }
      }

      .react-datepicker__day-name {
          color: ${({ theme }) => theme.smallCalendar.day};
          ${typography.paragraphs.xSmall};
          margin-top: 0.5rem;
      }
    }

    .react-datepicker__triangle{
      display: none;
    }
  }

  .datepicker_days {
    ${typography.paragraphs.xSmall}
    color: ${({ theme }) => theme.smallCalendar.text};
    height: 1.5rem;
    width: 1.5rem;
  }

  .react-datepicker__month{
    padding-block-end: 0.625rem;
    padding-block-start: 0.625rem;

      .react-datepicker__week{
        display:flex;
        align-items: baseline;
        justify-content: space-evenly;
        height: 1.5rem;

          .datepicker_days{
            display: flex;
            align-items:  center;
            justify-content: center;
          }

          .react-datepicker__day--keyboard-selected{
            border-radius: 50%;
          background-color: ${({ theme }) => theme.smallCalendar.selected};
            color: ${colors.white};
          }

          .react-datepicker__day--selected{
            border-radius: 50%;
              background-color: ${({ theme }) => theme.smallCalendar.selected};
            color: ${colors.white};
          }

          .react-datepicker__day:hover {
            border-radius: 50%;
            background-color:  ${({ theme }) => theme.smallCalendar.backgroundHover};
              color: ${({ theme }) => theme.smallCalendar.text};
          }

          .react-datepicker__day:focus-visible {
            outline: none;
          }

          .react-datepicker__day--outside-month {
            opacity: 0;
            pointer-events: none;
          }
          
      };

      .react-datepicker__day--today{
        background-color: ${({ theme }) => theme.smallCalendar.today.background};
        color: ${({ theme }) => theme.smallCalendar.today.text};
        border-radius: 50%;
      }
}   
.wrapper{ 
    .react-datepicker__close-icon{
    display: none;
  }
}`;

export const ArrowButton = styled.button`
  cursor: pointer;
`;

export const DatePickerLabel = styled.div`
  color: ${({ theme }) => theme.input.label};
`;
