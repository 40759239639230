import 'react-datepicker/dist/react-datepicker.css';
import { Checkbox } from '../../../Switches/Checkbox';
import Text from '../../../Text';
import { RawDatePicker } from '../RawDatePicker';
import { DatePickerStyles, DatePickerLabel } from '../styled';
import { RangeDatePickerProps } from '../types';
import { FlexItem, FlexWrapper } from './styled';

export const RangeDatePicker = ({
  label,
  checkbox,
  handleCheckbox,
  startDate,
  endDate,
  setStartDateSelect,
  setEndDateSelect,
  className,
  handleEmployedTo,
  dataCY,
  disableBothDateCheck = false,
  minDate,
  isChecked,
  setIsChecked,
  ...props
}: RangeDatePickerProps) => {
  return (
    <div className={className}>
      {label && (
        <DatePickerLabel>
          <Text.Paragraphs type="caption" style={{ marginLeft: 0, marginBottom: 8 }}>
            {label}
          </Text.Paragraphs>
        </DatePickerLabel>
      )}
      <FlexWrapper data-cy={dataCY}>
        <FlexItem data-cy={`${dataCY}-startDate`}>
          <RawDatePicker
            onChange={setStartDateSelect}
            selected={startDate}
            disabled={disableBothDateCheck}
            minDate={minDate}
          />
          <DatePickerStyles />
        </FlexItem>

        <FlexItem data-cy={`${dataCY}-endDate`}>
          <RawDatePicker
            onChange={setEndDateSelect}
            selected={endDate}
            disabled={isChecked || disableBothDateCheck}
          />
          <DatePickerStyles />
        </FlexItem>
      </FlexWrapper>
      {checkbox && (
        <Checkbox
          style={{ margin: '1.375rem 0' }}
          onClick={setIsChecked}
          label="Contract without a time limit"
          isChecked={isChecked}
          checkboxAction={handleCheckbox}
          dataCY={`${dataCY}-checkbox`}
          {...props}
        />
      )}
    </div>
  );
};
