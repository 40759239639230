import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const DropdownContainer = styled.div`
  max-width: 14rem;
  padding: 0.375em 0;
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  box-shadow: 0 0 2px ${({ theme }) => theme.DropdownList.shadow.firstValue},
    0 0 6px ${({ theme }) => theme.DropdownList.shadow.secondValue};
  z-index: 2;
`;

export const ListElementUl = styled.ul`
  min-width: 14rem;
  height: auto;
  padding: 0 0.75em;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
`;

export const ListElementLi = styled.li`
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: center;
  ${typography.buttonFontSize.large};
  color: ${({ theme }) => theme.department.text};
  height: 2.375rem;
  padding: 1em 0.75em;
  margin: 0.5rem 0;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.text.highlight};
    border-radius: ${borderRadius.sm};
    color: ${colors.white};
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 2.25rem;
  right: 2.375rem;
  z-index: 10;
`;
