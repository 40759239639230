import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';
import { CustomCheckboxProps } from './types';

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div<CustomCheckboxProps>`
  width: ${({ holidayBalanceVariant }) => (holidayBalanceVariant ? '20px' : '1.25rem')};
  height: ${({ holidayBalanceVariant }) => (holidayBalanceVariant ? '20px' : '1.25rem')};
  margin-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, checked }) => (checked ? theme.input.checked : theme.input.background)};
  border: 1px solid ${({ theme, checked }) => (checked ? theme.input.checked : theme.input.border)};
  border-radius: ${borderRadius.xxs};
  flex-shrink: 0;
  box-shadow: 0 0 1px
    ${({ theme, checked }) => (checked ? theme.input.checked : theme.input.border)};

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
      border-color: ${color};
    `};

  ${({ stateCheckbox }) =>
    stateCheckbox &&
    css`
      background: ${colors.white};
      border: 1px solid ${colors.gray[300]};
    `};
`;

export const CheckboxLabel = styled.div`
  color: ${({ theme }) => theme.input.label};
`;
