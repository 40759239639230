import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Layout } from 'components/Layout';
import { Spinner } from 'components/Spinner';
import { routes } from 'routes';
import EmployeesService, { Employee } from 'services/EmployeesService';
import HolidayService, { HolidayProps } from 'services/HolidayService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { HolidayManagerForm } from './HolidayManagerForm/HolidayManagerForm';
import { HolidaysFormCapacity, Wrapper } from './styled';
import { TeamURLParams } from './types';

export const HolidaysForm = () => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const [holiday, setHoliday] = useState<HolidayProps>();
  const [employee, setEmployee] = useState<Employee>();
  const [hasError, setError] = useState(false);
  const [params, setParams] = useState({
    'date[from]': '',
    'date[to]': '',
    'departmentId': null,
    'additionalComment': '',
  });

  const { holidayId } = useParams<TeamURLParams>();

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (!holiday) {
          const holidayRes = await HolidayService.fetchHolidayById(holidayId);
          setHoliday(holidayRes);

          const employeeRes = await EmployeesService.fetchEmployeeByIdOnCurrentYear(
            holidayRes.user.id,
            new Date(holidayRes.createdAt).getFullYear().toString(),
          );
          setEmployee(employeeRes);

          setParams({
            'date[from]': `${holidayRes.dateFrom}`,
            'date[to]': `${holidayRes.dateTo}`,
            'departmentId': null,
            'additionalComment': `${holidayRes.additionalComment}`,
          });
        }
      } catch (e) {
        setError(true);
      }
    };
    initialize();
  }, [holidayId, holiday, params]);

  if (hasError) {
    return <Redirect to={routes.notFound} />;
  }

  if (!holiday || !employee || !employmentTypesStore.listData) return <Spinner fullPage />;

  return (
    <Layout>
      <Wrapper>
        <HolidayManagerForm
          holidayTypes={employmentTypesStore.listData}
          setHoliday={setHoliday}
          holidayId={holidayId}
          holiday={holiday}
          employee={employee}
          additionalComment={params.additionalComment}
        />
        <HolidaysFormCapacity
          singleEmployeeId={employee?.departmentRole?.departmentId}
          capacityDayParams={params}
        />
      </Wrapper>
    </Layout>
  );
};
