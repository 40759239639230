import { GetDepartmentRolesActions } from 'store/actions';
import { DepartmentRolesActionTypesEnum } from 'store/actionTypes';
import { DepartmentRolesStateInterfaceTypes } from 'types';

const initialState: DepartmentRolesStateInterfaceTypes = {
  isProgress: false,
  listData: [],
};

export type DepartmentRolessReducerType = ReturnType<typeof departmentRolesReducer>;
export const departmentRolesReducer = (
  state: DepartmentRolesStateInterfaceTypes = initialState,
  action: GetDepartmentRolesActions,
): DepartmentRolesStateInterfaceTypes => {
  const {
    DEPARTMENT_ROLES_ACTION_REQUEST,
    DEPARTMENT_ROLES_ACTION_RESPONSE,
    DEPARTMENT_ROLES_ACTION_ERROR,
  } = DepartmentRolesActionTypesEnum;
  switch (action.type) {
    case DEPARTMENT_ROLES_ACTION_REQUEST:
      return {
        ...state,
        isProgress: true,
      };
    case DEPARTMENT_ROLES_ACTION_RESPONSE:
      return {
        listData: action.payload,
        isProgress: false,
      };
    case DEPARTMENT_ROLES_ACTION_ERROR:
      return {
        ...state,
        isProgress: false,
      };
    default:
      return state;
  }
};
