import { HolidayStatuses, HolidayType, TeamHoliday } from 'services/HolidaysService';
import { searchByEmployee } from 'utils';

export const filterHolidaysBySearchPhrase = (holiday: TeamHoliday, phrase: string) => {
  return searchByEmployee(phrase, holiday.user);
};

export const filterHolidaysByDepartment = (holiday: TeamHoliday, departmentId: number) => {
  return holiday.user.departmentId === departmentId;
};

export const filterHolidaysByTypes = (holiday: TeamHoliday, holidayTypes: HolidayType[]) => {
  return holidayTypes.some((holidayType) => holidayType.key === holiday.holidayType);
};

export const filterHolidaysByEmploymentTypes = (holiday: TeamHoliday, employmentType: string) => {
  return holiday.user.employmentType === employmentType;
};

export const filterHolidaysByStatuses = (
  holiday: TeamHoliday,
  holidayStatuses: HolidayStatuses[],
) => {
  return holidayStatuses.some((holidayStatus) => holidayStatus === holiday.status);
};
