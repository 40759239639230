import { DonutChart } from 'components/DonutChart';
import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { ClickableSection } from 'components/TileWrapper/types';
import { EmployeeHolidaySummary } from 'services/EmployeesService';
import { colors } from 'styles/colors';
import { Legend } from '../Legend/Legend';
import Text from '../Text';
import { TileWrapper } from '../TileWrapper';
import { LeftSection, Row, StyledTitle, StyledParagraph } from './styled';

type HolidayTileProps = Pick<
  EmployeeHolidaySummary,
  'total' | 'available' | 'onDemand' | 'onDemandTotal' | 'requested' | 'left'
> & {
  footer?: ClickableSection;
};

export const HolidayTile = ({
  total,
  available,
  onDemand,
  onDemandTotal,
  requested,
  footer,
}: HolidayTileProps) => {
  return (
    <TileWrapper style={{ paddingTop: 0 }} footer={footer}>
      <Row>
        <LeftSection>
          <div>
            <StyledTitle>{'Holiday'}</StyledTitle>
            <StyledParagraph>
              <Text.Paragraphs type="small">
                See your available holiday and plan your next holiday in calendar.
              </Text.Paragraphs>
            </StyledParagraph>
          </div>
          <Legend />
        </LeftSection>
        <DonutChart requested={requested} avaiable={available} total={total} />
      </Row>

      <HolidaySummaryRow left="Available holiday" right={`${available}`} />
      {onDemandTotal ? (
        <HolidaySummaryRow left="Available on demand" right={`${onDemandTotal - onDemand}`} />
      ) : null}
      <HolidaySummaryRow left="Pending request" right={requested} color={colors.dark.warning} />
    </TileWrapper>
  );
};
