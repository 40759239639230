import styled from 'styled-components';
import { InputFull } from 'components/Inputs';

export const Row = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;

export const Label = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: center;
`;

export const StyledInput = styled(InputFull)`
  margin: 0 0.5rem 1rem 0.5rem;
`;
