import ReactDOM from 'react-dom';
import { Button } from 'components/Button/ButtonBase';
import { SvgIconComponent } from 'components/Icons';
import { ModalHeader, ModalIconButton } from 'components/Modal/styled';
import Text from '../../Text';
import {
  ManagerModalContainer,
  ManagerModalOverlay,
  ManagerModalWrapper,
  ManagerRejectSubtilte,
  ButtonSection,
} from './styled';
import { RequestManagerModalProps } from './types';

export const RequestManagerModal = ({
  className,
  isShowing,
  label,
  subtitle,
  subtitleWithProps,
  buttonLeft,
  buttonRight,
  children,
  leftButtonAction,
  closeIcon,
  disabledButton,
  dataCY,
}: RequestManagerModalProps) => {
  if (!isShowing) {
    return null;
  }

  const date = `${subtitleWithProps?.startDate} - ${subtitleWithProps?.endDate}`;

  return ReactDOM.createPortal(
    <ManagerModalWrapper data-cy={dataCY}>
      <ManagerModalOverlay onClick={closeIcon} />
      <ManagerModalContainer className={className}>
        <ModalHeader>
          <Text.Heading type="2">{label}</Text.Heading>
          <ModalIconButton onClick={closeIcon} data-cy="modal-close-button">
            <SvgIconComponent icon={'CrossMarkIcon'} />
          </ModalIconButton>
        </ModalHeader>

        <div>{subtitle}</div>

        {subtitleWithProps && (
          <ManagerRejectSubtilte color={subtitleWithProps.color}>
            Are you sure you want to {subtitleWithProps.action} holiday request for{' '}
            {subtitleWithProps.fullName} on date{' '}
            {subtitleWithProps.startDate === subtitleWithProps.endDate
              ? subtitleWithProps.startDate
              : date}
            ?
          </ManagerRejectSubtilte>
        )}

        {children}

        <ButtonSection>
          <Button
            onClick={leftButtonAction}
            label={buttonLeft ? buttonLeft.label : 'Cancel'}
            variant={buttonLeft ? buttonLeft.type : 'basic'}
            dataCY="modal-left-button"
          />
          <Button
            onClick={buttonRight.onClick}
            label={buttonRight.label}
            variant={buttonRight ? buttonRight.type : 'tertiary'}
            disabled={disabledButton}
            dataCY="modal-right-button"
          />
        </ButtonSection>
      </ManagerModalContainer>
    </ManagerModalWrapper>,
    document.body,
  );
};
