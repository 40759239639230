import { Select } from 'components/Select';
import { CalendarType } from '../types';
import { CalendarSwitchWrapper } from './styled';
import { CalendarSwitchProps } from './types';

export const CalendarSwitch = ({ selectedOption, setSelectedOption }: CalendarSwitchProps) => (
  <CalendarSwitchWrapper>
    <Select
      variant="outline"
      options={[CalendarType.Month, CalendarType.Week]}
      selectedOption={selectedOption}
      onOptionClicked={setSelectedOption}
      placeholder={selectedOption}
      dataCY="team-calendar-buttons-switch"
    />
  </CalendarSwitchWrapper>
);
