import { useHistory } from 'react-router';
import { SvgIconComponent } from 'components/Icons';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { HolidayProps } from 'services/HolidayService';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { themes } from 'styles/themes';
import Text from '../../../Text';
import { Arrow, ContainerHeader, Heading, RowHeading } from '../styled';

interface HeaderProps {
  leaveSelectedByEmployee: string | undefined;
  holiday: HolidayProps;
  employeeFullName: string;
  employeePosition: string;
}

export const Header = ({
  leaveSelectedByEmployee,
  holiday,
  employeeFullName,
  employeePosition,
}: HeaderProps) => {
  const history = useHistory();
  const { getColorByTheme } = useThemeContext();

  return (
    <ContainerHeader>
      <Arrow onClick={() => history.goBack()}>
        <SvgIconComponent
          color={getColorByTheme(themes.light.holidayForm.arrow, themes.dark.holidayForm.arrow)}
          icon="ArrowLeftSkinnyIcon"
          width={24}
        />
      </Arrow>

      <Heading>
        <Text.Heading
          type="6"
          color={getColorByTheme(
            themes.light.holidayForm.requestHeader,
            themes.dark.holidayForm.requestHeader,
          )}>
          {leaveSelectedByEmployee}
          {holiday.status === HolidayStatuses.Pending && ' - request'}
          {holiday.status === HolidayStatuses.Accepted && ' - accepted'}
          {holiday.status === HolidayStatuses.Declined && ' - reject'}
        </Text.Heading>

        <RowHeading>
          <Text.Heading
            type="2"
            color={getColorByTheme(
              themes.light.holidayForm.employeeFullName,
              themes.dark.holidayForm.requestHeader,
            )}>
            {employeeFullName}
          </Text.Heading>

          <Text.ButtonText type="regular" color={colors.gray[600]}>
            {!!employeePosition && `(${employeePosition})`}
          </Text.ButtonText>
        </RowHeading>
      </Heading>
    </ContainerHeader>
  );
};
