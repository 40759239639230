import styled from 'styled-components';

export const Container = styled.div`
  margin: 2.5rem 1.5rem 0 1.5rem;

  @media (min-width: 1550px) {
    margin: 2.5rem 5.125rem 0 5.125rem;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1550px) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;
