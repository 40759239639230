import { icons } from './icons';
import { SvgIconStyled } from './styled';
import { SvgIconComponentProps } from './types';

export function SvgIconComponent({
  icon,
  width,
  height,
  rotate,
  color,
  className,
}: SvgIconComponentProps) {
  return (
    <SvgIconStyled
      src={icons[icon]}
      className={className}
      rotate={rotate}
      width={width}
      height={height}
      color={color}
    />
  );
}
