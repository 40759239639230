export enum HolidayTypesEnums {
  Vacation = 'vacation',
  Sick = 'sick',
  OnDemand = 'on_demand',
  Kid = 'kid',
  Occasional = 'occasional',
  Holiday = 'holiday',
}

export enum AdditionalHolidaysFieeldsNamesEnumType {
  NAME = 'name',
  DAYS = 'days',
  YEAR = 'year',
}
