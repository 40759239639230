import styled, { css } from 'styled-components';
import { typography } from 'styles/typography';
import { Ellipsis } from './Ellipsis';

interface SharedStylesProp {
  color?: string;
  hoverColor?: string;
  margin?: string;
  weight?: number;
}
const sharedStyles = css<SharedStylesProp>`
  ${({ color }) => (color ? `color: ${color};` : '')};
  ${({ hoverColor }) => (hoverColor ? `&:hover { color: ${hoverColor};}` : '')};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')};
  ${({ weight }) => (weight ? `font-weight: ${weight};` : '')};
`;

const Display = styled.div<SharedStylesProp & { type?: keyof typeof typography.display }>`
  ${({ type }) => typography.display[type || '1']};
  ${sharedStyles};
`;

const Heading = styled.div<SharedStylesProp & { type?: keyof typeof typography.heading }>`
  ${({ type }) => typography.heading[type || '1']}
  ${sharedStyles};
`;

const Paragraphs = styled.div<
  SharedStylesProp & {
    type?: keyof typeof typography.paragraphs;
  }
>`
  ${({ type }) => typography.paragraphs[type || 'small']};
  ${sharedStyles};
`;

const ButtonText = styled.div<SharedStylesProp & { type?: keyof typeof typography.buttonFontSize }>`
  ${({ type }) => typography.buttonFontSize[type || 'small']};
  ${sharedStyles};
`;

const Text = {
  Display,
  Heading,
  Paragraphs,
  ButtonText,
  Ellipsis,
};

export default Text;
