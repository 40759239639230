import { createContext, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { useCalendarContext } from './CalendarContext';

export const updateRequestHeader = (year: string) => {
  axios.defaults.headers.common['x-current-year'] = year;
};

export const CurrentYearContext = createContext({});

interface CurrentYearProps {
  children?: ReactNode;
}

export const CurrentYearProvider = ({ children }: CurrentYearProps) => {
  const { date } = useCalendarContext();

  useEffect(() => {
    axios.defaults.headers.common['x-current-year'] = date.year().toString();
  }, [date]);

  return <CurrentYearContext.Provider value={''}>{children}</CurrentYearContext.Provider>;
};
