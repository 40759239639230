import { useHistory } from 'react-router-dom';
import { Button } from 'components/Button/ButtonBase';
import { HolidayProps } from 'services/HolidayService';
import { HolidayStatuses } from 'services/HolidaysService';
import { DeleteButton, MainButtons, RowButtons } from '../styled';

interface ButtonProps {
  holiday: HolidayProps;
  setShowRejectModal: VoidFunction;
  setShowCancelModal: VoidFunction;
  acceptHoliday: () => Promise<void>;
  showButtons: boolean;
  onDemandHolidays?: boolean;
  makingChanges?: boolean;
}

export const Buttons = ({
  holiday,
  setShowRejectModal,
  setShowCancelModal,
  acceptHoliday,
  showButtons,
  onDemandHolidays,
  makingChanges,
}: ButtonProps) => {
  const pedningHoliday = holiday.status === HolidayStatuses.Pending;
  const completedHoliday = holiday.completed;
  const history = useHistory();

  return (
    <>
      {pedningHoliday && (
        <MainButtons>
          <Button
            label="Reject"
            variant="tertiaryOutline"
            onClick={setShowRejectModal}
            dataCY="reject-button"
          />
          <Button
            label="Accept"
            variant="tertiary"
            onClick={acceptHoliday}
            dataCY="accept-button"
          />
        </MainButtons>
      )}

      {!showButtons && !pedningHoliday && (
        <DeleteButton>
          <Button
            label="Delete Holiday"
            variant="failWithoutBorder"
            dataCY="delete-button"
            onClick={setShowCancelModal}
          />
        </DeleteButton>
      )}

      {showButtons && !pedningHoliday && (
        <RowButtons>
          <DeleteButton>
            {!completedHoliday && (
              <Button
                label="Delete Holiday"
                variant="failWithoutBorder"
                dataCY="delete-button"
                onClick={setShowCancelModal}
              />
            )}
          </DeleteButton>
          <MainButtons>
            <Button
              label="Cancel"
              variant="tertiaryOutline"
              onClick={() => (makingChanges ? setShowCancelModal() : history.goBack())}
              dataCY="cancel-button"
            />
            <Button
              disabled={onDemandHolidays}
              label="Apply"
              variant="tertiary"
              onClick={acceptHoliday}
              dataCY="apply-button"
            />
          </MainButtons>
        </RowButtons>
      )}
    </>
  );
};
