import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { InputStyleProps } from './types';

export const StyledInput = styled.input<InputStyleProps>`
  background: ${colors.surface.primary};
  border: none;
  box-shadow: none;
  min-width: 15rem;

  ::placeholder {
    color: ${colors.gray[600]};
    ${({ variant: { size } }) => typography.paragraphs[size]}
  }

  :focus-visible {
    outline: none;
  }
`;

export const WrapInput = styled.div`
  min-width: 19rem;
  min-height: 2.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625em 0 1em;

  background: ${colors.surface.primary};
  border-radius: ${borderRadius.xs};
  border: 1px solid ${colors.gray[800]}50; //Added opacity to border.
  box-shadow: 0 0 1px ${colors.gray[800]}50;
`;

export const Wrap = styled.div``;
