import { HeaderComponentProps } from '../../types';
import { EmployeeTeamCalendarHeader } from '../EmployeeTeamCalendarHeader';
import { Header } from '../styled';
import { TeamCalendarHeader } from '../TeamCalendarHeader';

export const HeaderComponent = ({
  isManager,
  calendarType,
  setCalendarType,
  selectedDayStateWeekCalendar,
}: HeaderComponentProps) => {
  return (
    <Header>
      {isManager ? (
        <TeamCalendarHeader
          calendarType={calendarType}
          setCalendarType={setCalendarType}
          selectedDayState={selectedDayStateWeekCalendar}
        />
      ) : (
        <EmployeeTeamCalendarHeader calendarType={calendarType} />
      )}
    </Header>
  );
};
