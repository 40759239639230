// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { CONFIG } from 'const/config';

export interface PaginationMeta {
  currentPage: number;
  nextPage: number | null;
  prevPage: number | null;
  totalPages: number;
  totalCount: number;
}

export default class ApiBaseService {
  static readonly API_BASE_URL = CONFIG.API_URL;
}
