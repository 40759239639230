import { GetEmploymentTypesActions } from 'store/actions';
import { EmploymentTypesActionTypesEnum } from 'store/actionTypes';
import { EmploymentTypeStateInterfaceTypes } from 'types';

const initialState: EmploymentTypeStateInterfaceTypes = {
  isProgress: true,
  listData: [],
};

export type EmploymentTypesReducerType = ReturnType<typeof employmentTypesReducer>;
export const employmentTypesReducer = (
  state: EmploymentTypeStateInterfaceTypes = initialState,
  action: GetEmploymentTypesActions,
): EmploymentTypeStateInterfaceTypes => {
  const {
    EMPLOYMENT_TYPES_ACTION_REQUEST,
    EMPLOYMENT_TYPES_ACTION_RESPONSE,
    EMPLOYMENT_TYPES_ACTION_ERROR,
  } = EmploymentTypesActionTypesEnum;
  switch (action.type) {
    case EMPLOYMENT_TYPES_ACTION_REQUEST:
      return {
        ...state,
        isProgress: true,
      };
    case EMPLOYMENT_TYPES_ACTION_RESPONSE:
      return {
        listData: action.payload,
        isProgress: false,
      };
    case EMPLOYMENT_TYPES_ACTION_ERROR:
      return {
        ...state,
        isProgress: false,
      };
    default:
      return state;
  }
};
