import dayjs from 'dayjs';
import { DepartmentRolesInterfaceTypes } from 'types';

interface CheckProfileChangesOptions {
  newDepRole: DepartmentRolesInterfaceTypes | undefined;
  defaultDepartment: number | undefined;
  newDepartment: number | undefined;
  defaultRole: number | undefined;
  defaultStartDate: string;
  newStartDate: Date | null;
  defaultEndDate: string;
  newEndDate: Date | null;
  defaultPosition: string;
  newPosition: string | null;
  defaultSeniority: string | null;
  choosedSeniority: Date | string | null;
  defaultEmploymentType: string | undefined;
  newEmploymentType: string | undefined;
  defaultKids: string;
  newKids: Date | null;
}

export const checkProfileChanges = ({
  newDepRole,
  defaultDepartment,
  newDepartment,
  defaultRole,
  defaultStartDate,
  newStartDate,
  defaultEndDate,
  newEndDate,
  defaultPosition,
  newPosition,
  defaultSeniority,
  choosedSeniority,
  defaultEmploymentType,
  newEmploymentType,
  defaultKids,
  newKids,
}: CheckProfileChangesOptions) => {
  return (
    !newDepRole ||
    ((checkDepartment(defaultDepartment, newDepartment) &&
    checkDepartment(defaultRole, newDepRole?.id)
      ? true
      : false) &&
      checkDates(defaultStartDate, newStartDate) &&
      checkDates(defaultEndDate, newEndDate) &&
      checkEmploymentType(defaultEmploymentType, newEmploymentType) &&
      checkPosition(defaultPosition, newPosition) &&
      checkSeniority(defaultSeniority, choosedSeniority) &&
      checkDates(defaultKids, newKids))
  );
};

const checkDates = (defaultDate: string, newDate: Date | null) => {
  return (newDate === null ? newDate : dayjs(newDate).format('YYYY-MM-DD')) === defaultDate;
};

const checkDepartment = (
  defaultDepartment: number | undefined,
  newDepartment: number | undefined,
) => {
  return newDepartment === defaultDepartment;
};

const checkEmploymentType = (
  defaultEmploymentType: string | undefined,
  newEmploymentType: string | undefined,
) => {
  return newEmploymentType === defaultEmploymentType;
};

const checkPosition = (defaultPosition: string | null, newPosition: string | null) => {
  return (
    newPosition === defaultPosition ||
    (!newPosition?.replace(/\s/g, '').length && newPosition !== '')
  );
};

const checkSeniority = (
  defaultSeniority: Date | string | null,
  choosedSeniority: Date | string | null,
) => {
  return (
    dayjs(choosedSeniority).format('YYYY-MM-DD') === dayjs(defaultSeniority).format('YYYY-MM-DD')
  );
};
