// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { initialPagination } from 'contexts/EmployeesContext';
import ApiBaseService, { PaginationMeta } from './ApiBaseService';
import { Access } from './AuthService';

export interface EmployeeHolidaySummary {
  total: number;
  used: number;
  available: number;
  onDemand: number;
  onDemandTotal: number;
  sick: number;
  requested: number;
  left: number;
  userId: number;
}

export interface EmploymentType {
  key: string;
  value: string;
}

export interface AdditionalHolidaUpdateTypes {
  id?: number;
  holidayType?: string;
  days?: number;
  hours?: number | null;
  month?: number | null;
  year?: number;
  name?: string | null;
  _destroy?: number;
  localId?: string;
  createdAt?: string;
  updatedAt?: string;
  repeatEveryYear?: boolean;
}

export interface AdditionalHolidayTypes {
  id: number;
  holidayType: string;
  days: number;
  hours: number | null;
  month: number | null;
  year: number;
  name: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface Employee {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  employedFrom: string;
  employedTo: string;
  active: boolean;
  organization: string;
  avatar: string;
  dateOfBirth: string;
  contractWithoutTimeLimit: boolean;
  seniorityStartDate: string | null;
  position: string;
  employmentType: EmploymentType | null;
  holiday?: EmployeeHolidaySummary;
  createdAt: string;
  updatedAt: string;
  departmentRole: {
    id: number;
    name: string;
    access: Access;
    departmentId: number;
  } | null;
  userSource: {
    id: number;
    name: string;
  };
  kidDateOfBirth: string;
  additionalHolidays: AdditionalHolidayTypes[];
}

export interface GetEmployeesParams {
  page?: number;
  per?: number;
  by_active?: boolean;
  by_department_id?: number;
  query?: string;
}

export interface FetchEmployeesResponseType {
  data: Employee[];
  meta: PaginationMeta;
  error?: Error;
}

export interface EmployeeUpdateEmployment {
  email?: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  seniorityStartDate?: string | null;
  employedFrom?: string;
  employedTo?: string | null;
  contractWithoutTimeLimit?: boolean;
  position?: string | null;
  employmentType?: string;
  departmentRoleId?: number;
  userSourceId?: number;
  dateOfBirth?: string | null;
  kidDateOfBirth?: string | null;
  additionalHolidaysAttributes?: AdditionalHolidaUpdateTypes[];
}

export interface FetchEmployeeHolidayRequestsProps {
  'date'?: string;
  'date[from]'?: string;
  'date[to]'?: string;
  'view'?: 'month' | 'week';
}

export default class EmployeesService extends ApiBaseService {
  static readonly API_URL = this.API_BASE_URL + '/users';

  // TODO find a better way to handle errors
  static fetchEmployees = async (params?: GetEmployeesParams) => {
    try {
      const { data } = await axios.get<FetchEmployeesResponseType>(this.API_URL, { params });
      return data;
    } catch (error) {
      return {
        data: [],
        meta: initialPagination,
        error,
      } as FetchEmployeesResponseType;
    }
  };

  static fetchEmployeeById = async (id: string | number) => {
    const { data } = await axios.get<{ data: Employee }>(`${this.API_URL}/${id}`);
    return data.data;
  };

  static fetchEmployeeByIdOnCurrentYear = async (id: string, currentYear: string) => {
    const { data } = await axios.get<{ data: Employee }>(`${this.API_URL}/${id}`, {
      headers: {
        'x-current-year': currentYear,
      },
    });
    return data.data;
  };

  static updateUserData = async (id: number, user: EmployeeUpdateEmployment) => {
    const { data } = await axios.put<{ data: Employee }>(`${this.API_URL}/${id}`, { user });
    return data.data;
  };

  static activateUserAccount = async (id: number) => {
    const { data } = await axios.put(`${this.API_URL}/${id}/activate`);
    return data.data;
  };

  static deactivateUserAccount = async (id: number) => {
    const { data } = await axios.put(`${this.API_URL}/${id}/deactivate`);
    return data.data;
  };

  static changeUserPassword = async (
    id: number,
    password: string,
    passwordConfirmation: string,
  ) => {
    const { data } = await axios.put(`${this.API_URL}/${id}/password`, {
      user: { password, passwordConfirmation },
    });

    return data.data;
  };

  static fetchEmployeeHolidayRequests = async (
    id: number | string,
    params: FetchEmployeeHolidayRequestsProps,
  ) => {
    const { data } = await axios.get(`${this.API_URL}/${id}/holiday_requests`, {
      params,
    });

    return data.data;
  };
}
