import styled, { css } from 'styled-components';
import { DepartmentTile } from 'components/DepartmentTile';
import { SvgIconComponent } from 'components/Icons';
import { Checkbox } from 'components/Switches/Checkbox';
import Text from 'components/Text';
import { TileWrapper } from 'components/TileWrapper';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { getHolidayColorByTypeOnManagerView } from 'utils';
import { IconType } from '../../../components/Icons/types';
import { HolidayRowProps } from '../types';

export const Departments = styled(DepartmentTile)`
  background-color: ${({ theme }) => theme.backgroundElement};
  margin: 0 0 1rem 1rem;
  border-radius: ${borderRadius.sm};
  position: fixed;
  grid-area: Sidebar;
  max-width: 20rem;
  overflow-y: auto;
`;

export const ReportWrapper = styled(TileWrapper)`
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  margin: 0 1rem 1rem 0;
`;

export const HolidaysWrapper = styled(TileWrapper)`
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  margin: 0 1rem 1rem 0;
`;

export const CapacityWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  margin: 0 1rem 1rem 0;
  padding: 2em 2em 2em 2em;
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 1.3rem 0;
`;

export const Wrap = styled.div`
  margin: 0.5rem 0;
`;

export const CapacityContentWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: start;
`;

export const ChooseDateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 4.75rem;
  padding: 1em 0 1em 0;

  > :first-child {
    width: 8.125rem;
  }

  > :nth-child(2) {
    width: 17.5rem;
    margin-left: 1rem;
  }
`;

export const CapacityLeftSide = styled.div`
  flex: 3;
`;

export const HolidayTypesListItem = styled.li`
  display: flex;
  margin-bottom: 0.25rem;
  align-items: center;
`;

export const Dot = styled.div<{ color?: string }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 0.5rem;
`;

export const CapacityRightSide = styled.div`
  flex: 2;
`;

export const NameWrapper = styled.div`
  /* width: 11.25rem; -> This value is consistent with Figma but I change this for better view for longest holiday type "Urlop za święta wypadające w sobotę" */
  min-width: 14rem;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
`;

export const StyledSvgIconComponent = styled(SvgIconComponent)<{ variant: IconType }>`
  position: absolute;
  right: -0.5rem;
  bottom: -0.25rem;
  ${({ variant }) => iconStyles[variant]}
`;

const iconStyles = {
  vacation: css`
    color: ${({ theme }) => theme.icon.vacation};
  `,
  holiday: css`
    color: ${({ theme }) => theme.icon.vacation};
  `,
  on_demand: css`
    color: ${({ theme }) => theme.icon.vacation};
  `,
  occasional: css`
    color: ${({ theme }) => theme.icon.vacation};
  `,
  sick: css`
    color: ${({ theme }) => theme.icon.sick};
  `,
  kid: css`
    color: ${({ theme }) => theme.icon.kid};
  `,
};

export const Border = styled.div<{ avatarStyle: HolidayRowProps['holiday']['holidayType'] }>`
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid ${({ avatarStyle }) => getHolidayColorByTypeOnManagerView(avatarStyle)};
`;

export const AvatarWrapper = styled.div`
  position: relative;
  width: 2rem;

  img {
    object-fit: cover;
  }
`;

export const StyledIconEllipsis = styled(SvgIconComponent)`
  display: flex;
`;

export const StyledIconCheck = styled(SvgIconComponent)`
  margin-right: 1.5rem;
`;

export const StyledIconCross = styled(SvgIconComponent)`
  margin-right: 1.625rem;
`;

export const Employee = styled.div`
  display: grid;
  grid-template-columns: 3.125rem 12.5rem 11rem 10rem 8rem 7rem 1fr auto;
  min-height: 3.5rem;
  padding: 1em;
  margin: 1rem -16px;
  box-sizing: border-box;
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  position: relative;

  :hover {
    background-color: ${({ theme }) => theme.table.hover};
    border-radius: ${borderRadius.xs};

    .icons {
      opacity: 1;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  position: relative;
  width: fit-content;
`;

export const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HolidaysFilters = styled.div`
  display: flex;
  color: ${({ theme }) => theme.dashboard.text};
  background-color: ${({ theme }) => theme.dashboard.filterBtnWrapper};
  border-radius: ${borderRadius.xs};
  padding: 0.125em 0.2em;
  box-sizing: border-box;
  height: 36px;
  align-items: center;
`;

export const FilterButton = styled.div<{ active: boolean }>`
  padding: 0.625em 1em;
  box-shadow: ${({ active }) => {
    return active
      ? '0px 0px 1px rgba(46, 51, 56, 0.2), 0px 4px 20px rgba(46, 51, 56, 0.08)'
      : 'none';
  }};
  text-transform: capitalize;
  font: ${typography.paragraphs.caption};
  border-radius: ${borderRadius.xs};
  cursor: pointer;
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.text[900]};
      background-color: ${({ theme }) => theme.dashboard.filterBtn};
    `}

  ${({ active }) =>
    !active &&
    css`
      &:not(:first-child)::before {
        content: '';
        display: block;
        width: 0.05rem;
        height: 1rem;
        background-color: ${({ theme }) => theme.dashboard.filterBtnBorderColor};
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    `}
`;

export const CapacityBalanceText = styled(Text.Paragraphs)`
  color: ${({ theme }) => theme.text[800]};
`;

export const CapacityBalanceHeader = styled(Text.Heading)`
  color: ${({ theme }) => theme.text[800]};
`;

export const DepartmentWithoutHoliday = styled.div`
  height: 6.25rem;
  padding-top: 2rem;
  width: 100%;

  display: grid;
  justify-content: center;
  align-content: center;

  border-top: 1px solid ${({ theme }) => theme.button.basic.borderColor};
  background-color: ${({ theme }) => theme.backgroundElement};
  border-bottom-left-radius: ${borderRadius.sm};
  border-bottom-right-radius: ${borderRadius.sm};

  ${typography.heading[5]};
  color: ${({ theme }) => theme.text[500]};
`;

export const TextTypeOfHoliday = styled(Text.Paragraphs)<{
  holidayColor: HolidayRowProps['holiday']['holidayType'];
}>`
  color: ${({ holidayColor }) => getHolidayColorByTypeOnManagerView(holidayColor)};
`;
