import { DepartmentRolesActionTypesEnum as CONSTANTS } from 'store/actionTypes';
import { DepartmentRolesInterfaceTypes } from 'types';

const {
  DEPARTMENT_ROLES_ACTION_REQUEST,
  DEPARTMENT_ROLES_ACTION_RESPONSE,
  DEPARTMENT_ROLES_ACTION_ERROR,
} = CONSTANTS;

export interface GetDepartmentRolesRequestAction {
  type: CONSTANTS.DEPARTMENT_ROLES_ACTION_REQUEST;
}

export const getDepartmentRolesRequest = (): GetDepartmentRolesRequestAction => ({
  type: DEPARTMENT_ROLES_ACTION_REQUEST,
});

export interface GetDepartmentRolesResponseAction {
  type: CONSTANTS.DEPARTMENT_ROLES_ACTION_RESPONSE;
  payload: DepartmentRolesInterfaceTypes[];
}

export const getDepartmentRolesResponse = (
  payload: DepartmentRolesInterfaceTypes[],
): GetDepartmentRolesResponseAction => ({
  type: DEPARTMENT_ROLES_ACTION_RESPONSE,
  payload,
});

export interface GetDepartmentRolesErrorAction {
  type: CONSTANTS.DEPARTMENT_ROLES_ACTION_ERROR;
}

export const getDepartmentRolesError = (): GetDepartmentRolesErrorAction => ({
  type: DEPARTMENT_ROLES_ACTION_ERROR,
});

export type GetDepartmentRolesActions =
  | GetDepartmentRolesRequestAction
  | GetDepartmentRolesResponseAction
  | GetDepartmentRolesErrorAction;
