import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import { Type, Variant } from 'components/AlertComponent/types';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { RejectHolidayModal } from 'components/Modals';
import Text from 'components/Text';
import { Dot } from 'components/UserLoggedHeader/styled';
import { useToastContext } from 'contexts/ToastContext';
import { routes } from 'routes';
import { ApiError } from 'services/AuthService';
import HolidayService from 'services/HolidayService';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { IconType } from '../../../components/Icons/types';
import { useThemeContext } from '../../../components/ThemeWrapper/ThemeWrapper';
import { HolidayRowProps, holidayIcon } from '../types';
import { getHolidayTimeSpan } from './helpers';
import {
  AvatarWrapper,
  Border,
  Column,
  Employee,
  Icons,
  Row,
  StyledIconEllipsis,
  StyledIconCheck,
  StyledIconCross,
  StyledSvgIconComponent,
  TextTypeOfHoliday,
} from './styled';

export const HolidayRow = ({
  holiday: {
    user: { id: userId, lastName, firstName, avatar, employmentType, position, department },
    createdAt,
    holidayType,
    id: holidayId,
    dateFrom,
    dateTo,
    status,
  },
  employmentTypes,
  refetch,
}: HolidayRowProps) => {
  const [messageToRejectHolidayRequest, setMessageToRejectHolidayRequest] = useState('');
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const history = useHistory();
  const { handleError, setToast } = useToastContext();
  const { getColorByTheme } = useThemeContext();

  const employmentTypeUser = useMemo(
    () => employmentTypes?.find((empType) => empType.key === employmentType)?.value || '',
    [employmentTypes, employmentType],
  );

  const holidayTypeUser = useMemo(
    () =>
      employmentTypes
        ?.flatMap((empType) => empType.holiday_types)
        .find((hType) => hType.key === holidayType)?.value || '',
    [employmentTypes, holidayType],
  );

  const visibleDropdownMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setShowDropdownMenu(!showDropdownMenu);
    },
    [showDropdownMenu],
  );

  const visibleRejectModal = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowRejectModal(true);
  }, []);

  const rejectHoliday = async () => {
    try {
      await HolidayService.rejectHolidayById(holidayId.toString(), {
        reason: messageToRejectHolidayRequest,
      });
      refetch();
      setShowRejectModal(false);
      history.push(routes.dashboard);
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been rejected.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  const acceptHoliday = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    try {
      await HolidayService.acceptHolidayById(holidayId);
      refetch();
      history.push(routes.dashboard);
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been accepted.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  const colorGrayParagraph = getColorByTheme(colors.gray[600], colors.gray[500]);
  const colorDarkGrayParagraph = getColorByTheme(colors.gray[800], colors.white);

  return (
    <>
      <Employee
        onClick={() => history.push(`${routes.team}/${userId}`)}
        data-cy="dashboard-holidays-employee-row">
        <Column>
          <AvatarWrapper>
            <Border avatarStyle={holidayType}>
              <Avatar name={`${lastName} ${firstName}`} size="32" round={true} src={avatar} />
            </Border>
            <StyledSvgIconComponent
              variant={holidayType as IconType}
              icon={holidayIcon[holidayType as keyof typeof holidayIcon]}
              width={18}
            />
          </AvatarWrapper>
        </Column>

        <Column>
          <Text.ButtonText type="regular" color={colorDarkGrayParagraph}>
            {lastName} {firstName}
          </Text.ButtonText>

          <Text.Paragraphs type="xSmall" color={colorGrayParagraph}>
            <Row style={{ whiteSpace: 'nowrap' }}>
              {position}
              <Dot />
              {department}
            </Row>
          </Text.Paragraphs>
        </Column>

        <Column>
          <Text.Paragraphs type="caption" color={colorGrayParagraph} style={{ marginBottom: 4 }}>
            Type of holiday
          </Text.Paragraphs>
          <TextTypeOfHoliday type="caption" holidayColor={holidayType}>
            <Row>{holidayTypeUser}</Row>
          </TextTypeOfHoliday>
        </Column>

        <Column>
          <Text.Paragraphs type="caption" color={colorGrayParagraph} style={{ marginBottom: 4 }}>
            Holiday time
          </Text.Paragraphs>

          <Text.Paragraphs type="xSmall" color={colorDarkGrayParagraph}>
            <Row>{getHolidayTimeSpan(dateFrom, dateTo, 'YYYY.MM.DD')}</Row>
          </Text.Paragraphs>
        </Column>

        <Column>
          <Text.Paragraphs type="caption" color={colorGrayParagraph} style={{ marginBottom: 4 }}>
            Contract
          </Text.Paragraphs>

          <Text.Paragraphs type="xSmall" color={colorDarkGrayParagraph}>
            <Row>{employmentTypeUser}</Row>
          </Text.Paragraphs>
        </Column>

        <Column>
          <Text.Paragraphs type="caption" color={colorGrayParagraph} style={{ marginBottom: 4 }}>
            Department
          </Text.Paragraphs>

          <Text.Paragraphs type="xSmall" color={colorDarkGrayParagraph}>
            <Row>{department ? department : 'Tribe A'}</Row>
          </Text.Paragraphs>
        </Column>

        <Column>
          <Text.Paragraphs type="caption" color={colorGrayParagraph} style={{ marginBottom: 4 }}>
            Date of application
          </Text.Paragraphs>

          <Text.Paragraphs type="xSmall" color={colorGrayParagraph}>
            <Row>{dayjs(createdAt).format('dddd D MMM')}</Row>
          </Text.Paragraphs>
        </Column>
        <Column>
          <Icons className="icons" data-cy="employee-row-actions">
            {status === HolidayStatuses.Pending && (
              <>
                <div onClick={acceptHoliday} data-cy="employee-row-accept-holiday">
                  <StyledIconCheck
                    icon="CheckIcon"
                    color={getColorByTheme(colors.dark.tertiary, colors.tertiary)}
                    width={12}
                  />
                </div>
                <div onClick={visibleRejectModal} data-cy="employee-row-reject-holiday">
                  <StyledIconCross
                    icon="CrossMarkIcon"
                    color={getColorByTheme(colors.dark.fail, colors.fail)}
                    width={12}
                  />
                </div>
                <div onClick={visibleDropdownMenu} data-cy="employee-row-dropdown-menu-button">
                  <StyledIconEllipsis
                    color={getColorByTheme(colors.gray[700], colors.white)}
                    icon="EllipsisIcon"
                    width={24}
                  />
                </div>
              </>
            )}

            {status !== HolidayStatuses.Pending && (
              <div onClick={visibleDropdownMenu}>
                <StyledIconEllipsis icon="EllipsisIcon" width={24} />
              </div>
            )}

            {showDropdownMenu && <DropdownMenu userId={userId} />}
          </Icons>
        </Column>
      </Employee>

      {showRejectModal && (
        <RejectHolidayModal
          isShowing={showRejectModal}
          subtitleWithProps={{
            fullName: `${lastName} ${firstName}`,
            startDate: `${dateFrom}`,
            endDate: `${dateTo}`,
            action: 'reject',
          }}
          rightButtonAction={rejectHoliday}
          leftButtonAction={() => setShowRejectModal(false)}
          closeIcon={() => setShowRejectModal(false)}
          setMessage={(message) => setMessageToRejectHolidayRequest(message)}
          message={messageToRejectHolidayRequest}
        />
      )}
    </>
  );
};
