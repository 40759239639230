import styled, { css } from 'styled-components';

export const Ellipsis = styled.div<{ centerText?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ centerText }) =>
    centerText &&
    css`
      text-align: center;
    `}
`;
