import { FC, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { isHoliday } from 'poland-public-holidays';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { useUserContext } from 'contexts/UserContext';
import { colors } from 'styles/colors';
import { PublicHolidayBar } from '../PublicHolidayBar';
import {
  StyledDayContainer,
  ShowTodayDay,
  PositionShowTodayDay,
  NameDayHeader,
  DayCenter,
  Wrap,
  WrapChildren,
} from './styled';
import { DayContainerProps } from './types';

export const DayContainer: FC<DayContainerProps> = ({
  children,
  day,
  teamHolidays,
  firstColumn,
  withDaysNames,
  onClick,
  disableCursor,
  setWeekHasHoliday,
  weekHasHoliday,
  showClickedDay,
  setOpenNewRequestModal,
}) => {
  const { isManager } = useUserContext();
  const isToday = dayjs().isSame(day, 'day');
  const [isNewRequestModalVisible, toggleNewRequestModal] = useModal();
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (!weekHasHoliday && isHoliday(new Date(day.toString()))) {
      setWeekHasHoliday(true);
    }
  }, [day, setWeekHasHoliday, weekHasHoliday]);

  const handleToggleNewRequestModal = useCallback(() => {
    if (teamHolidays) {
      if (isManager) toggleNewRequestModal();
    } else {
      setOpenNewRequestModal && setOpenNewRequestModal();
    }
  }, [isManager, toggleNewRequestModal, setOpenNewRequestModal, teamHolidays]);

  const dateForSingleDay = dayjs(day).format('YYYY-MM-DD');
  const dayOfWeek = dayjs(day).day();

  return (
    <>
      <StyledDayContainer
        disableCursor={disableCursor}
        firstRow={withDaysNames}
        firstColumn={firstColumn}
        onClick={onClick}
        showClickedDay={isNewRequestModalVisible || showClickedDay}
        data-cy={`calendar-day-container-${dateForSingleDay}-day-of-week-${dayOfWeek}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        {withDaysNames && (
          <NameDayHeader
            onClick={handleToggleNewRequestModal}
            type="xSmall"
            color={colors.gray[600]}>
            {day.format('dddd')}
          </NameDayHeader>
        )}
        <PositionShowTodayDay
          onClick={handleToggleNewRequestModal}
          type="small"
          margin={'0 0 0.1rem 0'}>
          <Wrap>
            <DayCenter>
              <ShowTodayDay
                isToday={isToday}
                isHover={isHover}
                {...(isToday && { id: 'isTodayOnLangeCalendar' })}>
                {day.format('D')}
              </ShowTodayDay>
            </DayCenter>
            <PublicHolidayBar date={day} />
          </Wrap>
        </PositionShowTodayDay>

        <WrapChildren weekHasHoliday={weekHasHoliday}>{children}</WrapChildren>
      </StyledDayContainer>

      {teamHolidays && isNewRequestModalVisible && (
        <NewRequestHolidayModal
          isVisible={isNewRequestModalVisible}
          toggle={toggleNewRequestModal}
          withEmployeeDropdown
          employmentType={null}
          startDate={day.toDate()}
          buttonProps={{ variant: 'tertiary', label: 'Add holiday' }}
        />
      )}
    </>
  );
};
