import styled, { css } from 'styled-components';
import { SvgIconComponent } from 'components/Icons';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { ButtonStyleProps, FixPaddingLeftIcon, FixPaddingsStyleProps } from './types';

export const StyledButton = styled.button<ButtonStyleProps>`
  border: 0;
  border-radius: ${borderRadius.xs};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  ${({ size }) => buttonStyles.sizes[size]}
  ${({ variant }) => buttonStyles.variants[variant]}
  ${({ size }) => typography.buttonFontSize[size]}

  ${({ activeHover }) =>
    activeHover &&
    css`
      &:hover {
        color: ${({ theme }) => theme.button.hover.text};
        background-color: ${({ theme }) => theme.button.hover.background};

        -webkit-transition: background-color 300ms linear;
        -moz-transition: background-color 300ms linear;
        -o-transition: background-color 300ms linear;
        -ms-transition: background-color 300ms linear;
        transition: background-color 300ms linear;
      }
    `}

  ${({ hideBorder }) =>
    hideBorder &&
    css`
      border-color: transparent;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.button.disabled.background};
      color: ${({ theme }) => theme.button.disabled.text};
      cursor: default;
    `}
    
  ${({ departmentButton }) =>
    departmentButton &&
    css`
      background-color: transparent;
    `}
`;

export const Wrap = styled.div<{ departmentButton?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: ${({ departmentButton }) => (departmentButton ? 'space-between' : 'center')};
  flex-grow: 1;
  max-width: 100%;
`;

export const LabelWrapper = styled.div<{
  threeDotsForLongWord?: boolean;
}>`
  ${({ threeDotsForLongWord }) =>
    threeDotsForLongWord &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
`;

export const FixPaddings = styled.div<FixPaddingsStyleProps>`
  ${({ size }) => fixPaddingsStyled.sizes[size]}
  display: flex;

  ${({ threeDotsForLongWord }) =>
    threeDotsForLongWord &&
    css`
      width: 80%;
    `}
`;

export const StyledIconLeft = styled(SvgIconComponent)<FixPaddingLeftIcon>`
  ${({ size }) => fixPaddingLeftIcon.sizes[size]}
`;

const fixPaddingLeftIcon = {
  sizes: {
    small: css`
      padding-right: 6px;
    `,
    regular: css`
      padding-right: 8px;
    `,
    large: css`
      padding-right: 10px;
    `,
  },
};

const fixPaddingsStyled = {
  sizes: {
    small: css`
      padding: 0.5rem 0.375rem;
    `,
    regular: css`
      padding: 0.75rem 0.5rem;
    `,
    large: css`
      padding: 1rem 0.625rem;
    `,
  },
};

const buttonStyles = {
  sizes: {
    small: css`
      min-width: 5.5rem;
      min-height: 1.75rem;
    `,
    regular: css`
      min-width: 7.125rem;
      min-height: 2.25rem;
    `,
    large: css`
      min-width: 11.125rem;
      min-height: 3rem;
    `,
  },
  variants: {
    primary: css`
      background-color: ${colors.primary};
      color: ${colors.black};
    `,
    secondary: css`
      color: ${colors.dark.primary};
      background-color: transparent;
      border: 1px solid ${colors.dark.primary};
    `,
    tertiary: css`
      background-color: ${({ theme }) => theme.button.tertiary.background};
      color: ${({ theme }) => theme.button.tertiary.text};
      svg {
        color: ${({ theme }) => theme.button.tertiary.text};
      }
    `,
    basic: css`
      background-color: ${({ theme }) => theme.button.basic.background};
      color: ${({ theme }) => theme.button.basic.text};
      border: 1px solid ${({ theme }) => theme.button.basic.borderColor};
    `,
    modalAlert: css`
      background-color: ${colors.modalAlert};
      color: ${colors.white};
      border: 1px solid ${colors.modalAlert};
      box-shadow: 0 0 1px ${colors.gray[800]}50;
    `,
    fail: css`
      color: ${colors.white};
      background-color: ${colors.fail};
      border: 1px solid ${colors.light.fail};
    `,
    failWithoutBorder: css`
      color: ${colors.dark.fail};
      background-color: transparent;
    `,
    strongFail: css`
      color: ${colors.white};
      background-color: ${colors.strongFail};
      border: 1px solid ${colors.strongFail};
    `,
    tertiaryOutline: css`
      color: ${({ theme }) => theme.button.tertiaryOutline.text};
      background-color: ${({ theme }) => theme.button.tertiaryOutline.background};
      border: 1px solid ${({ theme }) => theme.button.tertiaryOutline.borderColor};
    `,
    success: css`
      color: ${colors.white};
      background-color: ${colors.success};
      border: 1px solid ${colors.success};
    `,
  },
};
