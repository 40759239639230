import styled from 'styled-components';
import { AlertComponent as AlertComponentUI } from 'components/AlertComponent';

export const AlertComponentWrapper = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
`;

export const AlertComponent = styled(AlertComponentUI)`
  position: fixed;
  width: 30rem;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 2;
`;
