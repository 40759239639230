import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { Type, Variant } from 'components/AlertComponent/types';
import { EmployeesTile } from 'components/EmployeesTile';
import { SearchBar } from 'components/Inputs/SearchBar';
import { Spinner } from 'components/Spinner';
import { useEmployeeContext } from 'contexts/EmployeesContext';
import { useToastContext } from 'contexts/ToastContext';
import { PaginationMeta } from 'services/ApiBaseService';
import DepartmentsService, { Department } from 'services/DepartmentsService';
import EmployeesService, { Employee, GetEmployeesParams } from 'services/EmployeesService';
import { Departments } from 'views/Dashboard/components/styled';
import { Main, StyledNavbar, Wrapper } from 'views/Dashboard/styled';

export const Team = () => {
  const { setToast } = useToastContext();
  const { selectedDepartmentApiId } = useEmployeeContext();

  const [searchValue, setSearchValue] = useState<string>();
  const [isFetching, setFetching] = useState(false);
  const [employeesLoading, setEmployeeLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>();

  const { isLoading: departmentsLoading, data: departments } = useQuery<Department[], Error>(
    'departments',
    DepartmentsService.fetchDepartments,
  );

  const fetchEmployees = useCallback(
    async (params: GetEmployeesParams) => {
      try {
        setEmployeeLoading(true);
        if (params.page) {
          setFetching(true);
        }
        const { data, meta: m } = await EmployeesService.fetchEmployees(params);
        setMeta(m);
        if (params.page) {
          setEmployees((prev) => [...prev, ...data]);
        } else {
          setEmployees(data);
        }
      } catch (error) {
        setToast({
          variant: Variant.fail,
          type: Type.outline,
          iconInfo: 'CrossMarkIcon',
          actionIcon: 'CrossMarkIcon',
          label: `An error occurred: ${error}`,
        });
      } finally {
        setFetching(false);
        setEmployeeLoading(false);
      }
    },
    [setToast],
  );

  const debouncedFetchEmployees = useMemo(
    () =>
      debounce((val) => {
        fetchEmployees(val);
      }, 300),
    [fetchEmployees],
  );

  useEffect(() => {
    debouncedFetchEmployees({ by_department_id: selectedDepartmentApiId, query: searchValue });
  }, [selectedDepartmentApiId, searchValue, debouncedFetchEmployees]);

  const handleNextPage = async () => {
    if (meta?.nextPage && !isFetching) {
      fetchEmployees({
        by_department_id: selectedDepartmentApiId,
        query: searchValue,
        page: meta.nextPage,
      });
    }
  };

  const onSearchPress = () => {
    fetchEmployees({ by_department_id: selectedDepartmentApiId, query: searchValue });
  };

  const loading = useMemo(
    () => (isFetching ? false : employeesLoading || departmentsLoading),
    [isFetching, employeesLoading, departmentsLoading],
  );

  return (
    <>
      <StyledNavbar />
      <Wrapper>
        <Departments
          departments={departments || []}
          activeDepartmentId={selectedDepartmentApiId || null}
        />

        <Main>
          <SearchBar
            value={searchValue}
            onChangeText={setSearchValue}
            onSearchPress={onSearchPress}
          />

          {loading ? (
            <Spinner fullPage />
          ) : (
            <EmployeesTile
              employees={employees}
              departments={departments || []}
              handleNextPage={handleNextPage}
              hasMorePages={!!meta?.nextPage}
            />
          )}
        </Main>
      </Wrapper>
    </>
  );
};
