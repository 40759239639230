import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AdditionalHoliday } from 'components';
import { Type, Variant } from 'components/AlertComponent/types';
import { EmploymentComponent } from 'components/EmploymentComponent/EmploymentComponent';
import { HolidayTile } from 'components/HolidayTile/HolidayTile';
import { PersonalDetailsTile } from 'components/PersonalDetailsTile';
import { SingleEmployeeHeader } from 'components/SingleEmployeeHeader';
import { Spinner } from 'components/Spinner';
import { TileWrapper } from 'components/TileWrapper';
import { useDepartmentContext } from 'contexts/DepartmentsContext';
import { useToastContext } from 'contexts/ToastContext';
import { useUserContext } from 'contexts/UserContext';
import { getSortedDepartamentList } from 'helpers/getSortedDepartamentList';
import { useHolidays } from 'hooks';
import { ApiError } from 'services/AuthService';
import EmployeesService, { Employee, EmployeeUpdateEmployment } from 'services/EmployeesService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { StyledLayout, Container } from 'views/ProfileLoggedAccount/styled';
import { useSingleEmployee } from '../../hooks';
import { routes } from '../../routes';
import { useHolidayFooter } from './hooks';
import {
  ColumnWrapper,
  ProfileUserContent,
  Button,
  DeactivateModal,
  TextInModal,
  ActivateModal,
} from './styled';

type TeamURLParams = {
  id: string;
};

export const SingleEmployeeProfile = () => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest, getDepartmentRolesRequest } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const departmentRolesStore = useSelector((state: RootReducer) => state.departmentRoles);
  const { id } = useParams<TeamURLParams>();
  const { getUser } = useUserContext();
  const { setToast, handleError } = useToastContext();
  const { departments, isLoading } = useDepartmentContext();
  const [singleEmployee, setSingleEmployee] = useState<Employee>();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [accountIsActive, setAccountIsActive] = useState<boolean>();

  const idManager = `${getUser().id}`;
  const loggedUserIsManager = idManager === id;

  const { employee, refetchWithYear: refetchSingleEmployee } = useSingleEmployee(
    id,
    new Date().getFullYear().toString(),
  );

  const onEmploymentUpdate = async (user: EmployeeUpdateEmployment) => {
    const updatedUser = await EmployeesService.updateUserData(Number(id), user);
    setSingleEmployee(updatedUser);
  };

  useEffect(() => {
    refetchSingleEmployee();
  }, [employee, id]);

  const { holidays } = useHolidays(id);
  const { footer } = useHolidayFooter({ employee, holidays });

  const reloadWindow = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    setSingleEmployee(employee);
  }, [employee, holidays, id]);

  useEffect(() => {
    if (!departmentRolesStore.listData.length) {
      getDepartmentRolesRequest();
    }
  }, []);

  useEffect(() => {
    setAccountIsActive(singleEmployee && singleEmployee.active);
  }, [singleEmployee]);

  if (
    !singleEmployee ||
    isLoading ||
    departmentRolesStore.isProgress ||
    employmentTypesStore.isProgress
  ) {
    return <Spinner fullPage />;
  }

  const deactivateAccount = async () => {
    try {
      await EmployeesService.deactivateUserAccount(Number(id));
      setShowDeactivateModal(false);
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Account is deactivate.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      reloadWindow();
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  const activateAccount = async () => {
    try {
      await EmployeesService.activateUserAccount(Number(id));
      setShowActiveModal(false);
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Account is activate!',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      reloadWindow();
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  const sortedDeps = !!departments ? getSortedDepartamentList(departments) : [];

  return (
    <StyledLayout>
      <Container>
        <SingleEmployeeHeader employee={singleEmployee} navigateBackLink={routes.team} />
        <ProfileUserContent>
          <ColumnWrapper>
            <PersonalDetailsTile
              employee={singleEmployee}
              onEmploymentUpdate={onEmploymentUpdate}
            />

            {accountIsActive ? (
              <TileWrapper
                title={'Deactivate account'}
                subtitle={
                  loggedUserIsManager
                    ? 'You cannot deactivate your account'
                    : 'You can activate it anytime'
                }>
                <Button
                  label={'Deactivate account'}
                  variant={'strongFail'}
                  type={'solid'}
                  onClick={() => {
                    setShowDeactivateModal(true);
                  }}
                  dataCY="team-deactivate-account-button"
                  disabled={loggedUserIsManager}
                  hideBorder={loggedUserIsManager}
                />
              </TileWrapper>
            ) : (
              <TileWrapper
                title={'Activate account'}
                subtitle={
                  loggedUserIsManager
                    ? 'You cannot activate your account'
                    : 'You can deactivate it anytime'
                }>
                <Button
                  label={'Activate account'}
                  variant={'success'}
                  type={'solid'}
                  onClick={() => {
                    setShowActiveModal(true);
                  }}
                  dataCY="team-activate-account-button"
                  disabled={loggedUserIsManager}
                  hideBorder={loggedUserIsManager}
                />
              </TileWrapper>
            )}
          </ColumnWrapper>
          <ColumnWrapper>
            <EmploymentComponent
              employee={singleEmployee}
              onEmploymentUpdate={onEmploymentUpdate}
              departments={sortedDeps || []}
              departmentRoles={departmentRolesStore.listData || []}
              employmentTypes={employmentTypesStore.listData || []}
              refetch={refetchSingleEmployee}
            />
            <AdditionalHoliday employee={singleEmployee} />
          </ColumnWrapper>

          <HolidayTile
            available={singleEmployee.holiday?.available || 0}
            left={singleEmployee.holiday?.left || 0}
            onDemand={singleEmployee.holiday?.onDemand || 0}
            onDemandTotal={singleEmployee.holiday?.onDemandTotal || 0}
            requested={singleEmployee.holiday?.requested || 0}
            total={singleEmployee.holiday?.total || 0}
            footer={footer}
          />
        </ProfileUserContent>

        {showDeactivateModal && (
          <DeactivateModal
            isShowing={showDeactivateModal}
            label={'Deactivate this user account?'}
            buttonRight={{
              label: 'Deactivate this account',
              type: 'modalAlert',
              onClick: deactivateAccount,
            }}
            leftButtonAction={() => setShowDeactivateModal(false)}
            closeIcon={() => setShowDeactivateModal(false)}
            dataCY="deactivate-modal">
            <TextInModal type="xSmall">
              Do you want to deactivate {`${singleEmployee.lastName} ${singleEmployee.firstName} `}
              account? You can activate it anytime.
            </TextInModal>
          </DeactivateModal>
        )}

        {showActiveModal && (
          <ActivateModal
            isShowing={showActiveModal}
            label={'Activate this user account?'}
            buttonRight={{
              label: 'Activate this account',
              type: 'success',
              onClick: activateAccount,
            }}
            leftButtonAction={() => setShowActiveModal(false)}
            closeIcon={() => setShowActiveModal(false)}
            dataCY="activate-modal">
            <TextInModal type="xSmall">
              Do you want to activate {`${singleEmployee.lastName} ${singleEmployee.firstName} `}
              account? You can deactivate it anytime.
            </TextInModal>
          </ActivateModal>
        )}
      </Container>
    </StyledLayout>
  );
};
