import _ from 'lodash';
import { SvgIconComponent } from 'components/Icons';
import Text from 'components/Text';
import { Ellipsis } from 'components/Text/Ellipsis';
import { useUserContext } from 'contexts/UserContext';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { getHolidayColorByStatus, getHolidayColorByType } from 'utils';
import { HolidayBar } from '../styled';
import { ListWrapper, CloseButton, ContainerWithScroll } from './styled';
import { FullHolidaysListProps } from './types';

export const FullHolidaysList = ({
  day,
  setShowFullList,
  teamHolidays,
  onClick,
}: FullHolidaysListProps) => {
  const { isManager } = useUserContext();

  const alphabetSortTeamHolidayList = _.orderBy(teamHolidays, ['user.firstName']);

  return (
    <ListWrapper data-cy="full-holidays-list">
      <Text.Paragraphs type="small" color={colors.gray[600]}>
        {day.format('dddd')}
      </Text.Paragraphs>
      <Text.Paragraphs type="large" color={colors.gray[900]} margin={'0 0 0.75rem 0'}>
        {day.format('D')}
      </Text.Paragraphs>
      <ContainerWithScroll
        style={{
          height: 200,
          paddingLeft: 12,
          paddingRight: 20,
          overflowY: 'auto',
          marginLeft: -12,
          marginRight: -8,
        }}>
        {alphabetSortTeamHolidayList.map((teamHoliday, i) => {
          if (isManager) {
            return (
              <div
                onClick={onClick && (() => onClick(teamHoliday))}
                style={{ cursor: 'pointer' }}
                key={i}>
                <HolidayBar
                  color={
                    teamHoliday.status === HolidayStatuses.Accepted
                      ? getHolidayColorByType(teamHoliday.holidayType)
                      : getHolidayColorByStatus(teamHoliday.status)
                  }
                  withBorder={teamHoliday.status === HolidayStatuses.Pending}
                  key={i}
                  data-cy="calendar-holiday-bar">
                  {
                    <Text.Paragraphs
                      type="xSmall"
                      weight={700}
                      color={
                        teamHoliday.status === HolidayStatuses.Pending
                          ? colors.gray[800]
                          : colors.white
                      }
                      title={`${teamHoliday?.user?.lastName} ${teamHoliday?.user?.firstName}`}>
                      <Ellipsis>
                        {teamHoliday?.user?.lastName} {teamHoliday?.user?.firstName}
                      </Ellipsis>
                    </Text.Paragraphs>
                  }
                </HolidayBar>
              </div>
            );
          }

          return (
            <HolidayBar
              color={
                teamHoliday.status === HolidayStatuses.Accepted
                  ? getHolidayColorByType(teamHoliday.holidayType)
                  : getHolidayColorByStatus(teamHoliday.status)
              }
              key={i}
              data-cy="calendar-holiday-bar">
              {
                <Text.Paragraphs
                  type="xSmall"
                  weight={700}
                  color={
                    teamHoliday.status === HolidayStatuses.Pending ? colors.gray[800] : colors.white
                  }
                  title={`${teamHoliday?.user?.lastName} ${teamHoliday?.user?.firstName}`}>
                  <Ellipsis>
                    {teamHoliday?.user?.lastName} {teamHoliday?.user?.firstName}
                  </Ellipsis>
                </Text.Paragraphs>
              }
            </HolidayBar>
          );
        })}
        <CloseButton onClick={() => setShowFullList(false)}>
          <SvgIconComponent icon={'CrossMarkIcon'} width={12} />
        </CloseButton>
      </ContainerWithScroll>
    </ListWrapper>
  );
};
