import { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';
import { useUserContext } from 'contexts/UserContext';
import { routes } from 'routes';
import { TeamHoliday } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { foundTeamHolidaysContainingDay } from 'utils';
import { DayProps } from './Day';
import { DayContainer } from './DayContainer';
import { FullHolidaysList } from './FullHolidaysList';
import { ShowMoreHolidays, MoreArea, DayBottomWrapper } from './styled';
import { TeamHolidayBar } from './TeamHolidayBar';

export const TeamDayContent = ({
  day,
  weekRows,
  firstColumn,
  withDaysNames,
  filterTeamHolidays,
  setWeekHasHoliday,
  weekHasHoliday,
}: DayProps) => {
  const { getColorByTheme } = useThemeContext();
  const { teamHolidays } = useTeamHolidaysContext();
  const { isManager } = useUserContext();

  const history = useHistory();

  const [showFullList, setShowFullList] = useState(false);

  const [isNewRequestModalVisible, toggleNewRequestModal] = useModal();

  const filteredTeamHolidays = useMemo(() => {
    const dayHolidays = foundTeamHolidaysContainingDay(day, teamHolidays, isManager);
    return dayHolidays.filter(filterTeamHolidays);
  }, [day, teamHolidays, isManager, filterTeamHolidays]);

  const onClickHolidayBar = (teamHoliday: TeamHoliday) => {
    history.push(`${routes.teamCalendar}/${teamHoliday.id}`);
  };

  const moreHolidaysRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <DayContainer
        withDaysNames={withDaysNames}
        day={day}
        firstColumn={firstColumn}
        teamHolidays={!!teamHolidays}
        setWeekHasHoliday={setWeekHasHoliday}
        weekHasHoliday={weekHasHoliday}
        showClickedDay={isNewRequestModalVisible}>
        <div className="test" onClick={toggleNewRequestModal}>
          <TeamHolidayBar
            day={day}
            filteredTeamHolidays={filteredTeamHolidays}
            onClick={onClickHolidayBar}
            weekRows={weekRows}
          />
        </div>
        <DayBottomWrapper onClick={toggleNewRequestModal} />

        {filteredTeamHolidays.length > 5 && (
          <ShowMoreHolidays>
            <MoreArea
              type="xxSmall"
              color={getColorByTheme(colors.gray[800], colors.white)}
              hoverColor={getColorByTheme(colors.tertiary, colors.black)}
              weight={700}
              onClick={() => setShowFullList(!showFullList)}
              ref={moreHolidaysRef}
              data-cy="show-more-holidays">
              {!showFullList
                ? `+ ${filteredTeamHolidays.length - 5} more`
                : `${filteredTeamHolidays.length} displayed`}
            </MoreArea>
            {showFullList && (
              <FullHolidaysList
                day={day}
                teamHolidays={filteredTeamHolidays}
                setShowFullList={setShowFullList}
                onClick={onClickHolidayBar}
              />
            )}
          </ShowMoreHolidays>
        )}
      </DayContainer>

      {isNewRequestModalVisible && (
        <NewRequestHolidayModal
          isVisible={isNewRequestModalVisible}
          toggle={toggleNewRequestModal}
          withEmployeeDropdown
          employmentType={null}
          startDate={day.toDate()}
          buttonProps={{ variant: 'tertiary', label: 'Add holiday' }}
        />
      )}
    </>
  );
};
