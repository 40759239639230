import { SvgIconComponent } from 'components/Icons';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { colors } from 'styles/colors';
import { RemoveAdditionalHoliday } from './styled';

interface Props {
  id?: string;
  handleClick: (item: string) => void;
  isManager: boolean;
}

export const RemoveHolidayElement = ({ id, handleClick, isManager }: Props): JSX.Element => {
  const { getColorByTheme } = useThemeContext();
  const textColor = getColorByTheme(colors.black, colors.gray[300]);

  const handleRemoveClick = (): void => handleClick(id || '');

  return isManager ? (
    <RemoveAdditionalHoliday onClick={handleRemoveClick}>
      <SvgIconComponent icon={'CrossMarkIcon'} color={`${textColor}`} width={12} height={12} />
    </RemoveAdditionalHoliday>
  ) : (
    <></>
  );
};
