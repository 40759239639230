// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import ApiBaseService from './ApiBaseService';
import { Access } from './AuthService';
import { EmployeeHolidaySummary } from './EmployeesService';

export interface UserResponse {
  id: number;
  active?: boolean;
  email: string;
  firstName: string;
  lastName: string;
  department: string;
  departmentId: number;
  role: string;
  access: Access;
  organization: string;
  avatar: string;
  position: string;
  source: string;
  employmentType: {
    key: string;
    value: string;
  };
  holiday?: EmployeeHolidaySummary;
}

export default class AccountService extends ApiBaseService {
  static readonly API_URL = this.API_BASE_URL + '/account';

  static getAccountInfo = async () => {
    const { data } = await axios.get<{ data: UserResponse }>(`${this.API_URL}/profile`);
    return data.data;
  };

  static changeCurrentUserPassword = async (user: {
    password: string;
    passwordConfirmation: string;
  }) => {
    const { data } = await axios.put(`${this.API_URL}/password`, { user });
    return data.data;
  };

  static createCurrentUserHolidayRequest = async (holiday_request: {
    holidayType: string;
    dateFrom: string;
    dateTo: string;
    additionalComment?: string;
  }) => {
    const { data } = await axios.post(`${this.API_URL}/holiday_requests`, { holiday_request });
    return data.data;
  };

  static upDateCurrentUserHolidayRequest = async (
    id: number | undefined,
    holiday_request: {
      holidayType: string;
      dateFrom: string;
      dateTo: string;
      additionalComment: string | null;
    },
  ) => {
    const { data } = await axios.put<{ holiday_request: {} }>(
      `${this.API_URL}/holiday_requests/${id}`,
      {
        holiday_request,
      },
    );
    return data;
  };

  static deleteCurrentUserHolidayRequest = async (id: number | undefined) => {
    const { data } = await axios.delete(`${this.API_URL}/holiday_requests/${id}`);
    return data.data;
  };
}
