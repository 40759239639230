import styled, { css } from 'styled-components';
import { HolidayBar } from '../styled';

export const PublicHolidayBarContainer = styled.div<{ weekCalendar?: boolean }>`
  ${({ weekCalendar }) =>
    weekCalendar &&
    css`
      margin-top: 0.5rem;
      width: inherit;
    `}
`;

export const StyledHolidayBar = styled(HolidayBar)<{ weekCalendar?: boolean }>`
  ${({ weekCalendar }) =>
    weekCalendar &&
    css`
      margin: 0 0.5rem;
      cursor: default;
    `}
`;
