import { css } from 'styled-components';

export const defaultFamily = css`
  font-family: 'Lato', sans-serif;
`;

const buttonTextDefault = css`
  ${defaultFamily};
  font-weight: 700;
`;

export const displayDefault = css`
  ${defaultFamily};
  font-weight: 700;
`;

export const typography = {
  display: {
    '1': css`
      ${displayDefault};
      font-size: 5rem;
      line-height: 1.25;
    `,
    '2': css`
      ${displayDefault};
      font-size: 4.5rem;
      line-height: 1.25;
    `,
    '3': css`
      ${displayDefault};
      font-size: 4rem;
      line-height: 1.25;
    `,
    '4': css`
      ${displayDefault};
      font-size: 3.5rem;
      line-height: 1.25;
    `,
    '5': css`
      ${displayDefault};
      font-size: 3rem;
      line-height: 1.25;
    `,
    '6': css`
      ${displayDefault};
      font-size: 2.5rem;
      line-height: 1.25;
    `,
  },

  heading: {
    '1': css`
      ${displayDefault};
      font-size: 2.25rem;
      line-height: 1.35;
    `,
    '2': css`
      ${displayDefault};
      font-size: 1.75rem;
      line-height: 1.35;
    `,
    '3': css`
      ${displayDefault};
      font-size: 1.25rem;
      line-height: 1.35;
    `,
    '4': css`
      ${displayDefault};
      font-size: 1.125rem;
      line-height: 1.35;
    `,
    '5': css`
      ${displayDefault};
      font-size: 1rem;
      line-height: 1.35;
    `,
    '6': css`
      ${displayDefault};
      font-size: 0.875rem;
      line-height: 1.35;
    `,
  },

  paragraphs: {
    xlarge: css`
      ${defaultFamily};
      font-weight: 400;
      font-size: 1.75rem;
      line-height: 2.625rem;
    `,
    large: css`
      ${defaultFamily};
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.7rem;
    `,
    small: css`
      ${defaultFamily};
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
    `,
    xSmall: css`
      ${defaultFamily};
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
    `,
    xxSmall: css`
      ${defaultFamily};
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 0.75rem;
    `,
    subtitle: css`
      ${defaultFamily};
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
      text-transform: uppercase;
    `,
    caption: css`
      ${defaultFamily};
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 0.75rem;
    `,
  },

  buttonFontSize: {
    small: css`
      ${buttonTextDefault};
      font-size: 0.625rem;
      line-height: 0.75rem;
      letter-spacing: -0.005em;
    `,
    regular: css`
      ${buttonTextDefault};
      font-size: 0.75rem;
      line-height: 0.75rem;
    `,
    large: css`
      ${buttonTextDefault};
      font-size: 0.875rem;
      line-height: 1rem;
    `,
  },
  placeholderDotsPassword: "font-family: 'Verdana'",
};

export type ButtonText = keyof typeof typography.buttonFontSize;
export type TextSizes = keyof typeof typography.paragraphs;
