import styled from 'styled-components';
import { colors } from 'styles/colors';

const gutter = 1.5;
const separatorWidth = 0.5;

export const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${gutter}rem;
`;

export const FlexItem = styled.div`
  position: relative;
  &:first-child {
    &:after {
      content: '';
      width: ${separatorWidth}rem;
      height: 0.125rem;
      position: absolute;
      top: 50%;
      background-color: ${colors.gray[700]};
      left: calc(100% + ${(gutter - separatorWidth) / 2}rem);
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.input.disabledColor};
  }
`;
