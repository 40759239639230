import { SvgIconComponent } from '../Icons';
import { NotificationCircle, Number, Wrapper } from './styled';
import { NotificationProps } from './types';

export const Notification = ({ numOfNotifications }: NotificationProps) => {
  if (numOfNotifications < 0) return null;
  return (
    <Wrapper>
      <SvgIconComponent icon="BellIcon" />
      {!!numOfNotifications && (
        <NotificationCircle>
          <Number>{numOfNotifications}</Number>
        </NotificationCircle>
      )}
    </Wrapper>
  );
};
