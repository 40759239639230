import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'components/Button/ButtonBase';
import { colors } from 'styles/colors';
import { defaultFamily } from 'styles/typography';

export const SignInButton = styled(Button)`
  margin-right: 2rem;
`;

export const FirstSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoText = styled.div`
  display: flex;
  align-items: center;
`;

export const SecondSection = styled.div`
  display: flex;
`;

export const ThirdSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LayoutContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundElement};
  color: ${({ theme }) => theme.text[700]};
  transition: all 0.5s linear;
  width: fill-available;
  position: sticky;
  top: 0;
  padding-left: 2rem;
  display: grid;
  grid-template-columns: 10.625rem 1fr 10.75rem auto;
  align-items: center;
  box-shadow: 0 4px 8px ${colors.boxShadowColor};
  min-height: 3.7rem;
  z-index: 100;
`;

export const TextLeoHoliday = styled.h1`
  font-family: ${defaultFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.005em;
  color: ${({ theme }) => theme.text[900]};
  margin-left: 0.6rem;
  margin-right: 0.9rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.text[700]};
`;

export const NavbarDropdownWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 4.25rem;
  right: 1.375rem;
  z-index: 10;
`;
