import { forwardRef } from 'react';
import { DEFAULT_ICON_SIZE } from 'const';
import { colors } from 'styles/colors';
import Text from '../../Text/index';
import { StyledIcon } from '../Icon/StyledIcon';
import { StyledInput, Wrap, WrapInput, InputFullLabel } from './styled';
import { InputProps } from './types';

export const InputFull = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      iconOptions = { rotate: 0 },
      placeholder,
      label,
      type,
      helperText,
      disabled,
      color,
      className,
      isError,
      holidayForm,
      dataCY,
      ...props
    },
    ref,
  ) => {
    return (
      <Wrap className={className}>
        {label && (
          <InputFullLabel>
            <Text.Paragraphs type="caption" style={{ marginLeft: 0, marginBottom: 8 }}>
              {label}
            </Text.Paragraphs>
          </InputFullLabel>
        )}
        <WrapInput isError={isError} disabled={disabled} holidayForm={holidayForm} data-cy={dataCY}>
          <StyledInput
            ref={ref}
            disabled={disabled}
            variant={{ size: 'xSmall' }}
            placeholder={placeholder}
            type={type}
            isError={isError}
            holidayForm={holidayForm}
            {...props}
          />
          {iconOptions.name && (
            <div onClick={iconOptions.onClick} data-cy={`${dataCY}-icon`}>
              <StyledIcon
                icon={iconOptions.name}
                width={DEFAULT_ICON_SIZE}
                rotate={iconOptions.rotate}
                color={color}
                pointer={iconOptions.pointer}
              />
            </div>
          )}
        </WrapInput>
        {helperText && (
          <Text.Paragraphs type="xxSmall" color={colors.gray[400]} margin={'0.5rem'}>
            {helperText}
          </Text.Paragraphs>
        )}
      </Wrap>
    );
  },
);
