import { useCallback, useEffect, useState } from 'react';
import { Everyone } from 'const';
import { PaginationMeta } from 'services/ApiBaseService';
import { Department } from 'services/DepartmentsService';
import EmployeesService, { Employee, GetEmployeesParams } from 'services/EmployeesService';
import { makeContext } from './ContextCreator';
import { useUserContext } from './UserContext';

export const initialPagination: PaginationMeta = {
  currentPage: 1,
  nextPage: null,
  prevPage: null,
  totalPages: 1,
  totalCount: 0,
};

export const [useEmployeeContext, EmployeeProvider] = makeContext(() => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeesPagination, setEmployeesPagination] = useState<PaginationMeta>(initialPagination);
  const [employeesLoading, setEmployeesLoading] = useState<boolean>(false);
  const [employeesError, setEmployeesError] = useState<string>();
  const { isManager } = useUserContext();

  const [selectedDepartment, setSelectedDepartment] = useState<number>(Everyone.id);

  const selectDepartment = (department: Department) => {
    setEmployees([]);
    setSelectedDepartment(department.id);
    if (department.id === 999) {
      getEmployees({});
    } else {
      getEmployees({ by_department_id: department.id });
    }
  };

  const getEmployees = useCallback(async (params?: GetEmployeesParams) => {
    setEmployeesLoading(true);
    const response = await EmployeesService.fetchEmployees(params);

    if (response.error) {
      setEmployeesError('An error occurred while fetching users.');
    }

    setEmployees((prevState) => [...prevState, ...response.data]);

    setEmployeesPagination(response.meta);
    setEmployeesLoading(false);

    return response.data;
  }, []);

  useEffect(() => {
    isManager && getEmployees();
  }, [isManager, getEmployees]);

  const selectedDepartmentApiId =
    selectedDepartment === Everyone.id ? undefined : selectedDepartment;

  return {
    employees,
    employeesPagination,
    employeesLoading,
    employeesError,
    getEmployees,
    setEmployees,
    selectedDepartment,
    selectDepartment,
    selectedDepartmentApiId,
  };
});
