import { Button } from 'components/Button/ButtonBase';
import { SvgIconComponent } from 'components/Icons';
import Text from 'components/Text';
import {
  PrevIconWrapper,
  FormatDateWrapper,
  NextIconWrapper,
  CalendarMonthNavigationContainer,
  CalendarMonthNavigationHeader,
} from './styled';
import { MonthsNavigationProps } from './types';

export const CalendarMonthNavigation = ({
  prevIcon,
  prevIconWidth,
  nextIcon,
  nextIconWidth,
  onClickNextIcon,
  onClickPrevIcon,
  isFormatDate,
  formatDate,
  children,
  smallCalendar,
  isButtonToday,
  handleReset,
  isHeaderUserCalendar = false,
}: MonthsNavigationProps) => {
  return (
    <CalendarMonthNavigationContainer data-cy="months-navigation">
      {isButtonToday && (
        <Button
          label="Today"
          variant="basic"
          size="regular"
          onClick={handleReset}
          activeHover={true}
        />
      )}

      <PrevIconWrapper
        smallCalendar={smallCalendar}
        onClick={onClickPrevIcon}
        data-cy="months-navigation-prev-button">
        <SvgIconComponent icon={prevIcon} width={prevIconWidth} />
      </PrevIconWrapper>
      <Text.Heading type="5"> {children}</Text.Heading>
      <NextIconWrapper
        smallCalendar={smallCalendar}
        onClick={onClickNextIcon}
        data-cy="months-navigation-next-button">
        <SvgIconComponent icon={nextIcon} width={nextIconWidth} />
      </NextIconWrapper>

      <FormatDateWrapper isHeaderUserCalendar={isHeaderUserCalendar}>
        {isFormatDate && (
          <CalendarMonthNavigationHeader type="5">{formatDate}</CalendarMonthNavigationHeader>
        )}
      </FormatDateWrapper>
    </CalendarMonthNavigationContainer>
  );
};
