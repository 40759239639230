import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { HolidaysForm } from 'components/HolidaysForm';
import { Spinner } from 'components/Spinner';
import { Toast } from 'components/Toast';
import { useToastContext } from 'contexts/ToastContext';
import { useUserContext } from 'contexts/UserContext';
import { routes } from 'routes';
import { PrivateRoute } from 'routes/PrivateRoute';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { PublicRoute } from 'routes/PublicRoute';
import { Access } from 'services/AuthService';
import { Dashboard } from './Dashboard';
import { Login } from './Login';
import { NotFound } from './NotFound';
import { ProfileLoggedAccount } from './ProfileLoggedAccount';
import { Reports } from './Reports';
import { SingleEmployeeCalendarYear } from './SingleEmployeeCalendarYear';
import { SingleEmployeeProfile } from './SingleEmployeeProfile';
import { Team } from './Team';
import { TeamCalendar } from './TeamCalendar';
import { UserCalendar } from './UserCalendar';

export const App = () => {
  const { isAuthenticated, isUserLoaded } = useUserContext();
  const { isToastVisible } = useToastContext();

  if (isAuthenticated && !isUserLoaded) {
    return <Spinner fullPage />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={routes.login} component={Login} />
        <PrivateRoute exact path={routes.profile} component={ProfileLoggedAccount} />
        <PrivateRoute exact path={routes.dashboard} component={Dashboard} />
        <ProtectedRoute access={Access.Manager} exact path={routes.team} component={Team} />
        <ProtectedRoute access={Access.Manager} exact path={routes.reports} component={Reports} />
        <ProtectedRoute
          access={Access.Manager}
          exact
          path={routes.singleEmployeeYearCalendar}
          component={SingleEmployeeCalendarYear}
        />
        <PrivateRoute exact path={`${routes.team}/:id`} component={SingleEmployeeProfile} />
        <PrivateRoute exact path={routes.calendar} component={UserCalendar} />
        <PrivateRoute exact path={routes.teamCalendar} component={TeamCalendar} />
        <PrivateRoute exact path={`${routes.teamCalendar}/:holidayId`} component={HolidaysForm} />
        <Route path={routes.notFound} component={NotFound} />
        <Route path={'*'} render={() => <Redirect to={routes.notFound} />} />
      </Switch>
      {isToastVisible && <Toast />}
    </BrowserRouter>
  );
};
