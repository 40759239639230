import { isHoliday, getHolidayOnDate } from 'poland-public-holidays';
import Text from 'components/Text';
import { Ellipsis } from 'components/Text/Ellipsis';
import { colors } from 'styles/colors';
import { StyledHolidayBar, PublicHolidayBarContainer } from './styled';
import { PublicHolidayBarProps } from './types';

export const PublicHolidayBar = ({ date, weekCalendar }: PublicHolidayBarProps) => {
  return (
    <PublicHolidayBarContainer weekCalendar={weekCalendar}>
      {isHoliday(new Date(date.toString())) && (
        <StyledHolidayBar
          weekCalendar={weekCalendar}
          color={colors.dark.success}
          title={getHolidayOnDate(new Date(date.toString()))?.namePL}
          data-cy="calendar-public-holiday-bar">
          <Text.Paragraphs type="xSmall" color={colors.white} weight={700}>
            <Ellipsis centerText>{getHolidayOnDate(new Date(date.toString()))?.namePL}</Ellipsis>
          </Text.Paragraphs>
        </StyledHolidayBar>
      )}
    </PublicHolidayBarContainer>
  );
};
