import dayjs from 'dayjs';
import Text from 'components/Text';
import { Ellipsis } from 'components/Text/Ellipsis';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { getDayIndex, getHolidayColorByType } from 'utils';
import { HolidayBar } from '../styled';
import { EmployeeTeamHolidayBarProps } from './types';

export const EmployeeTeamHolidayBar = ({
  filteredTeamHolidays,
  day,
  weekRows,
  disableCursor,
}: EmployeeTeamHolidayBarProps) => {
  return (
    <>
      {[0, 1, 2, 3, 4].map((row) => {
        const dayIndex = getDayIndex(day);
        const holidayId = weekRows[row][dayIndex];
        const teamHoliday = filteredTeamHolidays
          .filter((hs) => hs.status === HolidayStatuses.Accepted)
          .find((hol) => hol.id === holidayId);

        if (!teamHoliday) {
          return (
            <HolidayBar disableCursor={disableCursor} color={'transparent'} key={Math.random()}>
              <Text.Paragraphs
                type="xSmall"
                color={'transparent'}
                weight={700}
                style={{ visibility: 'hidden' }}>
                &nbsp;
              </Text.Paragraphs>
            </HolidayBar>
          );
        }

        let holidayOnPreviousDay: number | null = null;
        for (const r in weekRows) {
          if (weekRows[r][dayIndex - 1] === holidayId) {
            holidayOnPreviousDay = holidayId;
          }
        }

        const hasHiddenHolidayOnPreviousDay =
          dayjs(day).isAfter(teamHoliday.dateFrom) && day.day() !== 1 && !holidayOnPreviousDay;

        return (
          <HolidayBar
            disableCursor={disableCursor}
            $hasHolidayOnPreviousDay={
              (dayjs(day).isAfter(teamHoliday.dateFrom) && day.day() !== 1) ||
              hasHiddenHolidayOnPreviousDay
            }
            $hasHolidayOnNextDay={dayjs(day).isBefore(teamHoliday.dateTo) && day.day() !== 0}
            $hasHiddenHolidayOnPreviousDay={hasHiddenHolidayOnPreviousDay}
            color={getHolidayColorByType(teamHoliday.holidayType)}
            key={teamHoliday.id}>
            <Text.Paragraphs
              type="xSmall"
              color={colors.white}
              style={
                dayjs(day).isAfter(teamHoliday.dateFrom) &&
                day.day() !== 1 &&
                !hasHiddenHolidayOnPreviousDay
                  ? { visibility: 'hidden' }
                  : {}
              }
              weight={700}
              title={`${teamHoliday?.user?.lastName} ${teamHoliday?.user?.firstName}`}>
              <Ellipsis>
                {teamHoliday?.user?.lastName} {teamHoliday?.user?.firstName}
              </Ellipsis>
            </Text.Paragraphs>
          </HolidayBar>
        );
      })}
    </>
  );
};
