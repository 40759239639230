import styled from 'styled-components';
import { Input } from 'components/Inputs';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';

export const SearchTile = styled.div`
  background-color: transparent;
  width: 36.25rem;
  height: 2.25rem;
  border-radius: ${borderRadius.sm};
  display: flex;
`;

export const StyledInput = styled(Input)`
  margin: 0 1rem 0 0;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.button.basic.borderColor};
`;

export const Element = styled.div`
  justify-items: end;
`;

export const Dot = styled.div`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: ${colors.gray[600]};
  margin: 0 0.5em;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  width: 100%;
  top: 3rem;
  position: fixed;
  padding: 2em 0 1em 0;
`;
