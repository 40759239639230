import { colors } from 'styles/colors';
import { StyledIcon } from '../../Inputs/Icon/StyledIcon';
import Text from '../../Text/index';
import { CheckboxWrapper, StyledCheckbox, CheckboxLabel } from './styled';
import { CheckBoxProps } from './types';

export const Checkbox = ({
  className,
  label,
  isChecked,
  stateCheckbox,
  checkboxAction,
  onClick,
  color,
  holidayBalanceVariant,
  dataCY,
  ...props
}: CheckBoxProps) => {
  return (
    <div
      className={className}
      onClick={onClick}
      data-cy={`${dataCY}${isChecked ? '-checked' : ''}`}
      {...props}>
      <CheckboxWrapper onClick={checkboxAction}>
        <StyledCheckbox
          stateCheckbox={stateCheckbox}
          checked={isChecked}
          color={color}
          holidayBalanceVariant={holidayBalanceVariant}>
          {isChecked && (
            <StyledIcon
              icon="CheckIcon"
              color={stateCheckbox ? colors.tertiary : colors.surface.primary}
              width={11}
            />
          )}
        </StyledCheckbox>
        {label && (
          <>
            {holidayBalanceVariant ? (
              <Text.Paragraphs type="xSmall">{label}</Text.Paragraphs>
            ) : (
              <CheckboxLabel>
                <Text.Paragraphs type="caption">{label}</Text.Paragraphs>
              </CheckboxLabel>
            )}
          </>
        )}
      </CheckboxWrapper>
    </div>
  );
};
