import { StyledIcon } from '../Icon/StyledIcon';
import { LeftSide, RightSide, StyledInput, Wrap } from './styled';
import { InputProps } from './types';

export const Input = ({
  placeholder,
  iconName,
  className,
  crossMarkIconOnClick,
  value,
  maxLength,
  dataCY,
  withoutBorder,
  ...props
}: InputProps) => {
  return (
    <Wrap className={className} withoutBorder={withoutBorder} data-cy={dataCY}>
      <LeftSide>
        <StyledIcon icon="SearchIcon" />
        <StyledInput
          variant={{ size: 'xSmall' }}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          {...props}
        />
      </LeftSide>
      {!!value && (
        <RightSide onClick={crossMarkIconOnClick}>
          <StyledIcon icon="CrossMarkIcon" width={10} />
          {iconName && <StyledIcon icon={iconName} marginLeft={1} />}
        </RightSide>
      )}
    </Wrap>
  );
};
