import { Department } from 'services/DepartmentsService';

export interface CapacityProps {
  activeDepartment?: Department;
}

export enum EmploymentType {
  Contract = 'contract',
  B2B = 'b2b',
}
