import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import { Select } from 'components/Select';
import { Title } from 'components/TileWrapper/styled';
import HolidayCapacitiesService, {
  HolidayCapacitiesResponseType,
} from 'services/HolidayCapacitiesService';
import { RootReducer } from 'store/reducers';
import { createAllHolidayTypesArray, getHolidayColorByType } from 'utils';
import {
  CapacityBalanceHeader,
  CapacityBalanceText,
  CapacityContentWrapper,
  CapacityLeftSide,
  CapacityRightSide,
  CapacityWrapper,
  ChooseDateRangeWrapper,
  Dot,
  HolidayTypesListItem,
  NameWrapper,
} from './styled';
import { CapacityProps } from './types';

type Option = string;
const options: Option[] = ['Last month', 'This month', 'Next month'];

export const Capacity = ({ activeDepartment }: CapacityProps) => {
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [startDate, setStartDate] = useState<null | Date>(
    new Date(dayjs().year(), dayjs().month(), 1),
  );

  const [endDate, setEndDate] = useState<null | Date>(
    new Date(dayjs().year(), dayjs().month() + 1, 0),
  );
  const handleStartDateSelect = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelect = (date: Date) => setEndDate(date);
  const [holidayCapacity, setHolidayCapacity] = useState<HolidayCapacitiesResponseType>();

  useEffect(() => {
    const tribeParams = {
      'date[from]': `${dayjs(startDate).format('YYYY-MM-DD')}`,
      'date[to]': `${dayjs(endDate).format('YYYY-MM-DD')}`,
      'departmentId': activeDepartment?.id || null,
    };

    const initialize = async () => {
      const responseOneDay = await HolidayCapacitiesService.fetchHolidayCapacities(tribeParams);
      setHolidayCapacity(responseOneDay);
    };

    initialize();
  }, [selectedOption, startDate, endDate, activeDepartment]);

  const holidayTypes = createAllHolidayTypesArray(employmentTypesStore.listData);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    const firstDayOfMonth = new Date(dayjs().year(), dayjs().month(), 1);
    const lastDayOfMonth = new Date(dayjs().year(), dayjs().month() + 1, 0);
    const firstDayOfLastMonth = new Date(dayjs().year(), dayjs().month() - 1, 1);
    const lastDayOfLastMonth = new Date(dayjs().year(), dayjs().month(), 0);
    const firstDayOfNextMonth = new Date(dayjs().year(), dayjs().month() + 1, 1);
    const lastDayOfNextMonth = new Date(dayjs().year(), dayjs().month() + 2, 0);

    switch (option) {
      case 'Last month':
        setStartDate(firstDayOfLastMonth);
        setEndDate(lastDayOfLastMonth);
        break;
      case 'This month':
        setStartDate(firstDayOfMonth);
        setEndDate(lastDayOfMonth);
        break;
      case 'Next month':
        setStartDate(firstDayOfNextMonth);
        setEndDate(lastDayOfNextMonth);
        break;
      default:
        break;
    }
  };

  return (
    <CapacityWrapper>
      <CapacityContentWrapper>
        <CapacityLeftSide>
          <Title>{`${activeDepartment?.name || 'Everyone'} Capacity`}</Title>
          <ChooseDateRangeWrapper>
            <Select
              placeholder={selectedOption || 'This month'}
              options={options}
              selectedOption={selectedOption}
              onOptionClicked={handleSelect}
              dataCY="dashboard-capacity-month-select"
            />
            <RangeDatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDateSelect={handleStartDateSelect}
              setEndDateSelect={handleEndDateSelect}
              dataCY="dashboard-capacity-datepicker"
            />
          </ChooseDateRangeWrapper>
          <CapacityBalanceHeader type="6" margin="1rem 0 1.5rem 0">
            {`${holidayCapacity?.occupancy_number}/${holidayCapacity?.capacity} employees on holiday`}
          </CapacityBalanceHeader>
          {holidayCapacity &&
            holidayCapacity.holiday_types.map(({ name, occupancy_percentage }) => {
              const filteredHolidayName = holidayTypes.find((dep) => dep.key === name)?.value;

              return (
                <HolidayTypesListItem key={name}>
                  <Dot color={getHolidayColorByType(name)} />
                  <NameWrapper>
                    <CapacityBalanceText type="xSmall">{filteredHolidayName}</CapacityBalanceText>
                  </NameWrapper>
                  <CapacityBalanceText type="xSmall">{`${occupancy_percentage}%`}</CapacityBalanceText>
                </HolidayTypesListItem>
              );
            })}
        </CapacityLeftSide>
        <CapacityRightSide></CapacityRightSide>
      </CapacityContentWrapper>
    </CapacityWrapper>
  );
};
