import styled from 'styled-components';
import { typography } from 'styles/typography';

export const Wrap = styled.button`
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  background: none;
  color: inherit;
  border: none;
  padding: 0 4em 0 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  box-sizing: border-box;
`;

export const UserName = styled.p`
  ${typography.paragraphs.caption};
  color: ${({ theme }) => theme.profile.userName};
  margin-left: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  margin-right: 0.125rem;
  white-space: nowrap;

  svg {
    color: ${({ theme }) => theme.profile.userName};
  }
`;
