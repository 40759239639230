import { SpinnerWrapper, StyledSpinner } from './styled';
import { SpinnerProps } from './types';

export const Spinner = ({ fullPage }: SpinnerProps) => {
  return (
    <SpinnerWrapper fullPage={fullPage}>
      <StyledSpinner />
    </SpinnerWrapper>
  );
};
