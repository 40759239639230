import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { colors } from 'styles/colors';
import { useThemeContext } from '../../../../components/ThemeWrapper/ThemeWrapper';
import { UserCalendarHeader } from '../../styled';
import { YourBalanceProps } from '../../types';

export const YourBalance = ({ holiday }: YourBalanceProps) => {
  const forUseOnDemand = holiday && holiday.onDemand;
  const { getColorByTheme } = useThemeContext();

  return (
    <>
      {holiday && (
        <>
          <UserCalendarHeader margin="32px 0 16px 0" type="6">
            Your Balance
          </UserCalendarHeader>
          <HolidaySummaryRow left="Available holiday" right={`${holiday.available}`} />
          {holiday.onDemandTotal ? (
            <HolidaySummaryRow left="On demand" right={`${forUseOnDemand}`} />
          ) : null}
          <HolidaySummaryRow
            left="Pending request"
            right={holiday.requested}
            color={getColorByTheme(colors.dark.tertiary, colors.tertiary)}
          />
        </>
      )}
    </>
  );
};
