import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { isHoliday } from 'poland-public-holidays';
import { foundHolidayContainingDay } from 'utils';
import { useCalendarContext } from '../../../contexts';
import { colors } from '../../../styles/colors';
import { useThemeContext } from '../../ThemeWrapper/ThemeWrapper';
import { Dot, SmallCalendarDayWrapper } from './styled';
import { SmallCalendarDayType } from './types';

export const SmallCalendarDay = ({
  day,
  onDotMouseOver,
  onDotMouseLeave,
  onPress,
  onClick,
  employeeCalendar,
  children,
  isSingleEmployeeYearCalendar,
  holidaysData,
}: SmallCalendarDayType) => {
  const { getHolidayColorByType } = useCalendarContext();
  const { getColorByTheme } = useThemeContext();
  const isToday = dayjs().isSame(day, 'day');
  const userHoliday = useMemo(
    () => foundHolidayContainingDay(day, holidaysData),
    [holidaysData, day],
  );
  const isPublic = useMemo(() => isHoliday(dayjs(day).toDate()), [day]);

  const getDotColor = useCallback(() => {
    if (userHoliday) {
      return getHolidayColorByType(userHoliday?.holidayType);
    }
    if (isPublic) {
      return getColorByTheme(colors.dark.success, colors.success);
    }
  }, [userHoliday, isPublic, getColorByTheme, getHolidayColorByType]);

  const holidayColor = employeeCalendar ? getDotColor() : 'none';

  const handleNewRequest = () => {
    onPress && onPress(day);
  };

  const dateForSingleDay = dayjs(day).format('YYYY-MM-DD');
  const dayOfWeek = dayjs(day).day();

  return (
    <>
      <SmallCalendarDayWrapper
        key={day.format('DD-MM-YY')}
        data-cy={`small-calendar-day-${dateForSingleDay}-day-of-week-${dayOfWeek}`}
        onClick={onClick}>
        <Dot
          isToday={isToday}
          onClick={handleNewRequest}
          holidayColor={holidayColor}
          isEmployeeCalendarYear={isSingleEmployeeYearCalendar}
          onMouseOver={onDotMouseOver}
          onMouseLeave={onDotMouseLeave}>
          {children}
        </Dot>
      </SmallCalendarDayWrapper>
    </>
  );
};
