import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { ChangingEmploymentTypeModal, useModal } from 'components';
import { Type, Variant } from 'components/AlertComponent/types';
import { SingleDatePicker } from 'components/Inputs/DatePicker/SingleDatePicker';
import { InputFullForm } from 'components/Inputs/InputFull/InputFullForm';
import { Select } from 'components/Select';
import { WrapperForSelectComponents } from 'components/Select/styled';
import { KidsForm } from 'components/Switches/SwitchComponents/KidsForm/KidsForm';
import { TileWrapper } from 'components/TileWrapper';
import { useToastContext } from 'contexts/ToastContext';
import { ApiError } from 'services/AuthService';
import { EmploymentType } from 'views/Dashboard/components/types';
import { checkProfileChanges } from './helpers';
import { PositionInput } from './styled';
import { EmployeeComponentProps } from './types';

export const EmploymentComponent = ({
  employee,
  onEmploymentUpdate,
  departments,
  departmentRoles,
  employmentTypes,
  refetch,
}: EmployeeComponentProps) => {
  const { setToast, handleError } = useToastContext();

  const { seniorityStartDate, departmentRole, position, employedFrom, employedTo } = employee;

  const [selectedDepartment, setSelectedDepartment] = useState(
    departments?.find((d) => d.id === departmentRole?.departmentId),
  );

  const [selectedDepRole, setSelectedDepRole] = useState(
    departmentRoles.find(
      (d) => d.name === departmentRole?.name && d.departmentId === departmentRole.departmentId,
    ),
  );

  const [availableDepartmentRoles, setAvailableDepartmentRoles] = useState(
    !selectedDepartment
      ? []
      : departmentRoles.filter((dr) => dr.departmentId === selectedDepartment?.id),
  );

  useEffect(() => {
    setAvailableDepartmentRoles(
      !selectedDepartment
        ? []
        : departmentRoles.filter((dr) => dr.departmentId === selectedDepartment?.id),
    );
  }, [selectedDepartment]);

  const employmentType = employmentTypes.find((d) => {
    if (employee.employmentType != null) return d.key === employee.employmentType?.key;
    else return false;
  });

  const [selectedEmploymentType] = useState(employmentType);

  const [startDate] = useState<Date | null>(employedFrom ? new Date(employedFrom) : null);

  const [endDate] = useState<Date | null>(employedTo ? new Date(employedTo) : null);
  const [positionValue, setPositionValue] = useState(position || null || '');
  const [newSeniority, setNewSeniority] = useState<Date | null>(
    seniorityStartDate ? new Date(seniorityStartDate) : null,
  );
  const [changingEmploymentType, setChangingEmploymentType] = useModal();

  const initialKidDateOfBirth = employee?.kidDateOfBirth
    ? new Date(employee?.kidDateOfBirth)
    : null;
  const [kidDateOfBirth, setKidDateOfBirth] = useState(initialKidDateOfBirth);
  const [isTurnedOn, setisTurnedOn] = useState(!!kidDateOfBirth);

  useEffect(() => {
    refetch();
    setPositionValue(position || null || '');
  }, [employee]);

  const isDisabled = useMemo(
    () =>
      checkProfileChanges({
        newDepRole: selectedDepRole,
        defaultDepartment: employee.departmentRole?.departmentId,
        newDepartment: selectedDepartment?.id,
        defaultRole: employee.departmentRole?.id,
        defaultStartDate: employee.employedFrom,
        newStartDate: startDate,
        defaultEndDate: employee.employedTo,
        newEndDate: endDate,
        defaultPosition: position,
        newPosition: positionValue,
        defaultSeniority: seniorityStartDate,
        choosedSeniority: newSeniority,
        defaultEmploymentType: employee.employmentType?.key,
        newEmploymentType: selectedEmploymentType?.key,
        defaultKids: employee?.kidDateOfBirth,
        newKids: kidDateOfBirth,
      }),
    [
      selectedDepRole,
      employee.departmentRole?.departmentId,
      selectedDepartment?.id,
      employee.departmentRole?.id,
      employee.employedFrom,
      startDate,
      employee.employedTo,
      endDate,
      position,
      positionValue,
      seniorityStartDate,
      newSeniority,
      employee.employmentType?.key,
      selectedEmploymentType?.key,
      employee?.kidDateOfBirth,
      kidDateOfBirth,
    ],
  );

  const changeSwitch = () => {
    if (isTurnedOn) {
      setKidDateOfBirth(null);
    } else {
      setKidDateOfBirth(initialKidDateOfBirth);
    }
    setisTurnedOn(!isTurnedOn);
  };

  const isEmploymentTypeChanged = selectedEmploymentType?.key !== employmentType?.key;

  const isContract = selectedEmploymentType?.key === EmploymentType.Contract;

  const submitEmploymentUpdate = async () => {
    try {
      await onEmploymentUpdate({
        seniorityStartDate: isContract ? dayjs(newSeniority).format('YYYY-MM-DD').toString() : null,
        departmentRoleId: selectedDepRole?.id || employee.departmentRole!.id,
        employmentType: selectedEmploymentType?.key || employee.employmentType?.key,
        employedFrom: dayjs(startDate).format('YYYY-MM-DD'),
        position: positionValue.trim(),
        kidDateOfBirth: dayjs(kidDateOfBirth).format('YYYY-MM-DD') || null,
      });
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Employee data has been updated!',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      if (isEmploymentTypeChanged) {
        setChangingEmploymentType();
      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (e) {
      handleError(e as ApiError);
    }
  };

  const onSubmit = async () => {
    if (isEmploymentTypeChanged) {
      setChangingEmploymentType();
    } else {
      submitEmploymentUpdate();
    }
  };

  const handleSeniority = (date: Date) => {
    setNewSeniority(date);
  };

  const departamentsWithRole = departments?.filter((departament) => {
    return departmentRoles.find((role) => departament.id === role.departmentId);
  });

  return (
    <TileWrapper
      title="Employment"
      button={{
        content: 'Save',
        onClick: onSubmit,
        disabled: isDisabled,
        dataCY: 'single-employee-employment-form-save-button',
      }}>
      <WrapperForSelectComponents gridRowGap={'2rem'} margin={'1rem 0'}>
        <Select
          label="Department"
          placeholder="choose department"
          options={departamentsWithRole || []}
          selectedOption={selectedDepartment}
          onOptionClicked={(dep) => {
            setSelectedDepartment(dep);
            setSelectedDepRole(undefined);
          }}
          labelSelector={(department) => department.name}
          dataCY="team-department-select"
        />
        <Select
          label="Role"
          placeholder="choose role"
          options={availableDepartmentRoles}
          selectedOption={selectedDepRole}
          onOptionClicked={setSelectedDepRole}
          labelSelector={(depRole) => depRole.name}
          dataCY="team-role-select"
        />
        <InputFullForm
          label={'Employment type'}
          placeholder={'employment'}
          value={employmentType?.value}
          dataCY="team-employment-type-select"
          disabled={true}
        />
      </WrapperForSelectComponents>
      <PositionInput
        label="Position"
        placeholder={'type the position'}
        value={positionValue}
        onChange={(e) => {
          setPositionValue(e.currentTarget.value);
        }}
        dataCY="team-position-input"
      />
      {selectedEmploymentType?.key === 'contract' && (
        <SingleDatePicker
          label={'10 years of seniority starts from'}
          initialDate={newSeniority}
          onChange={handleSeniority}
          dataCY="team-personal-details-seniority"
        />
      )}
      <KidsForm
        label="Kids"
        initialDate={kidDateOfBirth}
        onChange={setKidDateOfBirth}
        changeSwitch={changeSwitch}
        isTurnedOn={isTurnedOn}
      />
      {changingEmploymentType && (
        <ChangingEmploymentTypeModal
          isVisible={changingEmploymentType}
          toggle={setChangingEmploymentType}
          submitEmploymentUpdate={submitEmploymentUpdate}
          dataForUpdate={employee.additionalHolidays}
          employeeFirstName={employee.firstName}
          employeeLastName={employee.lastName}
          employeeId={employee.id}
        />
      )}
    </TileWrapper>
  );
};
