import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import HolidayCapacitiesService, {
  HolidayCapacitiesParams,
  HolidayCapacitiesResponseType,
} from '../services/HolidayCapacitiesService';

interface UseHolidayCapacityProps {
  capacity: HolidayCapacitiesResponseType | undefined;
}

interface Props {
  capacityParams: HolidayCapacitiesParams;
}

export const useHolidayCapacity = ({ capacityParams }: Props): UseHolidayCapacityProps => {
  const [capacity, setCapacity] = useState<HolidayCapacitiesResponseType>();

  const { data } = useQuery(
    ['holiday-capacity', capacityParams],
    () => HolidayCapacitiesService.fetchHolidayCapacities(capacityParams),
    {
      enabled: !!capacityParams,
    },
  );

  useEffect(() => {
    if (data) {
      setCapacity(data);
    }
  }, [data]);

  return { capacity };
};
