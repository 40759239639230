import { useCallback, useState } from 'react';
import { Type, Variant } from '../components/AlertComponent/types';
import { useToastContext } from '../contexts';
import { PaginationMeta } from '../services/ApiBaseService';
import ReportsService, { GetReportsParams, ReportHolidayProps } from '../services/ReportsService';

interface UseReportsInterface {
  dataReports: ReportHolidayProps[];
  isFetching: boolean;
  employeesLoading: boolean;
  isLoading: boolean;
  meta: PaginationMeta | undefined;
  fetchReports: (params: GetReportsParams) => Promise<void>;
}

export const useReports = (): UseReportsInterface => {
  const [dataReports, setDataReports] = useState<ReportHolidayProps[]>([]);
  const [isFetching, setFetching] = useState(false);
  const [employeesLoading, setEmployeeLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState<PaginationMeta>();
  const { setToast } = useToastContext();

  const fetchReports = useCallback(
    async (params: GetReportsParams) => {
      try {
        setEmployeeLoading(true);
        if (params.page) {
          setFetching(true);
        }
        const { data, meta: m } = await ReportsService.fetchReportHolidays(params);
        setMeta(m);
        if (params.page) {
          setDataReports((prev) => [...prev, ...data]);
        } else {
          setDataReports(data);
        }
      } catch (error) {
        setToast({
          variant: Variant.fail,
          type: Type.outline,
          iconInfo: 'CrossMarkIcon',
          actionIcon: 'CrossMarkIcon',
          label: `An error occurred: ${error}`,
        });
      } finally {
        setFetching(false);
        setEmployeeLoading(false);
        setIsLoading(false);
      }
    },
    [setToast],
  );

  return {
    dataReports,
    isFetching,
    isLoading,
    employeesLoading,
    meta,
    fetchReports,
  };
};
