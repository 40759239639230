import React, { useState } from 'react';
import { DepartmentProps } from 'components/DepartmentItem/types';
import { SvgIconName } from 'components/Icons/types';
import { StyledButton, SubDepartmentWrapper } from './styled';

export const DepartmentItem = ({
  departments,
  item,
  hasChildren,
  setActiveDepartment,
  activeDepartmentId,
  isActive,
  selectedDepartment,
  isChild,
}: DepartmentProps) => {
  const [activeDropdownMenu, setActiveDropdownMenu] = useState(true);

  const getIconNameRight = (): SvgIconName | undefined => {
    if (hasChildren) {
      return activeDropdownMenu ? 'ArrowUpIcon' : 'ArrowDownIcon';
    }
    return undefined;
  };

  const handleSetActiveDropdownMenu = (e: React.MouseEvent) => {
    setActiveDropdownMenu((prevState) => !prevState);
    e.stopPropagation();
  };

  const parentActive = selectedDepartment === item.id;

  return (
    <SubDepartmentWrapper isChild={isChild}>
      <StyledButton
        label={item.name}
        threeDotsForLongWord={true}
        title={item.name}
        onClick={() => {
          setActiveDepartment(item);
        }}
        isActive={parentActive || isActive}
        iconNameRight={getIconNameRight()}
        hideBorder={true}
        departmentButton
        iconOnClick={handleSetActiveDropdownMenu}
        dataCY="department-button"
      />

      {activeDropdownMenu &&
        departments
          .filter(({ parentId }) => parentId === item.id)
          .map((child, idx) => {
            const hasChildren2 = !!departments.find((d) => child.id === d.parentId);
            return (
              <DepartmentItem
                key={idx}
                isChild
                activeDepartmentId={activeDepartmentId}
                departments={departments}
                selectedDepartment={selectedDepartment}
                item={child}
                isActive={selectedDepartment === child.id}
                hasChildren={hasChildren2}
                setActiveDepartment={setActiveDepartment}
              />
            );
          })}
    </SubDepartmentWrapper>
  );
};
