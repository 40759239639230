import { useMemo } from 'react';
import Avatar from 'react-avatar';
import { useQuery } from 'react-query';
import { AvatarWrapper } from 'components/EmployeeTile/styled';
import DepartmentsService, { Department } from 'services/DepartmentsService';
import { Employee } from 'services/EmployeesService';
import { routes } from '../../routes';
import { SvgIconComponent } from '../Icons';
import {
  Wrapper,
  StyledLink,
  Dot,
  Element,
  TagSection,
  UserHeaderContent,
  WelcomeSection,
  UserProfileLink,
} from './styled';

interface SingleEmployeeProps {
  employee: Employee;
  navigateBackLink: string;
  nameIsClickable?: boolean;
}

export const SingleEmployeeHeader = ({
  employee,
  navigateBackLink,
  nameIsClickable = false,
}: SingleEmployeeProps) => {
  const {
    firstName,
    lastName,
    position,
    departmentRole,
    userSource,
    avatar,
    id: employeeId,
  } = employee;

  const { data: departments } = useQuery<Department[], Error>(
    'departments',
    DepartmentsService.fetchDepartments,
  );

  const departmentName = useMemo(
    () => departments?.find(({ id }) => id === departmentRole?.departmentId)?.name,
    [departments, departmentRole],
  );

  return (
    <Wrapper data-cy="single-employee-header">
      <StyledLink to={navigateBackLink}>
        <SvgIconComponent icon="ArrowLeftSkinnyIcon" width={24} />
        <span>Back</span>
      </StyledLink>
      <UserHeaderContent>
        <AvatarWrapper>
          <Avatar name={`${lastName} ${firstName}`} size="56" round={true} src={avatar} />
        </AvatarWrapper>
        {nameIsClickable ? (
          <UserProfileLink to={`${routes.team}/${employeeId}`}>
            {lastName} {firstName}
          </UserProfileLink>
        ) : (
          <WelcomeSection>
            {lastName} {firstName}
          </WelcomeSection>
        )}

        <TagSection>
          {position && (
            <>
              <Element>{position}</Element>
              <Dot />
            </>
          )}
          {departmentName && departmentRole && (
            <>
              <Element style={{ marginRight: 4 }}>{departmentName}</Element>
              <Element>{departmentRole.name}</Element>
              <Dot />
            </>
          )}
          {userSource && <Element>{userSource.name}</Element>}
        </TagSection>
      </UserHeaderContent>
    </Wrapper>
  );
};
