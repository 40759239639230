import styled, { css } from 'styled-components';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { InputStyleProps } from './types';

export const StyledInput = styled.input<InputStyleProps>`
  background-color: ${({ theme }) => theme.input.background};
  color: ${({ theme }) => theme.input.placeholder};
  border: none;
  box-shadow: none;
  width: 100%;
  margin-left: 1rem;

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
    ${({ variant: { size } }) => typography.paragraphs[size]}
  }

  :focus-visible {
    outline: none;
  }
`;

export const Wrap = styled.div<{ withoutBorder?: boolean }>`
  width: auto;
  min-height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1em 0 1em;
  background-color: ${({ theme }) => theme.input.background};
  color: ${({ theme }) => theme.input.placeholder};
  border-radius: ${borderRadius.xs};
  border: 1px solid ${({ theme }) => theme.input.border};
  box-shadow: 0 0 1px ${({ theme }) => theme.input.border};

  ${({ withoutBorder }) =>
    withoutBorder &&
    css`
      border: none;
      box-shadow: none;
    `}
`;

export const LeftSide = styled.div`
  width: 100%;
  padding-right: 1em;
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
