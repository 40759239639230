import { Dispatch, SetStateAction } from 'react';
import { InputFull } from 'components/Inputs';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import { Select } from 'components/Select';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { Employee } from 'services/EmployeesService';
import { HolidayProps } from 'services/HolidayService';
import { HolidayStatuses, HolidayType } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { HolidayTypesEnums } from 'types';
import { ContainerForm, Row, StyledBriefcaseIcon, StyledCalendarIcon } from '../styled';
import { TextPanel } from './TextPanel';

interface FormProps {
  holiday: HolidayProps;
  leaveSelectedByEmployee: HolidayType | undefined;
  employee: Employee;
  employeeHolidayType: HolidayType[];

  startDate: Date;
  endDate: Date;
  handleStartDateSelect: Dispatch<SetStateAction<Date>>;
  handleEndDateSelect: Dispatch<SetStateAction<Date>>;

  selectedHoliday: {
    key: string;
    value: string;
  };

  setSelectedHoliday: Dispatch<
    SetStateAction<{
      key: string;
      value: string;
    }>
  >;

  additionalComment: string | null;
}

export const Form = ({
  leaveSelectedByEmployee,
  employee,
  employeeHolidayType,
  startDate,
  endDate,
  handleEndDateSelect,
  handleStartDateSelect,
  selectedHoliday,
  setSelectedHoliday,
  holiday,
  additionalComment,
}: FormProps) => {
  const { getColorByTheme } = useThemeContext();

  const individualEmployeeHolidayTypes =
    employee?.kidDateOfBirth === null
      ? employeeHolidayType.filter((holType) => holType.key !== HolidayTypesEnums.Kid)
      : employeeHolidayType;

  const holidayTypes =
    employee?.employmentType?.key === 'b2b'
      ? individualEmployeeHolidayTypes.filter(
          (holType) =>
            holType.key === HolidayTypesEnums.Vacation || holType.key === HolidayTypesEnums.Sick,
        )
      : individualEmployeeHolidayTypes;

  const colorIcon = getColorByTheme(colors.gray[700], colors.gray[400]);

  const additionalCommentAfterTrim = additionalComment?.trim();

  const showAdditionalComment =
    !!additionalCommentAfterTrim &&
    additionalCommentAfterTrim !== 'null' &&
    additionalCommentAfterTrim !== null &&
    additionalCommentAfterTrim.length > 0;

  return (
    <ContainerForm>
      <div style={{ gridColumnStart: 2 }}>
        <Select
          label="Holiday type"
          placeholder={`${leaveSelectedByEmployee}`}
          options={holidayTypes}
          selectedOption={selectedHoliday}
          onOptionClicked={setSelectedHoliday}
          labelSelector={(holiday_types) => holiday_types.value}
          dataCY="holiday-type-select"
        />
      </div>

      <StyledCalendarIcon width={24} icon="CalendarOutlineIcon" color={colorIcon} />

      <RangeDatePicker
        label="Holiday Time"
        startDate={startDate}
        endDate={endDate}
        setStartDateSelect={handleStartDateSelect}
        setEndDateSelect={handleEndDateSelect}
        dataCY="holiday-time-datepicker"
        disableBothDateCheck={holiday.status === HolidayStatuses.Declined}
      />

      <StyledBriefcaseIcon width={18} icon="BriefcaseIcon" color={colorIcon} />

      <Row>
        <InputFull
          label="Contract"
          placeholder={employee.employmentType ? employee.employmentType.value : ''}
          disabled={true}
          dataCY="contract-input"
        />
        <InputFull
          label="Billing"
          placeholder="Select the billing type"
          disabled={true}
          dataCY="billing-input"
        />
      </Row>

      {showAdditionalComment && (
        <>
          <StyledBriefcaseIcon width={18} icon="InfoIcon" color={colorIcon} />
          <TextPanel title="Additional comment" content={additionalCommentAfterTrim} />
        </>
      )}

      {holiday.status === HolidayStatuses.Declined && (
        <>
          <StyledBriefcaseIcon width={18} icon="RejectedIcon" color="red" />
          <TextPanel title="Reason" content={holiday.reason} />
        </>
      )}
    </ContainerForm>
  );
};
