import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'components/Inputs';
import Text from 'components/Text';
import { All, Everyone } from 'const';
import DepartmentsService, { Department } from 'services/DepartmentsService';
import { GetReportsParams, ReportHolidayProps } from 'services/ReportsService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { EmploymentTypeInterfaceTypes } from 'types';
import { useReports } from '../../hooks';
import { EmployeesList } from './components/EmployeeList';
import { Parameters } from './components/Parameters';
import { FullReportHolidays, HeadingFullReport, NoEmployeesFound } from './components/styled';
import { Container, StyledLayout } from './styled';

const perPage = 25;

export const Reports = () => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const { isLoading, isFetching, employeesLoading, dataReports, meta, fetchReports } = useReports();
  const [startDate, setStartDate] = useState<null | Date>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<null | Date>(new Date());
  const startDateFormat = dayjs(startDate).format('YYYY-MM-DD');
  const endDateFormat = dayjs(endDate).format('YYYY-MM-DD');
  const [searchValue, setSearchValue] = useState<string>('');
  const [checkedReports, setCheckedReports] = useState<ReportHolidayProps[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(true);
  const [selectedDep, setSelectedDep] = useState<Department | null>(null);

  const { isLoading: departmentsLoading, data: departments } = useQuery<Department[], Error>(
    'departments',
    DepartmentsService.fetchDepartments,
  );

  const selectedDepApiId = useMemo(
    () => (selectedDep?.id === Everyone.id ? undefined : selectedDep?.name || selectedDep?.id),
    [selectedDep],
  );

  const employmentTypes: EmploymentTypeInterfaceTypes[] = employmentTypesStore.listData
    ? [All, ...employmentTypesStore.listData]
    : [All];
  const [selectedEmploymentType, setSelectedEmploymentType] =
    useState<EmploymentTypeInterfaceTypes>(employmentTypes[0]);

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    const newReports = dataReports.filter((report) => {
      const filterByDepartment = () => {
        if (!!selectedDepApiId) return report.department === selectedDepApiId;
        else return true;
      };

      const filterByEmploymentType = () => {
        if (selectedEmploymentType.key === 'all') return true;
        else if (!!selectedEmploymentType.key)
          return report.employmentType.key === selectedEmploymentType.key;
        else return true;
      };

      return filterByDepartment() && filterByEmploymentType();
    });

    setCheckedReports([...new Set(newReports)]);
  }, [dataReports, selectedDepApiId, selectedEmploymentType]);

  useEffect(() => {
    if (checkedReports.length !== 0) {
      return setIsAllChecked(selectedIds.length === checkedReports.length);
    }
  }, [checkedReports, selectedIds]);

  useEffect(() => {
    if (checkedReports.length === 0) {
      setSelectedIds([]);
    }
  }, [checkedReports, isAllChecked]);

  useEffect(() => {
    setIsAllChecked(true);
  }, [selectedDepApiId, selectedEmploymentType, searchValue]);

  useEffect(() => {
    if (isAllChecked) {
      return setSelectedIds(checkedReports.map((report) => report.id));
    }
    if (checkedReports.length === 0 && selectedIds.length === 0) {
      return setIsAllChecked(false);
    }
  }, [checkedReports, isAllChecked]);

  const baseParams = useMemo<GetReportsParams>(() => {
    return {
      'by_department_id':
        selectedDep && selectedDep.name !== 'Everyone' ? selectedDep.id : undefined,
      'by_employment_type': selectedEmploymentType.key !== 'all' ? selectedEmploymentType.key : '',
      'query': searchValue,
      'date[from]': startDateFormat,
      'date[to]': endDateFormat,
      'per': perPage,
    };
  }, [endDateFormat, searchValue, selectedDep, startDateFormat, selectedEmploymentType]);

  const debouncedFetchEmployees = useMemo(
    () =>
      debounce((val: GetReportsParams) => {
        fetchReports(val);
      }, 300),
    [fetchReports],
  );

  useEffect(() => {
    debouncedFetchEmployees(baseParams);
  }, [baseParams, debouncedFetchEmployees]);

  const handleNextPage = async () => {
    if (meta?.nextPage && !isFetching) {
      fetchReports({
        'by_department_id':
          selectedDep && selectedDep.name !== 'Everyone' ? selectedDep.id : undefined,
        'by_employment_type':
          selectedEmploymentType.key !== 'all' ? selectedEmploymentType.key : '',
        'query': searchValue,
        'page': meta.nextPage,
        'date[from]': startDateFormat,
        'date[to]': endDateFormat,
      });
    }
  };

  const loading = useMemo(
    () => (isFetching ? false : employeesLoading || departmentsLoading),
    [isFetching, departmentsLoading, employeesLoading],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.currentTarget.value);
    },
    [setSearchValue],
  );

  const handleCheckedAll = (checked: boolean, selectedData: number[]) => {
    setIsAllChecked(checked);
    setSelectedIds(selectedData);
  };

  const toggleIsAllChecked = useCallback(() => {
    if (isAllChecked || (isAllChecked && checkedReports.length === 0)) {
      handleCheckedAll(false, []);
    } else {
      handleCheckedAll(
        true,
        checkedReports.map((report) => report.id),
      );
    }
  }, [isAllChecked, checkedReports]);

  return (
    <StyledLayout>
      <Container>
        <Parameters
          selectedDep={selectedDep}
          setSelectedDep={setSelectedDep}
          departments={departments || []}
          selectedDepApiId={selectedDepApiId}
          employmentTypes={employmentTypes}
          selectedEmploymentType={selectedEmploymentType}
          setSelectedEmploymentType={setSelectedEmploymentType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startDateFormat={startDateFormat}
          endDateFormat={endDateFormat}
          selectedIds={isAllChecked && !searchValue ? [] : selectedIds}
          isAllChecked={isAllChecked}
          checkedReports={checkedReports}
        />
        <FullReportHolidays>
          <HeadingFullReport>
            <Text.Heading type="5">Full Report Holidays</Text.Heading>
            <Input
              placeholder="Search for employee"
              value={searchValue}
              onChange={handleSearch}
              crossMarkIconOnClick={() => setSearchValue('')}
              maxLength={50}
              dataCY="reports-search-input"
            />
          </HeadingFullReport>
          {!isLoading && (
            <EmployeesList
              isLoading={loading}
              selectedIds={selectedIds}
              isAllChecked={isAllChecked}
              toggleIsAllChecked={toggleIsAllChecked}
              setSelectedIds={setSelectedIds}
              reports={checkedReports}
              handleNextPage={handleNextPage}
              hasMorePages={!!meta?.nextPage}
            />
          )}
          {checkedReports.length === 0 && !isLoading && (
            <NoEmployeesFound>No employees found</NoEmployeesFound>
          )}
        </FullReportHolidays>
      </Container>
    </StyledLayout>
  );
};
