import { colors } from './colors';

const lightTheme = {
  text: {
    highlight: colors.dark.tertiary,
    fail: colors.dark.fail,
    900: colors.gray[900],
    800: colors.gray[800],
    700: colors.gray[700],
    600: colors.gray[600],
  },
  textHeaderInProfile: colors.gray[900],
  backgroundElement: colors.white,
  borderElement: colors.gray[200],
  background: colors.modalColorFirefox,
  table: {
    text: colors.gray[800],
    header: colors.gray[600],
    hover: colors.gray[50],
  },
  requestColors: {
    pending: colors.light.tertiary,
    holiday: colors.dark.tertiary,
    rejected: colors.dark.fail,
    demand: colors.primary,
    polishHolidays: colors.success,
    sick: colors.warning,
    other: colors.light.fail,
  },
  icon: {
    kid: colors.light.fail,
    sick: colors.warning,
    vacation: colors.dark.tertiary,
    basic: colors.gray[900],
    disabled: colors.gray[600],
    accept: colors.dark.tertiary,
    fail: colors.dark.fail,
  },
  input: {
    label: colors.gray[700],
    text: colors.gray[600],
    background: colors.white,
    placeholder: colors.gray[600],
    disabledPlaceholder: colors.gray[600],
    border: colors.gray[300],
    datePickerPlaceholder: colors.gray[800],
    checked: colors.dark.tertiary,
    disabledColor: colors.secondary,
  },
  select: {
    selected: {
      placeholder: colors.gray[800],
    },
    disabled: {
      placeholder: colors.gray[500],
    },
    basic: {
      placeholder: colors.gray[800],
    },
    openDropDownList: {
      background: colors.white,
      hovered: colors.tertiary,
    },
  },
  smallCalendar: {
    title: colors.gray[900],
    backgroundHeader: colors.gray[50],
    backgroundHover: 'rgba(171, 178, 186, 0.2)',
    day: colors.gray[500],
    text: colors.gray[700],
    border: colors.gray[200],
    selected: colors.dark.tertiary,
    navigationIcon: colors.gray[700],
    textDisabled: colors.gray[500],
    boxShadow: '0px 8px 12px rgba(12, 26, 75, 0.1)',
    today: {
      background: 'rgba(87, 171, 255, 0.2)',
      text: colors.tertiary,
    },
  },
  calendar: {
    dayNumberColor: colors.gray[700],
    header: colors.surface.secondary,
  },
  weekCalendar: {
    user: {
      background: colors.surface.secondary,
      border: colors.secondary,
    },
  },
  fullHolidaysList: colors.white,
  button: {
    basic: {
      background: colors.white,
      text: colors.gray[700],
      borderColor: colors.borderButton,
    },
    tertiary: {
      background: colors.dark.tertiary,
      text: colors.white,
    },
    tertiaryOutline: {
      text: colors.dark.tertiary,
      background: 'transparent',
      borderColor: colors.dark.tertiary,
    },
    disabled: {
      background: colors.secondary,
      text: colors.gray[600],
    },
    hover: {
      text: colors.white,
      background: colors.dark.tertiary,
    },
  },
  dashboard: {
    filterBtn: colors.white,
    filterBtnWrapper: colors.light.secondary,
    text: colors.gray[900],
    filterBtnBorderColor: colors.gray[300],
    rowHover: colors.surface.secondary,
  },
  department: {
    activeBg: colors.light.secondary,
    activeText: colors.gray[800],
    text: colors.gray[700],
    border: colors.gray[200],
  },
  modal: {
    iconClose: colors.gray[800],
    background: colors.modalBackgroundLight,
    elementInside: '',
    button: '',
    border: colors.modalBorderLight,
    text: colors.gray[700],
    rejectModalBackground: colors.modalBackgroundLight,
  },
  profile: {
    header: colors.gray[900],
    subHeader: colors.gray[600],
    text: colors.gray[700],
    link: colors.gray[800],
    divider: colors.secondary,
    legend: colors.gray[800],
    userName: colors.gray[800],
  },
  toggleSwitch: {
    backgroundActive: colors.white,
    backgroundDisabled: colors.light.secondary,
  },
  DropdownList: {
    shadow: {
      firstValue: colors.shadowToast.light.firstValue,
      secondValue: colors.shadowToast.light.secondValue,
    },
  },
  toast: {
    text: colors.black,
    border: colors.toastBorderLigth,
    shadow: {
      firstValue: colors.shadowToast.light.firstValue,
      secondValue: colors.shadowToast.light.secondValue,
    },
    svg: {
      left: '',
      right: colors.black,
    },
    types: {
      outline: {
        background: colors.surface.primary,
      },
      outlineAlt: {
        background: colors.surface.primary,
        color: colors.surface.primary,
      },
      solid: {
        color: colors.surface.primary,
      },
    },
  },
  holidayForm: {
    arrow: colors.gray[800],
    requestHeader: colors.gray[800],
    employeeFullName: colors.gray[900],
    employeePosition: colors.gray[600],
    background: colors.secondary,
    input: colors.white,
    capacity: {
      borderColor: colors.gray[200],
      background: colors.white,
    },
    dataPicker: '',
    rejectButton: '',
    svg: '',
    holidayBalance: {
      background: colors.white,
      border: colors.gray[200],
    },
    messegaOnRejectModal: colors.gray[800],
    textPanel: {
      text: colors.gray[600],
      background: colors.secondary,
      border: colors.gray[300],
    },
  },
};

const darkTheme = {
  text: {
    highlight: colors.dark.tertiary,
    fail: colors.fail,
    900: colors.surface.primary,
    800: colors.gray[200],
    700: colors.gray[600],
    600: colors.gray[500],
  },
  textHeaderInProfile: colors.gray[300],
  backgroundElement: colors.background,
  borderElement: colors.gray[800],
  background: colors.gray[800],
  table: {
    text: colors.white,
    header: colors.gray[500],
    hover: colors.gray[800],
  },
  requestColors: {
    pending: '#57ABFF',
    holiday: colors.tertiary,
    rejected: '#EB5757',
    demand: colors.primary,
    polishHolidays: '#66CC91',
    sick: colors.warning,
    other: colors.light.fail,
  },
  icon: {
    kid: colors.light.fail,
    sick: colors.warning,
    vacation: colors.tertiary,
    basic: colors.white,
    disabled: colors.gray[600],
    accept: colors.tertiary,
    fail: colors.fail,
  },
  input: {
    label: colors.gray[500],
    text: colors.gray[400],
    background: colors.gray[700],
    placeholder: colors.gray[600],
    disabledPlaceholder: colors.gray[100],
    border: colors.gray[800],
    datePickerPlaceholder: colors.white,
    checked: colors.tertiary,
    disabledColor: colors.gray[600],
  },
  select: {
    selected: {
      placeholder: colors.white,
    },
    disabled: {
      placeholder: colors.white,
    },
    basic: {
      placeholder: colors.white,
    },
    openDropDownList: {
      background: colors.gray[800],
      hovered: colors.gray[700],
    },
  },
  smallCalendar: {
    title: colors.gray[300],
    backgroundHeader: colors.gray[800],
    backgroundHover: 'rgba(135, 145, 157, 0.2)',
    day: colors.gray[600],
    text: colors.gray[300],
    selected: colors.tertiary,
    navigationIcon: colors.gray[500],
    textDisabled: colors.gray[700],
    boxShadow: '0px 8px 12px rgba(12, 26, 75, 0.1)',
    border: colors.gray[800],
    today: {
      background: 'rgba(87, 171, 255, 0.2)',
      text: colors.tertiary,
    },
  },
  calendar: {
    dayNumberColor: colors.gray[500],
    header: colors.surface.secondary,
  },
  weekCalendar: {
    user: {
      background: colors.gray[900],
      border: colors.gray[800],
    },
  },
  fullHolidaysList: colors.background,
  button: {
    basic: {
      background: 'transparent',
      text: colors.gray[300],
      borderColor: colors.gray[700],
    },
    tertiary: {
      background: colors.dark.tertiary,
      text: colors.white,
    },
    tertiaryOutline: {
      text: colors.tertiary,
      background: 'transparent',
      borderColor: colors.tertiary,
    },
    disabled: {
      background: colors.gray[800],
      text: colors.gray[500],
    },
    hover: {
      text: colors.white,
      background: colors.tertiary,
    },
  },
  dashboard: {
    filterBtn: colors.gray[700],
    filterBtnWrapper: colors.gray[800],
    text: colors.gray[600],
    filterBtnBorderColor: colors.gray[500],
    rowHover: colors.gray[800],
  },
  department: {
    activeBg: colors.gray[800],
    activeText: colors.white,
    text: colors.white,
    border: colors.gray[800],
  },
  modal: {
    iconClose: colors.gray[300],
    background: colors.modalBackgroundDark,
    elementInside: '',
    button: '',
    border: colors.modalBorderDark,
    text: colors.gray[300],
    rejectModalBackground: colors.modalBackgroundDark,
  },
  profile: {
    header: colors.gray[300],
    subHeader: colors.gray[500],
    text: colors.gray[500],
    link: colors.gray[200],
    divider: colors.gray[700],
    legend: colors.secondary,
    userName: colors.gray[50],
  },
  toggleSwitch: {
    backgroundActive: colors.gray[300],
    backgroundDisabled: colors.gray[900],
  },
  DropdownList: {
    shadow: {
      firstValue: colors.shadowToast.dark.firstValue,
      secondValue: colors.shadowToast.dark.secondValue,
    },
  },
  toast: {
    text: colors.white,
    border: colors.toastBorderDark,
    svg: {
      left: '',
      right: colors.white,
    },
    shadow: {
      firstValue: colors.shadowToast.dark.firstValue,
      secondValue: colors.shadowToast.dark.secondValue,
    },
    types: {
      outline: {
        background: colors.gray[800],
      },
      outlineAlt: {
        background: '',
        color: colors.white,
      },
      solid: {
        color: colors.white,
      },
    },
  },
  holidayForm: {
    arrow: colors.gray[400],
    requestHeader: colors.gray[400],
    employeeFullName: colors.gray[900],
    employeePosition: colors.gray[600],
    background: colors.background,
    input: colors.background,
    capacity: {
      borderColor: colors.gray[800],
      background: colors.background,
    },
    dataPicker: '',
    rejectButton: '',
    svg: '',
    holidayBalance: {
      background: colors.background,
      border: colors.gray[800],
    },
    messegaOnRejectModal: colors.white,
    textPanel: {
      text: colors.white,
      background: colors.gray[600],
      border: colors.gray[600],
    },
  },
};

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
