import InfiniteScroll from 'react-infinite-scroll-component';
import { EmployeesTileProps } from 'components/EmployeesTile/types';
import { EmployeeTile } from 'components/EmployeeTile/EmployeeTile';
import { Spinner } from 'components/Spinner';
import { useUserContext } from 'contexts/UserContext';
import { routes } from 'routes';
import { EmployeesWrap, StyledLink } from './styled';

export const EmployeesTile = ({
  employees,
  departments,
  handleNextPage,
  hasMorePages,
}: EmployeesTileProps) => {
  const { isManager } = useUserContext();

  return (
    <InfiniteScroll
      dataLength={employees.length}
      next={handleNextPage}
      hasMore={hasMorePages}
      loader={<Spinner />}
      style={{ overflow: 'initial' }}>
      <EmployeesWrap>
        {employees.map(({ id, firstName, lastName, departmentRole, userSource, avatar }) => {
          const department = departments.find((dep) => dep.id === departmentRole?.departmentId);

          if (isManager) {
            return (
              <StyledLink to={`${routes.team}/${id}`} key={id}>
                <EmployeeTile
                  fullName={`${lastName} ${firstName}`}
                  avatar={avatar}
                  department={department}
                  departmentRole={departmentRole}
                  userSource={userSource}
                  key={id}
                />
              </StyledLink>
            );
          }

          return (
            <EmployeeTile
              fullName={`${lastName} ${firstName}`}
              avatar={avatar}
              department={department}
              departmentRole={departmentRole}
              userSource={userSource}
              key={id}
            />
          );
        })}
      </EmployeesWrap>
    </InfiniteScroll>
  );
};
