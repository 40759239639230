import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { BalanceComponentProps } from 'components/Modals/types';
import { MessagePosition, MessageWrapper } from 'components/Select/styled';
import Text from 'components/Text';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { colors } from 'styles/colors';

export const BalanceComponent = ({
  title,
  requestingHoliday,
  onDemand,
  onDemandTotal,
  useLeft,
  total,
  tooManyOnDemand,
  onDemandValue,
}: BalanceComponentProps) => {
  const { getColorByTheme } = useThemeContext();
  const textColor = getColorByTheme(colors.gray[700], colors.gray[300]);

  const showOnDemand = onDemand >= 0 ? onDemandTotal - onDemand : 0;

  const allHolidaysUsed = useLeft < 0;
  const tooMuchDays = Math.abs(useLeft);

  const message = `You have exceeded your holiday balance by ${tooMuchDays} days`;

  return (
    <>
      <Text.Heading style={{ marginBottom: 16 }} type="4">
        {title}
      </Text.Heading>
      <HolidaySummaryRow
        left="Requesting"
        right={requestingHoliday}
        color={colors.dark.tertiary}
        weight={600}
      />

      {!!onDemandTotal && (
        <HolidaySummaryRow
          left="On demand left"
          right={`${showOnDemand}/${onDemandTotal || 0}`}
          color={`${textColor}`}
        />
      )}

      <HolidaySummaryRow
        left="Left"
        right={`${allHolidaysUsed ? 0 : useLeft}/${total || 0}`}
        color={`${textColor}`}
        weight={600}
      />

      <MessageWrapper>
        {tooManyOnDemand && (
          <MessagePosition>
            "{onDemandValue}" can only be taken for 1 day, for today or the next working day.
          </MessagePosition>
        )}

        {tooManyOnDemand && allHolidaysUsed && <MessagePosition>{'&'}</MessagePosition>}

        {allHolidaysUsed && <MessagePosition>{message}</MessagePosition>}
      </MessageWrapper>
    </>
  );
};
