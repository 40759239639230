import styled from 'styled-components';
import { InputFull } from 'components/Inputs';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import { colors } from 'styles/colors';

export const More10yInput = styled(InputFull)`
  margin-top: 40px;
`;

export const SeniorityForm = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid ${colors.dark.tertiary};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .container:hover input ~ .checkmark {
    background-color: transparent;
    border: 2px solid ${colors.dark.tertiary};
  }

  .container input:checked ~ .checkmark {
    background-color: white;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: flex;
  }

  .container .checkmark:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${colors.dark.tertiary};
  }
`;

export const StyledRangeDatePicker = styled(RangeDatePicker)`
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

export const PositionInput = styled(InputFull)`
  margin-bottom: 2.5rem;
`;
