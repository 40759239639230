import styled from 'styled-components';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';
import Text from '../../../../../components/Text';
import { MonthDayBoxProps } from '../../../types';

export const HeaderWrapper = styled.div`
  display: flex;
  top: 10rem;
  position: sticky;
  z-index: 500;

  border-top-left-radius: ${borderRadius.sm};
  border-top-right-radius: ${borderRadius.sm};
  background-color: ${({ theme }) => theme.backgroundElement};
  padding: 0.5em 0 0.5em 0;
`;

export const FiltersWrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: 1fr auto;
  min-width: 19rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DepartmentSelectWrapper = styled.div`
  width: 100%;
  align-self: center;
`;

export const TeamCapacityWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
`;

export const StyledProgress = styled.progress`
  max-width: 3.125rem;
  height: 0.625rem;
  margin: 0.3rem 0.3rem 0.3rem 0.75rem;
`;

export const DaysLegendWrapper = styled.div`
  flex: 4;
  display: grid;
  grid-template-columns: repeat(7, minmax(5rem, 1fr));
`;

export const DayBoxParagraphs = styled(Text.Paragraphs)`
  color: ${({ theme }) => theme.text[600]};
`;

export const DayBoxContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  user-select: none;
`;

export const MonthDayBox = styled.div<MonthDayBoxProps>`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 200ms ease;

  border-radius: ${borderRadius.xs};
  border: 1px solid
    ${({ theme, isSelected }) => {
      return isSelected ? theme.text.highlight : theme.button.basic.borderColor;
    }};

  background-color: ${({ isSelected }: MonthDayBoxProps) => {
    return isSelected ? colors.light.tertiary : 'inherit';
  }};

  margin-top: 0.5rem;
  cursor: pointer;
`;

export const CountBadge = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -30%);

  min-width: 1rem;
  height: 1rem;

  padding: 0.25em 0.25em;

  background-color: ${({ theme }) => theme.text.highlight};
  color: ${colors.white};

  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${borderRadius.xs};
`;

export const DepartmentSelectHeaderWrapper = styled.div`
  display: grid;
  width: 9.375rem;
  padding-left: 0.625rem;
`;
