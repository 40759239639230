import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { routes } from '.';
import { Access } from '../services/AuthService';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
  access: Access;
}

export const ProtectedRoute = ({
  children,
  component: Component,
  access,
  ...rest
}: ProtectedRouteProps) => {
  const { getUser, isAuthenticated } = useUserContext();
  const user = getUser();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          const isAuthorized = isAuthenticated && user.access === access;
          if (isAuthorized) {
            return <Component {...props} />;
          } else {
            return <Redirect to={routes.notFound} />;
          }
        } else {
          return <Redirect to={routes.login} />;
        }
      }}
    />
  );
};
