// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import ApiBaseService, { PaginationMeta } from './ApiBaseService';

export interface FetchReportsResponseType {
  data: ReportHolidayProps[];
  meta: PaginationMeta;
  error?: Error;
}

export interface GetReportsParams {
  'by_department_id'?: number;
  'by_employment_type'?: string;
  'query'?: string;
  'date[from]': string;
  'date[to]': string;
  'page'?: number;
  'per'?: number;
}

export interface ReportHolidayProps {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  employmentType: {
    key: string;
    value: string;
  };
  startContractFrom: string;
  employedFrom: string;
  department: string;
  base: number;
  additional: number;
  total: number;
  onDemand: number;
  onDemandTotal: number;
  used: number;
  requested: number;
  available: number;
  sickLeave: number;
  onKidTotal: number;
  onKid: number;
}

export const initialPagination: PaginationMeta = {
  currentPage: 1,
  nextPage: null,
  prevPage: null,
  totalPages: 1,
  totalCount: 0,
};

export default class ReportsService extends ApiBaseService {
  static readonly API_URL = this.API_BASE_URL + '/reports/holidays';

  static fetchReportHolidays = async (params?: GetReportsParams) => {
    try {
      const { data } = await axios.get<FetchReportsResponseType>(this.API_URL, { params });
      return data;
    } catch (error) {
      return {
        data: [],
        meta: initialPagination,
        error,
      } as FetchReportsResponseType;
    }
  };
}
