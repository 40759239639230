import { colors } from '../../../styles/colors';
import { StyledSvgIcon, StyledToggle } from './styled';
import { ToggleSwitchProps } from './types';

export function ToggleSwitch({
  value,
  onChange,
  leftIcon = 'SunIcon',
  rightIcon = 'MoonIcon',
}: ToggleSwitchProps) {
  return (
    <StyledToggle>
      <label className="toggle-switch">
        <input type="checkbox" checked={value} onChange={onChange} />
        <div className="switch">
          <StyledSvgIcon icon={leftIcon} color={value ? colors.gray[600] : colors.gray[800]} />
          <StyledSvgIcon icon={rightIcon} color={value ? colors.gray[800] : colors.gray[600]} />
        </div>
      </label>
    </StyledToggle>
  );
}
