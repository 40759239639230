import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { CONFIG } from 'const/config';
import AccountService, { UserResponse } from 'services/AccountService';
import AuthService, { Access } from 'services/AuthService';
import { makeContext } from './ContextCreator';

type UserContextType = {
  login: (idToken: string, accessToken: string, refreshToken: string) => Promise<void>;
  getUser: () => UserResponse;
  fetchAuthenticatedUser: () => void;
  isAuthenticated: boolean;
  isUserLoaded: boolean;
  isManager: boolean;
};

export const [useUserContext, UserProvider] = makeContext<UserContextType>(() => {
  const [user, setUser] = useState<UserResponse>();
  const token = Cookies.get(CONFIG.SSO_COOKIE_KEY);
  const isAuthenticated = Boolean(token);

  useEffect(() => {
    if (isAuthenticated) {
      fetchAuthenticatedUser();
    }
  }, [isAuthenticated]);

  if (token) {
    AuthService.setAxiosToken(token);
  }

  interface JwtPayload {
    aud: unknown;
    exp: number;
    iat: number;
    jti: string;
    scp: string;
    sub: string;
  }

  const login = async (idToken: string, accessToken: string, refreshToken: string) => {
    const jwtPayload = jwtDecode<JwtPayload>(idToken);
    const expireDate = new Date(jwtPayload.exp * 1000);
    const tokenForOneYear = new Date(expireDate.setFullYear(expireDate.getFullYear() + 1));

    Cookies.set(CONFIG.SSO_COOKIE_KEY, idToken, {
      path: '/',
      domain: CONFIG.SSO_COOKIE_DOMAIN,
      expires: tokenForOneYear,
    });
    const response = await AuthService.login(idToken, accessToken, refreshToken);
    setUser(response);
  };

  const getUser = () => {
    if (!user) {
      throw new TypeError('User should be declared');
    }
    return user;
  };

  const fetchAuthenticatedUser = async () => {
    const response = await AccountService.getAccountInfo();
    setUser(response);
    return response;
  };

  return {
    login,
    getUser,
    fetchAuthenticatedUser,
    isUserLoaded: Boolean(user),
    isAuthenticated: isAuthenticated,
    isManager: Boolean(user?.access === Access.Manager),
  };
});
