import { filterHolidaysByType, getHolidayColorByType, getHolidayDaysByMonth } from 'utils/calendar';
import { UseSelectedHolidayTypesProps } from '../types';
import { HolidayTypeListItem } from '../types/types';

export const useSelectedHolidayTypes = ({
  holidayTypes,
  selectedHolidayTypes,
  teamHolidays,
  monthIndex,
}: UseSelectedHolidayTypesProps): HolidayTypeListItem[] => {
  return holidayTypes
    .map((holidayType) => ({
      holidayType,
      label: holidayType.value,
      days: getHolidayDaysByMonth(filterHolidaysByType(teamHolidays, holidayType.key), monthIndex),
      color: getHolidayColorByType(holidayType.key),
      checked: selectedHolidayTypes.some((type) => type.key === holidayType.key),
    }))
    .filter((item) => item.days !== 0);
};
