import React from 'react';
import { TileWrapper } from 'components/TileWrapper';
import { TeamHoliday } from 'services/HolidaysService';
import { YourHolidays } from '../../../components/Calendar';
import { EmptyState, HolidayRow } from './styled';

interface Props {
  holidays: TeamHoliday[];
}
export const HolidaysTile = ({ holidays }: Props) => {
  return (
    <TileWrapper title="Holidays">
      {holidays.length > 0 ? (
        <HolidayRow>
          <YourHolidays holidaysData={holidays} />
        </HolidayRow>
      ) : (
        <EmptyState>No holidays</EmptyState>
      )}
    </TileWrapper>
  );
};
