import styled from 'styled-components';
import { CapacityHolidayTile } from 'components/Calendar/CapacityHolidayTile';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 35rem 1fr;
  column-gap: 5rem;
  margin-top: 2.5rem;
  margin-left: 2rem;
`;

export const HolidaysFormCapacity = styled(CapacityHolidayTile)`
  row-gap: 2rem;
  max-width: 22rem;
`;
