import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Checkbox, InputFull, Select } from 'components';
import Text from 'components/Text';
import { generateArrayOfYears } from 'helpers';
import { AdditionalHolidaUpdateTypes } from 'services/EmployeesService';
import { AdditionalHolidaysFieeldsNamesEnumType } from 'types';
import { RemoveHolidayElement } from './RemoveHolidayElement';
import {
  ElementsInOneLine,
  StyledCheckboxWrapper,
  StyledInputWrapper,
  StyledSelectWrapper,
  StyledTextWrapper,
} from './styled';

interface Props {
  item: AdditionalHolidaUpdateTypes;
  isB2B: boolean;
  getYear: number;
  isManager: boolean;
  handleChangeValuesHoliday: (
    e: SyntheticEvent<HTMLInputElement>,
    index: string,
    field: AdditionalHolidaysFieeldsNamesEnumType,
  ) => void;
  handleChangeValuesSelectHoliday: (
    option: number,
    index: string,
    field: AdditionalHolidaysFieeldsNamesEnumType,
  ) => void;
  handleChangeRepeatEveryYear: (index: string) => void;
  setRemoveAdditionalHolidayModal: () => void;
  handleDataToRemove: (index: string) => void;
  removeLocalHoliday: (localId: string) => void;
}

export const AdditionalHolidayListItem = ({
  item,
  isB2B,
  getYear,
  isManager,
  removeLocalHoliday,
  setRemoveAdditionalHolidayModal,
  handleChangeValuesHoliday,
  handleChangeValuesSelectHoliday,
  handleChangeRepeatEveryYear,
  handleDataToRemove,
}: Props): JSX.Element => {
  const [name, setName] = useState<string>(item?.name || '');
  const [days, setDays] = useState<number | string>(item?.days || '');
  const [selectYeaer, setSelectYear] = useState<number>(item?.year || getYear);
  const [repeat, setRepeat] = useState<boolean>(item?.repeatEveryYear || false);

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChangeValuesHoliday(
      event,
      item.localId || `${item.id}-${item.name}` || '',
      AdditionalHolidaysFieeldsNamesEnumType.NAME,
    );
    const result = (event.target as HTMLInputElement).value;
    setName(result);
  };

  const handleChangeDays = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChangeValuesHoliday(
      event,
      item.localId || `${item.id}-${item.name}` || '',
      AdditionalHolidaysFieeldsNamesEnumType.DAYS,
    );
    const result = (event.target as HTMLInputElement).value;
    setDays(result);
  };

  const handleChangeYear = (year: number): void => {
    handleChangeValuesSelectHoliday(
      year,
      item.localId || `${item.id}-${item.name}` || '',
      AdditionalHolidaysFieeldsNamesEnumType.YEAR,
    );
    setSelectYear(year);
  };

  const handleChangeRepeat = (): void => {
    handleChangeRepeatEveryYear(item.localId || `${item.id}-${item.name}` || '');
    setRepeat(!repeat);
  };

  const handleRemoveHoliday = () => {
    if (!item.localId) {
      setRemoveAdditionalHolidayModal();
      handleDataToRemove(item.id?.toString() || '');
    } else {
      removeLocalHoliday(item.localId);
    }
  };

  return (
    <>
      <ElementsInOneLine>
        {isB2B ? (
          <StyledSelectWrapper>
            <InputFull
              label="Holiday type"
              placeholder={item?.name || 'name holiday type'}
              value={name}
              onChange={handleChangeName}
            />
          </StyledSelectWrapper>
        ) : (
          <StyledTextWrapper>
            <Text.Paragraphs type="xSmall" weight={700}>
              Remaining vacation(s) from previous year(s)
            </Text.Paragraphs>
          </StyledTextWrapper>
        )}
        <RemoveHolidayElement
          handleClick={handleRemoveHoliday}
          id={item.localId || item.id?.toString()}
          isManager={isManager}
        />
      </ElementsInOneLine>
      <>
        <StyledInputWrapper>
          <InputFull
            label="Days"
            placeholder="00"
            type="number"
            value={days}
            onChange={handleChangeDays}
          />
          <Select
            label="Year"
            options={generateArrayOfYears()}
            selectedOption={selectYeaer}
            placeholder={item.year?.toString() || getYear.toString()}
            onOptionClicked={handleChangeYear}
          />
        </StyledInputWrapper>
        {isB2B && (
          <StyledCheckboxWrapper>
            <Checkbox
              label={'Repeat every year'}
              isChecked={repeat}
              checkboxAction={handleChangeRepeat}
            />
          </StyledCheckboxWrapper>
        )}
      </>
    </>
  );
};
