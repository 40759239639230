import styled from 'styled-components';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const EmployeeTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: ${borderRadius.sm};
  background-color: ${({ theme }) => theme.backgroundElement};
  padding: 1.875em 0;
  width: 100%;
`;

export const WelcomeSection = styled.div`
  ${typography.heading[3]};
  margin: 0.5rem 0 0.425rem 0;
  color: ${({ theme }) => theme.text[900]};
  text-align: center;
`;

export const TagSection = styled.div`
  ${typography.heading[6]}
  display: flex;
  list-style-type: none;
  align-items: center;
`;

export const Element = styled.div`
  color: ${({ theme }) => theme.text[600]};
  padding: 0 0;
`;

export const Dot = styled.div`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.text[600]};
  margin: 0 0.5em;
`;

export const AvatarWrapper = styled.div`
  img {
    object-fit: cover;
  }
`;
