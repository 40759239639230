import { Fragment, useState } from 'react';
import dayjs from 'dayjs';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayWeekCalendarModal } from 'components/Modals';
import { useCalendarContext } from 'contexts/CalendarContext';
import { UserGridProps } from '../../../types';
import { UserGridWrapper, ActionArea } from './styled';
import { VacationPanel } from './VacationPanel';

export const UserGrid = ({ user }: UserGridProps) => {
  const { firstDayOfWeek, date } = useCalendarContext();

  const [isModalVisible, toggleModal] = useModal();
  const [chosenDay, setChosenDay] = useState<Date>();

  const moreHolidayTypesInTheSameDay = user.holidays.length > 1;

  return (
    <UserGridWrapper>
      {user.holidays.map((holiday) => {
        const daysToHolidayStart = dayjs(holiday.dateFrom).diff(firstDayOfWeek, 'days');
        const daysToHolidayEnd = dayjs(holiday.dateTo).diff(firstDayOfWeek, 'days');
        const holidayDays = dayjs(holiday.dateTo).diff(holiday.dateFrom, 'days');

        const holidayStarts = daysToHolidayStart < 0;
        const holidayEnds = holidayDays + daysToHolidayStart >= 7;

        const getDayIndex = (): number => {
          if (holidayStarts) {
            return 1;
          }

          return (daysToHolidayStart % 7) + 1;
        };

        const getHolidayDuration = () => {
          if (holidayStarts) {
            return daysToHolidayEnd + 1;
          } else if (holidayEnds) {
            return 7 - daysToHolidayStart;
          } else {
            return holidayDays + 1;
          }
        };

        const dayIndex = getDayIndex();
        const duration = getHolidayDuration();

        const sentDate = (day: number) => {
          setChosenDay(
            dayjs(`${date.year()}-${date.month() + 1}`)
              .week(date.week())
              .day(day)
              .toDate(),
          );
        };

        return (
          <Fragment key={holiday.id}>
            <VacationPanel
              weekCalendar={true}
              moreHolidayTypesInTheSameDay={moreHolidayTypesInTheSameDay}
              holiday={holiday}
              user={user}
              dayIndex={dayIndex}
              duration={duration}
              startsBeforeCurrentWeek={holidayStarts}
              endsAfterCurrentWeek={holidayEnds}
            />

            {[1, 2, 3, 4, 5, 6, 7].map((dayOffset) => (
              <ActionArea
                onClick={() => {
                  sentDate(dayOffset);
                  toggleModal();
                }}
                key={dayOffset}
                notDisplay={dayOffset === dayIndex}
                numberColumn={dayOffset}
              />
            ))}

            {isModalVisible && chosenDay && (
              <NewRequestHolidayWeekCalendarModal
                isVisible={isModalVisible}
                toggle={toggleModal}
                startDate={chosenDay}
                userId={user.id}
              />
            )}
          </Fragment>
        );
      })}
    </UserGridWrapper>
  );
};
