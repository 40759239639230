import { useCallback, useMemo, useState } from 'react';
import { Dayjs } from 'dayjs';
import { useLocation } from 'react-router-dom';
import { CapacityHolidayTile } from 'components/Calendar/CapacityHolidayTile';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { Spinner } from 'components/Spinner';
import { useCalendarContext } from 'contexts/CalendarContext';
import { useUserContext } from 'contexts/UserContext';
import { AvailableCalendarPath } from '../types';
import { SmallCalendarBasic } from './SmallCalendarBasic';
import { CapacitiesParticularDay, MouseTooltipWrapper } from './styled';
import { SmallCalendarProps, DateFormat } from './types';

const settingPath = (path: string): string => {
  if (path.includes(AvailableCalendarPath.TEAM_CALENDAR)) {
    return AvailableCalendarPath.TEAM_CALENDAR;
  } else if (path.includes(AvailableCalendarPath.CALENDAR)) {
    return AvailableCalendarPath.CALENDAR;
  } else {
    return AvailableCalendarPath.SINGLE_EMPLOYEE_YEAR_CALENDAR;
  }
};

export const SmallCalendar = ({ customMonthIndex, holidaysData }: SmallCalendarProps) => {
  const { isManager, getUser } = useUserContext();
  const { holiday, employmentType } = getUser();

  const location = useLocation();
  const chosenCalendar = useMemo(() => settingPath(location.pathname), [location.pathname]);
  const { date } = useCalendarContext();

  const [isVisibleEmployeeModal, toggleEmployeeModal] = useModal();
  const [isVisibleManagerModal, toggleManagerModal] = useModal();

  const [showCapacitiesParticularDay, setShowCapacitiesParticularDay] = useState(false);
  const [dayToCapacities, setDayToCapacities] = useState<string>('');
  const [dayToWeekCapacities, setDayToWeekCapacities] = useState({
    startDay: '',
    endDay: '',
  });

  const onDayCapacityProps = useMemo(
    () => ({
      'date[from]': `${dayToCapacities}`,
      'date[to]': `${dayToCapacities}`,
      'departmentId': null,
    }),
    [dayToCapacities],
  );

  const weekCapacityProps = useMemo(
    () => ({
      'date[from]': `${dayToWeekCapacities.startDay}`,
      'date[to]': `${dayToWeekCapacities.endDay}`,
      'departmentId': null,
    }),
    [dayToWeekCapacities],
  );

  const mouseOver = useCallback(
    (day: Dayjs) => {
      if (chosenCalendar === AvailableCalendarPath.TEAM_CALENDAR && isManager) {
        setDayToWeekCapacities({
          startDay: day.startOf('week').add(1, 'days').format(DateFormat.YEAR),
          endDay: day.endOf('week').add(1, 'days').format(DateFormat.YEAR),
        });
        setDayToCapacities(day.format(DateFormat.YEAR));
        setShowCapacitiesParticularDay(true);
      }
    },
    [isManager, chosenCalendar],
  );

  if (!getUser().holiday) {
    return <Spinner fullPage />;
  }

  return (
    <div data-cy="small-calendar" onMouseLeave={() => setShowCapacitiesParticularDay(false)}>
      <SmallCalendarBasic
        customMonthIndex={customMonthIndex}
        holidaysData={holidaysData}
        toggleManagerModal={toggleManagerModal}
        toggleEmployeeModal={toggleEmployeeModal}
        setShowCapacitiesParticularDay={setShowCapacitiesParticularDay}
        mouseOver={mouseOver}
      />

      {isVisibleEmployeeModal && (
        <NewRequestHolidayModal
          isVisible={isVisibleEmployeeModal}
          toggle={toggleEmployeeModal}
          holiday={holiday}
          employmentType={employmentType}
          startDate={date.toDate()}
        />
      )}

      {isVisibleManagerModal && (
        <NewRequestHolidayModal
          isVisible={isVisibleManagerModal}
          toggle={toggleManagerModal}
          withEmployeeDropdown
          employmentType={null}
          buttonProps={{ variant: 'tertiary', label: 'Add holiday' }}
          startDate={date.toDate()}
        />
      )}

      {isManager && chosenCalendar === AvailableCalendarPath.TEAM_CALENDAR && (
        <MouseTooltipWrapper visible={showCapacitiesParticularDay} offsetX={30} offsetY={-54}>
          <CapacitiesParticularDay data-cy="capacity-particular-day">
            <CapacityHolidayTile
              capacityWeekParams={weekCapacityProps}
              capacityDayParams={onDayCapacityProps}
            />
          </CapacitiesParticularDay>
        </MouseTooltipWrapper>
      )}
    </div>
  );
};
