import { useEffect, useMemo } from 'react';
import { AvailableCalendarPath } from 'components/Calendar/types';
import { getMonthMatrix } from 'utils';
import { StyledNavbar } from 'views/Dashboard/styled';
import { SmallCalendar, MonthCalendar } from '../../components/Calendar';
import { useCalendarContext, useUserContext, useHolidaysContext } from '../../contexts';
import { YourBalance, YourHolidaysWrapperComponent, CalendarHeader } from './components';
import { CalendarLayout, Header, SidebarWrapper, Wrapper } from './styled';

export const UserCalendar = () => {
  const { monthIndex, date } = useCalendarContext();
  const { smallCalendarUserHolidays, yearHolidays } = useHolidaysContext();
  const { getUser, fetchAuthenticatedUser } = useUserContext();
  const { holiday, employmentType } = getUser();

  const year = useMemo(() => date.year(), [date]);

  useEffect(() => {
    fetchAuthenticatedUser();
  }, [year]);

  const currentMonthMatrixMemoized = useMemo(
    () => getMonthMatrix(monthIndex, year),
    [monthIndex, year],
  );

  return (
    <>
      <StyledNavbar />
      <Wrapper>
        <Header>
          <CalendarHeader holiday={holiday} employmentType={employmentType} />
        </Header>
        <CalendarLayout>
          <SidebarWrapper
            isCalendarMonth={window.location.pathname.includes(AvailableCalendarPath.CALENDAR)}>
            <SmallCalendar holidaysData={smallCalendarUserHolidays} customMonthIndex={monthIndex} />
            <YourBalance holiday={holiday} />
            <YourHolidaysWrapperComponent yearHolidays={yearHolidays} />
          </SidebarWrapper>
          <MonthCalendar month={currentMonthMatrixMemoized} />
        </CalendarLayout>
      </Wrapper>
    </>
  );
};
