import { useEffect } from 'react';
import { useToastContext } from 'contexts/ToastContext';
import { useFetchHolidaysForCurrentWeek } from '../../hooks';
import { WeekCalendarProps } from '../../types';
import { Header } from './Header';
import { WeekCalendarWrapper, WeekWithoutHoliday } from './styled';
import { UserGrid, UserInfo, UserRowWrapper } from './UserRow';

export const WeekCalendar = ({
  filteredUsers,
  searchPhraseState,
  selectedDayState,
  selectedDepartmentIdState,
}: WeekCalendarProps) => {
  const { dataToast } = useToastContext();
  const fetchHolidays = useFetchHolidaysForCurrentWeek();

  useEffect(fetchHolidays, [fetchHolidays, dataToast]);

  return (
    <WeekCalendarWrapper>
      <Header
        searchPhraseState={searchPhraseState}
        selectedDayState={selectedDayState}
        selectedDepartmentIdState={selectedDepartmentIdState}
        filteredUsers={filteredUsers}
      />
      {Object.keys(filteredUsers).length !== 0 ? (
        filteredUsers.map((user) => (
          <UserRowWrapper key={user.id}>
            <UserInfo user={user} />
            <UserGrid user={user} />
          </UserRowWrapper>
        ))
      ) : (
        <WeekWithoutHoliday>No holidays this week</WeekWithoutHoliday>
      )}
    </WeekCalendarWrapper>
  );
};
