import { Button } from 'components/Button/ButtonBase';
import { SingleDatePicker } from 'components/Inputs/DatePicker/SingleDatePicker';
import { routes } from 'routes';
import { CalendarSwitch } from '../CalendarSwitch';
import { CalendarButtonsContainer, DatePickerWrapper, StyledLink } from './styled';
import { CalendarButtonsProps } from './types';

export const CalendarButtons = ({
  onChange,
  date,
  handleReset,
  selectTypeCalendar,
  calendarType,
  setCalendarType,
}: CalendarButtonsProps) => {
  return (
    <CalendarButtonsContainer>
      {selectTypeCalendar && calendarType !== undefined && setCalendarType !== undefined && (
        <CalendarSwitch selectedOption={calendarType} setSelectedOption={setCalendarType} />
      )}

      <DatePickerWrapper>
        <SingleDatePicker
          variant="outline"
          onChange={onChange}
          date={date}
          dataCY="team-calendar-buttons-date-picker"
        />
      </DatePickerWrapper>

      <Button
        label="Today"
        variant="basic"
        size="regular"
        onClick={handleReset}
        dataCY="team-calendar-buttons-today-button"
        activeHover={true}
      />

      <StyledLink to={routes.calendar}>
        <Button
          label="Go to my calendar"
          variant="basic"
          size="regular"
          dataCY="team-calendar-buttons-go-to-my-calendar"
          activeHover={true}
        />
      </StyledLink>
    </CalendarButtonsContainer>
  );
};
