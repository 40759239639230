import { useState } from 'react';
import { getAuth, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { ButtonNavbar } from 'components/Button/ButtonNavbar';
import { DropdownList } from 'components/DropdownMenu';
import { SvgIconComponent } from 'components/Icons';
import { Notification } from 'components/NotificationIcon';
import { ToggleSwitch } from 'components/Switches/ToggleSwitch';
import { ThemeMode, useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { User } from 'components/User';
import { CONFIG } from 'const/config';
import { useUserContext } from 'contexts/UserContext';
import { routes } from 'routes';
import AuthService from 'services/AuthService';
import { firebaseApp } from '../../firebaseApp';
import {
  SignInButton,
  SecondSection,
  LayoutContainer,
  FirstSection,
  LogoText,
  ThirdSection,
  StyledLink,
  TextLeoHoliday,
  NavbarDropdownWrapper,
} from './styled';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'consent' });
const auth = getAuth(firebaseApp);

export const Navbar = ({ className }: { className?: string }) => {
  const { activeTheme, themeToggle } = useThemeContext();
  const { isAuthenticated, isManager, getUser } = useUserContext();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const idManager = isAuthenticated && getUser().id;

  const history = useHistory();

  const submitHandler = () => {
    AuthService.logout();
    Cookies.remove(CONFIG.SSO_COOKIE_KEY, {
      path: '/',
      domain: CONFIG.SSO_COOKIE_DOMAIN,
    });
    auth.signOut();
    window.location.reload();
  };

  const activeLoader = !!Cookies.get('active_loader');

  const direction = isManager ? `${routes.team}/${idManager}` : routes.profile;

  const listItems = [
    {
      dataCy: 'your-profile',
      name: 'Your profile',
      action: () => isAuthenticated && history.push(direction),
    },
    { dataCy: 'sign-out', name: 'Sign out', action: submitHandler },
  ];

  const loginWithGoogle = async () => {
    Cookies.set('active_loader', 'true', { path: '/', domain: CONFIG.SSO_COOKIE_DOMAIN });
    await signInWithRedirect(auth, provider);
  };

  return (
    <LayoutContainer className={className}>
      <FirstSection>
        <SvgIconComponent icon="SquaresIcon" />

        <StyledLink to={isManager ? routes.dashboard : routes.calendar}>
          <LogoText>
            <SvgIconComponent icon="LionIcon" width={19.31} height={27.27} />
            <TextLeoHoliday>Leo Holiday</TextLeoHoliday>
          </LogoText>
        </StyledLink>
      </FirstSection>

      {!isAuthenticated ? (
        <>
          {/* For MVP -> This empty div is for in order to function properly navbar, in future we change this */}
          <div />
          {!activeLoader && (
            <SignInButton
              onClick={loginWithGoogle}
              label="Sign In"
              variant="tertiary"
              dataCY="sign-in-navbar-button"
            />
          )}
        </>
      ) : (
        <SecondSection>
          {isManager && (
            <ButtonNavbar to={routes.dashboard} label="Dashboard" data-cy="dashboard-tab" />
          )}
          <ButtonNavbar to={routes.calendar} label="Calendar" data-cy="calendar-tab" />
          <ButtonNavbar
            to={routes.teamCalendar}
            label="Team calendar"
            data-cy="team-calendar-tab"
          />
          {isManager && <ButtonNavbar to={routes.reports} label="Reports" data-cy="reports-tab" />}
          {isManager && <ButtonNavbar to={routes.team} label="Team" data-cy="team-tab" />}
        </SecondSection>
      )}

      {isAuthenticated && (
        <>
          <ThirdSection>
            <ToggleSwitch value={activeTheme === ThemeMode.dark} onChange={themeToggle} />
            <Notification numOfNotifications={-1} />
          </ThirdSection>
          <User onClick={() => setShowDropdownMenu(!showDropdownMenu)} />
          {showDropdownMenu && (
            <NavbarDropdownWrapper>
              <DropdownList listObject={listItems} />
            </NavbarDropdownWrapper>
          )}
        </>
      )}
    </LayoutContainer>
  );
};
