import dayjs from 'dayjs';
import { CalendarButtons, CalendarMonthNavigation } from 'components/Calendar';
import { CalendarType } from 'components/Calendar/types';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { useCalendarContext } from 'contexts/CalendarContext';
import { useUserContext } from 'contexts/UserContext';
import { EmployeeTeamCalendarHeaderProps } from '../../types';
import { ButtonOpenModal, CalendarHeaderWrapper, Container } from '../styled';

export const EmployeeTeamCalendarHeader = ({ calendarType }: EmployeeTeamCalendarHeaderProps) => {
  const {
    date,
    firstDayOfWeek,
    decreaseMonthIndex,
    increaseMonthIndex,
    decreaseWeekIndex,
    increaseWeekIndex,
    handleReset,
    setDate,
  } = useCalendarContext();

  const handleNext = () => {
    calendarType === CalendarType.Month ? increaseMonthIndex() : increaseWeekIndex();
  };

  const handlePrev = () => {
    calendarType === CalendarType.Month ? decreaseMonthIndex() : decreaseWeekIndex();
  };

  const formatDate = () => {
    if (calendarType === CalendarType.Month) {
      return date.format('MMMM YYYY');
    } else {
      const startDay = firstDayOfWeek;
      const endDay = startDay.add(6, 'day');

      return `${startDay.format('MMM DD')} - ${endDay.format('MMM DD')}`;
    }
  };

  const { getUser } = useUserContext();
  const { holiday, employmentType } = getUser();
  const [isVisible, toggle] = useModal();

  const handleDateChange = (inputDate: Date) => setDate(dayjs(inputDate));

  return (
    <CalendarHeaderWrapper data-cy="employee-team-calendar-header">
      <Container>
        <ButtonOpenModal
          iconNameLeft="PlusIcon"
          label="New request"
          size="regular"
          variant="tertiary"
          onClick={toggle}
          dataCY="employee-team-calendar-new-request-button"
        />

        {isVisible && (
          <NewRequestHolidayModal
            isVisible={isVisible}
            toggle={toggle}
            holiday={holiday}
            employmentType={employmentType}
          />
        )}

        <CalendarMonthNavigation
          prevIcon="ArrowLeftIcon"
          prevIconWidth={25}
          nextIcon="ArrowRightIcon"
          nextIconWidth={25}
          onClickPrevIcon={handlePrev}
          onClickNextIcon={handleNext}
          isFormatDate
          formatDate={formatDate()}
        />
      </Container>

      <CalendarButtons onChange={handleDateChange} date={date.toDate()} handleReset={handleReset} />
    </CalendarHeaderWrapper>
  );
};
