import Text from '../Text';
import { RowBetween } from './styled';
import { HolidaySummaryRowProps } from './types';

export const HolidaySummaryRow = ({ left, right, color, weight }: HolidaySummaryRowProps) => {
  return (
    <RowBetween>
      <Text.Paragraphs {...{ color, weight }} type="xSmall">
        {left}
      </Text.Paragraphs>
      <Text.Paragraphs {...{ color, weight }} type="xSmall">
        {right}
        {right === 1 ? ' day' : ' days '}
      </Text.Paragraphs>
    </RowBetween>
  );
};
