import InlineSVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';
import { DEFAULT_ICON_SIZE } from 'const';

export const SvgIconStyled = styled(InlineSVG)<{
  width?: number;
  height?: number;
  rotate?: number;
  color?: string;
  onClick?: VoidFunction;
  isDark?: boolean;
}>`
  width: ${DEFAULT_ICON_SIZE}px;

  color: ${({ theme }) => theme.icon.basic};

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate}deg);
    `}

    ${({ color }) =>
    color &&
    css`
       {
        color: ${color};
    `}
`;
