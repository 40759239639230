import styled, { css } from 'styled-components';
import { DEFAULT_ICON_SIZE } from 'const';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { InputStyleProps, WrapInputProps } from './types';

export const StyledInput = styled.input<InputStyleProps>`
  background-color: ${({ theme }) => theme.input.background};
  color: ${({ theme }) => theme.input.placeholder};
  border: none;
  box-shadow: none;
  width: calc(100% - ${DEFAULT_ICON_SIZE}px);
  color: ${({ theme, isError }) => (isError ? colors.fail : theme.input.text)};

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
    ${({ variant: { size } }) => typography.paragraphs[size]}
  }

  :focus-visible {
    outline: none;
  }

  &[type='number'] {
    &::-moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &[type='password'] {
    ${typography.placeholderDotsPassword};
    ${typography.paragraphs.small};
    color: ${({ isError }) => (isError ? colors.fail : colors.gray[500])};
    letter-spacing: 0.2rem;

    &::placeholder {
      ${typography.placeholderDotsPassword};
      ${typography.paragraphs.small};
      color: ${colors.secondary};
      letter-spacing: 0.2rem;
    }
  }

  &[type='password-visible'] {
    ${typography.placeholderDotsPassword};
    ${typography.paragraphs.small};
    color: ${({ isError }) => (isError ? colors.fail : colors.gray[700])};

    &::placeholder {
      ${typography.placeholderDotsPassword};
      ${typography.paragraphs.small};
      color: ${({ theme }) => theme.input.placeholder};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.input.disabledColor};
    color: ${({ theme }) => theme.input.disabledPlaceholder};

    ::placeholder {
      color: ${({ theme }) => theme.input.disabledPlaceholder};
    }
  }

  ${({ holidayForm }) =>
    holidayForm &&
    css`
      background-color: ${({ theme }) => theme.holidayForm.input} !important;
    `}
`;

export const WrapInput = styled.div<WrapInputProps>`
  box-sizing: border-box;
  width: auto;
  height: 2.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625em 0 1em;

  background-color: ${({ theme }) => theme.input.background};
  border-radius: ${borderRadius.xs};

  border: 1px solid ${({ theme, isError }) => (isError ? colors.fail : theme.input.border)};
  box-shadow: 0 0 1px ${({ theme }) => theme.input.border};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.input.disabledColor};
    `}

  ${({ holidayForm }) =>
    holidayForm &&
    css`
      background-color: ${({ theme }) => theme.holidayForm.input} !important;
    `}
`;

export const InputFullLabel = styled.div`
  color: ${({ theme }) => theme.input.label};
`;

export const Wrap = styled.div`
  border-radius: ${borderRadius.xs};
`;
