import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'globalStyle';
import { themes } from 'styles/themes';

export enum ThemeMode {
  light = 'light',
  dark = 'dark',
}

interface WrapperProps {
  children?: ReactNode;
}

export const ThemeContext = createContext({
  activeTheme: ThemeMode.light,
  themeToggle: () => {},
  getColorByTheme: (defaultColor: string, darkThemeColor: string) => defaultColor,
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeWrapper = ({ children }: WrapperProps) => {
  useEffect(() => {
    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', ThemeMode.light);
    }
  }, []);

  const [theme, setTheme] = useState(
    (localStorage.getItem('theme') as ThemeMode) ?? ThemeMode.light,
  );

  const getColorByTheme = (defaultColor: string, darkThemeColor: string): string => {
    return theme === ThemeMode.dark ? darkThemeColor : defaultColor;
  };

  const themeToggle = () => {
    const currentTheme = theme === ThemeMode.light ? ThemeMode.dark : ThemeMode.light;
    setTheme(currentTheme);
    localStorage.setItem('theme', currentTheme);
  };

  const { light, dark } = themes;

  return (
    <ThemeProvider theme={theme === ThemeMode.light ? light : dark}>
      <ThemeContext.Provider value={{ activeTheme: theme, themeToggle, getColorByTheme }}>
        <GlobalStyle />
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};
