import styled from 'styled-components';
import { remCalc } from 'helpers';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';

export const AdditionalHolidayWrapper = styled.div`
  margin-top: -1.5rem;
`;

export const ElementsInOneLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AdditionalHolidayTitle = styled.div`
  margin-bottom: 1rem;
  ${typography.heading['5']};
`;

export const NewAdditionalHoliday = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${colors.tertiary};
  border-radius: 100%;
  border: none;
  cursor: pointer;
`;

export const RemoveAdditionalHoliday = styled.div`
  background-color: none;
  cursor: pointer;
  padding-bottom: ${remCalc(12)};
  display: flex;
  align-items: flex-end;
  margin-right: ${remCalc(6)};
`;

export const StyledSelectWrapper = styled.div`
  width: ${remCalc(264)};
  padding-top: ${remCalc(32)};
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  padding-top: ${remCalc(8)};

  & > div:first-child {
    flex: 1;
  }

  & > div:last-child {
    margin-left: ${remCalc(24)};
    margin-right: ${remCalc(36)};
    flex: 2;
  }
`;

export const StyledTextWrapper = styled.div`
  padding: ${remCalc(30)} 0 ${remCalc(6)};
`;

export const StyledCheckboxWrapper = styled.div`
  padding: ${remCalc(8)} 0;
`;
