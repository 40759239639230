import dayjs from 'dayjs';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { getDayIndex } from 'utils';
import { useCalendarContext } from '../../../contexts';
import Text from '../../Text';
import { Ellipsis } from '../../Text/Ellipsis';
import { HolidayBar } from '../styled';
import { TeamHolidayBarProps } from './types';

export const TeamHolidayBar = ({
  filteredTeamHolidays,
  day,
  weekRows,
  onClick,
}: TeamHolidayBarProps) => {
  const { getHolidayColorByType, getHolidayColorByStatus } = useCalendarContext();

  return (
    <>
      {[0, 1, 2, 3, 4].map((row) => {
        const dayIndex = getDayIndex(day);
        const holidayId = weekRows[row][dayIndex];
        const teamHoliday = filteredTeamHolidays.find((hol) => hol.id === holidayId);

        if (!teamHoliday) {
          return (
            <HolidayBar
              color={'transparent'}
              data-cy="team-calendar-holiday-bar"
              key={Math.random()}>
              <Text.Paragraphs
                type="xSmall"
                color={'transparent'}
                weight={700}
                style={{ visibility: 'hidden' }}>
                &nbsp;
              </Text.Paragraphs>
            </HolidayBar>
          );
        }

        let holidayOnPreviousDay: number | null = null;
        for (const r in weekRows) {
          if (weekRows[r][dayIndex - 1] === holidayId) {
            holidayOnPreviousDay = holidayId;
          }
        }

        const hasHiddenHolidayOnPreviousDay =
          dayjs(day).isAfter(teamHoliday.dateFrom) && day.day() !== 1 && !holidayOnPreviousDay;

        return (
          <HolidayBar
            onClick={onClick && (() => onClick(teamHoliday))}
            $hasHolidayOnPreviousDay={
              (dayjs(day).isAfter(teamHoliday.dateFrom) && day.day() !== 1) ||
              hasHiddenHolidayOnPreviousDay
            }
            $hasHolidayOnNextDay={dayjs(day).isBefore(teamHoliday.dateTo) && day.day() !== 0}
            color={
              teamHoliday.status === HolidayStatuses.Accepted
                ? getHolidayColorByType(teamHoliday.holidayType)
                : getHolidayColorByStatus(teamHoliday.status)
            }
            withBorder={teamHoliday.status === HolidayStatuses.Pending}
            data-cy="team-calendar-holiday-bar"
            key={teamHoliday.id}>
            <Text.Paragraphs
              type="xSmall"
              color={
                teamHoliday.status === HolidayStatuses.Pending ? colors.gray[800] : colors.white
              }
              style={
                dayjs(day).isAfter(teamHoliday.dateFrom) &&
                day.day() !== 1 &&
                !hasHiddenHolidayOnPreviousDay
                  ? { visibility: 'hidden' }
                  : {}
              }
              weight={700}
              title={`${teamHoliday?.user?.lastName} ${teamHoliday?.user?.firstName}`}>
              <Ellipsis>
                {teamHoliday?.user?.lastName} {teamHoliday?.user?.firstName}
              </Ellipsis>
            </Text.Paragraphs>
          </HolidayBar>
        );
      })}
    </>
  );
};
