import { createGlobalStyle } from 'styled-components';
import { defaultFamily } from 'styles/typography';

const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${defaultFamily};
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text[700]};
  transition: all 0.5s linear;
  height: calc(100vh - 100px);
}

#root {
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`;

export default GlobalStyle;
