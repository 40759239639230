import { useRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { AvailableCalendarPath } from 'components/Calendar/types';
import 'react-datepicker/dist/react-datepicker.css';
import { InputFull } from '../InputFull';
import { CustomDatePickerHeader } from './CustomDatePickerHeader';

export const RawDatePicker = (props: ReactDatePickerProps) => {
  const ref = useRef<DatePicker>(null);
  const arrayForFormat = ['dd.MM.yyyy', 'dd/MM/yyyy', 'dd.MM/yyyy', 'dd/MM.yyyy'];

  const isHolidayForm = window.location.pathname.includes(AvailableCalendarPath.HOLIDAY_FORM);

  return (
    <DatePicker
      ref={ref}
      calendarStartDay={1}
      calendarClassName={'datepicker_calendar'}
      weekDayClassName={() => 'react-datepicker__week'}
      dayClassName={() => 'datepicker_days'}
      wrapperClassName="wrapper"
      dateFormat={arrayForFormat}
      placeholderText={'dd.mm.yyyy'}
      customInput={
        <InputFull
          iconOptions={{
            name: 'CalendarOutlineIcon',
            rotate: 0,
            onClick: () => ref.current?.setOpen(!ref.current.isCalendarOpen()),
            pointer: true,
          }}
          holidayForm={isHolidayForm}
        />
      }
      renderCustomHeader={CustomDatePickerHeader}
      {...props}
    />
  );
};
