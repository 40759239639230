import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { SvgIconComponent } from '../../Icons';
import { months } from './consts';
import { ArrowButton } from './styled';

export const CustomDatePickerHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div style={{ margin: 10, display: 'flex', justifyContent: 'space-between' }}>
      <div>
        {months[date.getMonth()]} {date.getFullYear()}
      </div>
      <div>
        <ArrowButton onClick={decreaseMonth}>
          <SvgIconComponent icon={'ArrowUpIcon'} />
        </ArrowButton>
        <ArrowButton onClick={increaseMonth}>
          <SvgIconComponent icon={'ArrowUpIcon'} rotate={180} />
        </ArrowButton>
      </div>
    </div>
  );
};
