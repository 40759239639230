import { useEffect, useState } from 'react';
import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/Button/ButtonBase';
import { Layout } from 'components/Layout';
import { Spinner } from 'components/Spinner';
import { CONFIG } from 'const/config';
import { useUserContext } from 'contexts';
import { routes } from 'routes';
import { firebaseApp } from '../../firebaseApp';
import { LoginButtonPosition } from './styled';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'consent' });
const auth = getAuth(firebaseApp);

export const Login = () => {
  const { login, isAuthenticated, isManager } = useUserContext();
  const history = useHistory();

  const [idToken, setIdToken] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();

  const activeLoader = !!Cookies.get('active_loader');

  if (activeLoader) {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIdToken(await user.getIdToken());
        setRefreshToken(user.refreshToken);
      }
    });
  }

  useEffect(() => {
    getRedirectResult(auth).then(async (result) => {
      if (result) {
        const accessTokenFromCredential =
          GoogleAuthProvider.credentialFromResult(result)?.accessToken;
        setAccessToken(accessTokenFromCredential);
        Cookies.remove('active_loader', { path: '/', domain: CONFIG.SSO_COOKIE_DOMAIN });
      }
    });
  }, []);

  useEffect(() => {
    if (idToken && accessToken && refreshToken) {
      login(idToken, accessToken, refreshToken);
    }
  }, [idToken, accessToken, refreshToken, login]);

  if (isAuthenticated) {
    history.push(isManager ? routes.dashboard : routes.calendar);
  }

  const loginWithGoogle = async () => {
    Cookies.set('active_loader', 'true', { path: '/', domain: CONFIG.SSO_COOKIE_DOMAIN });
    await signInWithRedirect(auth, provider);
  };

  setTimeout(() => {
    if (activeLoader) {
      auth.signOut();
      Cookies.remove('active_loader', { path: '/', domain: CONFIG.SSO_COOKIE_DOMAIN });
      window.location.reload();
    }
  }, 25000);

  return (
    <Layout>
      <LoginButtonPosition>
        {activeLoader ? (
          <Spinner fullPage />
        ) : (
          <Button
            onClick={loginWithGoogle}
            label="Sign In"
            variant="tertiary"
            dataCY="sign-in-center-button"
            disabled={activeLoader}
          />
        )}
      </LoginButtonPosition>
    </Layout>
  );
};
