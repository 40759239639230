import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Type, Variant } from 'components/AlertComponent/types';
import { useModal } from 'components/Modal/useModal';
import { EditHolidayModal, NewRequestHolidayModal, PreviewHolidayModal } from 'components/Modals';
import { Spinner } from 'components/Spinner';
import { useHolidaysContext } from 'contexts/HolidaysContext';
import { useToastContext } from 'contexts/ToastContext';
import { useUserContext } from 'contexts/UserContext';
import AccountService from 'services/AccountService';
import { ApiError } from 'services/AuthService';
import { Holiday, HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { foundHolidayListContainingDay, foundHolidaysContainingDay } from 'utils';
import { useThemeContext } from '../../ThemeWrapper/ThemeWrapper';
import { EmployeeDayProps } from './Day';
import { DayContainer } from './DayContainer';
import { EmployeeHolidayBar } from './EmployeeHolidayBar';
import { DayBottomWrapper } from './styled';

export const EmployeeDayContent = ({
  day,
  holidayType,
  firstColumn,
  withDaysNames,
  setWeekHasHoliday,
  weekHasHoliday,
}: EmployeeDayProps) => {
  const { getColorByTheme } = useThemeContext();
  const { holidays } = useHolidaysContext();
  const [holiday, setHoliday] = useState<Holiday | undefined>();
  const holidaysListInThisDay = foundHolidayListContainingDay(day, holidays);
  const { fetchAuthenticatedUser, getUser } = useUserContext();
  const { employmentType, holiday: employeeHoliday } = getUser();
  const { handleError, setToast } = useToastContext();

  const [isEditModalVisible, toggleEditModal] = useModal();
  const [isRemoveModalVisible, toggleRemoveModal] = useModal();
  const [isRejectModalVisible, toggleRejectedModal] = useModal();
  const [isNewRequestModalVisible, toggleNewRequestModal] = useModal();
  const [isPreviewModalVisible, togglePreviewModal] = useModal();

  const requestedDates =
    holiday?.dateFrom === holiday?.dateTo
      ? `${dayjs(holiday?.dateFrom).format('dddd, MMMM D')}`
      : `${dayjs(holiday?.dateFrom).format('dddd, MMMM D')} - ${dayjs(holiday?.dateTo).format(
          'dddd, MMMM D',
        )}` || '';

  const filteredHolidays = useMemo(() => {
    return foundHolidaysContainingDay(day, holidays);
  }, [day, holidays]);

  const onSubmitRemoveHoliday = async () => {
    if (!holiday) {
      return;
    }

    try {
      await AccountService.deleteCurrentUserHolidayRequest(holiday.id);
      fetchAuthenticatedUser();
      toggleRemoveModal();
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been deleted.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  const isClickable = useMemo(() => {
    const isDifferentThanDeclined = holidaysListInThisDay.some(
      (el) => el.status !== HolidayStatuses.Declined,
    );
    return holidaysListInThisDay.length === 0 || !isDifferentThanDeclined;
  }, [holidaysListInThisDay]);

  const handleToggleNewRequestModal = () => {
    if (isClickable) {
      return toggleNewRequestModal();
    }
  };

  const onHolidayBarPress = (chosenHoliday: Holiday) => {
    if (chosenHoliday.status === HolidayStatuses.Declined) {
      toggleRejectedModal();
    } else {
      togglePreviewModal();
    }
    setHoliday(chosenHoliday);
  };

  if (!getUser().holiday) {
    return <Spinner fullPage />;
  }

  const showIcon =
    (dayjs(holiday?.dateFrom).isAfter(dayjs()) && holiday?.status !== HolidayStatuses.Declined) ||
    holiday?.status === HolidayStatuses.Pending;

  return (
    <>
      <DayContainer
        withDaysNames={withDaysNames}
        day={day}
        firstColumn={firstColumn}
        setWeekHasHoliday={setWeekHasHoliday}
        weekHasHoliday={weekHasHoliday}
        setOpenNewRequestModal={handleToggleNewRequestModal}
        showClickedDay={
          isNewRequestModalVisible ||
          isEditModalVisible ||
          isRemoveModalVisible ||
          isRejectModalVisible
        }>
        {filteredHolidays &&
          filteredHolidays.map((mappedHoliday: Holiday) => (
            <EmployeeHolidayBar
              day={day}
              onClick={onHolidayBarPress}
              holiday={mappedHoliday}
              label={holidayType?.value}
              key={mappedHoliday.id}
            />
          ))}
        <DayBottomWrapper onClick={handleToggleNewRequestModal} />
      </DayContainer>

      <PreviewHolidayModal
        toggle={() => {
          toggleRemoveModal();
          togglePreviewModal();
        }}
        toggleRemove={() => {
          toggleRemoveModal();
        }}
        isVisibleRemove={isRemoveModalVisible}
        label="Cancel this Holiday?"
        subLabel="Are you sure you want to remove holiday in that date?"
        holidayType={holidayType}
        requestedDates={requestedDates}
        additionalButtonProps={{
          label: 'Cancel this holiday',
          size: 'regular',
          variant: 'fail',
        }}
        buttonProps={{
          label: 'No, keep this holiday',
          size: 'regular',
          variant: 'tertiary',
        }}
        onClickButton={() => {
          toggleRemoveModal();
          togglePreviewModal();
        }}
        onClickAdditionalButton={onSubmitRemoveHoliday}
        crossMarkIcon
        hideButton={false}
        additionalComment={!!holiday ? holiday.additionalComment : null}
      />

      {holiday && (
        <>
          {isEditModalVisible && (
            <EditHolidayModal
              isVisible={isEditModalVisible}
              onTrashPress={() => {
                toggleEditModal();
                toggleRemoveModal();
              }}
              close={() => {
                toggleEditModal();
                togglePreviewModal();
              }}
              editEndDate={new Date(holiday.dateTo)}
              editStartDate={new Date(holiday.dateFrom)}
              holiday={holiday}
              currentHolidayType={holidayType}
              additionalComment={holiday.additionalComment || null}
            />
          )}

          <PreviewHolidayModal
            toggle={toggleRejectedModal}
            isVisibleReject={isRejectModalVisible}
            label="Holiday request"
            subLabel="Your request has been rejected."
            colorSubLabel={getColorByTheme(colors.dark.fail, colors.fail)}
            holidayType={holidayType}
            requestedDates={requestedDates}
            buttonProps={{
              label: 'Close',
              size: 'regular',
              variant: 'tertiaryOutline',
            }}
            onClickButton={toggleRejectedModal}
            reason
            reasonMessage={holiday.reason}
            hideButton
            additionalComment={holiday?.additionalComment}
            crossMarkIcon
          />

          <PreviewHolidayModal
            toggle={togglePreviewModal}
            isVisibleReject={isPreviewModalVisible}
            label="Holiday"
            colorSubLabel={getColorByTheme(colors.dark.fail, colors.fail)}
            holidayType={holidayType}
            requestedDates={requestedDates}
            editIcon={showIcon}
            onEdit={() => {
              toggleEditModal();
              togglePreviewModal();
            }}
            crossMarkIcon
            trashIcon={showIcon}
            onDelete={() => {
              toggleRemoveModal();
              togglePreviewModal();
            }}
            hideButton
            additionalComment={holiday?.additionalComment}
          />
        </>
      )}

      {isNewRequestModalVisible && (
        <NewRequestHolidayModal
          isVisible={isNewRequestModalVisible}
          toggle={toggleNewRequestModal}
          holiday={employeeHoliday}
          employmentType={employmentType}
          startDate={day.toDate()}
        />
      )}
    </>
  );
};
