import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CalendarButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

export const DatePickerWrapper = styled.div`
  padding: 0 0.625em;
  max-width: 7.5rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 1rem;
`;
