import styled from 'styled-components';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const WeekCalendarWrapper = styled.div`
  flex: 4;
`;

export const WeekWithoutHoliday = styled.div`
  height: 6.25rem;
  width: 100%;

  display: grid;
  justify-content: center;
  align-content: center;

  border-top: 1px solid ${({ theme }) => theme.button.basic.borderColor};
  background-color: ${({ theme }) => theme.backgroundElement};
  border-bottom-left-radius: ${borderRadius.sm};
  border-bottom-right-radius: ${borderRadius.sm};

  ${typography.heading[5]};
  color: ${({ theme }) => theme.text[500]};
`;
