import { useMemo, useRef, useState } from 'react';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';
import { useUserContext } from 'contexts/UserContext';
import { colors } from 'styles/colors';
import { foundTeamHolidaysContainingDay } from 'utils';
import { DayProps } from './Day';
import { DayContainer } from './DayContainer';
import { EmployeeTeamHolidayBar } from './EmployeeTeamHolidayBar';
import { FullHolidaysList } from './FullHolidaysList';
import { HolidaysListWrapper, ShowMoreHolidays, MoreArea } from './styled';

export const EmployeeTeamDayContent = ({
  day,
  weekRows,
  firstColumn,
  withDaysNames,
  filterTeamHolidays,
  setWeekHasHoliday,
  weekHasHoliday,
}: DayProps) => {
  const { teamHolidays } = useTeamHolidaysContext();

  const [showFullList, setShowFullList] = useState(false);

  const [isVisibleModal, toggleModal] = useModal();
  const { getUser } = useUserContext();
  const { holiday, employmentType, id } = getUser();

  const filteredTeamHolidays = useMemo(() => {
    const dayHolidays = foundTeamHolidaysContainingDay(day, teamHolidays);
    return dayHolidays.filter(filterTeamHolidays);
  }, [day, teamHolidays, filterTeamHolidays]);

  const moreHolidaysRef = useRef<HTMLDivElement>(null);

  const disableCursor = !!filteredTeamHolidays.find((hol) => hol.user.id === id);

  const onDayClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!moreHolidaysRef.current?.contains(e.target as Node) && !disableCursor) {
      toggleModal();
    }
  };

  return (
    <>
      <DayContainer
        onClick={(e) => onDayClick(e)}
        withDaysNames={withDaysNames}
        day={day}
        firstColumn={firstColumn}
        teamHolidays={!!teamHolidays}
        setWeekHasHoliday={setWeekHasHoliday}
        weekHasHoliday={weekHasHoliday}
        showClickedDay={isVisibleModal}
        disableCursor={disableCursor}>
        <HolidaysListWrapper>
          <div>
            <EmployeeTeamHolidayBar
              day={day}
              filteredTeamHolidays={filteredTeamHolidays}
              weekRows={weekRows}
              disableCursor={disableCursor}
            />
          </div>

          {filteredTeamHolidays.length > 5 && (
            <ShowMoreHolidays ref={moreHolidaysRef}>
              <MoreArea
                type="xxSmall"
                color={colors.gray[800]}
                hoverColor={colors.tertiary}
                weight={700}
                onClick={() => setShowFullList(!showFullList)}
                data-cy="show-more-holidays">
                {!showFullList
                  ? `+ ${filteredTeamHolidays.length - 5} more`
                  : `${filteredTeamHolidays.length} displayed`}
              </MoreArea>
              {showFullList && (
                <FullHolidaysList
                  day={day}
                  teamHolidays={filteredTeamHolidays}
                  setShowFullList={setShowFullList}
                />
              )}
            </ShowMoreHolidays>
          )}
        </HolidaysListWrapper>
      </DayContainer>

      {isVisibleModal && (
        <NewRequestHolidayModal
          isVisible={isVisibleModal}
          toggle={toggleModal}
          holiday={holiday}
          employmentType={employmentType}
          startDate={day.toDate()}
        />
      )}
    </>
  );
};
