import { useState } from 'react';
import { Type, Variant } from 'components/AlertComponent/types';
import { HolidayTile } from 'components/HolidayTile/HolidayTile';
import { InputFullForm } from 'components/Inputs/InputFull/InputFullForm';
// import { SecurityTile } from 'components/SecurityTile';
import { Spinner } from 'components/Spinner';
import { TileWrapper } from 'components/TileWrapper';
import { UserLoggedHeader } from 'components/UserLoggedHeader';
import { useToastContext } from 'contexts';
import { useUserContext } from 'contexts/UserContext';
import { ApiError } from 'services/AuthService';
import EmployeesService, { EmployeeUpdateEmployment } from 'services/EmployeesService';
// import { usePasswordChange } from 'hooks';
import { ProfileUserContent, StyledLayout, Container } from './styled';

export const ProfileLoggedAccount = () => {
  const { getUser, fetchAuthenticatedUser } = useUserContext();
  const { holiday, firstName, lastName, id } = getUser();
  const { setToast, handleError } = useToastContext();

  const [firstNameAfterChange, setFirstNameAfterChange] = useState(firstName);
  const [lastNameAfterChange, setLastNameAfterChange] = useState(lastName);

  if (!firstNameAfterChange || !lastNameAfterChange) {
    return <Spinner fullPage />;
  }

  const isDisabled = firstNameAfterChange !== firstName || lastNameAfterChange !== lastName;

  const handleFirstName = (e: React.FormEvent<HTMLInputElement>) => {
    setFirstNameAfterChange(e.currentTarget.value);
  };

  const handleLastName = (e: React.FormEvent<HTMLInputElement>) => {
    setLastNameAfterChange(e.currentTarget.value);
  };

  // const handlePasswordChange = usePasswordChange();

  const onEmploymentUpdate = async (user: EmployeeUpdateEmployment) => {
    await EmployeesService.updateUserData(Number(id), user);
  };

  const onSubmit = async () => {
    try {
      await onEmploymentUpdate({
        firstName: firstNameAfterChange,
        lastName: lastNameAfterChange,
      });
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Personal details changed successfully!',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      fetchAuthenticatedUser();
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  return (
    <StyledLayout>
      <Container>
        <UserLoggedHeader />
        <ProfileUserContent>
          <TileWrapper
            title={'Personal details'}
            button={{
              content: 'Save',
              onClick: onSubmit,
              disabled: !isDisabled,
              dataCY: 'account-personal-details-save-button',
            }}>
            <InputFullForm
              label={'Name'}
              value={firstNameAfterChange}
              onChange={handleFirstName}
              dataCY="profile-first-name-input"
            />
            <InputFullForm
              label={'Surname'}
              value={lastNameAfterChange}
              onChange={handleLastName}
              dataCY="profile-last-name-input"
            />
          </TileWrapper>
          {/* <SecurityTile onSubmit={handlePasswordChange} /> */}
          <HolidayTile
            available={holiday?.available || 0}
            left={holiday?.left || 0}
            onDemand={holiday?.onDemand || 0}
            onDemandTotal={holiday?.onDemandTotal || 0}
            requested={holiday?.requested || 0}
            total={holiday?.total || 0}
          />
        </ProfileUserContent>
      </Container>
    </StyledLayout>
  );
};
