import React from 'react';
import { YourHolidays } from '../../../../components/Calendar';
import { UserCalendarHeader } from '../../styled';
import { YourHolidaysWrapperComponentProps } from '../../types';
import { YourHolidaysWrapper } from './styled';

export const YourHolidaysWrapperComponent = ({
  yearHolidays,
}: YourHolidaysWrapperComponentProps) => (
  <YourHolidaysWrapper>
    <UserCalendarHeader margin="0 0 16px 0" type="6">
      Your Holidays
    </UserCalendarHeader>
    <YourHolidays holidaysData={yearHolidays} />
  </YourHolidaysWrapper>
);
