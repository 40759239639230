import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { colors } from 'styles/colors';
import Text from '../../../Text/index';
import { TextPanelWrapper } from '../styled';

interface TextPanelProps {
  title: string;
  content?: string;
}

export const TextPanel = ({ title, content }: TextPanelProps) => {
  const { getColorByTheme } = useThemeContext();

  return (
    <div style={{ gridColumnStart: 2 }}>
      <Text.Paragraphs
        type="caption"
        style={{ marginLeft: 0, marginBottom: 8 }}
        color={getColorByTheme(colors.gray[700], colors.gray[500])}>
        {title}
      </Text.Paragraphs>
      <TextPanelWrapper>
        <Text.Paragraphs type="xSmall" color="">
          {content}
        </Text.Paragraphs>
      </TextPanelWrapper>
    </div>
  );
};
