import { PublicHolidayBar } from 'components/Calendar';
import Text from 'components/Text';
import { colors } from 'styles/colors';
import { DayBoxProps } from '../../../types';
import { DayBoxContainer, MonthDayBox, CountBadge, DayBoxParagraphs } from './styled';

export const DayBox = ({ date, holidayCount, onClick, isSelected }: DayBoxProps) => {
  const color = isSelected ? colors.dark.tertiary : colors.gray[600];

  return (
    <DayBoxContainer>
      <div>
        <DayBoxParagraphs>{date.format('dddd')}</DayBoxParagraphs>

        <MonthDayBox isSelected={isSelected} onClick={onClick}>
          <Text.Heading type="5" color={color}>
            {date.format('DD')}
          </Text.Heading>

          {holidayCount > 0 && <CountBadge>{holidayCount}</CountBadge>}
        </MonthDayBox>
      </div>

      <PublicHolidayBar date={date} weekCalendar />
    </DayBoxContainer>
  );
};
