import Avatar from 'react-avatar';
import { EmployeeTileProps } from 'components/EmployeesTile/types';
import {
  Dot,
  Element,
  EmployeeTileWrapper,
  TagSection,
  WelcomeSection,
  AvatarWrapper,
} from './styled';

export const EmployeeTile = ({
  fullName,
  department,
  departmentRole,
  userSource,
  avatar,
}: EmployeeTileProps) => {
  return (
    <EmployeeTileWrapper data-cy="employee-tile">
      <AvatarWrapper>
        <Avatar name={fullName} size="56" round={true} src={avatar} />
      </AvatarWrapper>
      <WelcomeSection>{fullName}</WelcomeSection>
      <TagSection>
        {department && departmentRole && (
          <>
            <Element style={{ marginRight: 4 }}>{department.name}</Element>
            <Element>{departmentRole.name}</Element>
          </>
        )}

        {userSource && (
          <>
            <Dot />
            <Element>{userSource.name}</Element>
          </>
        )}
      </TagSection>
    </EmployeeTileWrapper>
  );
};
