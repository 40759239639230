import React from 'react';
import { Dayjs } from 'dayjs';
import { Holiday } from 'services/HolidaysService';
import { MonthType } from 'utils';

export type SmallCalendarDayType = {
  day: Dayjs;
  onPress?: (date: Dayjs) => void;
  onDotMouseOver?: VoidFunction;
  onDotMouseLeave?: VoidFunction;
  employeeCalendar: boolean;
  onClick?: VoidFunction;
  isSingleEmployeeYearCalendar?: boolean;
  children: React.ReactNode;
  holidaysData: Holiday[];
};

export enum DateFormat {
  'YEAR' = 'YYYY-MM-DD',
  'MONTH' = 'MMMM YYYY',
}

export interface SmallCalendarNavigationProps {
  currentYear: number;
  currentMonthIdx: number;
  setCurrentMonthIdx: (value: number) => void;
  setCurrentYear: (value: number) => void;
  showNavigation: boolean;
}

export interface SmallCalendarProps {
  customMonthIndex: number;
  showNavigation?: boolean;
  holidaysData: Holiday[];
}

export interface SmallCalendarBasicProps extends SmallCalendarProps {
  toggleEmployeeModal?: () => void;
  toggleManagerModal?: () => void;
  mouseOver?: (day: Dayjs) => void;
  setShowCapacitiesParticularDay?: (value: boolean) => void;
}

export interface SmallCalendarBodyProps {
  currentMonth: MonthType;
  smallCalendarUserHolidays: Holiday[];
  currentMonthIdx: number;
  chosenCalendar: string;
  toggleManagerModal?: () => void;
  toggleEmployeeModal?: () => void;
  setShowCapacitiesParticularDay?: (value: boolean) => void;
  mouseOver?: (day: Dayjs) => void;
  isManager: boolean;
  holidaysData: Holiday[];
}
