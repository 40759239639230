import React, { useEffect, useRef, useState } from 'react';
import { AvailableCalendarPath } from 'components/Calendar/types';
import { SvgIconComponent } from 'components/Icons';
import { colors } from '../../styles/colors';
import Text from '../Text';
import { useThemeContext } from '../ThemeWrapper/ThemeWrapper';
import { SelectDropdown } from './SelectDropdown/SelectDropdown';
import { DropDownContainer, DropDownHeader, SelectedText } from './styled';
import { SelectProps } from './types';

export function Select<T>(props: SelectProps<T>) {
  const {
    label,
    firstForcedInfo,
    placeholder,
    selectedOption,
    labelSelector,
    headerSelector,
    className,
    dataCY,
    withoutBorder,
    disabled = false,
    variant,
  } = props;

  const [isDropdownOpen, toggleDropdown] = useState(false);
  const { getColorByTheme } = useThemeContext();
  const [showEnteredValue, setShowEnteredValue] = useState(true);
  const toggling = () => toggleDropdown((prevState) => !prevState);

  const myRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if (!myRef.current?.contains(e.target as Node)) {
      toggleDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const renderSelector = () => {
    if (!selectedOption) return placeholder;
    else if (headerSelector) {
      return headerSelector(selectedOption);
    } else if (labelSelector) {
      return labelSelector(selectedOption);
    }
    return placeholder;
  };

  const selectType = () => {
    if (variant) return variant;

    if (selectedOption && !disabled) {
      return 'selected';
    }
    if (disabled) {
      return 'disabled';
    }
    return 'basic';
  };

  const isHolidayForm = window.location.pathname.includes(AvailableCalendarPath.HOLIDAY_FORM);

  const backgroundForSelectComponent = isHolidayForm
    ? getColorByTheme(colors.white, colors.background)
    : getColorByTheme(colors.white, colors.gray[800]);

  return (
    <div
      ref={myRef}
      onClick={handleClickOutside as object as React.MouseEventHandler<HTMLDivElement>}
      className={className}>
      {label && (
        <Text.Paragraphs
          type="caption"
          color={getColorByTheme(colors.gray[700], colors.gray[500])}
          style={{ marginLeft: 0, marginBottom: 8 }}>
          {label}
        </Text.Paragraphs>
      )}
      <DropDownContainer data-cy={dataCY}>
        <DropDownHeader
          variant={selectType()}
          withoutBorder={withoutBorder}
          onClick={toggling}
          style={{ backgroundColor: backgroundForSelectComponent }}>
          <SelectedText variant={selectType()}>
            {!!firstForcedInfo && showEnteredValue && firstForcedInfo}
            {renderSelector()}
          </SelectedText>
          <SvgIconComponent height={16} icon="ArrowDownIcon" />
        </DropDownHeader>
        {isDropdownOpen && !disabled && (
          <SelectDropdown<T> {...props} {...{ toggleDropdown, setShowEnteredValue }} />
        )}
      </DropDownContainer>
    </div>
  );
}
