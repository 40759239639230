import styled, { css } from 'styled-components';
import { borderRadius } from 'styles/variables';

interface HolidayBarProps {
  color: string;
  withBorder?: boolean;
  $hasHolidayOnPreviousDay?: boolean;
  $hasHolidayOnNextDay?: boolean;
  $hasHiddenHolidayOnPreviousDay?: boolean;
  disableCursor?: boolean;
}

export const HolidayBar = styled.div<HolidayBarProps>`
  cursor: pointer;
  ${({ disableCursor }) =>
    disableCursor &&
    css`
      cursor: default;
    `}

  background-color: ${({ color }) => color};
  position: relative;
  margin: 0 ${({ $hasHolidayOnNextDay }) => ($hasHolidayOnNextDay ? '-1.05rem' : '-0.75rem')} 2px
    ${({ $hasHolidayOnPreviousDay, $hasHiddenHolidayOnPreviousDay }) => {
      return $hasHolidayOnPreviousDay && !$hasHiddenHolidayOnPreviousDay ? '-1.1rem' : '-0.75rem';
    }};
  ${({ $hasHolidayOnNextDay }) =>
    !$hasHolidayOnNextDay &&
    css`
      border-top-right-radius: ${borderRadius.xs};
      border-bottom-right-radius: ${borderRadius.xs};
    `}
  ${({ $hasHolidayOnPreviousDay, $hasHiddenHolidayOnPreviousDay }) =>
    (!$hasHolidayOnPreviousDay || $hasHiddenHolidayOnPreviousDay) &&
    css`
      border-top-left-radius: ${borderRadius.xs};
      border-bottom-left-radius: ${borderRadius.xs};
    `}
  ${({
    theme,
    withBorder,
    color,
    $hasHolidayOnPreviousDay,
    $hasHolidayOnNextDay,
    $hasHiddenHolidayOnPreviousDay,
  }) =>
    css`
      border: 1px solid ${withBorder ? theme.text.highlight : color};
      border-left-width: ${$hasHolidayOnPreviousDay && !$hasHiddenHolidayOnPreviousDay ? 0 : '5px'};
      border-right-width: ${$hasHolidayOnNextDay ? 0 : '1px'};
    `}
`;
