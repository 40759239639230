import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Text from 'components/Text';
import { useCalendarContext } from 'contexts';
import { useHolidaysContext } from 'contexts/HolidaysContext';
import { Holiday, HolidayStatuses, HolidayType } from 'services/HolidaysService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { createAllHolidayTypesArray } from 'utils';
import {
  ContentWrapper,
  DatesParagraph,
  DatesWrapper,
  Status,
  WrapHolidayStatus,
  YourHolidaysDot,
  YourHolidaysLabelWrapper,
  YourHolidaysRow,
} from './styled';
import { YourHolidaysItem } from './types';

/** Holiday items should be first divided into 2 sections, holidays that will be counted
 * as 'pending', and holiday that will be further divided by type */
const divideHolidays = (holidays: Holiday[]) => {
  let statusHolidays: Holiday[] = [];
  let typeHolidays: Holiday[] = [];

  holidays.forEach((holiday) => {
    holiday.status === HolidayStatuses.Pending
      ? statusHolidays.push(holiday)
      : typeHolidays.push(holiday);
  });

  return { statusHolidays, typeHolidays };
};

const createHolidayItems = (
  holidays: Holiday[],
  holidayTypes: HolidayType[],
  getHolidayColorByType: (holidayType: string | undefined) => string,
) => {
  const { statusHolidays, typeHolidays } = divideHolidays(holidays);

  const typesItems: YourHolidaysItem[] = holidayTypes.map((holidayType) => ({
    label: holidayType.value,
    holidays: typeHolidays.filter((holiday) => holiday.holidayType === holidayType.key),
    color: getHolidayColorByType(holidayType.key),
  }));

  const statusesItems: YourHolidaysItem[] = [
    {
      label: 'Requested',
      holidays: statusHolidays.filter((holiday) => holiday.status === HolidayStatuses.Pending),
      color: colors.light.tertiary,
    },
  ];

  return [...statusesItems, ...typesItems].filter((item) => item.holidays.length > 0);
};

const formatHolidayDateRange = (holiday: Holiday) => {
  const { dateFrom, dateTo } = holiday;
  if (dateFrom === dateTo) {
    return dayjs(dateFrom).format('DD.MM.YYYY');
  } else if (
    dayjs(dateFrom).isSame(dayjs(dateTo), 'month') &&
    dayjs(dateFrom).isSame(dayjs(dateTo), 'year')
  ) {
    return `${dayjs(dateFrom).format('DD')}-${dayjs(dateTo).format('DD.MM.YYYY')}`;
  } else {
    return `${dayjs(dateFrom).format('DD.MM.YYYY')}-${dayjs(dateTo).format('DD.MM.YYYY')}`;
  }
};

interface Props {
  holidaysData: Holiday[];
}

export const YourHolidays = ({ holidaysData }: Props) => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const { getHolidayColorByType } = useCalendarContext();

  const { date } = useCalendarContext();
  const { getHolidaysForYear } = useHolidaysContext();

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    getHolidaysForYear();
  }, [date]);

  const holidayTypes = createAllHolidayTypesArray(employmentTypesStore.listData);

  const yourHolidayItems = useMemo(
    () => createHolidayItems(holidaysData, holidayTypes, getHolidayColorByType),
    [holidaysData, holidayTypes, getHolidayColorByType],
  );

  return (
    <>
      {yourHolidayItems.map(({ label, holidays: yourHolidays, color }) => (
        <YourHolidaysRow key={label}>
          <YourHolidaysLabelWrapper>
            <YourHolidaysDot color={color} withBorder={label === 'Requested'} />
            <ContentWrapper>
              <Text.Paragraphs type="xSmall">{label}</Text.Paragraphs>
              <DatesWrapper>
                {yourHolidays.map((holiday) => (
                  <DatesParagraph type="xxSmall" key={holiday.id}>
                    {formatHolidayDateRange(holiday)}
                  </DatesParagraph>
                ))}
              </DatesWrapper>
            </ContentWrapper>
          </YourHolidaysLabelWrapper>
          {label === 'Requested' && (
            <Status>
              <WrapHolidayStatus type="xxSmall" weight={700} margin="0">
                {HolidayStatuses.Pending}
              </WrapHolidayStatus>
            </Status>
          )}
        </YourHolidaysRow>
      ))}
    </>
  );
};
