import styled from 'styled-components';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';
import { SvgIconComponent } from '../../Icons';

export const StyledToggle = styled.div`
  .toggle-switch {
    position: relative;
    display: flex;
    width: 6.25rem;
    height: 2.25rem;
    margin-right: 1.5rem;
    background: ${({ theme }) => theme.toggleSwitch.backgroundDisabled};
    border-radius: ${borderRadius.xs};
  }
  .toggle-switch input[type='checkbox'] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: ${({ theme }) => theme.toggleSwitch.backgroundDisabled};
    border-radius: ${borderRadius.lg};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: '';
    left: 0.13rem;
    top: 0.13rem;
    width: 3rem;
    height: 2rem;
    background-color: ${({ theme }) => theme.toggleSwitch.backgroundActive};
    border-radius: ${borderRadius.xs};
    transition: transform 0.3s ease;
    box-shadow: 0 0 1px ${colors.gray[800]}20, 0 4px 20px ${colors.gray[800]}8;
  }
  .toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(100%);
    background-color: ${({ theme }) => theme.toggleSwitch.backgroundActive};
  }
  .toggle-switch input[type='checkbox']:checked + .switch {
    background-color: ${({ theme }) => theme.toggleSwitch.backgroundDisabled};
  }

  .toggle-switch .switch {
    justify-content: space-around;
    align-items: center;
    display: flex;
  }
`;

export const StyledSvgIcon = styled(SvgIconComponent)`
  z-index: 100;
  transition: stroke 0.3s ease;
`;
