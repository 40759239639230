import styled from 'styled-components';
import { colors } from 'styles/colors';
import { displayDefault } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const Wrapper = styled.div`
  position: relative;
  min-width: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  display: -webkit-flex;
  display: -webkit-box;
  justify-content: center;
`;

export const NotificationCircle = styled.div`
  position: absolute;
  top: 0;
  left: 100%;

  min-width: 1rem;
  height: 0.9rem;
  padding: 0 0.25em;
  box-sizing: border-box;
  transform: translate(-0.75rem, -50%);

  background-color: ${colors.dark.tertiary};
  border-radius: ${borderRadius.lg};
`;

export const Number = styled.p`
  ${displayDefault};
  font-style: normal;
  font-size: 0.7rem;
  text-align: center;
  color: ${colors.surface.primary};
  display: flex;
  margin: 0;
  height: 0.875rem;
  align-items: center;
  justify-content: center;
`;
