import { AlertComponent, AlertComponentWrapper } from 'components/Toast/styled';
import { useToastContext } from 'contexts/ToastContext';

export const Toast = () => {
  const { dataToast, setIsToastVisible } = useToastContext();
  const { variant, type, label, iconInfo, actionIcon } = dataToast;

  return (
    <AlertComponentWrapper>
      <AlertComponent
        variant={variant}
        type={type}
        label={label}
        iconInfo={iconInfo}
        actionIcon={actionIcon}
        onClick={() => setIsToastVisible(false)}
      />
    </AlertComponentWrapper>
  );
};
