import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { SelectType } from './types';

export const SelectedText = styled.div<{ variant: SelectType }>`
  padding-left: 0.25rem;
  ${({ variant }) => selectType[variant]}
`;

export const DropDownContainer = styled.div`
  width: 100%;
  position: relative;
`;

const selectType = {
  disabled: css`
    color: ${({ theme }) => theme.select.disabled.placeholder};
  `,
  basic: css`
    color: ${({ theme }) => theme.select.basic.placeholder};
  `,
  selected: css`
    color: ${({ theme }) => theme.select.selected.placeholder};
  `,
  outline: css`
    color: ${({ theme }) => theme.select.basic.placeholder};
    border-color: ${({ theme }) => theme.button.basic.borderColor};
  `,
};

export const DropDownHeader = styled.div<{
  withoutBorder?: boolean;
  variant: SelectType;
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.625em 1em;

  border: 1px solid ${({ theme }) => theme.input.border};
  box-shadow: 0 0 1px ${({ theme }) => theme.input.border};
  border-radius: ${borderRadius.xs};

  ${({ withoutBorder }) =>
    withoutBorder &&
    css`
      border: none;
      box-shadow: none;
    `}

  ${({ variant }) => selectType[variant]}
  background-color: ${({ theme }) => theme.input.background};
  ${typography.paragraphs.xSmall};
`;

export const DropDownListContainer = styled.div`
  box-shadow: 0 0 1px rgba(46, 51, 56, 0.2), 0 4px 4px rgba(46, 51, 56, 0.1);
  border-radius: ${borderRadius.sm};
  position: absolute;
  z-index: 10;
  width: 100%;
`;

export const DropDownList = styled.ul`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.select.openDropDownList.background};

  scrollbar-width: thin;
  scrollbar-color: #258cf3 #e6e6e6;

  border-radius: ${borderRadius.sm};

  ::-webkit-scrollbar {
    width: 1rem;
    margin: 0.5rem;
    scroll-padding: 20rem;
  }

  ::-webkit-scrollbar-corner {
    margin: 20rem;
    border-right: 0.5rem solid ${colors.white};
    padding-right: 2rem;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.secondary};
    margin: 0.5rem;
    border-right: 0.5rem solid ${({ theme }) => theme.backgroundElement};
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.dark.tertiary};
    border-right: 0.5rem solid ${({ theme }) => theme.backgroundElement};
    border-radius: 0;
  }

  &:first-child {
    padding-top: 0.875em;
  }
  &:last-child {
    padding-bottom: 0.75em;
    background-color: ${({ theme }) => theme.select.openDropDownList.background};
  }
`;

export const ListItem = styled.div<{ active?: boolean }>`
  cursor: pointer;
  list-style: none;
  ${typography.buttonFontSize.large};
  background: ${({ theme }) => theme.backgroundElement};
  color: ${({ theme }) => theme.select.basic.placeholder};
  padding: 0.625em;
  margin: 0.375em 0.75em;
  border-radius: ${borderRadius.xs};

  ${({ active }) =>
    active &&
    css`
      color: ${colors.white};
      border-radius: ${borderRadius.xs};
      background: ${({ theme }) => theme.select.openDropDownList.hovered};
    `}

  :hover {
    color: ${colors.white};
    border-radius: ${borderRadius.xs};
    background: ${({ theme }) => theme.select.openDropDownList.hovered};
  }
`;

interface WrapperForSelectComponentsProps {
  gridRowGap: string;
  margin?: string;
}

export const WrapperForSelectComponents = styled.div<WrapperForSelectComponentsProps>`
  display: grid;

  ${({ gridRowGap }) =>
    gridRowGap &&
    css`
      grid-row-gap: ${gridRowGap};
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const MessagePosition = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  ${typography.heading['6']}
  color: ${colors.dark.fail};
`;

export const MessageWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const WrongDates = styled.div`
  margin-top: -1rem;
  margin-left: 0.5rem;

  ${typography.paragraphs.caption};
  color: ${colors.dark.fail};
`;
