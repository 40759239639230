import React from 'react';
import { SmallCalendarBasic } from 'components/Calendar';
import { TileWrapper } from 'components/TileWrapper';
import { TeamHoliday } from 'services/HolidaysService';
import { YearCalendarWrapper } from './styled';

const months = new Array(12).fill(true);

interface Props {
  holidays: TeamHoliday[];
}

export const YearCalendarTile = ({ holidays }: Props) => {
  return (
    <TileWrapper title="Year Calendar">
      <YearCalendarWrapper>
        {months.map((_, index) => (
          <SmallCalendarBasic
            key={index}
            holidaysData={holidays}
            customMonthIndex={index}
            showNavigation={false}
          />
        ))}
      </YearCalendarWrapper>
    </TileWrapper>
  );
};
