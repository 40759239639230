import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Cookies from 'js-cookie';
import { Button } from 'components/Button/ButtonBase';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import { Select } from 'components/Select';
import { Checkbox } from 'components/Switches/Checkbox';
import Text from 'components/Text';
import { All, Everyone } from 'const';
import { CONFIG } from 'const/config';
import { getSortedDepartamentListWithEveryone } from 'helpers/getSortedDepartamentListWithEveryone';
import { Department } from 'services/DepartmentsService';
import { ReportHolidayProps } from 'services/ReportsService';
import { EmploymentTypeInterfaceTypes } from 'types';
import { ContainerParameters, HeadingParameters } from './styled';

interface ParametersProps {
  departments: Department[];
  selectedDep: Department | null;
  setSelectedDep: Dispatch<SetStateAction<Department | null>>;
  selectedDepApiId: string | number | undefined;
  employmentTypes: EmploymentTypeInterfaceTypes[];
  selectedEmploymentType: EmploymentTypeInterfaceTypes;
  setSelectedEmploymentType: Dispatch<SetStateAction<EmploymentTypeInterfaceTypes>>;

  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  startDateFormat: string;
  endDateFormat: string;

  selectedIds: number[];
  isAllChecked: boolean;

  checkedReports: ReportHolidayProps[];
}

export const Parameters = ({
  departments,
  selectedDep,
  setSelectedDep,
  selectedDepApiId,
  employmentTypes,
  selectedEmploymentType,
  setSelectedEmploymentType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startDateFormat,
  endDateFormat,
  selectedIds,
  isAllChecked,
  checkedReports,
}: ParametersProps) => {
  const [isAllPeopleInOneFile, setIsSetIsAllPeopleInOneFile] = useState(true);
  const token = Cookies.get(CONFIG.SSO_COOKIE_KEY);
  const selectedEmploymentTypeApi = selectedEmploymentType ?? employmentTypes[1];

  const sortedDeps = getSortedDepartamentListWithEveryone(departments);

  const departmentIdQueryString =
    selectedDepApiId === undefined ? '' : `&by_department_id=${selectedDep?.id}`;

  const employmentTypeQueryString =
    selectedEmploymentTypeApi.key === All.key
      ? ''
      : `&by_employment_type=${selectedEmploymentTypeApi.key}`;

  const generateReport = useCallback(() => {
    window.open(
      `${CONFIG.API_URL}/reports/holidays/export?date[from]=${startDateFormat}&date[to]=${endDateFormat}&user_ids=${selectedIds}&token=${token}&all_in_one=${isAllPeopleInOneFile}${departmentIdQueryString}${employmentTypeQueryString}`,
    );
  }, [
    selectedIds,
    endDateFormat,
    startDateFormat,
    isAllPeopleInOneFile,
    departmentIdQueryString,
    token,
    employmentTypeQueryString,
  ]);

  const disabledGenerateReportButton = isAllChecked
    ? checkedReports.length === 0
    : checkedReports.length === 0 || selectedIds.length === 0;

  return (
    <ContainerParameters>
      <HeadingParameters>
        <Text.Heading type="6">Parameters</Text.Heading>
      </HeadingParameters>
      <RangeDatePicker
        label="Time Range"
        startDate={startDate}
        endDate={endDate}
        setStartDateSelect={setStartDate}
        setEndDateSelect={setEndDate}
        dataCY="generate-report-datepicker"
      />
      <Select
        label="Departments"
        options={sortedDeps || []}
        selectedOption={selectedDep ? selectedDep : Everyone}
        onOptionClicked={setSelectedDep}
        labelSelector={(dep) => dep.name || ''}
        dataCY="generate-report-select"
      />
      <Select
        label="Employment type"
        options={employmentTypes}
        selectedOption={selectedEmploymentTypeApi ? selectedEmploymentTypeApi : All}
        onOptionClicked={setSelectedEmploymentType}
        labelSelector={(employmentType) => employmentType.value}
        dataCY="team-employment-type-select"
      />
      <Checkbox
        isChecked={isAllPeopleInOneFile}
        label="All  people in one file"
        checkboxAction={() => {
          setIsSetIsAllPeopleInOneFile((value) => !value);
        }}
        dataCY="all-people-in-one-file-checkbox"
      />
      <Button
        variant="tertiary"
        label="Generate Report"
        onClick={generateReport}
        disabled={disabledGenerateReportButton}
        dataCY="parameters-generate-report-button"
      />
    </ContainerParameters>
  );
};
