import { useState, useEffect, SyntheticEvent } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TileWrapper, SvgIconComponent, useModal, AdditionalHolidayRemoveModal } from 'components';
import { Type, Variant } from 'components/AlertComponent/types';
import { useToastContext, useUserContext } from 'contexts';
import { ApiError } from 'services/AuthService';
import EmployeesService, {
  AdditionalHolidaUpdateTypes,
  AdditionalHolidayTypes,
  Employee,
} from 'services/EmployeesService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { AdditionalHolidaysFieeldsNamesEnumType } from 'types';
import { additionalHolidaysB2BTypesArray, additionalHolidaysContractTypesArray } from 'utils';
import { AdditionalHolidayListItem } from './AdditionalHolidayListItem';
import {
  additionalHolidaysForRequestHelper,
  checkboxValue,
  inputValues,
  isInputsDataFilled,
  selectValues,
} from './additionalHolidaysHelpers';
import {
  AdditionalHolidayWrapper,
  ElementsInOneLine,
  AdditionalHolidayTitle,
  NewAdditionalHoliday,
} from './styled';

interface Props {
  employee: Employee;
}

export const AdditionalHoliday = ({ employee }: Props) => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const { isManager } = useUserContext();
  const { setToast, handleError } = useToastContext();

  const holidayB2BTypes = additionalHolidaysB2BTypesArray(employmentTypesStore.listData);
  const holidayContractTypes = additionalHolidaysContractTypesArray(employmentTypesStore.listData);
  const [initialAdditionalHolidays, setInitialAdditionalHolidays] = useState<
    AdditionalHolidaUpdateTypes[]
  >([]);
  const [additionalHolidays, setAdditionalHolidays] = useState<AdditionalHolidaUpdateTypes[]>([]);
  const [removeAdditionalHolidayModal, setRemoveAdditionalHolidayModal] = useModal();
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [dataToRemove, setDataToRemove] = useState<AdditionalHolidaUpdateTypes[]>([]);

  const isB2B = employee.employmentType?.value === 'B2B';
  const getYear = new Date().getFullYear() - 1;

  const addNewHoliday = (): void => {
    const newHoliday = {
      localId: _.uniqueId(),
      holidayType: isB2B
        ? holidayB2BTypes?.additional_holiday_types[0].key
        : holidayContractTypes?.additional_holiday_types[0].key,
      days: undefined,
      name: isB2B ? '' : holidayContractTypes?.additional_holiday_types[0].value,
      year: getYear,
      repeatEveryYear: false,
    };
    setAdditionalHolidays([...additionalHolidays, newHoliday]);
  };

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    if (!isManager) {
      setSaveDisabled(true);
      return;
    }
    if (
      !_.isEqual(initialAdditionalHolidays, additionalHolidays) &&
      isInputsDataFilled(additionalHolidays)
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [additionalHolidays]);

  useEffect(() => {
    if (employee.additionalHolidays) {
      const newHolidayArray = employee.additionalHolidays.map(
        (holiday: AdditionalHolidayTypes): AdditionalHolidaUpdateTypes => {
          return {
            ...holiday,
            localId: undefined,
          };
        },
      );
      setInitialAdditionalHolidays(newHolidayArray);
      setAdditionalHolidays(newHolidayArray);
    }
  }, []);

  const handleChangeValuesHoliday = (
    e: SyntheticEvent<HTMLInputElement>,
    index: string,
    field: AdditionalHolidaysFieeldsNamesEnumType,
  ): void => {
    setAdditionalHolidays((prevState) => inputValues(prevState, e, index, field));
  };

  const handleChangeValuesSelectHoliday = (
    option: number,
    index: string,
    field: AdditionalHolidaysFieeldsNamesEnumType,
  ): void => {
    setAdditionalHolidays((prevState) => selectValues(prevState, option, index, field));
  };

  const handleChangeRepeatEveryYear = (index: string) => {
    setAdditionalHolidays((prevState) => checkboxValue(prevState, index));
  };

  const handleDataToRemove = (id: string) => {
    const additionalHolidaysForRequest = additionalHolidaysForRequestHelper(
      additionalHolidays,
      id,
    ).filter((item: AdditionalHolidaUpdateTypes) => item._destroy);
    setDataToRemove(additionalHolidaysForRequest);
  };

  const removeLocalHoliday = (localId: string): void => {
    const removeLocal = additionalHolidays.filter((item: AdditionalHolidaUpdateTypes) => {
      return localId !== item.localId;
    });
    setAdditionalHolidays(removeLocal);
  };

  const handleSaveAdditionalHolidays = async () => {
    const dataForUpdate = _.differenceWith(
      additionalHolidays,
      initialAdditionalHolidays,
      _.isEqual,
    );
    const newUserData = dataForUpdate.map(({ localId, ...rest }) => rest);
    try {
      await EmployeesService.updateUserData(employee.id, {
        additionalHolidaysAttributes: newUserData,
      });
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Additional holidays successfully updated',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  return (
    <TileWrapper
      title=""
      button={{
        content: 'Save',
        onClick: handleSaveAdditionalHolidays,
        disabled: saveDisabled,
        dataCY: 'additional-holiday-title',
      }}>
      <AdditionalHolidayWrapper>
        {isManager && (
          <ElementsInOneLine>
            <AdditionalHolidayTitle>Additional Holiday</AdditionalHolidayTitle>
            <NewAdditionalHoliday onClick={addNewHoliday}>
              <SvgIconComponent icon={'PlusIcon'} color={colors.white} width={8} height={8} />
            </NewAdditionalHoliday>
          </ElementsInOneLine>
        )}
        {additionalHolidays.map((item: AdditionalHolidaUpdateTypes) => (
          <AdditionalHolidayListItem
            key={item.id || item.localId}
            item={item}
            isB2B={isB2B}
            getYear={getYear}
            isManager={isManager}
            setRemoveAdditionalHolidayModal={setRemoveAdditionalHolidayModal}
            handleDataToRemove={handleDataToRemove}
            handleChangeValuesHoliday={handleChangeValuesHoliday}
            handleChangeValuesSelectHoliday={handleChangeValuesSelectHoliday}
            handleChangeRepeatEveryYear={handleChangeRepeatEveryYear}
            removeLocalHoliday={removeLocalHoliday}
          />
        ))}
      </AdditionalHolidayWrapper>
      {removeAdditionalHolidayModal && (
        <AdditionalHolidayRemoveModal
          isVisible={removeAdditionalHolidayModal}
          toggle={setRemoveAdditionalHolidayModal}
          dataForUpdate={dataToRemove}
          employeeFirstName={employee.firstName}
          employeeLastName={employee.lastName}
          employeeId={employee.id}
        />
      )}
    </TileWrapper>
  );
};
