import styled, { css } from 'styled-components';
import Text from 'components/Text';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';

export const MonthWrapper = styled.div<{ weeksCount: number }>`
  flex: 4;
  display: grid;
  grid-template-columns: repeat(7, minmax(3rem, 14.3%));
  grid-template-rows: repeat(${({ weeksCount }) => weeksCount}, 1fr);
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  margin-bottom: 1rem;
`;

interface StyledDayContainerProps {
  firstRow: boolean;
  firstColumn: boolean;
  disableCursor?: boolean;
  showClickedDay?: boolean;
}

export const StyledDayContainer = styled.div<StyledDayContainerProps>`
  border-top: 1px solid
    ${({ theme, firstRow }) => (firstRow ? 'transparent' : theme.smallCalendar.border)};
  border-left: 1px solid
    ${({ theme, firstColumn }) => (firstColumn ? 'transparent' : theme.smallCalendar.border)};
  padding: 1rem 1rem 0.5rem 1rem;
  text-align: center;
  color: ${({ theme }) => theme.text[700]};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;

  ${({ disableCursor }) =>
    disableCursor &&
    css`
      cursor: default;
    `}

  :hover {
    background-color: ${({ theme }) => theme.table.hover};

    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
  }

  ${({ showClickedDay }) =>
    showClickedDay &&
    css`
      background-color: ${({ theme }) => theme.table.hover};
    `}
`;

export const StatusWrapper = styled.div<{ oneDayHoliday?: boolean }>`
  ${({ oneDayHoliday }) =>
    !oneDayHoliday &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

export const HolidaysListWrapper = styled.div`
  flex-grow: 1;
`;

export const ShowMoreHolidays = styled.div`
  margin: 0 -1rem -0.5rem -1rem;
  padding: 0.25rem 0;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${colors.light.tertiary};
  }
`;

export const MoreArea = styled(Text.Paragraphs)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  padding: -0.25rem 0;
`;

export const ListWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  background-color: ${({ theme }) => theme.fullHolidaysList};
  box-shadow: 0 0 8px ${colors.boxShadowModalColor};
  border-radius: ${borderRadius.sm};
  padding: 0.25rem 1rem;
  cursor: auto;
  z-index: 1000;
`;

export const ContainerWithScroll = styled.div`
  ::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.secondary};
    border-radius: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.dark.tertiary};
    border-radius: 0.25rem;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const ShowTodayDay = styled.div<{ isToday?: boolean; isHover?: boolean }>`
  width: 1.6rem;
  border-radius: 100%;

  ${({ isToday, isHover }) =>
    (isToday || isHover) &&
    css`
      color: ${colors.white};
      background-color: ${({ theme }) => theme.text.highlight};

      -webkit-transition: background-color 300ms linear;
      -moz-transition: background-color 300ms linear;
      -o-transition: background-color 300ms linear;
      -ms-transition: background-color 300ms linear;
      transition: background-color 300ms linear;
    `};
`;

export const NameDayHeader = styled(Text.Paragraphs)`
  margin: -0.625rem -0.625rem 0 -0.625rem;
  padding: 0.625em 0 0.75em 0;
`;

export const PositionShowTodayDay = styled(Text.Paragraphs)`
  padding: 0.375em 0;
  cursor: pointer;
  width: 100%;
`;

export const WrapSpinner = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const DayCenter = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.calendar.dayNumberColor};
`;

export const Wrap = styled.div`
  height: 1.6rem;
`;

export const WrapChildren = styled.div<{ weekHasHoliday: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ weekHasHoliday }) =>
    weekHasHoliday &&
    css`
      margin-top: 1.1rem;
    `}
`;

export const DayBottomWrapper = styled.div`
  height: 100%;
`;
