import styled from 'styled-components';
import { SvgIconComponent } from 'components/Icons';
import { borderRadius } from 'styles/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 5% 95%;
  margin-bottom: 38px;
`;

export const Arrow = styled.div`
  cursor: pointer;
  padding-top: 0.25em;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const RowHeading = styled.div`
  display: grid;
  align-items: baseline;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  margin-top: 0.5rem;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: 34px 1fr;
  grid-template-rows: repeat(3, 1fr) auto;
  gap: 1rem;
  margin-bottom: 50px;
`;

export const StyledCalendarIcon = styled(SvgIconComponent)`
  align-self: 'flex-start';
  margin-top: 24px;
`;

export const StyledBriefcaseIcon = styled(SvgIconComponent)`
  align-self: 'flex-start';
  margin-top: 29px;
  padding-left: 0.16em;
`;

export const HolidaySummaryContainer = styled.div`
  padding: 1em 1em 0.5em 1em;
  background: ${({ theme }) => theme.holidayForm.holidayBalance.background};
  border: 1px solid ${({ theme }) => theme.holidayForm.holidayBalance.border};
  border-radius: ${borderRadius.xs};
  margin-left: 48px;
  margin-bottom: 23px;
`;

export const MainButtons = styled.div`
  display: flex;
  margin-left: 48px;
  justify-content: flex-end;
  gap: 1rem;
`;

export const DeleteButton = styled.div`
  display: flex;
  margin-left: 48px;
  justify-content: flex-start;
`;

export const RowButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextPanelWrapper = styled.div`
  color: ${({ theme }) => theme.holidayForm.textPanel.text};
  background-color: ${({ theme }) => theme.holidayForm.textPanel.background};
  border-radius: ${borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.holidayForm.textPanel.border};
  padding: 0.5rem 1rem;
`;
