import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { CONFIG } from 'const/config';
import { routes } from 'routes';
import { DropdownList } from './DropdownList';
import { DropdownWrapper } from './styled';

interface DropdownMenuProps {
  userId: number;
}

export const DropdownMenu = ({ userId }: DropdownMenuProps) => {
  const history = useHistory();
  const listItems = [
    { name: 'View profile', action: () => history.push(`${routes.team}/${userId}`) },
    { name: 'Generate Report', action: () => generateReport() },
  ];
  const startDateFormat = `${dayjs().year()}-01-01`;
  const endDateFormat = `${dayjs().year()}-12-31`;
  const token = Cookies.get(CONFIG.SSO_COOKIE_KEY);

  const generateReport = () => {
    window.open(
      `${CONFIG.API_URL}/reports/holiday_requests?date[from]=${startDateFormat}&date[to]=${endDateFormat}&token=${token}&userId=${userId}`,
      '_blank',
    );
  };

  return (
    <DropdownWrapper data-cy="employee-row-dropdown-menu">
      <DropdownList listObject={listItems} />
    </DropdownWrapper>
  );
};
