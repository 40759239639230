import styled from 'styled-components';
import { ModalContainer, ModalWrapper } from 'components/Modal/styled';
import { typography } from 'styles/typography';

export const ManagerModalOverlay = styled.div`
  position: flex;
  align-items: center;
  justify-content: center;
`;

export const ManagerModalWrapper = styled(ModalWrapper)`
  flex-direction: column;
  background: ${({ theme }) => theme.modal.rejectModalBackground};
`;

export const ManagerModalContainer = styled(ModalContainer)`
  z-index: 20;
  width: 100%;
  padding: 1.5em 2em;
  background: ${({ theme }) => theme.backgroundElement};
`;

export const ManagerRejectSubtilte = styled.div<{ color?: string }>`
  ${typography.paragraphs.small}
  margin-top: 1rem;
  margin-bottom: 1.375rem;
  color: ${({ color }) => color};
`;

export const ButtonSection = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: end;
`;
