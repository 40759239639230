import { Dayjs } from 'dayjs';
import { ShortUserWithHolidays } from 'contexts/TeamHolidaysContext';
import { State } from '../../../../../types/global';
import { DepartmentSelect } from '../../DepartmentSelect';
import { EmployeeSearch } from '../../EmployeeSearch';
import { DaysLegend } from './DaysLegend';
import { FiltersWrapper, HeaderWrapper, Wrapper, DepartmentSelectHeaderWrapper } from './styled';
import { TeamCapacity } from './TeamCapacity';

interface HeaderProps {
  searchPhraseState: State<string | null>;
  selectedDepartmentIdState: State<number | null>;
  selectedDayState: State<Dayjs | null>;
  filteredUsers: ShortUserWithHolidays[];
}

export const Header = ({
  searchPhraseState,
  selectedDayState,
  selectedDepartmentIdState,
  filteredUsers,
}: HeaderProps) => {
  const selectedDepartmentId = selectedDepartmentIdState.state;
  const holidays = filteredUsers.map((user) => user.holidays).flat();

  return (
    <HeaderWrapper>
      <FiltersWrapper>
        <Wrapper>
          <DepartmentSelectHeaderWrapper>
            <DepartmentSelect
              selectedDepartmentIdState={selectedDepartmentIdState}
              withoutBorder={true}
              hideLabel={true}
            />
          </DepartmentSelectHeaderWrapper>
          {selectedDepartmentId && <TeamCapacity selectedDepartmentId={selectedDepartmentId} />}
        </Wrapper>
        <EmployeeSearch searchPhraseState={searchPhraseState} withoutBorder={true} />
      </FiltersWrapper>
      <DaysLegend selectedDayState={selectedDayState} holidays={holidays} />
    </HeaderWrapper>
  );
};
