import dayjs from 'dayjs';
import Text from 'components/Text';
import { Ellipsis } from 'components/Text/Ellipsis';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { getHolidayColorByType, getHolidayColorByStatus } from 'utils';
import { HolidayBar } from '../styled';
import { getLabelColor, isOneDayHoliday } from './helpers';
import { StatusWrapper } from './styled';
import { EmployeeHolidayBarProps } from './types';

export const EmployeeHolidayBar = ({ holiday, day, onClick, label }: EmployeeHolidayBarProps) => {
  return (
    <HolidayBar
      $hasHolidayOnPreviousDay={dayjs(day).isAfter(holiday.dateFrom) && day.day() !== 1}
      $hasHolidayOnNextDay={dayjs(day).isBefore(holiday.dateTo) && day.day() !== 0}
      color={
        holiday.status === HolidayStatuses.Accepted
          ? getHolidayColorByType(holiday.holidayType)
          : getHolidayColorByStatus(holiday.status)
      }
      onClick={onClick && (() => onClick(holiday))}
      withBorder={holiday.status === HolidayStatuses.Pending}
      data-cy="calendar-holiday-bar">
      {label && (
        <Text.Paragraphs
          type="xSmall"
          color={getLabelColor(holiday, day)}
          weight={700}
          style={
            dayjs(day).isAfter(holiday.dateFrom) && day.day() !== 1 ? { visibility: 'hidden' } : {}
          }
          title={label}>
          <Ellipsis>{label}</Ellipsis>
        </Text.Paragraphs>
      )}
      {(!dayjs(day).isBefore(holiday.dateTo) || day.day() === 0) && (
        <StatusWrapper oneDayHoliday={isOneDayHoliday(holiday, day)}>
          {holiday.status === HolidayStatuses.Pending && (
            <Text.Paragraphs
              type="xSmall"
              color={
                holiday.status === HolidayStatuses.Pending ? colors.dark.tertiary : colors.white
              }>
              {holiday.status}
            </Text.Paragraphs>
          )}
        </StatusWrapper>
      )}
    </HolidayBar>
  );
};
