import { Select } from 'components/Select';
import { Everyone } from 'const';
import { useDepartmentContext } from 'contexts/DepartmentsContext';
import { getSortedDepartamentListWithEveryone } from 'helpers/getSortedDepartamentListWithEveryone';
import { Department } from 'services/DepartmentsService';
import { DepartmentSelectProps } from '../../types';
import { DepartmentSelectWrapper } from '../WeekCalendar/Header/styled';

export const DepartmentSelect = ({
  selectedDepartmentIdState,
  withoutBorder = false,
  hideLabel = false,
}: DepartmentSelectProps) => {
  const { departments } = useDepartmentContext();
  const sortedDeps = getSortedDepartamentListWithEveryone(departments);

  const selectedDepartmentId = selectedDepartmentIdState.state;
  const selectedDepartment =
    sortedDeps && sortedDeps.find((department) => department.id === selectedDepartmentId);

  const handleSelect = (department: Department) => {
    const selectedId = department.id === Everyone.id ? null : department.id;
    selectedDepartmentIdState.setState(selectedId);
  };

  const labelSelector = (department: Department) => department.name;

  return (
    <DepartmentSelectWrapper>
      {sortedDeps && (
        <Select
          label={hideLabel ? '' : 'Departments'}
          options={sortedDeps || []}
          selectedOption={selectedDepartment}
          onOptionClicked={handleSelect}
          placeholder="Everyone"
          labelSelector={labelSelector}
          withoutBorder={withoutBorder}
          dataCY="calendar-department-select"
        />
      )}
    </DepartmentSelectWrapper>
  );
};
