import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import { Select } from 'components/Select';
import { Everyone } from 'const';
import { CONFIG } from 'const/config';
import { useDepartmentContext } from 'contexts/DepartmentsContext';
import { getSortedDepartamentListWithEveryone } from 'helpers/getSortedDepartamentListWithEveryone';
import { Department } from 'services/DepartmentsService';
import { ReportWrapper, StyledCheckbox } from './styled';
import { EmploymentType } from './types';

export const Report = () => {
  const { departments } = useDepartmentContext();
  const sortedDeps = getSortedDepartamentListWithEveryone(departments);

  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);
  const [startDate, setStartDate] = useState<null | Date>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<null | Date>(new Date());

  const [isCheck, setIsCheck] = useState(false);
  const [isGenerateOnlyForUoP, setIsGenerateOnlyForUoP] = useState<EmploymentType | null>(null);

  const startDateFormat = useMemo(() => dayjs(startDate).format('YYYY-MM-DD'), [startDate]);
  const endDateFormat = useMemo(() => dayjs(endDate).format('YYYY-MM-DD'), [endDate]);
  const token = Cookies.get(CONFIG.SSO_COOKIE_KEY);

  const selectedDepApiId = useMemo(
    () => (selectedDepartment?.id === Everyone.id ? null : selectedDepartment?.id),
    [selectedDepartment],
  );

  const generateReport = useCallback(() => {
    window.open(
      `${CONFIG.API_URL}/reports/holiday_requests?date[from]=${startDateFormat}&date[to]=${endDateFormat}&token=${token}&departmentId=${selectedDepApiId}&employment_type=${isGenerateOnlyForUoP}`,
    );
  }, [startDateFormat, endDateFormat, token, selectedDepApiId, isGenerateOnlyForUoP]);

  return (
    <ReportWrapper
      title="Generate Report"
      variant="tertiaryOutline"
      button={{
        content: 'Generate Report',
        onClick: generateReport,
        dataCY: 'generate-report-button',
      }}>
      <Select
        label="Generate for"
        placeholder="choose department"
        options={sortedDeps || []}
        selectedOption={selectedDepartment ? selectedDepartment : Everyone}
        onOptionClicked={setSelectedDepartment}
        labelSelector={(dep) => dep?.name || ''}
        dataCY="generate-report-select"
      />

      <StyledCheckbox
        isChecked={isCheck}
        label="generate only for UoP"
        checkboxAction={() => {
          setIsCheck(!isCheck);
          setIsGenerateOnlyForUoP(!isCheck ? EmploymentType.Contract : null);
        }}
        dataCY="generate-only-for-UoP-checkbox"
      />

      <RangeDatePicker
        label="Time Range"
        startDate={startDate}
        endDate={endDate}
        setStartDateSelect={setStartDate}
        setEndDateSelect={setEndDate}
        dataCY="generate-report-datepicker"
      />
    </ReportWrapper>
  );
};
