// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import ApiBaseService from './ApiBaseService';

export interface HolidayCapacitiesParams {
  'date[from]': string;
  'date[to]': string;
  'departmentId': number | null;
}

interface DepartmentsPatams {
  id: number;
  name: string;
  capacity: number;
  occupancy_number: number;
  occupancy_percentage: number;
}

interface HolidayTypesPrams {
  name: string;
  occupancy_number: number;
  occupancy_percentage: number;
}

export interface HolidayCapacitiesResponseType {
  date_from: string;
  date_to: string;
  department_id: number | null;
  holiday_requests_count: number;
  capacity: number;
  occupancy_number: number;
  occupancy_percentage: number;
  departments: DepartmentsPatams[];
  holiday_types: HolidayTypesPrams[];
}

export default class HolidayCapacitiesService extends ApiBaseService {
  static readonly API_URL = `${this.API_BASE_URL}/holiday_capacities`;

  static fetchHolidayCapacities = async (params: HolidayCapacitiesParams) => {
    const { data } = await axios.get<HolidayCapacitiesResponseType>(this.API_URL, {
      params,
    });
    return data;
  };
}
