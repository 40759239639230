import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const EmployeesWrap = styled.div`
  margin-top: 3.3rem;
  display: grid;
  grid-gap: 1rem;
  padding-top: 1em;

  @media (min-width: 1750px) {
    grid-template-columns: repeat(4, minmax(320px, 1fr));
  }

  @media (max-width: 1759px) {
    grid-template-columns: repeat(3, minmax(320px, 1fr));
  }

  @media (max-width: 1385px) {
    grid-template-columns: repeat(2, minmax(320px, 1fr));
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
