import { useState, useEffect, useMemo } from 'react';
import Text from 'components/Text';
import { useCalendarContext } from 'contexts/CalendarContext';
import { useHolidayCapacity } from '../../../../../hooks';
import { TeamCapacityProps } from '../../../types';
import { StyledProgress, TeamCapacityWrapper } from './styled';

export const TeamCapacity = ({ selectedDepartmentId }: TeamCapacityProps) => {
  const { firstDayOfWeek, lastDayOfWeek } = useCalendarContext();

  const capacityParams = useMemo(
    () => ({
      'date[from]': firstDayOfWeek.format('YYYY-MM-DD'),
      'date[to]': lastDayOfWeek.format('YYYY-MM-DD'),
      'departmentId': selectedDepartmentId,
    }),
    [firstDayOfWeek, lastDayOfWeek, selectedDepartmentId],
  );

  const { capacity } = useHolidayCapacity({ capacityParams });

  useEffect(() => {
    setChosenTribePercentage(capacity ? Math.ceil(capacity.occupancy_percentage) : 0);
  }, [capacity]);

  const [chosenTribePercentage, setChosenTribePercentage] = useState<number>();

  return (
    <TeamCapacityWrapper>
      <Text.Heading type="3">{chosenTribePercentage}%</Text.Heading>
      <StyledProgress value={chosenTribePercentage} max={100} />
    </TeamCapacityWrapper>
  );
};
