import styled, { css } from 'styled-components';
import { SvgIconComponent } from '../../Icons';

interface IconProps {
  color?: string;
  marginLeft?: number;
  onClick?: VoidFunction;
  pointer?: boolean;
}

export const StyledIcon = styled(SvgIconComponent)<IconProps>`
  color: ${(props) => (props.color ? props.color : props.theme.input.placeholder)};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}rem;` : '')}

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}
`;
