import { SvgIconComponent } from 'components/Icons';
import { colors } from 'styles/colors';
import {
  Button,
  Content,
  Footer,
  FooterWrapper,
  IconWrapper,
  Subtitle,
  TileWrapperContent,
  Title,
} from './styled';
import { TileWrapperProps } from './types';

export const TileWrapper = ({
  isLargeTitle = false,
  title,
  subtitle,
  children,
  button,
  footer,
  variant,
  paddingTop,
  ...props
}: TileWrapperProps) => {
  return (
    <TileWrapperContent {...props}>
      {title && <Title isLargeTitle={isLargeTitle}>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Content>{children}</Content>
      {button && (
        <Button
          onClick={button.onClick}
          variant={variant}
          size={'regular'}
          label={button.content}
          hideBorder={true}
          disabled={button.disabled}
          data-cy={button.dataCY}
        />
      )}

      {footer && (
        <FooterWrapper isRequest={footer.isRequest} onClick={footer.onClick}>
          {footer.isRequest && (
            <IconWrapper>
              <SvgIconComponent icon="ExclamationIcon" width={10} color={colors.black} />
            </IconWrapper>
          )}
          <Footer
            size={'regular'}
            label={footer.content}
            hideBorder={true}
            disabled={!!footer.disabled}
            variant={footer.isRequest ? 'primary' : undefined}
          />
        </FooterWrapper>
      )}
    </TileWrapperContent>
  );
};
