import { HolidayStatuses, TeamHoliday } from 'services/HolidaysService';

export const searchByEmployee = (
  searchQuery: string | undefined,
  employee: Pick<TeamHoliday['user'], 'lastName' | 'firstName'>,
): boolean => {
  const { lastName, firstName } = employee;
  const preparedFullname = `${lastName} ${firstName}`.toLowerCase().trim();
  const preparedSearchQuery = searchQuery?.toLowerCase().trim();
  return preparedSearchQuery ? preparedFullname.includes(preparedSearchQuery) : true;
};

export const searchByStatus = (
  statusFilter?: HolidayStatuses,
  teamHolidayStatus?: HolidayStatuses,
) => statusFilter === teamHolidayStatus;

export const filterByDepartment = (userDepartment: string, activeDepartment?: string) => {
  if (!activeDepartment) return true;
  else {
    return activeDepartment === userDepartment;
  }
};
