import styled from 'styled-components';
import { Title } from '../TileWrapper/styled';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 2.125rem;
`;

export const StyledTitle = styled(Title)`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; ;
`;

export const StyledParagraph = styled.div`
  color: ${({ theme, color }) => (color ? color : theme.profile.text)};
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1.875rem;
`;
