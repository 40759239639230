import { useState } from 'react';
import Avatar from 'react-avatar';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import Text from 'components/Text';
import { Dot } from 'components/UserLoggedHeader/styled';
import { colors } from 'styles/colors';
import { StyledIconEllipsis } from 'views/Dashboard/components/styled';
import { UserInfoProps } from '../../../types';
import { AvatarWrapper, SubInfoWrapper, TextInfoWrapper, UserInfoWrapper } from './styled';

export const UserInfo = ({ user }: UserInfoProps) => {
  const fullName = `${user.lastName} ${user.firstName}`;
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  return (
    <UserInfoWrapper onMouseLeave={() => setShowDropdownMenu(false)}>
      <AvatarWrapper>
        <Avatar name={fullName} size="40" src={user.avatar} round />
      </AvatarWrapper>
      <TextInfoWrapper>
        <Text.Heading type={'6'}>{fullName}</Text.Heading>
        <SubInfoWrapper type="xSmall" color={colors.gray['600']}>
          {user.position}
          <Dot />
          {user.department}
        </SubInfoWrapper>
      </TextInfoWrapper>
      <div onClick={() => setShowDropdownMenu(!showDropdownMenu)}>
        <StyledIconEllipsis icon="EllipsisIcon" width={24} />
      </div>
      {showDropdownMenu && <DropdownMenu userId={user.id} />}
    </UserInfoWrapper>
  );
};
