import styled, { css } from 'styled-components';
import { borderRadius } from 'styles/variables';
import { colors } from '../../styles/colors';

export type DotType = 'used' | 'available' | 'requested';

export const Dot = styled.div<{ type: DotType }>`
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.625rem;
  border-radius: ${borderRadius.xs};
  ${({ type }) => dotColor[type]}
`;

export const LegendRow = styled.div`
  flex-direction: row;
  margin-right: 1rem;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.profile.legend};
`;

export const LegendContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`;

const dotColor = {
  used: css`
    background-color: ${({ theme }) => theme.text.highlight};
  `,
  available: css`
    background-color: ${({ theme }) => theme.profile.divider};
  `,
  requested: css`
    background-color: ${colors.warning};
  `,
};
