import styled, { css } from 'styled-components';
import { colors } from '../../../styles/colors';
import { typography } from '../../../styles/typography';

export const HolidayRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

interface SharedStylesProp {
  backgroundColor?: string;
  borderColor?: string;
}

const sharedStyles = css<SharedStylesProp>`
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
  ${({ borderColor }) => (borderColor ? `border-color: ${borderColor};` : '')}
`;

export const Dot = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  ${sharedStyles}
`;

export const HolidayBalanceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1.5em;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export const HolidayBalanceList = styled.div`
  width: 50%;
`;

export const YearCalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1550px) {
    grid-column-gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const EmptyState = styled.div`
  ${typography.heading[5]};
  color: ${colors.gray[500]};
`;
