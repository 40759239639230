import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Type, Variant } from 'components/AlertComponent/types';
import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import Modal from 'components/Modal/Modal';
import { Select } from 'components/Select';
import { WrapperForSelectComponents, WrongDates } from 'components/Select/styled';
import { useToastContext } from 'contexts/ToastContext';
import { countWorkingDays } from 'helpers/CountWorkoutDays';
import { ApiError } from 'services/AuthService';
import EmployeesService, { Employee } from 'services/EmployeesService';
import HolidayService from 'services/HolidayService';
import { EnumEmploymentType, HolidayType } from 'services/HolidaysService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { HolidayTypesEnums } from 'types';
import Text from '../Text/index';
import { BalanceComponent } from './BalanceComponent';
import { onDemandIsPossibility } from './helpers';
import { NewRequestHolidayWeekCalendarModalProps } from './types';

const AdditionalInfoOnHolidayRequest = styled.textarea`
  width: 21.375rem;
  height: 3rem;
  padding: 1em 1em;
  resize: none;
  outline: none;
  border-radius: ${borderRadius.xs};
  ${typography.paragraphs.xSmall};
  color: ${({ theme }) => theme.holidayForm.messegaOnRejectModal};
  border: 1px solid ${({ theme }) => theme.input.border};
  background-color: ${({ theme }) => theme.input.background};
  opacity: 0.82;

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }
`;

const AdditionalInfoTitle = styled.div`
  color: ${({ theme }) => theme.input.label};
  margin-left: 0;
  margin-bottom: 0.5rem;
`;

export const NewRequestHolidayWeekCalendarModal = ({
  toggle,
  isVisible,
  startDate: defaultStartDate,
  userId,
}: NewRequestHolidayWeekCalendarModalProps) => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const [startDate, handleStartDateSelect] = useState<Date>(defaultStartDate);
  const todayDate = new Date().setHours(0, 0, 0, 0);
  const [rightDemandDate, setRightDemandDate] = useState(true);
  const [endDate, handleEndDateSelect] = useState<Date>(defaultStartDate);

  const [selectedHolidayTypes, setSelectedHolidayTypes] = useState<HolidayType>();

  const [additionalMessage, setAdditionalMessage] = useState<string>('');

  const { handleError, setToast } = useToastContext();

  const { data: userDetails, refetch } = useQuery<Employee>('single_employee', () => {
    return EmployeesService.fetchEmployeeById(userId);
  });

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    refetch();
  }, [selectedHolidayTypes, startDate, endDate, userId]);

  useEffect(() => {
    if (startDate) {
      const dateCompare = startDate.setHours(0, 0, 0, 0) - todayDate;
      setRightDemandDate(dateCompare >= 172800000 || dateCompare < 0 ? true : false);
    }
  }, [startDate]);

  const holidayTypes =
    employmentTypesStore.listData?.find(
      (empType) => empType.value === userDetails?.employmentType?.value,
    )?.holiday_types || [];

  const individualHolidayTypes = () => {
    if (userDetails?.kidDateOfBirth === null) {
      return holidayTypes.filter((holType) => holType.key !== HolidayTypesEnums.Kid);
    } else {
      return holidayTypes;
    }
  };

  const onSubmit = async () => {
    try {
      if (!selectedHolidayTypes) {
        return;
      }

      await HolidayService.createHolidayRequest({
        userId: userId,
        dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
        dateTo: dayjs(endDate).format('YYYY-MM-DD'),
        holidayType: selectedHolidayTypes.key!,
        additionalComment: additionalMessage.trim(),
      });

      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been added',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      toggle();
    } catch (e) {
      handleError(e as ApiError);
    }
  };

  const onDemandValue =
    selectedHolidayTypes?.key === HolidayTypesEnums.OnDemand ? selectedHolidayTypes.value : '';

  // Balance
  const requestingHoliday = countWorkingDays(startDate, endDate) || 0;

  const forUseOnDemand = userDetails?.holiday?.onDemand || 0;

  const forUseOnDemandDynamic =
    userDetails?.holiday?.onDemand === userDetails?.holiday?.onDemandTotal
      ? forUseOnDemand
      : forUseOnDemand + requestingHoliday;

  const correctDates = startDate && endDate && startDate > endDate;

  const tooManyOnDemand =
    !!selectedHolidayTypes &&
    selectedHolidayTypes.key === EnumEmploymentType.On_demand &&
    (requestingHoliday > 1 || rightDemandDate)
      ? requestingHoliday
      : null;

  const forUseLeftDynamic =
    (userDetails?.holiday?.total || 0) -
    (userDetails?.holiday?.requested || 0) -
    (userDetails?.holiday?.used || 0) -
    requestingHoliday;

  const idOnDemand =
    selectedHolidayTypes && selectedHolidayTypes?.key === EnumEmploymentType.On_demand;

  const label = selectedHolidayTypes?.key === HolidayTypesEnums.Sick ? 'Add Sick' : 'Add';

  const disabledButton =
    !selectedHolidayTypes ||
    !endDate ||
    !startDate ||
    correctDates ||
    forUseLeftDynamic < 0 ||
    (!!tooManyOnDemand && tooManyOnDemand > 1) ||
    onDemandIsPossibility({
      statusIsOnDemand: !!idOnDemand,
      startDate: startDate,
    }) ||
    (idOnDemand && userDetails?.holiday?.onDemand === userDetails?.holiday?.onDemandTotal);

  return (
    <Modal
      label={`${label} - ${userDetails?.lastName} ${userDetails?.firstName}`}
      visible={isVisible}
      onClose={toggle}
      onClickButton={onSubmit}
      crossMarkIcon
      disabled={disabledButton}
      startDate={startDate}
      endDate={endDate}
      holidayType={selectedHolidayTypes}
      dataCY="new-request-week-calendar-modal">
      <WrapperForSelectComponents gridRowGap={'1.5rem'} margin={'1rem 0'}>
        <Select
          label="Holiday type"
          placeholder="choose"
          options={individualHolidayTypes()}
          selectedOption={selectedHolidayTypes}
          onOptionClicked={setSelectedHolidayTypes}
          labelSelector={(holidayType) => holidayType.value}
          dataCY="new-request-holiday-type-select"
        />

        <RangeDatePicker
          label="Holiday Time"
          startDate={startDate}
          endDate={endDate}
          setStartDateSelect={handleStartDateSelect}
          setEndDateSelect={handleEndDateSelect}
          dataCY="new-request-holiday-time-date-picker"
        />

        {correctDates && <WrongDates>The selected dates are incorrect</WrongDates>}

        <div style={{ marginBottom: 8 }}>
          <AdditionalInfoTitle>
            <Text.Paragraphs type="caption">Additional comment</Text.Paragraphs>
          </AdditionalInfoTitle>

          <AdditionalInfoOnHolidayRequest
            placeholder={'More information about holiday request...'}
            onChange={(e) => setAdditionalMessage(e.currentTarget.value)}
            data-cy="additional-info-on-holiday-request"
          />
        </div>

        {userDetails?.holiday &&
          (selectedHolidayTypes?.key === HolidayTypesEnums.Sick ||
            selectedHolidayTypes?.key === HolidayTypesEnums.Holiday ||
            selectedHolidayTypes?.key === HolidayTypesEnums.Occasional) && (
            <HolidaySummaryRow
              left="Requesting"
              right={countWorkingDays(startDate, endDate) || 0}
              color={colors.dark.tertiary}
              weight={600}
            />
          )}
      </WrapperForSelectComponents>

      {userDetails?.holiday && selectedHolidayTypes?.key === HolidayTypesEnums.OnDemand && (
        <BalanceComponent
          title={'Balance'}
          requestingHoliday={requestingHoliday}
          onDemand={forUseOnDemandDynamic}
          onDemandTotal={userDetails?.holiday?.onDemandTotal || 0}
          useLeft={forUseLeftDynamic}
          total={userDetails?.holiday?.total || 0}
          tooManyOnDemand={!!tooManyOnDemand}
          onDemandValue={onDemandValue}
        />
      )}

      {userDetails?.holiday &&
        (selectedHolidayTypes?.key === HolidayTypesEnums.Vacation ||
          selectedHolidayTypes?.key === HolidayTypesEnums.Kid) && (
          <BalanceComponent
            title={'Balance'}
            requestingHoliday={requestingHoliday}
            onDemand={userDetails.holiday.onDemand}
            onDemandTotal={userDetails.holiday.onDemandTotal || 0}
            useLeft={forUseLeftDynamic}
            total={userDetails.holiday.total || 0}
          />
        )}
    </Modal>
  );
};
