import styled from 'styled-components';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';

export const Container = styled.aside`
  background-color: ${({ theme }) => theme.backgroundElement};
  width: 100%;
  height: 77vh;
  border-radius: ${borderRadius.sm};
  margin: 0 0.5rem 1rem 0;
  overflow-y: auto;
  z-index: 1;

  &:hover {
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 1rem;
      margin: 0.5rem;
      scroll-padding: 20rem;
    }
  }

  ::-webkit-scrollbar-corner {
    margin: 20rem;
    border-right: 0.5rem solid ${colors.white};
    padding-right: 2rem;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.secondary};
    margin: 0.5rem;
    border-right: 0.5rem solid ${({ theme }) => theme.backgroundElement};
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.dark.tertiary};
    border-right: 0.5rem solid ${({ theme }) => theme.backgroundElement};
    border-radius: 0;
  }
`;

export const Wrap = styled.div`
  margin: 1.5rem 1rem;
  position: relative;
`;
