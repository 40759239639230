import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { routes } from '.';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
}

export const PrivateRoute = ({ children, component: Component, ...rest }: PrivateRouteProps) => {
  const { isAuthenticated } = useUserContext();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={routes.login} />;
        }
      }}
    />
  );
};
