import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Type, Variant } from 'components/AlertComponent/types';
import { SingleDatePicker } from 'components/Inputs/DatePicker/SingleDatePicker';
import { TileWrapper } from 'components/TileWrapper';
import { useToastContext } from 'contexts/ToastContext';
import { ApiError } from 'services/AuthService';
import { InputFullForm } from '../Inputs/InputFull/InputFullForm';
import { PersonalDetailsTileProps } from './types';

export const PersonalDetailsTile = ({ employee, onEmploymentUpdate }: PersonalDetailsTileProps) => {
  const { setToast, handleError } = useToastContext();
  const [firstName, setFirstName] = useState(employee.firstName);
  const [lastName, setLastName] = useState(employee.lastName);
  const [dateOfBirth, setDateOfBirth] = useState<undefined | null | Date>(
    employee.dateOfBirth ? new Date(employee.dateOfBirth) : null,
  );

  useEffect(() => {
    setFirstName(employee.firstName);
    setLastName(employee.lastName);
    setDateOfBirth(new Date(employee.dateOfBirth));
  }, [employee]);

  const isDisabled =
    employee.firstName !== firstName ||
    employee.lastName !== lastName ||
    dayjs(dateOfBirth).format('YYYY-MM-DD') !== employee.dateOfBirth;

  const handleFirstName = (e: React.FormEvent<HTMLInputElement>) => {
    setFirstName(e.currentTarget.value);
  };

  const handleLastName = (e: React.FormEvent<HTMLInputElement>) => {
    setLastName(e.currentTarget.value);
  };

  const handleDateOfBirth = (date: Date) => {
    setDateOfBirth(date);
  };

  const onSubmit = async () => {
    try {
      await onEmploymentUpdate({
        firstName,
        lastName,
        dateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
      });
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Personal details changed successfully!',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  return (
    <>
      <TileWrapper
        title={'Personal details'}
        button={{
          content: 'Save',
          onClick: onSubmit,
          disabled: !isDisabled,
          dataCY: 'team-personal-details-save-button',
        }}>
        <InputFullForm
          label={'Name'}
          placeholder={'Employee’s name'}
          value={firstName}
          onChange={handleFirstName}
          dataCY="team-personal-details-first-name"
        />
        <InputFullForm
          label={'Surname'}
          placeholder={'Employee’s surname'}
          value={lastName}
          onChange={handleLastName}
          dataCY="team-personal-details-last-name"
        />
        <InputFullForm
          label={'Email'}
          placeholder={'employee@leocode.com'}
          value={employee.email}
          dataCY="team-personal-details-email"
          disabled={true}
        />
        <SingleDatePicker
          label={'Date of birth'}
          initialDate={dateOfBirth}
          onChange={handleDateOfBirth}
          dataCY="team-personal-details-date-of-birth"
        />
      </TileWrapper>
    </>
  );
};
