import { SvgIconComponent } from '../../Icons';
import { FixPaddings, StyledButton, StyledIconLeft, Wrap, LabelWrapper } from './styled';
import { ButtonProps } from './types';

export const Button = ({
  type,
  variant = 'primary',
  size = 'regular',
  label,
  threeDotsForLongWord = false,
  hideBorder = false,
  iconNameLeft,
  iconNameRight,
  iconOnClick,
  disabled = false,
  activeHover = false,
  departmentButton = false,
  dataCY,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      hideBorder={hideBorder}
      disabled={disabled}
      data-cy={dataCY}
      activeHover={activeHover}
      {...props}>
      <Wrap departmentButton={departmentButton}>
        <FixPaddings size={size} threeDotsForLongWord={threeDotsForLongWord}>
          {iconNameLeft && (
            <div onClick={iconOnClick}>
              <StyledIconLeft size={size} icon={iconNameLeft} width={iconSizes[size].leftIcon} />
            </div>
          )}
          <LabelWrapper threeDotsForLongWord={threeDotsForLongWord}>
            <span>{label}</span>
          </LabelWrapper>
        </FixPaddings>
        {iconNameRight && (
          <div onClick={iconOnClick}>
            <SvgIconComponent
              icon={iconNameRight}
              width={departmentButton ? 20 : iconSizes[size].chevron}
            />
          </div>
        )}
      </Wrap>
    </StyledButton>
  );
};

const iconSizes = {
  small: { chevron: 8, leftIcon: 12 },
  regular: { chevron: 10, leftIcon: 12 },
  large: { chevron: 14, leftIcon: 16 },
};
