import ApprovalIcon from 'assets/icons/ApprovalIcon.svg';
import ArrowDownIcon from 'assets/icons/ArrowDownIcon.svg';
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon.svg';
import ArrowLeftSkinnyIcon from 'assets/icons/ArrowLeftSkinnyIcon.svg';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon.svg';
import ArrowRightSkinnyIcon from 'assets/icons/ArrowRightSkinnyIcon.svg';
import ArrowUpIcon from 'assets/icons/ArrowUpIcon.svg';
import BellIcon from 'assets/icons/BellIcon.svg';
import BriefcaseIcon from 'assets/icons/BriefcaseIcon.svg';
import CalendarOutlineIcon from 'assets/icons/CalendarOutlineIcon.svg';
import CheckIcon from 'assets/icons/CheckIcon.svg';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon.svg';
import CrossMarkIcon from 'assets/icons/CrossMarkIcon.svg';
import DisapprovalIcon from 'assets/icons/DisapprovalIcon.svg';
import EllipsisIcon from 'assets/icons/EllipsisIcon.svg';
import EmojiLionIcon from 'assets/icons/EmojiLionIcon.svg';
import ExclamationIcon from 'assets/icons/ExclamationIcon.svg';
import EyeIcon from 'assets/icons/EyeIcon.svg';
import EyeOutlineIcon from 'assets/icons/EyeOutlineIcon.svg';
import GiftIcon from 'assets/icons/GiftIcon.svg';
import HolidayIcon from 'assets/icons/HolidayIcon.svg';
import InfoIcon from 'assets/icons/InfoIcon.svg';
import LionIcon from 'assets/icons/LionIcon.svg';
import MaternalHolidayIcon from 'assets/icons/MaternalHolidayIcon.svg';
import MoonIcon from 'assets/icons/MoonIcon.svg';
import MoonOutlineIcon from 'assets/icons/MoonOutlineIcon.svg';
import PalmIcon from 'assets/icons/PalmIcon.svg';
import PenIcon from 'assets/icons/PenIcon.svg';
import PlusIcon from 'assets/icons/PlusIcon.svg';
import RejectedIcon from 'assets/icons/RejectedIcon.svg';
import RequestedDateIcon from 'assets/icons/RequestedDateIcon.svg';
import SearchIcon from 'assets/icons/SearchIcon.svg';
import SettingsIcon from 'assets/icons/SettingsIcon.svg';
import SickHolidayIcon from 'assets/icons/SickHolidayIcon.svg';
import SquaresIcon from 'assets/icons/SquaresIcon.svg';
import SuitcaseIcon from 'assets/icons/SuitcaseIcon.svg';
import SunIcon from 'assets/icons/SunIcon.svg';
import SunOutlineIcon from 'assets/icons/SunOutlineIcon.svg';
import TrashIcon from 'assets/icons/TrashIcon.svg';
import UserGreyIcon from 'assets/icons/UserGreyIcon.svg';
import UserIcon from 'assets/icons/UserIcon.svg';
import WarningIcon from 'assets/icons/Warring.svg';

export const icons = {
  ApprovalIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowLeftSkinnyIcon,
  ArrowRightIcon,
  ArrowRightSkinnyIcon,
  ArrowUpIcon,
  BellIcon,
  BriefcaseIcon,
  CalendarOutlineIcon,
  CheckIcon,
  ChevronRightIcon,
  CrossMarkIcon,
  DisapprovalIcon,
  EllipsisIcon,
  EmojiLionIcon,
  ExclamationIcon,
  EyeIcon,
  EyeOutlineIcon,
  GiftIcon,
  HolidayIcon,
  InfoIcon,
  LionIcon,
  MaternalHolidayIcon,
  MoonIcon,
  MoonOutlineIcon,
  PalmIcon,
  PenIcon,
  PlusIcon,
  RejectedIcon,
  RequestedDateIcon,
  SearchIcon,
  SettingsIcon,
  SickHolidayIcon,
  SquaresIcon,
  SuitcaseIcon,
  SunIcon,
  SunOutlineIcon,
  TrashIcon,
  UserGreyIcon,
  UserIcon,
  WarningIcon,
};
