import { SvgIconName } from 'components/Icons/types';
import { Department } from 'services/DepartmentsService';
import { TeamHoliday } from 'services/HolidaysService';
import { EmploymentTypeInterfaceTypes } from 'types';

export type HolidayRowProps = {
  holiday: TeamHoliday;
  employmentTypes: EmploymentTypeInterfaceTypes[] | undefined;
  refetch: VoidFunction;
};

export const holidayIcon = {
  on_demand: 'HolidayIcon' as SvgIconName,
  sick: 'SickHolidayIcon' as SvgIconName,
  vacation: 'HolidayIcon' as SvgIconName,
  holiday: 'HolidayIcon' as SvgIconName,
  kid: 'MaternalHolidayIcon' as SvgIconName,
  occasional: 'HolidayIcon' as SvgIconName,
};

export interface HolidayProps {
  activeDepartment?: Department;
}
