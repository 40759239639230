import styled, { css } from 'styled-components';
import { Text } from '../../index';

export const CalendarMonthNavigationContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const MonthsNavigationButton = css`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const PrevIconWrapper = styled.div<{ smallCalendar?: boolean }>`
  ${MonthsNavigationButton};
  color: ${({ theme }) => theme.smallCalendar.navigationIcon};
  margin: 0 0.25rem 0 ${({ smallCalendar }) => (smallCalendar ? 0 : '1.5rem')};
`;

export const NextIconWrapper = styled.div<{ smallCalendar?: boolean }>`
  ${MonthsNavigationButton};
  margin: 0 ${({ smallCalendar }) => (smallCalendar ? 0 : '1.5rem')} 0 0.25rem;
`;

interface FormatDateWrapperProp {
  isHeaderUserCalendar?: boolean;
}

export const FormatDateWrapper = styled.div<FormatDateWrapperProp>`
  ${({ isHeaderUserCalendar }) =>
    isHeaderUserCalendar &&
    css`
      width: 120px;
    `}
`;

export const CalendarMonthNavigationHeader = styled(Text.Heading)`
  color: ${({ theme }) => theme.text[900]};
`;
