import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CalendarMonthNavigation } from '../../components/Calendar';
import { SingleEmployeeHeader } from '../../components/SingleEmployeeHeader';
import { Spinner } from '../../components/Spinner';
import { useCalendarContext } from '../../contexts';
import { useHolidays, useSingleEmployee } from '../../hooks';
import { routes } from '../../routes';
import { Employee } from '../../services/EmployeesService';
import { StyledNavbar } from '../Dashboard/styled';
import {
  HolidayBalanceTile,
  HolidaysTile,
  PersonalReportTile,
  YearCalendarTile,
} from './components';
import { Wrapper, Row, Container, FlexWrapper } from './styled';

type TeamURLParams = {
  id: string;
};

export const SingleEmployeeCalendarYear = () => {
  const { id } = useParams<TeamURLParams>();
  const { decreaseYearIndex, increaseYearIndex, currentYear } = useCalendarContext();
  const [singleEmployee, setSingleEmployee] = useState<Employee>();

  const { employee, refetchWithYear: refetchSingleEmployee } = useSingleEmployee(
    id,
    currentYear.toString(),
  );
  const { holidays, refetch: refetchHolidays } = useHolidays(id);

  useEffect(() => {
    refetchHolidays();
    refetchSingleEmployee();
  }, [currentYear]);

  useEffect(() => {
    setSingleEmployee(employee);
  }, [employee]);

  if (!singleEmployee) {
    return <Spinner fullPage />;
  }

  return (
    <>
      <StyledNavbar />
      <Container>
        <SingleEmployeeHeader
          nameIsClickable={true}
          employee={singleEmployee}
          navigateBackLink={routes.reports}
        />
        <FlexWrapper>
          <CalendarMonthNavigation
            prevIcon="ArrowLeftIcon"
            prevIconWidth={25}
            nextIcon="ArrowRightIcon"
            nextIconWidth={25}
            onClickPrevIcon={decreaseYearIndex}
            onClickNextIcon={increaseYearIndex}
            isFormatDate>
            {currentYear.toString()}
          </CalendarMonthNavigation>
        </FlexWrapper>
        <Wrapper>
          <Row>
            <HolidaysTile holidays={holidays} />
            {singleEmployee.holiday && <HolidayBalanceTile holiday={singleEmployee.holiday} />}
            <PersonalReportTile userId={singleEmployee.id.toString()} />
          </Row>
          <YearCalendarTile holidays={holidays} />
        </Wrapper>
      </Container>
    </>
  );
};
