import { useMemo, useState } from 'react';
import { Dayjs } from 'dayjs';
import { ShortUserWithHolidays } from 'contexts/TeamHolidaysContext';
import { dateOverlapsHoliday } from 'utils';
import { searchByEmployee } from 'utils/search';

const filterByDepartment = (user: ShortUserWithHolidays, departmentId: number): boolean => {
  return user.departmentId === departmentId;
};

const filterByPhrase = (user: ShortUserWithHolidays, phrase: string): boolean => {
  return searchByEmployee(phrase, user);
};

const filterByDay = (user: ShortUserWithHolidays, date: Dayjs): boolean => {
  const userHolidaysOverlappingDate = user.holidays.filter((holiday) =>
    dateOverlapsHoliday(date, holiday),
  );

  return !!userHolidaysOverlappingDate.length;
};

export const useFiltersWeekCalendar = (users: ShortUserWithHolidays[], defaultPhrase?: string) => {
  const [searchPhrase, setSearchPhrase] = useState<string | null>(defaultPhrase ?? null);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number | null>(null);
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);

  const filteredUsersWeekCalendar = useMemo(() => {
    const sortedUsers = [...users].sort((userA, userB) => userA.id - userB.id);

    return sortedUsers.filter((user) => {
      const hasMatchingDepartment = selectedDepartmentId
        ? filterByDepartment(user, selectedDepartmentId)
        : true;

      const hasMatchingName = searchPhrase ? filterByPhrase(user, searchPhrase) : true;
      const hasVacationDuringSelectedDay = selectedDay ? filterByDay(user, selectedDay) : true;

      return hasMatchingDepartment && hasMatchingName && hasVacationDuringSelectedDay;
    });
  }, [searchPhrase, selectedDay, selectedDepartmentId, users]);

  const searchPhraseStateWeekCalendar = { state: searchPhrase, setState: setSearchPhrase };
  const selectedDepartmentIdStateWeekCalendar = {
    state: selectedDepartmentId,
    setState: setSelectedDepartmentId,
  };
  const selectedDayStateWeekCalendar = { state: selectedDay, setState: setSelectedDay };

  return {
    filteredUsersWeekCalendar,
    searchPhraseStateWeekCalendar,
    selectedDayStateWeekCalendar,
    selectedDepartmentIdStateWeekCalendar,
  };
};
