import { useQuery } from 'react-query';
import DepartmentsService, { Department } from 'services/DepartmentsService';
import { makeContext } from './ContextCreator';
import { useUserContext } from './UserContext';

export const [useDepartmentContext, DepartmentProvider] = makeContext(() => {
  const { isManager } = useUserContext();

  const { isLoading, data: departments } = useQuery<Department[], Error>(
    'departments',
    DepartmentsService.fetchDepartments,
    {
      enabled: isManager,
    },
  );
  return { departments, isLoading };
});
