import styled from 'styled-components';
import { Button as ButtonUI } from 'components/Button/ButtonBase';
import { RequestManagerModal } from 'components/Modals';
import Text from 'components/Text';
import { remCalc } from 'helpers';

export const ProfileUserContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 533px;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  align-items: flex-start;
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: ${remCalc(70)};
`;

export const Button = styled(ButtonUI)`
  width: 11.5rem;
`;

export const DeactivateModal = styled(RequestManagerModal)`
  width: 35.5rem;
`;

export const ActivateModal = styled(RequestManagerModal)`
  width: 35.5rem;
`;

export const TextInModal = styled(Text.Paragraphs)`
  margin: 1rem 0 2.5rem 0;
`;
