import { HolidayStatuses, TeamHoliday } from 'services/HolidaysService';
import { filterHolidaysByStatus, getHolidayDaysByMonth } from 'utils';
import { HolidayStatusListItem } from '../types/types';

export const useSelectedHolidayStatuses = (
  statuses: HolidayStatuses[],
  teamHolidays: TeamHoliday[],
  monthIndex: number,
): HolidayStatusListItem[] => {
  return [
    {
      status: HolidayStatuses.Accepted,
      label: 'Accepted',
      days: getHolidayDaysByMonth(
        filterHolidaysByStatus(teamHolidays, HolidayStatuses.Accepted),
        monthIndex,
      ),
      checked: statuses.some((status) => status === HolidayStatuses.Accepted),
    },
    {
      status: HolidayStatuses.Pending,
      label: 'Requested',
      days: getHolidayDaysByMonth(
        filterHolidaysByStatus(teamHolidays, HolidayStatuses.Pending),
        monthIndex,
      ),
      checked: statuses.some((status) => status === HolidayStatuses.Pending),
    },
    {
      status: HolidayStatuses.Declined,
      label: 'Rejected',
      days: getHolidayDaysByMonth(
        filterHolidaysByStatus(teamHolidays, HolidayStatuses.Declined),
        monthIndex,
      ),
      checked: statuses.some((status) => status === HolidayStatuses.Declined),
    },
  ].filter((item) => item.days !== 0);
};
