import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { RangeDatePicker } from '../../../components/Inputs/DatePicker/RangeDatePicker';
import { TileWrapper } from '../../../components/TileWrapper';
import { CONFIG } from '../../../const/config';

interface Props {
  userId: string;
}

export const PersonalReportTile = ({ userId }: Props) => {
  const [startDate, setStartDate] = useState<null | Date>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<null | Date>(new Date());

  const startDateFormat = useMemo(() => dayjs(startDate).format('YYYY-MM-DD'), [startDate]);
  const endDateFormat = useMemo(() => dayjs(endDate).format('YYYY-MM-DD'), [endDate]);
  const token = Cookies.get(CONFIG.SSO_COOKIE_KEY);

  const generateReport = () => {
    window.open(
      `${CONFIG.API_URL}/reports/holidays/export?date[from]=${startDateFormat}&date[to]=${endDateFormat}&user_ids=${userId}&token=${token}`,
    );
  };

  return (
    <TileWrapper
      title="Personal report"
      button={{
        content: 'Generate Report',
        onClick: generateReport,
        dataCY: 'generate-report-button',
      }}>
      <RangeDatePicker
        label="Time Range"
        startDate={startDate}
        endDate={endDate}
        setStartDateSelect={setStartDate}
        setEndDateSelect={setEndDate}
        dataCY="generate-report-datepicker"
      />
    </TileWrapper>
  );
};
