import dayjs from 'dayjs';
import { CalendarButtons, CalendarMonthNavigation } from 'components/Calendar';
import { CalendarType } from 'components/Calendar/types';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { useCalendarContext } from 'contexts/CalendarContext';
import { TeamCalendarHeaderProps } from '../../types';
import { ButtonOpenModal, CalendarHeaderWrapper, Container } from '../styled';

export const TeamCalendarHeader = ({
  calendarType,
  setCalendarType,
  selectedDayState,
}: TeamCalendarHeaderProps) => {
  const {
    date,
    firstDayOfWeek,
    decreaseMonthIndex,
    increaseMonthIndex,
    decreaseWeekIndex,
    increaseWeekIndex,
    handleReset,
    setDate,
  } = useCalendarContext();

  const handleNext = () => {
    if (calendarType === CalendarType.Month) {
      increaseMonthIndex();
    } else if (calendarType === CalendarType.Week) {
      increaseWeekIndex();
      selectedDayState.setState(null);
    }
  };

  const handlePrev = () => {
    if (calendarType === CalendarType.Month) {
      decreaseMonthIndex();
    } else if (calendarType === CalendarType.Week) {
      decreaseWeekIndex();
      selectedDayState.setState(null);
    }
  };

  const formatDate = () => {
    if (calendarType === CalendarType.Month) {
      return date.format('MMMM YYYY');
    } else {
      const startDay = firstDayOfWeek;
      const endDay = startDay.add(6, 'day');

      return `${startDay.format('MMM DD')} - ${endDay.format('MMM DD')}`;
    }
  };

  const [isVisible, toggle] = useModal();

  const handleDateChange = (inputDate: Date) => setDate(dayjs(inputDate));

  return (
    <CalendarHeaderWrapper data-cy="team-calendar-header">
      <Container>
        <ButtonOpenModal
          iconNameLeft="PlusIcon"
          label="New request"
          size="regular"
          variant="tertiary"
          onClick={toggle}
          dataCY="team-calendar-new-request-button"
        />

        {isVisible && (
          <NewRequestHolidayModal
            isVisible={isVisible}
            toggle={toggle}
            withEmployeeDropdown
            employmentType={null}
            buttonProps={{ variant: 'tertiary', label: 'Add holiday' }}
          />
        )}

        <CalendarMonthNavigation
          prevIcon="ArrowLeftIcon"
          prevIconWidth={25}
          nextIcon="ArrowRightIcon"
          nextIconWidth={25}
          onClickPrevIcon={handlePrev}
          onClickNextIcon={handleNext}
          formatDate={formatDate()}
          isFormatDate
        />
      </Container>

      <CalendarButtons
        onChange={handleDateChange}
        date={date.toDate()}
        handleReset={handleReset}
        selectTypeCalendar={true}
        calendarType={calendarType}
        setCalendarType={setCalendarType}
      />
    </CalendarHeaderWrapper>
  );
};
