export const routes = {
  dashboard: '/',
  reports: '/reports',
  singleEmployeeYearCalendar: '/reports/:id',
  login: '/login',
  team: '/team',
  calendar: '/calendar',
  teamCalendar: '/team-calendar',
  profile: '/profile',
  notFound: '/not-found',
};
