import { useLocation } from 'react-router-dom';
import { StyledLink } from 'components/Navbar/styled';
import { FixPadding, StyledButton, Wrap } from './styled';
import { ButtonNavbarProps } from './types';

export const ButtonNavbar = ({ label, to, ...props }: ButtonNavbarProps) => {
  const location = useLocation();
  const isActive = to === location.pathname;

  return (
    <StyledLink to={to}>
      <StyledButton isActive={isActive} {...props}>
        <Wrap>
          <FixPadding>{label}</FixPadding>
        </Wrap>
      </StyledButton>
    </StyledLink>
  );
};
