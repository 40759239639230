import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  margin: 1rem 1.5rem 0 1.5rem;
  height: 100%;
`;

export const UserWrapper = styled.div`
  height: 12.625rem;
`;
