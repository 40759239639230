import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Text from '../../../Text';
import { RawDatePicker } from '../RawDatePicker';
import { DatePickerStyles, DatePickerLabel } from '../styled';
import { SingleDatePickerProps } from '../types';

export const SingleDatePicker = ({
  label,
  initialDate,
  date,
  onChange,
  dataCY,
  variant,
}: SingleDatePickerProps) => {
  const [startDate, setStartDate] = useState<undefined | null | Date>(initialDate);

  useEffect(() => {
    setStartDate(initialDate);
  }, [label, initialDate, date, onChange, variant]);

  const handleDateSelect = (selectedDate: Date) => {
    setStartDate(selectedDate);
    onChange?.(selectedDate);
  };

  return (
    <div>
      {label && (
        <DatePickerLabel>
          <Text.Paragraphs type="caption" margin="0 0 8px 0">
            {label}
          </Text.Paragraphs>
        </DatePickerLabel>
      )}
      <div data-cy={dataCY}>
        <RawDatePicker
          selected={date || startDate}
          onChange={handleDateSelect}
          isClearable={true}
        />
        <DatePickerStyles variant={variant} />
      </div>
    </div>
  );
};
