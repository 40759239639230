import { SvgIconComponent } from 'components/Icons';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text';
import { colors } from 'styles/colors';
import { useThemeContext } from '../ThemeWrapper/ThemeWrapper';
import { EmployeeDetails } from './components/EmployeeDetails';
import { Row } from './styled';
import { PreviewHolidayModalProps } from './types';

export const PreviewHolidayModal = ({
  toggle,
  isVisibleRemove,
  isVisibleReject,
  buttonProps,
  onClickButton,
  additionalButtonProps,
  onClickAdditionalButton,
  label,
  subLabel,
  colorSubLabel,
  holidayType,
  requestedDates,
  reason,
  reasonMessage,
  crossMarkIcon,
  editIcon,
  onEdit,
  trashIcon,
  onDelete,
  hideButton,
  additionalComment,
}: PreviewHolidayModalProps) => {
  const { getColorByTheme } = useThemeContext();
  return (
    <Modal
      label={label}
      visible={isVisibleRemove || isVisibleReject}
      onClose={toggle}
      crossMarkIcon={crossMarkIcon}
      onClickButton={onClickButton}
      buttonProps={buttonProps}
      additionalButtonProps={additionalButtonProps}
      onClickAdditionalButton={onClickAdditionalButton}
      dataCY="preview-holiday-modal"
      onEdit={onEdit}
      editIcon={editIcon}
      trashIcon={trashIcon}
      onDelete={onDelete}
      hideButton={hideButton}
      holidayType={holidayType}>
      <Text.Paragraphs
        style={{ marginBottom: 25, marginTop: 11 }}
        type="xSmall"
        color={colorSubLabel}>
        {subLabel}
      </Text.Paragraphs>

      <EmployeeDetails
        holidayTypeValue={holidayType?.value}
        requestedDates={requestedDates}
        marginBottomEmpDetails="31px"
        additionalComment={additionalComment}
      />

      {reason && (
        <>
          <Row style={{ marginBottom: 12, marginTop: 24 }}>
            <SvgIconComponent
              height={14}
              icon="RejectedIcon"
              color={getColorByTheme(colors.dark.fail, colors.fail)}
            />
            <Text.Paragraphs
              style={{ marginLeft: 11 }}
              type="caption"
              color={getColorByTheme(colors.dark.fail, colors.fail)}>
              Reason
            </Text.Paragraphs>
          </Row>
          <Text.Paragraphs
            style={{ marginLeft: 2, marginBottom: 32 }}
            type="xSmall"
            color={getColorByTheme(colors.gray[800], colors.gray[200])}>
            {reasonMessage}
          </Text.Paragraphs>
        </>
      )}
    </Modal>
  );
};
