import { CapacityHolidayOnDayEmployee } from './CapacityHolidayOnDayEmployee';
import { CapacityHolidayOnWeek } from './CapacityHolidayOnWeek';
import { CapacityWrapper } from './styled';
import { CapacityHolidayTileProps } from './types';

export const CapacityHolidayTile = ({
  singleEmployeeId,
  className,
  capacityWeekParams,
  capacityDayParams,
}: CapacityHolidayTileProps) => {
  return (
    <CapacityWrapper className={className}>
      {capacityWeekParams && <CapacityHolidayOnWeek capacityParams={capacityWeekParams} />}
      {capacityDayParams && (
        <CapacityHolidayOnDayEmployee
          singleEmployeeId={singleEmployeeId}
          capacityParams={capacityDayParams}
        />
      )}
    </CapacityWrapper>
  );
};
