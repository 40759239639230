import { useCallback } from 'react';
import { useCalendarContext } from 'contexts/CalendarContext';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';

export const useFetchHolidaysForCurrentWeek = () => {
  const { firstDayOfWeek } = useCalendarContext();
  const { getTeamHolidays } = useTeamHolidaysContext();

  const fetch = useCallback(() => {
    getTeamHolidays({
      'date[from]': firstDayOfWeek.format('YYYY-MM-DD'),
      'date[to]': firstDayOfWeek.add(6, 'day').format('YYYY-MM-DD'),
    });
  }, [firstDayOfWeek, getTeamHolidays]);

  return fetch;
};
