import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'components/Spinner';
import { useCalendarContext } from 'contexts/CalendarContext';
import { useHolidaysContext } from 'contexts/HolidaysContext';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';
import { useUserContext } from 'contexts/UserContext';
import { getDateByMonth, getMonthMatrix } from 'utils';
import { AvailableCalendarPath } from '../types';
import { SmallCalendarBody } from './SmallCalendarBody';
import { SmallCalendarNavigation } from './SmallCalendarNavigation';
import { SmallCalendarBasicProps } from './types';

const settingPath = (path: string): string => {
  if (path.includes(AvailableCalendarPath.TEAM_CALENDAR)) {
    return AvailableCalendarPath.TEAM_CALENDAR;
  } else if (path.includes(AvailableCalendarPath.CALENDAR)) {
    return AvailableCalendarPath.CALENDAR;
  } else {
    return AvailableCalendarPath.SINGLE_EMPLOYEE_YEAR_CALENDAR;
  }
};

export const SmallCalendarBasic = ({
  customMonthIndex,
  showNavigation = true,
  holidaysData,
  toggleManagerModal,
  toggleEmployeeModal,
  mouseOver,
  setShowCapacitiesParticularDay,
}: SmallCalendarBasicProps) => {
  const { isManager, getUser } = useUserContext();
  const location = useLocation();
  const chosenCalendar = useMemo(() => settingPath(location.pathname), [location.pathname]);
  const { date } = useCalendarContext();

  const { getSmallCalendarUserHolidays } = useHolidaysContext();
  const { getSmallCalendarTeamHolidays } = useTeamHolidaysContext();

  const [currentYear, setCurrentYear] = useState(date.year());
  const [currentMonthIdx, setCurrentMonthIdx] = useState<number>(0);
  const [currentMonth, setCurrentMonth] = useState(getMonthMatrix());

  useEffect(() => {
    setCurrentMonthIdx(customMonthIndex);
    setCurrentYear(date.year());
  }, [date, customMonthIndex]);

  useEffect(() => {
    setCurrentMonth(getMonthMatrix(currentMonthIdx, currentYear));
    if (isManager && chosenCalendar === AvailableCalendarPath.TEAM_CALENDAR) {
      getSmallCalendarTeamHolidays({ date: getDateByMonth(currentMonthIdx, currentYear) });
    } else if (chosenCalendar === AvailableCalendarPath.CALENDAR) {
      getSmallCalendarUserHolidays({ date: getDateByMonth(currentMonthIdx, currentYear) });
    }
  }, [
    currentMonthIdx,
    getSmallCalendarUserHolidays,
    currentYear,
    chosenCalendar,
    getSmallCalendarTeamHolidays,
    isManager,
  ]);

  if (!currentMonth.length) return null;

  if (!getUser().holiday) {
    return <Spinner fullPage />;
  }

  return (
    <div data-cy="small-calendar">
      <SmallCalendarNavigation
        currentYear={currentYear}
        currentMonthIdx={currentMonthIdx}
        showNavigation={showNavigation}
        setCurrentMonthIdx={setCurrentMonthIdx}
        setCurrentYear={setCurrentYear}
      />
      <SmallCalendarBody
        holidaysData={holidaysData}
        currentMonth={currentMonth}
        smallCalendarUserHolidays={holidaysData}
        currentMonthIdx={currentMonthIdx}
        chosenCalendar={chosenCalendar}
        isManager={isManager}
        toggleEmployeeModal={toggleEmployeeModal}
        toggleManagerModal={toggleManagerModal}
        mouseOver={mouseOver}
        setShowCapacitiesParticularDay={setShowCapacitiesParticularDay}
      />
    </div>
  );
};
