import Avatar from 'react-avatar';
import { AvatarWrapper } from 'components/EmployeeTile/styled';
import { useUserContext } from 'contexts/UserContext';
import { Dot, Element, TagSection, UserHeaderContent, WelcomeSection } from './styled';

export const UserLoggedHeader = () => {
  const { getUser } = useUserContext();
  const user = getUser();

  const { firstName, lastName, position, department, source, role, avatar } = user;

  return (
    <UserHeaderContent>
      <AvatarWrapper>
        <Avatar name={`${lastName} ${firstName}`} size="56" round={true} src={avatar} />
      </AvatarWrapper>
      <WelcomeSection>
        Welcome, {lastName} {firstName}
      </WelcomeSection>

      <TagSection>
        {position && (
          <>
            <Element>{position}</Element>
            <Dot />
          </>
        )}
        {department && role && (
          <>
            <Element style={{ marginRight: 4 }}>{department}</Element>
            <Element>{role}</Element>
            <Dot />
          </>
        )}
        {source && <Element>{source}</Element>}
      </TagSection>
    </UserHeaderContent>
  );
};
