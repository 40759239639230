import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { CalendarType } from 'components/Calendar/types';
import { ClickableSection } from 'components/TileWrapper/types';
import { useCalendarContext } from 'contexts';
import { routes } from 'routes';
import { Employee } from 'services/EmployeesService';
import { HolidayStatuses, TeamHoliday } from 'services/HolidaysService';

interface UseHolidayFooterInterface {
  footer: ClickableSection | undefined;
}

interface Props {
  employee: Employee | undefined;
  holidays: TeamHoliday[];
}

export const useHolidayFooter = ({ holidays, employee }: Props): UseHolidayFooterInterface => {
  const history = useHistory();
  const { setDate } = useCalendarContext();

  const firstPendingRequest = useMemo(() => {
    return holidays.find((firstHoliday) => firstHoliday.status === HolidayStatuses.Pending);
  }, [holidays]);

  useEffect(() => {
    firstPendingRequest && setDate(dayjs(firstPendingRequest.dateFrom));
  }, [firstPendingRequest, setDate]);

  const footer: ClickableSection | undefined = useMemo(() => {
    if (!firstPendingRequest || !employee) {
      return undefined;
    }

    const onClick = () => {
      localStorage.setItem('calendar_period', CalendarType.Week);
      history.push(routes.teamCalendar, {
        userName: `${employee.lastName} ${employee.firstName}`,
      });
    };

    return {
      content: 'Holiday request waiting for approval',
      onClick,
      isRequest: true,
    };
  }, [employee, firstPendingRequest, history, holidays]);

  return { footer };
};
