import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useCalendarContext, useToastContext } from '../contexts';
import { ApiError } from '../services/AuthService';
import EmployeesService, { FetchEmployeeHolidayRequestsProps } from '../services/EmployeesService';
import { TeamHoliday } from '../services/HolidaysService';

interface UseSHolidaysInterface {
  holidays: TeamHoliday[];
  refetch: () => void;
}

export const useHolidays = (
  id: string,
  requestParams?: FetchEmployeeHolidayRequestsProps,
): UseSHolidaysInterface => {
  const [holidays, setHolidays] = useState<TeamHoliday[]>([]);
  const { currentYear } = useCalendarContext();
  const { handleError } = useToastContext();

  const defaultParams = useMemo(
    () => ({
      'date[from]': `${currentYear}-01-01`,
      'date[to]': `${currentYear}-12-31`,
    }),
    [currentYear],
  );

  const [params, setParams] = useState<FetchEmployeeHolidayRequestsProps>(defaultParams);
  const { data, error, refetch } = useQuery<TeamHoliday[]>(['holidays', params], () =>
    EmployeesService.fetchEmployeeHolidayRequests(id, params),
  );

  useEffect(() => {
    if (requestParams) {
      setParams(requestParams);
    }
  }, [requestParams]);

  useEffect(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  useEffect(() => {
    if (data) {
      setHolidays(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error as ApiError);
    }
  }, [error, handleError]);

  return { holidays, refetch };
};
