import dayjs from 'dayjs';
import { isHoliday } from 'poland-public-holidays';

export function countWorkingDays(
  firstDate: Date | undefined | null,
  secondDate: Date | undefined | null,
) {
  let days = 0;
  let curr = dayjs(firstDate);
  if (!firstDate || !secondDate) return undefined;
  do {
    if (curr.day() !== 6 && curr.day() !== 0 && !isHoliday(curr.toDate())) {
      days += 1;
    }
    curr = curr.add(1, 'days');
  } while (curr.valueOf() <= secondDate.valueOf());

  return days;
}
