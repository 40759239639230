import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { PaginationMeta } from '../services/ApiBaseService';
import DashboardService, {
  FetchDashboardParams,
  FetchDashboardResponse,
} from '../services/DashboardService';
import { TeamHoliday } from '../services/HolidaysService';

interface UseDashboardProps {
  dashboardHolidays: TeamHoliday[];
  meta: PaginationMeta | undefined;
  metaData: TeamHoliday[];
  refetch: () => void;
}

export const useDashboard = (params: FetchDashboardParams): UseDashboardProps => {
  const [dashboardHolidays, setDashboardHolidays] = useState<TeamHoliday[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>();
  const [metaData, setMetaData] = useState<TeamHoliday[]>([]);

  const { data, refetch } = useQuery<FetchDashboardResponse>(['dashboard-holiday', params], () =>
    DashboardService.fetchDashboardService(params),
  );

  useEffect(() => {
    if (data) {
      if (params.page) {
        setDashboardHolidays((currentData) => [...currentData, ...data.data]);
      } else {
        setDashboardHolidays(data.data);
      }
      setMeta(data.meta);
      setMetaData(data.data);
    }
  }, [data, params.page]);

  return { dashboardHolidays, refetch, meta, metaData };
};
