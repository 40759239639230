import { AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, Observable, of, catchError, map, switchMap } from 'rxjs';
import { employmentTypesService } from 'services';
import {
  getEmploymentTypesResponse,
  getEmploymentTypesError,
  GetEmploymentTypesRequestAction,
} from 'store/actions';
import { EmploymentTypesActionTypesEnum } from 'store/actionTypes';
import { EmploymentTypeServiceResponseInterfaceTypes } from 'types';

export const employmentTypesEpic: Epic = (action$: Observable<GetEmploymentTypesRequestAction>) =>
  action$.pipe(
    ofType(EmploymentTypesActionTypesEnum.EMPLOYMENT_TYPES_ACTION_REQUEST),
    switchMap(() =>
      from(employmentTypesService()).pipe(
        map((response: AxiosResponse<EmploymentTypeServiceResponseInterfaceTypes, unknown>) =>
          getEmploymentTypesResponse(response.data),
        ),
        catchError(() => of(getEmploymentTypesError())),
      ),
    ),
  );
