import styled from 'styled-components';
import { Layout } from 'components/Layout';

export const StyledLayout = styled(Layout)`
  margin: 1rem 2rem 0 2rem;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-template-areas: 'Parameters  FullReport';
  column-gap: 1rem;
  align-items: start;
`;
