import {
  ContentBox,
  ActionIconWrapper,
  IconLeftStyled,
  IconRightStyled,
  Labels,
  StyledAlertComponent,
  TextLabelStyled,
  TextSublabelStyled,
} from './styled';
import { AlertComponentProps } from './types';

export const AlertComponent = ({
  variant,
  type,
  label,
  subLabel,
  iconInfo,
  actionIcon,
  onClick,
  ...props
}: AlertComponentProps) => {
  return (
    <StyledAlertComponent variant={variant} type={type} {...props} data-cy="alert">
      <ContentBox>
        <IconLeftStyled icon={iconInfo} />
        <Labels data-cy="alert-text">
          <TextLabelStyled>{label}</TextLabelStyled>
          {subLabel && <TextSublabelStyled>{subLabel}</TextSublabelStyled>}
        </Labels>
      </ContentBox>
      {actionIcon && (
        <ActionIconWrapper onClick={onClick} data-cy="alert-icon">
          <IconRightStyled icon={actionIcon} />
        </ActionIconWrapper>
      )}
    </StyledAlertComponent>
  );
};
