import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { useQuery } from 'react-query';
import { AvatarWrapper } from 'components/EmployeeTile/styled';
import EmployeesService, { Employee, FetchEmployeesResponseType } from 'services/EmployeesService';
import { Select } from '..';
import { Label, Row } from './styled';

interface EmployeeDropdownSelectProps {
  defaultValue?: Employee;
  onEmployeeSelected?: (employee: Employee) => void;
}

export function EmployeeDropdownSelect({
  defaultValue,
  onEmployeeSelected,
}: EmployeeDropdownSelectProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedEmployee, setEmployee] = useState(defaultValue);

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setEmployee(undefined);
    setSearchQuery(e.currentTarget.value);
  };

  const { data } = useQuery<FetchEmployeesResponseType>(['search_employee', searchQuery], () =>
    EmployeesService.fetchEmployees({ query: searchQuery }),
  );
  const employeesList = data?.data.filter((employee) => employee.active) || [];

  const onSelect = (employee: Employee) => {
    setEmployee(employee);
    onEmployeeSelected && onEmployeeSelected(employee);
  };

  return (
    <Select
      label="Employee"
      searchInputProps={{
        handleChange,
        selected: selectedEmployee,
        onChange: handleChange,
      }}
      options={employeesList}
      headerSelector={({ firstName, lastName }) => `${firstName} ${lastName}`}
      labelSelector={({ avatar, firstName, lastName }) => (
        <Row>
          <AvatarWrapper>
            <Avatar name={`${lastName} ${firstName}`} size="24" round={true} src={avatar} />
          </AvatarWrapper>
          <Label>
            {lastName} {firstName}
          </Label>
        </Row>
      )}
      onOptionClicked={onSelect}
      selectedOption={selectedEmployee}
      placeholder="select employee"
      dataCY="employee-dropdown-select"
    />
  );
}
