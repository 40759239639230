import React, { useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isHoliday as isPublicHoliday } from 'poland-public-holidays';
import { useCalendarContext, updateRequestHeader } from 'contexts';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { foundHolidayContainingDay } from 'utils';
import Text from '../../Text';
import { useThemeContext } from '../../ThemeWrapper/ThemeWrapper';
import { AvailableCalendarPath } from '../types';
import { SmallCalendarDay } from './SmallCalendarDay';
import { SmallCalendarDayWrapper, SmallCalendarMonthWrapper } from './styled';
import { SmallCalendarBodyProps } from './types';

export const SmallCalendarBody = ({
  currentMonth,
  smallCalendarUserHolidays,
  currentMonthIdx,
  chosenCalendar,
  toggleManagerModal,
  toggleEmployeeModal,
  isManager,
  setShowCapacitiesParticularDay,
  mouseOver,
  holidaysData,
}: SmallCalendarBodyProps) => {
  const { setDate, getHolidayColorByType } = useCalendarContext();
  const { getColorByTheme } = useThemeContext();

  const isTeamCalendar = useMemo(
    () => chosenCalendar === AvailableCalendarPath.TEAM_CALENDAR,
    [chosenCalendar],
  );

  const isSingleEmployeeYearCalendar = useMemo(
    () => chosenCalendar === AvailableCalendarPath.SINGLE_EMPLOYEE_YEAR_CALENDAR,
    [chosenCalendar],
  );

  const getCalendarColor = useCallback(
    (isToday: boolean, isPublic: boolean, defaultColor: string) => {
      if (isToday) {
        return colors.surface.primary;
      } else if (isPublic) {
        return getColorByTheme(colors.dark.success, colors.success);
      } else {
        return defaultColor;
      }
    },
    [getColorByTheme],
  );

  const calendar = useMemo(
    () =>
      currentMonth.map((row) =>
        row.map((day) => {
          const isToday = dayjs().isSame(day, 'day');
          const isPublic = isPublicHoliday(dayjs(day).toDate());
          const isCurrentMonth = dayjs(day).month() === currentMonthIdx;
          const holidayFound = foundHolidayContainingDay(day, smallCalendarUserHolidays);
          const holidayColor = getHolidayColorByType(holidayFound?.holidayType);
          const colorCurrentMonth =
            localStorage.getItem('theme') === 'light'
              ? colors.gray[isCurrentMonth ? 700 : 500]
              : colors.gray[isCurrentMonth ? 200 : 700];
          const textColor = holidayFound ? holidayColor : colorCurrentMonth;

          const colorTeamCalendar = getCalendarColor(isToday, isPublic, colorCurrentMonth);
          const colorCalendar = getCalendarColor(isToday, isPublic, textColor);

          const chosenColor = isTeamCalendar ? colorTeamCalendar : colorCalendar;

          return { day, color: chosenColor };
        }),
      ),
    [
      currentMonth,
      isTeamCalendar,
      currentMonthIdx,
      smallCalendarUserHolidays,
      getCalendarColor,
      getHolidayColorByType,
    ],
  );

  const pressOnCurrentDay = useCallback(
    (pressedDay: Dayjs) => {
      if (isSingleEmployeeYearCalendar) return null;

      const holidayFound = foundHolidayContainingDay(pressedDay, smallCalendarUserHolidays);

      setShowCapacitiesParticularDay && setShowCapacitiesParticularDay(false);
      updateRequestHeader(dayjs(pressedDay).year().toString());
      setDate(pressedDay);
      const isHoliday =
        holidayFound?.status === HolidayStatuses.Accepted ||
        holidayFound?.status === HolidayStatuses.Pending;

      if (isTeamCalendar && isManager && toggleManagerModal) {
        toggleManagerModal();
      } else if (!isHoliday && toggleEmployeeModal) {
        toggleEmployeeModal();
      }
    },
    [
      smallCalendarUserHolidays,
      isTeamCalendar,
      setDate,
      setShowCapacitiesParticularDay,
      isManager,
      isSingleEmployeeYearCalendar,
      toggleManagerModal,
      toggleEmployeeModal,
    ],
  );

  return (
    <SmallCalendarMonthWrapper weeksCount={currentMonth.length}>
      {currentMonth[0].map((day, i) => (
        <SmallCalendarDayWrapper key={i}>
          <Text.Paragraphs type="xSmall">{day.format('dd')}</Text.Paragraphs>
        </SmallCalendarDayWrapper>
      ))}
      {calendar.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((dayCalendar, idx) => (
            <SmallCalendarDay
              holidaysData={holidaysData}
              isSingleEmployeeYearCalendar={isSingleEmployeeYearCalendar}
              day={dayCalendar.day}
              onPress={() => pressOnCurrentDay(dayCalendar.day)}
              onDotMouseOver={() => mouseOver && mouseOver(dayCalendar.day)}
              onDotMouseLeave={() =>
                setShowCapacitiesParticularDay && setShowCapacitiesParticularDay(false)
              }
              key={idx}
              employeeCalendar={!isTeamCalendar}>
              <Text.Paragraphs type="xSmall" color={dayCalendar.color}>
                {dayCalendar.day.format('D')}
              </Text.Paragraphs>
            </SmallCalendarDay>
          ))}
        </React.Fragment>
      ))}
    </SmallCalendarMonthWrapper>
  );
};
