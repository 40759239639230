// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import ApiBaseService from './ApiBaseService';

export interface HolidayProps {
  id: number;
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string | null;
  };
  requestedBy: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string | null;
  };
  holidayType: string;
  dateFrom: string;
  dateTo: string;
  days: number;
  status: string;
  additionalComment: string | null;
  reason: string;
  reviewedBy: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string | null;
  };
  reviewedAt: string;
  createdAt: string;
  updatedAt: string;
  completed: boolean;
}

export default class HolidayService extends ApiBaseService {
  static readonly API_URL = `${this.API_BASE_URL}`;

  static fetchHolidayById = async (id: string) => {
    const { data } = await axios.get<{ data: HolidayProps }>(
      `${this.API_URL}/holiday_requests/${id}`,
    );
    return data.data;
  };

  static createHolidayRequest = async (holiday_request: {
    userId: number | undefined;
    holidayType: string;
    dateFrom: string;
    dateTo: string;
    additionalComment?: string;
  }) => {
    const { data } = await axios.post(`${this.API_URL}/holiday_requests`, { holiday_request });
    return data.data;
  };

  static rejectHolidayById = (
    id: string | number,
    holiday_approval: {
      reason: string;
    },
  ) => {
    return axios.put<{ data: HolidayProps }>(`${this.API_URL}/holiday_requests/${id}/decline`, {
      holiday_approval,
    });
  };

  static acceptHolidayById = (id: string | number) => {
    return axios.put<{ data: HolidayProps }>(`${this.API_URL}/holiday_requests/${id}/accept`);
  };

  static deleteHolidayById = (id: number | string) => {
    return axios.delete<{ data: HolidayProps }>(`${this.API_URL}/holiday_requests/${id}`);
  };

  static updateHolidayById = (
    id: number | string,
    holiday_request: {
      holidayType: string;
      dateFrom: string;
      dateTo: string;
      additionalComment: string | null;
    },
  ) => {
    return axios.put<{ data: HolidayProps }>(`${this.API_URL}/holiday_requests/${id}`, {
      holiday_request,
    });
  };
}
