import Avatar from 'react-avatar';
import { AvatarWrapper } from 'components/EmployeeTile/styled';
import { useUserContext } from 'contexts/UserContext';
import { colors } from '../../styles/colors';
import { SvgIconComponent } from '../Icons';
import { useThemeContext } from '../ThemeWrapper/ThemeWrapper';
import { UserName, Wrap } from './styled';
import { UserProps } from './types';

export const User = ({ onClick }: UserProps) => {
  const { getUser } = useUserContext();
  const { firstName, lastName, avatar } = getUser();
  const userName = `${lastName} ${firstName}`;
  const { getColorByTheme } = useThemeContext();

  return (
    <Wrap onClick={onClick} data-cy="user-menu">
      <AvatarWrapper>
        <Avatar
          name={userName}
          size="24"
          round={true}
          src={avatar}
          color={getColorByTheme(colors.dark.tertiary, colors.tertiary)}
        />
      </AvatarWrapper>
      <UserName data-cy="user-name">{userName}</UserName>
      <SvgIconComponent icon="ArrowDownIcon" />
    </Wrap>
  );
};
