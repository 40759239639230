import styled, { css } from 'styled-components';
import { Button } from 'components/Button/ButtonBase';
import { colors } from 'styles/colors';
import { Text } from '../../../../components';

export const Container = styled.div`
  display: flex;
`;

export const ButtonOpenModal = styled(Button)`
  margin-right: 1.5rem;
`;

export const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  margin-right: 0.5em;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.background};
`;

export const HolidayLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.125rem;
`;

export const Dot = styled.div<{
  isToday?: boolean;
  holidayColor?: string;
  isEmployeeCalendarYear?: boolean;
}>`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ isToday }) => (isToday ? colors.dark.tertiary : 'unset')};
  transition: 0.25s;
  cursor: ${({ isEmployeeCalendarYear }) => (isEmployeeCalendarYear ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ isToday }) => (isToday ? colors.dark.tertiary : colors.gray[200])};
  }

  ${({ isEmployeeCalendarYear }) =>
    isEmployeeCalendarYear &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}

  ${({ holidayColor }) =>
    holidayColor &&
    css`
      div {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: ${holidayColor};
        }
      }
    `}
`;

export const HolidayLeftHeader = styled(Text.Heading)`
  color: ${({ theme }) => theme.text[900]};
`;
