import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { Button as ButtonUI } from '../Button/ButtonBase/index';
import { TitleProps } from './types';

export const TileWrapperContent = styled.div`
  box-sizing: border-box;
  display: inline-block;
  min-width: 22.5rem;
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  padding: 2em;
  position: relative;
  overflow: visible;
`;

export const Title = styled.div<TitleProps>`
  color: ${({ theme }) => theme.text[900]};
  ${typography.heading[5]}

  ${({ isLargeTitle }) =>
    isLargeTitle &&
    css`
      ${typography.heading[2]}
    `};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.profile.subHeader};
  ${typography.paragraphs.xSmall};
  margin-top: 0.5rem;
`;

export const Content = styled.div`
  margin-top: 1.5em;
`;

export const Button = styled(ButtonUI)`
  ${typography.buttonFontSize.regular}
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25em;
  margin-top: 2.5rem;
  width: 100%;
  background-color: ${colors.dark.tertiary};
  color: ${colors.surface.primary};
  border-radius: ${borderRadius.xs};
  border: none;

  ${({ variant }) =>
    variant === 'tertiaryOutline' &&
    css`
      color: ${colors.dark.tertiary};
      background-color: transparent;
      border: 1px solid ${colors.dark.tertiary};
    `};

  ${({ variant }) =>
    variant === 'fail' &&
    css`
      color: ${colors.white};
      background-color: ${colors.strongFail};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.button.disabled.background};
      color: ${({ theme }) => theme.button.disabled.text};
      cursor: default;
    `};
`;

interface FooterWrapperProps {
  isRequest?: boolean;
}

export const FooterWrapper = styled.div<FooterWrapperProps>`
  position: relative;
  width: calc(100% + 4rem);
  margin: 20px -2rem -2rem;
  min-height: 50px;

  border-bottom-left-radius: ${borderRadius.sm};
  border-bottom-right-radius: ${borderRadius.sm};

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.surface.primary};

  ${({ isRequest }) =>
    isRequest &&
    css`
      cursor: pointer;
      background-color: ${colors.primary};
    `};

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.profile.divider};
    position: absolute;
    top: 0;
  }
`;

export const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.warning};
  padding: 7px;
  border-radius: 50%;
`;

export const Footer = styled(ButtonUI)`
  cursor: pointer;
  position: relative;

  ${({ variant }) => variant === 'primary' && 'margin-right: auto;'}

  border: none;
  color: ${({ variant }) => (variant === 'primary' ? colors.gray['900'] : colors.tertiary)};

  background: inherit;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.button.tertiary};
    `}
`;
