// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import ApiBaseService from './ApiBaseService';

export interface Department {
  id: number;
  name: string;
  parentId: number | null;
}

export default class DepartmentsService extends ApiBaseService {
  static readonly API_URL = this.API_BASE_URL;

  static fetchDepartments = async () => {
    const { data } = await axios.get<{ data: Department[] }>(this.API_URL + '/departments');
    return data.data;
  };
}
