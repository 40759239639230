import { useMemo } from 'react';
import { useDepartmentContext } from 'contexts/DepartmentsContext';
import { useEmployeeContext } from 'contexts/EmployeesContext';
import { Capacity } from './components/Capacity';
import { Holidays } from './components/Holidays';
import { Report } from './components/Report';
import { Departments } from './components/styled';
import { Main, Row, StyledNavbar, Wrapper } from './styled';

export const ManagerDashboard = () => {
  const { departments } = useDepartmentContext();
  const { selectedDepartment } = useEmployeeContext();

  const activeDepartment = useMemo(
    () => departments?.find((d) => d.id === selectedDepartment),
    [departments, selectedDepartment],
  );

  return (
    <>
      <StyledNavbar />
      <Wrapper>
        <Departments activeDepartmentId={selectedDepartment} departments={departments || []} />
        <Main>
          <Row>
            <Capacity activeDepartment={activeDepartment} />
            <Report />
          </Row>
          <Holidays activeDepartment={activeDepartment} />
        </Main>
      </Wrapper>
    </>
  );
};
