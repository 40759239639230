import { SyntheticEvent } from 'react';
import { AdditionalHolidaUpdateTypes } from 'services/EmployeesService';
import { AdditionalHolidaysFieeldsNamesEnumType } from 'types';

export const inputValues = (
  prevState: AdditionalHolidaUpdateTypes[],
  e: SyntheticEvent<HTMLInputElement>,
  index: string,
  field: AdditionalHolidaysFieeldsNamesEnumType,
): AdditionalHolidaUpdateTypes[] => [
  ...prevState.map((item: AdditionalHolidaUpdateTypes) => {
    const result = (e.target as HTMLInputElement).value;
    const name = field === AdditionalHolidaysFieeldsNamesEnumType.NAME ? { name: result } : {};
    const days =
      field === AdditionalHolidaysFieeldsNamesEnumType.DAYS ? { days: Number(result) } : {};
    if (item.localId === index || `${item.id}-${item.name}` === index) {
      return {
        ...item,
        ...name,
        ...days,
      };
    }
    return item;
  }),
];

export const isInputsDataFilled = (items: AdditionalHolidaUpdateTypes[]): boolean => {
  let localCount = 0;
  let localCountCheecked = 0;
  let removedCount = 0;

  items.forEach((item: AdditionalHolidaUpdateTypes) => {
    if (item.localId) {
      localCount += 1;
      if (item.holidayType === 'custom') {
        localCountCheecked =
          item.year !== undefined && item.days !== undefined && item.name !== ''
            ? localCountCheecked + 1
            : localCountCheecked;
      } else if (item.holidayType === 'overdue') {
        localCountCheecked =
          item.year !== undefined && item.days !== undefined
            ? localCountCheecked + 1
            : localCountCheecked;
      }
    } else if (item.id) {
      removedCount += 1;
    }
  });

  if (
    (localCount > 0 && localCount === localCountCheecked) ||
    (localCount === 0 && removedCount > 0)
  ) {
    return true;
  }
  return false;
};

export const selectValues = (
  prevState: AdditionalHolidaUpdateTypes[],
  option: number,
  index: string,
  field: AdditionalHolidaysFieeldsNamesEnumType,
): AdditionalHolidaUpdateTypes[] => [
  ...prevState.map((item: AdditionalHolidaUpdateTypes) => {
    if (item.localId === index || `${item.id}-${item.name}` === index) {
      const year = field === AdditionalHolidaysFieeldsNamesEnumType.YEAR ? { year: option } : {};
      return {
        ...item,
        ...year,
      };
    }
    return item;
  }),
];

export const checkboxValue = (
  prevState: AdditionalHolidaUpdateTypes[],
  index: string,
): AdditionalHolidaUpdateTypes[] => [
  ...prevState.map((item: AdditionalHolidaUpdateTypes) => {
    if (item.localId === index || `${item.id}-${item.name}` === index) {
      const repeatEveryYear = !item.repeatEveryYear;
      return {
        ...item,
        repeatEveryYear,
      };
    }
    return item;
  }),
];

export const additionalHolidaysForRequestHelper = (
  additionalHolidays: AdditionalHolidaUpdateTypes[],
  index: string,
) =>
  additionalHolidays
    .map((item: AdditionalHolidaUpdateTypes) => {
      if (item.id?.toString() === index) {
        return {
          id: item.id,
          _destroy: 1,
          name: item.name,
          days: item.days,
          year: item.year,
        };
      }
      return item;
    })
    .filter((item: AdditionalHolidaUpdateTypes) => !(item.localId === index));
