import { REGEXES } from 'const/regexes';
import { InfoText, ModalRejectTextArea, StyledRejectModal } from './styled';
import { RejectModalProps } from './types';

export const RejectHolidayModal = ({
  setMessage,
  message,
  label,
  rightButtonAction,
  leftButtonAction,
  disabledButton,
  closeIcon,
  ...modalProps
}: RejectModalProps) => {
  const isButtonDisabled = () => message.replace(REGEXES.WHITE_SPACE, '').length <= 5;

  return (
    <StyledRejectModal
      label={label || 'Reject holiday request?'}
      buttonRight={{
        label: 'Reject holiday request',
        type: 'tertiary',
        onClick: rightButtonAction,
      }}
      disabledButton={isButtonDisabled()}
      closeIcon={closeIcon}
      leftButtonAction={leftButtonAction}
      dataCY="reject-holiday-modal"
      {...modalProps}>
      <InfoText>In order to reject the holiday request you have to write a reason</InfoText>
      <ModalRejectTextArea
        placeholder={'Rejection reason...'}
        onChange={(e) => setMessage(e.currentTarget.value)}
        data-cy="reject-modal-textarea"
      />
    </StyledRejectModal>
  );
};
