import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'components/Select';
import { All } from 'const';
import { RootReducer } from 'store/reducers';
import { EmploymentTypeInterfaceTypes } from 'types';
import { EmploymentTypeSelectProps } from '../../types';

export const EmploymentTypeSelect = ({
  selectedEmploymentTypeState,
}: EmploymentTypeSelectProps) => {
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);

  const [selectedEmploymentType, setSelectedEmploymentType] =
    useState<EmploymentTypeInterfaceTypes>(All);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState<
    EmploymentTypeInterfaceTypes[]
  >([All]);

  useEffect(() => {
    if (employmentTypesStore.listData) {
      setEmploymentTypeOptions((type) => [...type, ...employmentTypesStore.listData]);
    }
  }, [employmentTypesStore.listData]);

  useEffect(() => {
    if (selectedEmploymentTypeState) {
      const selectedKey =
        selectedEmploymentType.key === All.key ? null : selectedEmploymentType.key;
      selectedEmploymentTypeState.setState(selectedKey);
    }
  }, [selectedEmploymentType, selectedEmploymentTypeState]);

  return (
    <Select
      label="Employment type"
      options={employmentTypeOptions}
      selectedOption={selectedEmploymentType}
      onOptionClicked={setSelectedEmploymentType}
      labelSelector={(employmentType) => employmentType.value}
      dataCY="month-employee-type-select"
    />
  );
};
