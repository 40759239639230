import Text from '../Text';
import { Dot, DotType, LegendContainer, LegendRow } from './styled';

export function Legend() {
  const renderItem = (title: string, type: DotType) => (
    <LegendRow>
      <Dot {...{ type }} />
      <Text.Paragraphs type="caption">{title}</Text.Paragraphs>
    </LegendRow>
  );

  return (
    <LegendContainer>
      {renderItem('Used', 'used')}
      {renderItem('Available', 'available')}
      {renderItem('Requested', 'requested')}
    </LegendContainer>
  );
}
