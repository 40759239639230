import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';

export const ShowForWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const EmptyStateYourHolidays = styled.div`
  ${typography.heading[5]}
  color: ${colors.gray[500]}
`;
