import { Dayjs } from 'dayjs';
import { useCalendarContext } from 'contexts/CalendarContext';
import { calculateHolidaysPerDay } from '../../../helpers';
import { DaysLegendProps } from '../../../types';
import { DayBox } from './DayBox';
import { DaysLegendWrapper } from './styled';

export const DaysLegend = ({ selectedDayState, holidays }: DaysLegendProps) => {
  const { firstDayOfWeek } = useCalendarContext();
  const days = [0, 1, 2, 3, 4, 5, 6].map((dayOffset) => firstDayOfWeek.add(dayOffset, 'days'));

  const counts = calculateHolidaysPerDay(holidays, days);

  const handleClick = (day: Dayjs) => {
    selectedDayState.setState((selectedDay) => (selectedDay?.isSame(day, 'days') ? null : day));
  };

  return (
    <DaysLegendWrapper>
      {days.map((day, index) => (
        <DayBox
          holidayCount={counts[index]}
          date={day}
          isSelected={selectedDayState.state?.isSame(day, 'days') || false}
          onClick={() => handleClick(day)}
          key={day.format()}
        />
      ))}
    </DaysLegendWrapper>
  );
};
