import { Checkbox } from 'components/Switches/Checkbox';
import { ContainerHeader, NameHeader, NumberHeader, Row } from './styled';

interface HeaderProps {
  isAllChecked: boolean;
  toggleIsAllChecked: () => void;
}

const tableNameHeaders = ['Name', 'Employment Type', 'Department'];
const tableNumberHeaders = ['Total', 'On demand', 'Used', 'Requested', 'Available', 'Sick leave'];

export const Header = ({ isAllChecked, toggleIsAllChecked }: HeaderProps) => {
  return (
    <ContainerHeader>
      <Checkbox
        isChecked={isAllChecked}
        onClick={toggleIsAllChecked}
        dataCY="reports-main-checkbox"
      />
      <Row>
        {tableNameHeaders.map((header) => (
          <NameHeader key={header} type="caption">
            {header}
          </NameHeader>
        ))}
        {tableNumberHeaders.map((header) => (
          <NumberHeader key={header} type="caption">
            {header}
          </NumberHeader>
        ))}
      </Row>
    </ContainerHeader>
  );
};
