import { combineReducers } from 'redux';
import { departmentRolesReducer, DepartmentRolessReducerType } from './departmentRolesReducer';
import { employmentTypesReducer, EmploymentTypesReducerType } from './employmentTypesReducer';

export interface RootReducer {
  employmentTypes: EmploymentTypesReducerType;
  departmentRoles: DepartmentRolessReducerType;
}

const reducers = combineReducers({
  employmentTypes: employmentTypesReducer,
  departmentRoles: departmentRolesReducer,
});

export default reducers;
