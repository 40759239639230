import styled from 'styled-components';
import Text from 'components/Text';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';

export const YourHolidaysRow = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const YourHolidaysDot = styled.div<{ color: string; withBorder?: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${({ color, withBorder }) => (withBorder ? colors.surface.primary : color)};
  border: 1px solid ${({ theme, color, withBorder }) => (withBorder ? theme.text.highlight : color)};
  border-radius: 50%;
  margin: 4px 0.5rem 0 0;
`;

export const YourHolidaysLabelWrapper = styled.div`
  display: flex;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const WrapHolidayStatus = styled(Text.Paragraphs)`
  padding: 0.5rem 1rem;
  background-color: ${colors.light.tertiary};
  border-radius: ${borderRadius.xs};
  color: ${({ theme }) => theme.text.highlight};
`;

export const DatesWrapper = styled.div`
  max-width: 60%;
  display: flex;
  flex-direction: column;
`;

export const DatesParagraph = styled(Text.Paragraphs)`
  color: ${({ theme }) => theme.text[600]};
`;

export const ContentWrapper = styled.div`
  min-width: 150px;
`;
