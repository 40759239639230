import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'components/Spinner';
import { ReportHolidayProps } from 'services/ReportsService';
import { EmployeeRowReport } from './EmployeeRowReport';
import { Header } from './Header';
import { Table } from './styled';

interface EmployeesListProps {
  isLoading: boolean;
  isAllChecked: boolean;
  reports: ReportHolidayProps[];
  handleNextPage: () => Promise<void>;
  toggleIsAllChecked: () => void;
  setSelectedIds: (id: number[]) => void;
  hasMorePages: boolean;
  selectedIds: number[];
}

export const EmployeesList = ({
  isLoading,
  isAllChecked,
  toggleIsAllChecked,
  setSelectedIds,
  reports,
  handleNextPage,
  hasMorePages,
  selectedIds,
}: EmployeesListProps) => {
  const [unCheckedIds, setUncheckedIds] = useState<number[]>([]);
  const newArray: number[] = [];

  useEffect(() => {
    if (reports.length === 0) {
      return toggleIsAllChecked();
    }
  }, [reports]);

  newArray.push(...selectedIds);
  newArray.push(...reports.map((employee) => employee.id));

  const handleCheckboxPress = useCallback(
    (id: number) => {
      if (!selectedIds.includes(id)) {
        const value = [...new Set([...selectedIds, id])];
        setSelectedIds(value);
        const unchecked = unCheckedIds.filter((row) => row !== id);
        setUncheckedIds(unchecked);
      }
      if (selectedIds.includes(id)) {
        const value = [...new Set(selectedIds.filter((row) => row !== id))];
        setSelectedIds(value);
        unCheckedIds.push(id);
      }
    },
    [selectedIds, setSelectedIds],
  );

  useEffect(() => {
    if (isAllChecked) {
      setSelectedIds(newArray.filter((el) => !unCheckedIds.includes(el)));
    }
  }, [reports]);

  if (isLoading) return <Spinner fullPage />;

  return (
    <Table>
      <Header isAllChecked={isAllChecked} toggleIsAllChecked={toggleIsAllChecked} />
      <InfiniteScroll
        dataLength={reports.length}
        next={handleNextPage}
        hasMore={hasMorePages}
        loader={<Spinner />}
        style={{ overflow: 'initial' }}>
        {reports.map((report) => (
          <EmployeeRowReport
            isSelected={selectedIds.includes(report.id)}
            onCheckboxPress={handleCheckboxPress}
            key={report.id}
            element={report}
          />
        ))}
      </InfiniteScroll>
    </Table>
  );
};
