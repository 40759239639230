import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import EmployeesService, { Employee } from '../services/EmployeesService';

interface UseSingleEmployeeInterface {
  employee: Employee | undefined;
  refetch: () => void;
  refetchWithYear: () => void;
}

export const useSingleEmployee = (id: string, year?: string): UseSingleEmployeeInterface => {
  const [employee, setEmployee] = useState<Employee>();

  const { data: singleEmployeeWithYear, refetch: refetchWithYear } = useQuery<Employee>(
    ['single-employee', id],
    () => EmployeesService.fetchEmployeeByIdOnCurrentYear(id, year ?? ''),
  );

  const { data: singleEmployee, refetch } = useQuery<Employee>(['single-employee', id], () =>
    EmployeesService.fetchEmployeeById(id),
  );

  useEffect(() => {
    if (!year) {
      return setEmployee(singleEmployee);
    }
    if (year) {
      return setEmployee(singleEmployeeWithYear);
    }
  }, [singleEmployee]);

  return { employee, refetch, refetchWithYear };
};
