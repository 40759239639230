import { FormEvent } from 'react';
import { Input } from 'components/Inputs';
import { EmployeeSearchProps } from '../../types';

export const EmployeeSearch = ({
  searchPhraseState,
  withoutBorder = false,
}: EmployeeSearchProps) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) =>
    searchPhraseState.setState(e.currentTarget.value || null);

  const handleClear = () => searchPhraseState.setState(null);

  return (
    <Input
      placeholder="Search for employee"
      value={searchPhraseState.state ?? ''}
      onChange={handleChange}
      crossMarkIconOnClick={handleClear}
      withoutBorder={withoutBorder}
      dataCY="calendar-employee-search"
    />
  );
};
