import styled from 'styled-components';
import { Layout } from 'components/Layout';

export const ProfileUserContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  align-items: flex-start;
`;

export const StyledLayout = styled(Layout)`
  max-width: 1440px;
  margin: 0 auto;
`;

export const Container = styled.div`
  margin: 2.5rem 5.125rem 0 5.125rem;
`;
