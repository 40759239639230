import { useCallback, useEffect, useState } from 'react';
import { Type, Type as TypeAlert, Variant } from 'components/AlertComponent/types';
import { SvgIconName } from 'components/Icons/types';
import { makeContext } from 'contexts/ContextCreator';
import { ApiError } from 'services/AuthService';

export interface ToastProps {
  variant: Variant;
  type: TypeAlert;
  label: string;
  iconInfo: SvgIconName;
  actionIcon?: SvgIconName;
}

type ToastContextType = {
  setToast: (data: ToastProps) => void;
  dataToast: ToastProps;
  setIsToastVisible: (isVisible: boolean) => void;
  isToastVisible: boolean;
  handleError: (message?: ApiError) => void;
};

export const [useToastContext, ToastProvider] = makeContext<ToastContextType>(() => {
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);
  const [dataToast, setDataToast] = useState<ToastProps>({
    variant: Variant.success,
    type: TypeAlert.outline,
    label: 'Success',
    iconInfo: 'CheckIcon',
    actionIcon: 'CrossMarkIcon',
  });

  useEffect(() => {
    const timerAlert = setTimeout(() => {
      setIsToastVisible(false);
    }, 4000);
    if (!isToastVisible) {
      clearTimeout(timerAlert);
    }
  }, [isToastVisible]);

  const setToast = useCallback((data: ToastProps) => {
    setDataToast(data);
    setIsToastVisible(true);
  }, []);

  const handleError = useCallback(
    (err?: ApiError) => {
      setToast({
        variant: Variant.fail,
        type: Type.outline,
        label: err?.data?.error || 'Something went wrong. Try again later',
        iconInfo: 'CrossMarkIcon',
        actionIcon: 'CrossMarkIcon',
      });
    },
    [setToast],
  );

  return { setToast, dataToast, isToastVisible, setIsToastVisible, handleError };
});
