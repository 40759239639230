import { HolidayType } from 'services/HolidaysService';
import { EmployeeDetails } from './components/EmployeeDetails';
import { RequestManagerModalProps } from './RequestManagerModal/types';
import { StyledCancelModal } from './styled';

interface CancelModalProps extends Omit<RequestManagerModalProps, 'label' | 'buttonRight'> {
  holidayType?: HolidayType;
  requestedDates: string;
  fullName: string;
  marginBottomEmpDetails: string;
  additionalComment: string | null;
}

export const CancelHolidayModal = ({
  disabledButton,
  leftButtonAction,
  closeIcon,
  holidayType,
  requestedDates,
  fullName,
  marginBottomEmpDetails,
  additionalComment,
  ...modalProps
}: CancelModalProps) => {
  return (
    <StyledCancelModal
      label="Cancel holiday request?"
      buttonLeft={{ label: 'Cancel this holiday', type: 'strongFail' }}
      buttonRight={{
        label: 'Keep this holiday',
        type: 'basic',
        onClick: closeIcon,
      }}
      leftButtonAction={leftButtonAction}
      closeIcon={closeIcon}
      dataCY="cancel-holiday-modal"
      {...modalProps}>
      <EmployeeDetails
        holidayTypeValue={holidayType?.value}
        requestedDates={requestedDates}
        fullName={fullName}
        marginBottomEmpDetails={marginBottomEmpDetails}
      />
    </StyledCancelModal>
  );
};
