import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { SvgIconComponent } from '../Icons';
import { Variant, Type } from './types';

const variantsColors = {
  main: colors.secondary,
  success: colors.success,
  fail: colors.fail,
  alert: colors.warning,
  info: colors.tertiary,
};

export const IconLeftStyled = styled(SvgIconComponent)`
  margin-right: 1.25rem;
  padding: 0.8rem;
  overflow: visible;
`;

export const ActionIconWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
`;

export const IconRightStyled = styled(SvgIconComponent)`
  cursor: pointer;
  color: ${({ theme }) => theme.toast.svg.right};
`;

export const TextLabelStyled = styled.p`
  ${typography.heading['5']};
  margin: 0;
  color: ${({ theme }) => theme.toast.text};
`;

export const TextSublabelStyled = styled.p`
  margin: 0;
  ${typography.heading['6']};
  font-weight: 400;
  color: ${({ theme }) => theme.toast.text};
`;

export const StyledAlertComponent = styled.div<{ variant: Variant; type: Type }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem;
  box-shadow: 0 0 1px ${({ theme }) => theme.toast.shadow.firstValue},
    0 1px 8px -1px ${({ theme }) => theme.toast.shadow.secondValue};
  border-radius: ${borderRadius.sm};
  color: ${colors.surface.primary};
  ${({ variant }) => css`
    ${IconLeftStyled} {
      background-color: ${variantsColors[variant]};
      border: solid 3px ${variantsColors[variant]};
    }
  `}
  ${({ variant }) => alertStyle.variants[variant]}
  ${({ type }) => alertStyle.types[type]}
  ${({ type, variant }) => getSpecificsStyles({ type, variant })}
`;

const getSpecificsStyles = ({
  type,
  variant,
}: {
  type: Type;
  variant: Variant;
}): FlattenSimpleInterpolation => {
  if (type === 'outline') {
    if (variant === 'main') {
      return css`
        ${IconLeftStyled} {
          border: solid 3px transparent;
        }
      `;
    }
  }

  if (type === 'solid') {
    if (variant === 'main') {
      return css`
        color: ${colors.tertiary};
      `;
    }

    if (variant === 'alert') {
      return css`
        ${IconLeftStyled} {
          color: ${colors.black};
        }

        ${TextSublabelStyled} {
          color: ${colors.black};
        } ;
      `;
    }
  }

  return css``;
};

const alertStyle = {
  variants: {
    main: css`
      color: ${colors.secondary};
      ${IconLeftStyled} {
        color: ${colors.secondary};
      }
    `,
    success: css`
      color: ${colors.success};
      ${IconLeftStyled} {
        color: ${colors.success};
      }
    `,
    fail: css`
      color: ${colors.fail};
      ${IconLeftStyled} {
        color: ${colors.fail};
      }
    `,
    alert: css`
      color: ${colors.primary};
      ${IconLeftStyled} {
        color: ${colors.primary};
      }

      ${IconRightStyled} {
        color: ${colors.black} !important;
      }

      ${TextLabelStyled} {
        color: ${colors.black} !important;
      }
    `,
    info: css`
      color: ${colors.tertiary};
      ${IconLeftStyled} {
        color: ${colors.tertiary};
      }
    `,
  },
  types: {
    outline: css`
      background-color: ${({ theme }) => theme.toast.types.outline.background};
      ${IconLeftStyled} {
        background-color: ${({ theme }) => theme.toast.types.outline.background};
        border-radius: 50%;
        border: solid 3px currentColor;
      }
    `,
    outlineAlt: css`
      background-color: ${({ theme }) => theme.toast.types.outlineAlt.background};
      ${IconLeftStyled} {
        border-radius: 50%;
        color: ${({ theme }) => theme.toast.types.outlineAlt.color};
      }
    `,
    solid: css`
      background-color: currentColor;
      ${IconLeftStyled} {
        background-color: currentColor;
        border-radius: 50%;
        border: solid 3px ${({ theme }) => theme.toast.border};
        background-color: rgba(0, 0, 0, 0.25);
        color: ${({ theme }) => theme.toast.types.solid.color};
      }

      ${IconRightStyled} {
        color: ${({ theme }) => theme.toast.types.solid.color};
      }

      ${TextLabelStyled} {
        color: ${({ theme }) => theme.toast.types.solid.color};
      }

      ${TextSublabelStyled} {
        color: ${({ theme }) => theme.toast.types.solid.color};
      } ;
    `,
  },
};

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
  flex: 1;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;
