import { colors } from 'styles/colors';
import Text from '../Text';
import { useThemeContext } from '../ThemeWrapper/ThemeWrapper';
import { Content, Root, StyledSvg } from './styled';

export interface DonutChartProps {
  requested: number;
  avaiable: number;
  total: number;
  className?: string;
}

export const DonutChart = ({ total, requested, avaiable, className }: DonutChartProps) => {
  const { getColorByTheme } = useThemeContext();
  const used = total - avaiable;

  const sortedData = [
    { value: requested, color: colors.chart.requested },
    { value: used, color: colors.chart.used },
  ].sort((a, b) => b.value - a.value);

  const circleInsideFill = getColorByTheme(colors.surface.primary, 'transparent');
  const circleOutsideStroke = getColorByTheme(colors.chart.available, colors.gray[800]);
  const chartCaption = getColorByTheme(colors.gray[600], colors.gray[500]);
  const chartHeader = getColorByTheme(colors.gray[800], colors.gray[300]);

  return (
    <Root {...{ className }}>
      <StyledSvg viewBox="0 0 42 42">
        <circle cx="21" cy="21" r="16" fill={circleInsideFill} />
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r="16"
          fill="transparent"
          stroke={circleOutsideStroke}
          strokeWidth="1.5"
        />

        {sortedData.map((data, i) => {
          const percent = (data.value / total) * 100;
          const dasharray = `${percent} ${100 - percent}`;
          return (
            <circle
              key={i}
              style={{ transition: 'stroke-dasharray 850ms ease-in-out', strokeLinecap: 'round' }}
              className="donut-segment"
              cx="21"
              cy="21"
              r="16"
              fill="transparent"
              stroke={data.color || 'black'}
              strokeWidth="1.2"
              strokeDasharray={dasharray}
              strokeDashoffset="25"
            />
          );
        })}
      </StyledSvg>
      <Content>
        <Text.Heading color={chartHeader} style={{ marginTop: 0 }} type="3">
          {avaiable}/{total}
        </Text.Heading>
        <Text.Heading color={chartCaption} style={{ marginBottom: 0 }} type="6">
          Days available
        </Text.Heading>
      </Content>
    </Root>
  );
};
