import styled from 'styled-components';
import { Button } from 'components/Button/ButtonBase';
import { borderRadius } from 'styles/variables';
import Text from '../Text';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
`;

export const ModalContainer = styled.div`
  z-index: 100;
  width: 23rem;
  padding: 1.75rem;
  background: ${({ theme }) => theme.modal.background};
  border: 1px solid ${({ theme }) => theme.modal.border};
  box-shadow: rgb(12 26 75 / 8%) 0px 8px 8px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: ${borderRadius.sm};

  @supports (-moz-appearance: none) {
    background: ${({ theme }) => theme.modal.background};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text[900]};
`;

export const ModalIconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.modal.iconClose};
`;

export const ButtonRequest = styled(Button)`
  width: inherit;
  margin: 0.5rem 0;
`;

export const WrapLabel = styled(Text.Heading)`
  width: 300px;
`;
