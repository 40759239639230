import { useState } from 'react';
import { makeContext } from 'contexts/ContextCreator';
import { useEmployeeContext } from 'contexts/EmployeesContext';
import { Employee } from 'services/EmployeesService';

type FilteredEmployeesContextType = {
  filteredEmployees: Employee[];
  setFilterEmployees: ({
    firstNameLastName,
    departmentIdToSearch,
  }: {
    firstNameLastName?: string;
    departmentIdToSearch?: number;
  }) => void;
};

export const [useFilteredEmployeesContext, FilteredEmployeesContextProvider] =
  makeContext<FilteredEmployeesContextType>(() => {
    const { employees } = useEmployeeContext();
    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);

    const setFilterEmployees = ({
      firstNameLastName,
      departmentIdToSearch,
    }: {
      firstNameLastName?: string;
      departmentIdToSearch?: number;
    }) => {
      const newFilterEmployees = (employees || []).filter(
        ({ firstName, lastName, departmentRole }) => {
          if (firstNameLastName && departmentIdToSearch) {
            return (
              `${lastName} ${firstName}`.toLowerCase().includes(firstNameLastName) &&
              (departmentIdToSearch === null ||
                departmentRole?.departmentId === departmentIdToSearch)
            );
          }
          if (firstNameLastName) {
            return `${lastName} ${firstName}`.toLowerCase().includes(firstNameLastName);
          }
          // eslint-disable-next-line eqeqeq
          if (departmentIdToSearch != undefined) {
            return departmentRole?.departmentId === departmentIdToSearch;
          }

          return true;
        },
      );

      setFilteredEmployees(newFilterEmployees);
    };

    return { filteredEmployees, setFilterEmployees };
  });
