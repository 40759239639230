import styled, { css } from 'styled-components';
import { typography } from 'styles/typography';
import { ButtonNavbarStyleProps } from './types';

export const StyledButton = styled.button<ButtonNavbarStyleProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 0;
  color: ${({ theme }) => theme.text[700]};
  background-color: transparent;
  padding: 0.6rem 1em;

  ${typography.buttonFontSize.regular}

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.text.highlight};
      border-radius: 0;
      color: ${({ theme }) => theme.text.highlight};
    `};
`;

export const Wrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FixPadding = styled.div`
  padding: 0.75rem 0.2rem;
`;
