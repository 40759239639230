import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Type, Variant } from 'components/AlertComponent/types';
import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import Modal from 'components/Modal/Modal';
import { BalanceComponent } from 'components/Modals/BalanceComponent';
import { Select } from 'components/Select';
import { EmployeeDropdownSelect } from 'components/Select/EmployeeSelect/EmployeeDropdownSelect';
import { WrapperForSelectComponents, WrongDates } from 'components/Select/styled';
import { useToastContext } from 'contexts/ToastContext';
import { useUserContext } from 'contexts/UserContext';
import { countWorkingDays } from 'helpers/CountWorkoutDays';
import AccountService from 'services/AccountService';
import { ApiError } from 'services/AuthService';
import EmployeesService, { Employee } from 'services/EmployeesService';
import HolidayService from 'services/HolidayService';
import { EnumEmploymentType, HolidayType } from 'services/HolidaysService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { HolidayTypesEnums } from 'types';
import Text from '../Text/index';
import { onDemandIsPossibility } from './helpers';
import { NewRequestModalProps } from './types';

const AdditionalInfoOnHolidayRequest = styled.textarea`
  width: 21.375rem;
  height: 3rem;
  padding: 1em 1em;
  resize: none;
  outline: none;
  border-radius: ${borderRadius.xs};
  ${typography.paragraphs.xSmall};
  color: ${({ theme }) => theme.holidayForm.messegaOnRejectModal};
  border: 1px solid ${({ theme }) => theme.input.border};
  background-color: ${({ theme }) => theme.input.background};
  opacity: 0.82;

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }
`;

const AdditionalInfoTitle = styled.div`
  color: ${({ theme }) => theme.input.label};
  margin-left: 0;
  margin-bottom: 0.5rem;
`;

export const NewRequestHolidayModal = ({
  toggle,
  isVisible,
  withEmployeeDropdown,
  startDate: defaultStartDate,
  buttonProps,
  ...rest
}: NewRequestModalProps) => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const { handleError, setToast } = useToastContext();
  const { fetchAuthenticatedUser, getUser } = useUserContext();
  const [startDate, setStartDateSelect] = useState<Date | undefined>(defaultStartDate);
  const todayDate = new Date().setHours(0, 0, 0, 0);
  const [rightDemandDate, setRightDemandDate] = useState(true);
  const [endDate, setEndDateSelect] = useState<Date | undefined>(defaultStartDate);
  const [selectedHolidayTypes, setSelectedHolidayTypes] = useState<HolidayType>();
  const [{ holiday, employmentType, employeeId, employeeHasKid }, setEmployeeData] = useState({
    ...rest,
    employeeId: getUser().id.toString(),
    employeeHasKid: '',
  });

  const [additionalMessage, setAdditionalMessage] = useState<string>('');

  const currentYear = useMemo(() => dayjs(startDate).year().toString(), [startDate]);

  const { data: employeeCurrent, refetch } = useQuery<Employee>('single_employee', () => {
    return EmployeesService.fetchEmployeeByIdOnCurrentYear(employeeId, currentYear);
  });

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    setEmployeeData((currentData) => ({ ...currentData, holiday: employeeCurrent?.holiday }));
  }, [employeeCurrent]);

  useEffect(() => {
    refetch();
  }, [selectedHolidayTypes, startDate, endDate, employeeId]);

  useEffect(() => {
    if (startDate) {
      const dateCompare = startDate.setHours(0, 0, 0, 0) - todayDate;
      setRightDemandDate(dateCompare >= 172800000 || dateCompare < 0 ? true : false);
    }
  }, [startDate]);

  const handleSetStartDate = (date: Date) => {
    setStartDateSelect(date);
    setEndDateSelect(date);
  };

  const holidayTypes = useMemo(
    () =>
      employmentTypesStore.listData?.find((empType) => empType.value === employmentType?.value)
        ?.holiday_types || [],
    [employmentTypesStore.listData, employmentType],
  );

  const individualHolidayTypes = () => {
    if (
      (withEmployeeDropdown && employeeHasKid === null) ||
      (!withEmployeeDropdown && employeeCurrent?.kidDateOfBirth === null)
    ) {
      return holidayTypes.filter((holType) => holType.key !== HolidayTypesEnums.Kid);
    } else {
      return holidayTypes;
    }
  };

  const onSubmit = async () => {
    try {
      if (!selectedHolidayTypes) {
        return;
      }

      if (withEmployeeDropdown) {
        await HolidayService.createHolidayRequest({
          userId: Number(employeeId),
          dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
          dateTo: dayjs(endDate).format('YYYY-MM-DD'),
          holidayType: selectedHolidayTypes.key!,
          additionalComment: additionalMessage.trim(),
        });
      } else {
        await AccountService.createCurrentUserHolidayRequest({
          dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
          dateTo: dayjs(endDate).format('YYYY-MM-DD'),
          holidayType: selectedHolidayTypes.key!,
          additionalComment: additionalMessage.trim(),
        });
      }
      await fetchAuthenticatedUser();
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been added.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      toggle();
    } catch (e) {
      handleError(e as ApiError);
    }
  };

  const onDemandValue =
    selectedHolidayTypes?.key === HolidayTypesEnums.OnDemand ? selectedHolidayTypes.value : '';

  // Your Balance
  const requestingHoliday = countWorkingDays(startDate, endDate) || 0;

  const forUseOnDemand = holiday?.onDemand || 0;
  const forUseOnDemandDynamic =
    holiday?.onDemand === holiday?.onDemandTotal
      ? forUseOnDemand
      : forUseOnDemand + requestingHoliday;
  const correctDates = startDate && endDate && startDate > endDate;

  const tooManyOnDemand =
    !!selectedHolidayTypes &&
    selectedHolidayTypes.key === EnumEmploymentType.On_demand &&
    (requestingHoliday > 1 || rightDemandDate)
      ? requestingHoliday
      : null;

  const forUseLeftDynamic =
    (holiday?.total || 0) - (holiday?.requested || 0) - (holiday?.used || 0) - requestingHoliday;

  const idOnDemand =
    selectedHolidayTypes && selectedHolidayTypes?.key === EnumEmploymentType.On_demand;

  const label = selectedHolidayTypes?.key === HolidayTypesEnums.Sick ? 'Add Sick' : 'Add';

  const disabledButton =
    !selectedHolidayTypes ||
    !startDate ||
    !endDate ||
    correctDates ||
    forUseLeftDynamic < 0 ||
    (!!tooManyOnDemand && tooManyOnDemand > 1) ||
    onDemandIsPossibility({
      statusIsOnDemand: !!idOnDemand,
      startDate: startDate,
    }) ||
    (idOnDemand && holiday?.onDemand === holiday?.onDemandTotal);

  return (
    <Modal
      label={label}
      visible={isVisible}
      onClose={toggle}
      onClickButton={onSubmit}
      crossMarkIcon
      buttonProps={buttonProps}
      disabled={disabledButton}
      startDate={startDate}
      endDate={endDate}
      holidayType={selectedHolidayTypes}
      dataCY="new-request-modal">
      <WrapperForSelectComponents gridRowGap={'1.5rem'} margin={'1rem 0'}>
        {withEmployeeDropdown && (
          <EmployeeDropdownSelect
            onEmployeeSelected={(selectedEmployee) =>
              setEmployeeData({
                holiday: selectedEmployee.holiday,
                employmentType: selectedEmployee.employmentType,
                employeeId: selectedEmployee.id.toString(),
                employeeHasKid: selectedEmployee.kidDateOfBirth,
              })
            }
          />
        )}
        {!!employmentType && (
          <Select
            label="Holiday type"
            placeholder="choose"
            options={individualHolidayTypes()}
            selectedOption={selectedHolidayTypes}
            onOptionClicked={setSelectedHolidayTypes}
            labelSelector={(holidayType) => holidayType.value}
            dataCY="new-request-holiday-type-select"
          />
        )}
        <RangeDatePicker
          label="Holiday Time"
          startDate={startDate}
          endDate={endDate}
          setStartDateSelect={handleSetStartDate}
          setEndDateSelect={setEndDateSelect}
          dataCY="new-request-holiday-time-date-picker"
        />

        {correctDates && <WrongDates>The selected dates are incorrect</WrongDates>}

        <div style={{ marginBottom: 8 }}>
          <AdditionalInfoTitle>
            <Text.Paragraphs type="caption">Additional comment</Text.Paragraphs>
          </AdditionalInfoTitle>

          <AdditionalInfoOnHolidayRequest
            placeholder={'More information about holiday request...'}
            onChange={(e) => setAdditionalMessage(e.currentTarget.value)}
            data-cy="additional-info-on-holiday-request"
          />
        </div>

        {holiday &&
          (selectedHolidayTypes?.key === HolidayTypesEnums.Sick ||
            selectedHolidayTypes?.key === HolidayTypesEnums.Holiday ||
            selectedHolidayTypes?.key === HolidayTypesEnums.Occasional) && (
            <HolidaySummaryRow
              left="Requesting"
              right={countWorkingDays(startDate, endDate) || 0}
              color={colors.dark.tertiary}
              weight={600}
            />
          )}
      </WrapperForSelectComponents>

      {holiday && selectedHolidayTypes?.key === HolidayTypesEnums.OnDemand && (
        <BalanceComponent
          title={'Balance'}
          requestingHoliday={requestingHoliday}
          onDemand={forUseOnDemandDynamic}
          onDemandTotal={holiday.onDemandTotal || 0}
          useLeft={forUseLeftDynamic}
          total={holiday.total || 0}
          tooManyOnDemand={!!tooManyOnDemand}
          onDemandValue={onDemandValue}
        />
      )}

      {holiday &&
        (selectedHolidayTypes?.key === HolidayTypesEnums.Vacation ||
          selectedHolidayTypes?.key === HolidayTypesEnums.Kid) && (
          <BalanceComponent
            title={'Balance'}
            requestingHoliday={requestingHoliday}
            onDemand={holiday.onDemand}
            onDemandTotal={holiday.onDemandTotal || 0}
            useLeft={forUseLeftDynamic}
            total={holiday.total || 0}
          />
        )}
    </Modal>
  );
};
