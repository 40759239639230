import { DropdownContainer, ListElementLi, ListElementUl } from 'components/DropdownMenu/styled';

interface DropdownListProps {
  listObject: {
    name: string;
    action: VoidFunction;
    dataCy?: string;
  }[];
}

export const DropdownList = ({ listObject }: DropdownListProps) => {
  return (
    <DropdownContainer data-cy="select-dropdown">
      <ListElementUl>
        {listObject.map((item) => (
          <ListElementLi
            key={item.name}
            onClick={(e) => {
              e.stopPropagation();
              item?.action?.();
            }}
            data-cy={`select-dropdown-option-${item.dataCy}`}>
            {item.name}
          </ListElementLi>
        ))}
      </ListElementUl>
    </DropdownContainer>
  );
};
