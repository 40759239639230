import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NotFoundCircleLightSvg from 'assets/images/NotFoundCircleLight.svg';
import { Button } from 'components/Button/ButtonBase';
import { colors } from 'styles/colors';

export const NotFoundWrapper = styled.div`
  min-height: 100%;
  width: 100vw;
  margin-left: calc((100vw - 100%) * (-0.5));
  background-color: transparent;
  background-image: url(${NotFoundCircleLightSvg});
  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotFoundContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledButton = styled(Button)`
  background-color: ${colors.white};
  color: ${colors.gray[800]};
  margin-top: 1.5rem;
`;

export const NotFoundImage = styled.img`
  width: 80%;
  max-width: 65.5rem;
`;
