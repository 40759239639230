import MouseTooltip from 'react-sticky-mouse-tooltip';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const SmallCalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.text[900]};
`;

export const SmallCalendarMonthWrapper = styled.div<{ weeksCount: number }>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(${({ weeksCount }) => weeksCount + 1}, 1fr);
  margin: 1rem 0;
`;

export const SmallCalendarDayWrapper = styled.div`
  height: 100%;
  margin: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.smallCalendar.day};
`;

export const MouseTooltipWrapper = styled(MouseTooltip)`
  position: relative;
  z-index: 1000;
`;

export const CapacitiesParticularDay = styled.div`
  width: 22rem;
`;

export const Dot = styled.div<{
  isToday?: boolean;
  holidayColor?: string;
  isEmployeeCalendarYear?: boolean;
}>`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme, isToday }) => (isToday ? theme.text.highlight : 'unset')};
  transition: 0.25s;
  cursor: ${({ isEmployeeCalendarYear }) => (isEmployeeCalendarYear ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ theme }) => theme.text.highlight};

    > div {
      color: ${colors.white};
    }
  }

  ${({ isEmployeeCalendarYear }) =>
    isEmployeeCalendarYear &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}

  ${({ holidayColor }) =>
    holidayColor &&
    css`
      div {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: ${holidayColor};
        }
      }
    `}
`;
