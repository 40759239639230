import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StyledSwitch = styled.div`
  .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .label-switch {
    display: flex;
    align-items: center;

    .switch {
      width: 3rem;
      height: 1.5rem;
      border-radius: 6rem;
      background-color: ${({ theme }) => theme.backgroundElement};
      border: 1px solid ${({ theme }) => theme.text.highlight};
      position: relative;
      transition: all 0.5s;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0.2rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.text.highlight};
        transition: all 0.5s;
      }
    }
  }
  input {
    &[type='checkbox'] {
      display: none;

      &:checked {
        + label {
          &.label-switch {
            .switch {
              background-color: ${({ theme }) => theme.text.highlight};
              border: 1px solid ${({ theme }) => theme.text.highlight};

              &:after {
                left: calc(100% - 40%);
                background-color: ${colors.white};
              }
            }
          }

          &.label-check {
            .circle {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;
