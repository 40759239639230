import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isHoliday } from 'poland-public-holidays';
import { TeamHolidayWithoutUser } from 'contexts/TeamHolidaysContext';
import { Holiday, HolidayStatuses, HolidayType, TeamHoliday } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { EmploymentTypeInterfaceTypes } from 'types';

const getWeekOfMonth = (date: Date): number => {
  const firstDay = new Date(date.setDate(1)).getDay();
  const fiveWeekRowsForCalendar = 5;
  const sixWeekRowsForCalendar = 6;

  return firstDay >= 1 && firstDay < 6 ? fiveWeekRowsForCalendar : sixWeekRowsForCalendar;
};

export type MonthType = Dayjs[][];

export const getMonthMatrix = (
  month: number = Math.floor(dayjs().month()),
  year: number = Math.floor(dayjs().year()),
): MonthType => {
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  const weeksCount = getWeekOfMonth(new Date(year, month, 1));
  let currentMonthCount = firstDayOfTheMonth === 0 ? -6 : 1 - firstDayOfTheMonth;
  return new Array(weeksCount).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount = currentMonthCount + 1;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
};

export const getDateByMonth = (month: number, year?: number): `${number}-${number}-${number}` => {
  if (year) {
    return dayjs(new Date(year, month, 1)).format('YYYY-MM-DD') as `${number}-${number}-${number}`;
  }

  return dayjs(new Date(dayjs().year(), month, 1)).format(
    'YYYY-MM-DD',
  ) as `${number}-${number}-${number}`;
};

dayjs.extend(isBetween);

export const foundHolidayContainingDay = (day: Dayjs, holidays: Holiday[]): Holiday | undefined =>
  holidays.find((holiday) => dayjs(day).isBetween(holiday.dateFrom, holiday.dateTo, null, '[]'));

export const foundHolidayListContainingDay = (day: Dayjs, holidays: Holiday[]): Holiday[] =>
  holidays.filter((item) => dayjs(day).isBetween(item.dateFrom, item.dateTo, null, '[]'));

export const foundHolidaysContainingDay = (day: Dayjs, holidays: Holiday[]): Holiday[] =>
  holidays
    .filter((holiday) => dayjs(day).isBetween(holiday.dateFrom, holiday.dateTo, null, '[]'))
    .sort((a, b) => b.days - a.days);

export const foundTeamHolidaysContainingDay = (
  day: Dayjs,
  teamHolidays: TeamHoliday[],
  isManager?: boolean,
): TeamHoliday[] => {
  return isManager
    ? teamHolidays.filter((holiday) =>
        dayjs(day).isBetween(holiday.dateFrom, holiday.dateTo, null, '[]'),
      )
    : teamHolidays
        .filter(
          (holiday) =>
            holiday.status === HolidayStatuses.Accepted &&
            dayjs(day).isBetween(holiday.dateFrom, holiday.dateTo, null, '[]'),
        )
        .sort((a, b) => b.days - a.days);
};

export const getHolidayColorByType = (holidayType: string | undefined): string => {
  switch (holidayType) {
    case 'vacation':
    case 'holiday':
    case 'kid':
    case 'occasional':
    case 'overdue':
    case 'increase':
      return colors.dark.tertiary;
    case 'sick':
      return colors.warning;
    case 'on_demand':
      return colors.primary;
    case 'maternity':
    case 'faternity':
    case 'paternity':
    case 'parental':
      return colors.light.fail;
    default:
      return 'transparent';
  }
};

export const getHolidayBorderByType = (holidayType: string | undefined): string => {
  switch (holidayType) {
    case 'vacation':
    case 'holiday':
    case 'kid':
    case 'occasional':
    case 'overdue':
    case 'increase':
      return colors.dark.tertiary;
    case 'on_demand':
      return colors.primary;
    case 'sick':
      return colors.warning;
    case 'maternity':
    case 'faternity':
    case 'paternity':
    case 'parental':
      return colors.dark.fail;
    default:
      return 'transparent';
  }
};

export const getHolidayColorByTypeOnManagerView = (holidayType: string | undefined): string => {
  switch (holidayType) {
    case 'vacation':
    case 'holiday':
    case 'occasional':
    case 'overdue':
    case 'increase':
      return colors.dark.tertiary;
    case 'on_demand':
      return colors.primary;
    case 'sick':
      return colors.warning;
    case 'kid':
    case 'maternity':
    case 'faternity':
    case 'paternity':
    case 'parental':
      return colors.light.fail;
    default:
      return 'transparent';
  }
};

export const getHolidayColorByStatus = (holidayStatus: HolidayStatuses): string => {
  switch (holidayStatus) {
    case HolidayStatuses.Pending:
      return colors.light.tertiary;
    case HolidayStatuses.Declined:
      return colors.dark.fail;
    default:
      return 'transparent';
  }
};

export const getHolidayBorderByStatus = (holidayStatus: HolidayStatuses): string => {
  switch (holidayStatus) {
    case HolidayStatuses.Pending:
      return colors.dark.tertiary;
    case HolidayStatuses.Declined:
      return colors.dark.fail;
    default:
      return 'transparent';
  }
};

export const getHolidayEmojiIcon = (holidayType: string | undefined) => {
  switch (holidayType) {
    case 'vacation':
    case 'holiday':
    case 'occasional':
    case 'overdue':
    case 'increase':
    case 'on_demand':
      return '🌴';
    case 'sick':
      return '🤒';
    case 'kid':
    case 'maternity':
    case 'faternity':
    case 'paternity':
    case 'parental':
      return '👶';
    default:
      return 'transparent';
  }
};

export const createAllHolidayTypesArray = (
  employmentTypes: EmploymentTypeInterfaceTypes[] | undefined,
): HolidayType[] => {
  if (!employmentTypes) return [];
  return employmentTypes
    .flatMap((employmentType) => [
      ...employmentType.holiday_types,
      ...employmentType.additional_holiday_types,
    ])
    .reduce((uniqueTypesArray: HolidayType[], holidayType) => {
      if (!uniqueTypesArray.find((type) => type.key === holidayType.key))
        uniqueTypesArray.push(holidayType);
      return uniqueTypesArray;
    }, []);
};

export const additionalHolidaysB2BTypesArray = (
  employmentTypes: EmploymentTypeInterfaceTypes[] | undefined,
): EmploymentTypeInterfaceTypes | undefined => {
  if (!employmentTypes) return undefined;
  return employmentTypes.find(
    (employmentType: EmploymentTypeInterfaceTypes) => employmentType.key === 'b2b',
  );
};

export const additionalHolidaysContractTypesArray = (
  employmentTypes: EmploymentTypeInterfaceTypes[] | undefined,
): EmploymentTypeInterfaceTypes | undefined => {
  if (!employmentTypes) return undefined;
  return employmentTypes.find(
    (employmentType: EmploymentTypeInterfaceTypes) => employmentType.key === 'contract',
  );
};

export const filterHolidaysByType = (
  holidays: Holiday[] | TeamHoliday[],
  type: HolidayType['key'],
): Holiday[] | TeamHoliday[] => {
  return holidays.filter(({ holidayType }) => type === holidayType);
};

export const filterHolidaysByStatus = (
  holidays: Holiday[] | TeamHoliday[],
  holidayStatus: HolidayStatuses,
): Holiday[] | TeamHoliday[] => {
  return holidays.filter(({ status }) => status === holidayStatus);
};

export const getHolidayDaysByMonth = (
  holidays: Holiday[] | TeamHoliday[],
  currentMonth: number,
): number => {
  return holidays
    .flatMap((holiday) => {
      const year = dayjs().year();
      const dateFrom = dayjs(holiday.dateFrom);
      const dateTo = dayjs(holiday.dateTo);
      const dateFromMonth = dateFrom.month();
      const dateToMonth = dateTo.month();
      const startOfCurrentMonth = dayjs(new Date(year, currentMonth, 1));
      const startOfNextMonth = dayjs(new Date(year, currentMonth + 1, 1));

      let daysInHoliday: number;
      let startDate: Dayjs;

      if (
        (dateFromMonth < currentMonth && dateToMonth < currentMonth) ||
        (dateFromMonth > currentMonth && dateToMonth > currentMonth)
      ) {
        // the holiday begins and ends previous or next month
        daysInHoliday = 0;
        startDate = dateFrom;
      } else if (dateFromMonth === currentMonth && dateToMonth === currentMonth) {
        // the holiday begins and ends current month
        daysInHoliday = Math.abs(dateFrom.diff(dateTo, 'day')) + 1;
        startDate = dateFrom;
      } else if (dateFromMonth !== currentMonth && dateToMonth === currentMonth) {
        // the holiday begins previous month and ends current month
        daysInHoliday = Math.abs(startOfCurrentMonth.diff(dateTo, 'day')) + 1;
        startDate = startOfCurrentMonth;
      } else if (dateFromMonth === currentMonth && dateToMonth !== currentMonth) {
        // the holiday begins this month and ends next month
        daysInHoliday = Math.abs(dateFrom.diff(startOfNextMonth, 'day'));
        startDate = dateFrom;
      } else {
        // the holiday begins previous month and ends next month
        daysInHoliday = startOfCurrentMonth.daysInMonth();
        startDate = startOfCurrentMonth;
      }

      // array, which contains all current month holiday days
      return new Array(daysInHoliday).fill(null).map((__, i) => {
        return startDate.add(i, 'day');
      });
    })
    .filter((day) => !isHoliday(new Date(day.toString()))).length;
};

export const getDayIndex = (day: Dayjs) => {
  let dayIndex = dayjs(day).day();
  // dayjs assumes sunday is day 0
  if (dayIndex === 0) {
    dayIndex = 6;
  } else {
    dayIndex = dayIndex - 1;
  }
  return dayIndex;
};

export const dateOverlapsHoliday = (date: Dayjs, holiday: TeamHolidayWithoutUser | TeamHoliday) => {
  const from = dayjs(holiday.dateFrom);
  const to = dayjs(holiday.dateTo);

  return date.diff(from, 'days') >= 0 && date.diff(to, 'days') <= 0;
};
