import { useState } from 'react';

type StorageKey = 'calendar_period';

export const usePersistedString = <T extends string>(
  key: StorageKey,
  defaultValue: T,
): [val: T, setState: (val: T) => void] => {
  const [state, setState] = useState(() => localStorage.getItem(key) ?? defaultValue);

  const setValue = (val: T) => {
    setState(val);
    localStorage.setItem(key, val);
  };

  return [state as T, setValue];
};
