import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ThemeWrapper } from 'components/ThemeWrapper/ThemeWrapper';
import { CalendarProvider } from 'contexts/CalendarContext';
import { DepartmentProvider } from 'contexts/DepartmentsContext';
import { EmployeeProvider } from 'contexts/EmployeesContext';
import { HolidaysProvider } from 'contexts/HolidaysContext';
import { TeamHolidaysProvider } from 'contexts/TeamHolidaysContext';
import { ToastProvider } from 'contexts/ToastContext';
import { UserProvider } from 'contexts/UserContext';
import rootStore from 'store/rootStore';
import { App } from 'views/App';
import { CurrentYearProvider } from './contexts/CurrentYearContext';

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <Provider store={rootStore}>
      <QueryClientProvider client={queryClient}>
        <ThemeWrapper>
          <UserProvider>
            <EmployeeProvider>
              <DepartmentProvider>
                <ToastProvider>
                  <CalendarProvider>
                    <CurrentYearProvider>
                      <HolidaysProvider>
                        <TeamHolidaysProvider>
                          <App />
                        </TeamHolidaysProvider>
                      </HolidaysProvider>
                    </CurrentYearProvider>
                  </CalendarProvider>
                </ToastProvider>
              </DepartmentProvider>
            </EmployeeProvider>
          </UserProvider>
        </ThemeWrapper>
      </QueryClientProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
