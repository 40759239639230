import styled, { keyframes } from 'styled-components';
import { colors } from 'styles/colors';
import { SpinnerProps } from './types';

const spinnerAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const SpinnerWrapper = styled.div<SpinnerProps>`
  height: ${({ fullPage }) => (fullPage ? '100vh' : '100%')};
  padding: ${({ fullPage }) => (fullPage ? '0' : '2rem')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSpinner = styled.span`
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  align-self: center;

  &:after,
  &:before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${colors.dark.tertiary};
    position: absolute;
    left: 0;
    top: 0;
    animation: ${spinnerAnimation} 2s linear infinite;
  }
  &:after {
    animation-delay: 1s;
  }
`;
