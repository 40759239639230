import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import { AvatarWrapper } from 'components/EmployeeTile/styled';
import { Checkbox } from 'components/Switches/Checkbox';
import Text from 'components/Text';
import { routes } from 'routes';
import { ReportHolidayProps } from 'services/ReportsService';
import { EmploymentType } from 'views/Dashboard/components/types';
import { Main, NumberMain, Row, User, RowTextWrapper } from './styled';

interface EmployeeRowReportProps {
  element: ReportHolidayProps;
  onCheckboxPress: (id: number) => void;
  isSelected: boolean;
}

export const EmployeeRowReport = ({
  element,
  onCheckboxPress,
  isSelected,
}: EmployeeRowReportProps) => {
  const {
    firstName,
    lastName,
    avatar,
    employmentType,
    department,
    total,
    onDemand,
    used,
    requested,
    available,
    sickLeave,
    id,
  } = element;

  const history = useHistory();
  const fullName = `${lastName} ${firstName}`;
  const correctOnDemand = employmentType.key === EmploymentType.B2B ? '-' : onDemand;

  return (
    <Main>
      <Checkbox
        isChecked={isSelected}
        onClick={() => onCheckboxPress(id)}
        dataCY="reports-checkbox"
      />
      <Row onClick={() => history.push(`${routes.reports}/${element.id}`)}>
        <User>
          <AvatarWrapper>
            <Avatar style={{ marginRight: '1rem' }} name={fullName} size="24" round src={avatar} />
          </AvatarWrapper>
          <Text.Paragraphs
            type="xSmall"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '10rem',
            }}>
            {fullName}
          </Text.Paragraphs>
        </User>

        <RowTextWrapper>
          <Text.Paragraphs type="xSmall">{employmentType.value}</Text.Paragraphs>
        </RowTextWrapper>
        <RowTextWrapper>
          <Text.Paragraphs type="xSmall">{department}</Text.Paragraphs>
        </RowTextWrapper>
        <NumberMain type="xSmall">{total}</NumberMain>
        <NumberMain type="xSmall">{correctOnDemand}</NumberMain>
        <NumberMain type="xSmall">{used}</NumberMain>
        <NumberMain type="xSmall">{requested}</NumberMain>
        <NumberMain type="xSmall">{available}</NumberMain>
        <NumberMain type="xSmall">{sickLeave}</NumberMain>
      </Row>
    </Main>
  );
};
