import styled from 'styled-components';
import Text from 'components/Text';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  padding: 1.5rem;
  box-sizing: border-box;
`;

export const FullReportHolidays = styled(Container)`
  grid-area: FullReport;
  padding: 1.5rem 0;
`;

export const ContainerParameters = styled(Container)`
  grid-area: Parameters;
  display: grid;
  grid-row-gap: 1.5rem;
`;

export const HeadingFullReport = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 1.5rem;
  color: ${({ theme }) => theme.text[900]};
`;

export const ContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.table.hover};
  gap: 1rem;
`;

export const Table = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const NameHeader = styled(Text.Paragraphs)`
  align-self: center;
  color: ${({ theme }) => theme.table.header};
`;

export const User = styled.div`
  display: flex;
  padding-right: 12px;
  color: ${({ theme }) => theme.table.text};
`;

export const RowTextWrapper = styled.div`
  color: ${({ theme }) => theme.table.text};
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.table.hover};
  gap: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.table.hover};
  }
`;

export const Row = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 180px 140px repeat(7, minmax(3rem, 14.28%));

  @media (min-width: 1550px) {
    grid-template-columns: 200px 140px 100px 60px repeat(5, minmax(3rem, 20%));
  }

  @media (min-width: 2000px) {
    grid-template-columns: 200px 180px 140px 80px repeat(5, minmax(3rem, 20%));
  }

  @media (min-width: 2400px) {
    grid-template-columns: 200px 180px 180px 100px repeat(5, minmax(3rem, 20%));
  }
`;

export const NumberMain = styled(Text.Paragraphs)`
  text-align: right;
  color: ${({ theme }) => theme.table.text};
`;

export const NumberHeader = styled(Text.Paragraphs)`
  align-self: center;
  text-align: right;
  color: ${({ theme }) => theme.table.header};
`;

export const HeadingParameters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.25rem;
  color: ${({ theme }) => theme.text[900]};
`;

export const NoEmployeesFound = styled.div`
  height: 6.25rem;
  padding-top: 2rem;
  width: 100%;

  display: grid;
  justify-content: center;
  align-content: center;

  border-top: 1px solid ${({ theme }) => theme.button.basic.borderColor};
  background-color: ${({ theme }) => theme.backgroundElement};
  border-bottom-left-radius: ${borderRadius.sm};
  border-bottom-right-radius: ${borderRadius.sm};

  ${typography.heading[5]};
  color: ${({ theme }) => theme.text[500]};
`;
