import React from 'react';
import { SearchTile, StyledInput, Container } from './styled';
import { SearchBarProps } from './types';

export const SearchBar = ({ onSearchPress, value, onChangeText, className }: SearchBarProps) => {
  const clearInput = () => onChangeText('');

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchPress();
    }
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeText(e.currentTarget.value);
  };

  return (
    <Container className={className}>
      <SearchTile>
        <StyledInput
          placeholder={'Search for employee'}
          value={value || ''}
          onChange={onChange}
          crossMarkIconOnClick={clearInput}
          onKeyDown={handleKeyPress}
          maxLength={50}
          dataCY="team-search-bar"
        />
      </SearchTile>
    </Container>
  );
};
