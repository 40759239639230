import dayjs, { Dayjs } from 'dayjs';
import { Holiday, HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';

export const getLabelColor = (hol: Holiday, day: Dayjs) => {
  if (!dayjs(day).isAfter(hol.dateFrom) || day.day() === 1) {
    return hol.status === HolidayStatuses.Pending ? colors.gray[800] : colors.white;
  }
  return 'transparent';
};

export const isOneDayHoliday = (hol: Holiday, day: Dayjs) => {
  return (
    hol.days === 1 ||
    (!dayjs(day).isAfter(hol.dateFrom) && day.day() === 0) ||
    (!dayjs(day).isBefore(hol.dateTo) && day.day() === 1)
  );
};
