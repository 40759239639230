import { useState } from 'react';

export const useModal = (): [boolean, VoidFunction] => {
  const [isVisible, setIsVisible] = useState(false);

  function toggle() {
    setIsVisible((prev) => !prev);
  }

  return [isVisible, toggle];
};
