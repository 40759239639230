import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HolidayStatuses, HolidayType } from 'services/HolidaysService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { createAllHolidayTypesArray } from 'utils';
import { State } from '../../../types/global';

export const useFilters = () => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [departmentId, setDepartmentId] = useState<number | null>(null);
  const [holidayTypes, setHolidayTypes] = useState<HolidayType[]>([]);
  const [employmentType, setEmploymentType] = useState<string | null>(null);
  const [loadingHolidayTypes, setLoadingHolidayTypes] = useState(true);
  const [holidayStatuses, setHolidayStatuses] = useState<HolidayStatuses[]>([
    HolidayStatuses.Accepted,
    HolidayStatuses.Pending,
    HolidayStatuses.Declined,
  ]);

  const searchPhraseState: State<string | null> = {
    state: searchPhrase,
    setState: setSearchPhrase,
  };

  const departmentIdState: State<number | null> = {
    state: departmentId,
    setState: setDepartmentId,
  };

  const employmentTypeState: State<string | null> = {
    state: employmentType,
    setState: setEmploymentType,
  };

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    setLoadingHolidayTypes(employmentTypesStore.isProgress);
  }, [employmentTypesStore.isProgress]);

  useEffect(() => {
    const allHolidayTypes = createAllHolidayTypesArray(employmentTypesStore.listData);
    setHolidayTypes(allHolidayTypes);
  }, [employmentTypesStore.listData]);

  const holidayTypesState: State<HolidayType[]> = {
    state: holidayTypes,
    setState: setHolidayTypes,
  };

  const holidayStatusesState: State<HolidayStatuses[]> = {
    state: holidayStatuses,
    setState: setHolidayStatuses,
  };

  return {
    searchPhraseState,
    departmentIdState,
    holidayTypesState,
    employmentTypeState,
    holidayStatusesState,
    loadingHolidayTypes,
  };
};
