import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useModal } from 'components/Modal/useModal';
import { NewRequestHolidayModal } from 'components/Modals';
import { ProgressBar } from 'components/ProgressBar';
import { Spinner } from 'components/Spinner';
import { colors } from 'styles/colors';
import { CalendarMonthNavigation } from '../../../../components/Calendar';
import { useThemeContext } from '../../../../components/ThemeWrapper/ThemeWrapper';
import { useUserContext, useCalendarContext } from '../../../../contexts';
import { CalendarHeaderProps } from '../../types';
import {
  CalendarHeaderWrapper,
  ButtonOpenModal,
  Container,
  HolidayLeft,
  HolidayLeftHeader,
} from './styled';

export const CalendarHeader = ({ holiday, employmentType }: CalendarHeaderProps) => {
  const { monthIndex, increaseMonthIndex, decreaseMonthIndex, date, handleReset } =
    useCalendarContext();
  const { fetchAuthenticatedUser } = useUserContext();
  const [isVisible, toggle] = useModal();
  const { getColorByTheme } = useThemeContext();

  const year = useMemo(() => date.year(), [date]);

  useEffect(() => {
    fetchAuthenticatedUser();
  }, [date]);

  if (!holiday) {
    return <Spinner fullPage />;
  }

  return (
    <CalendarHeaderWrapper data-cy="calendar-header">
      <Container>
        <ButtonOpenModal
          iconNameLeft="PlusIcon"
          label="New request"
          size="regular"
          variant="tertiary"
          onClick={toggle}
          dataCY="calendar-new-request-button"
        />

        {isVisible && (
          <NewRequestHolidayModal
            isVisible={isVisible}
            toggle={toggle}
            holiday={holiday}
            employmentType={employmentType}
          />
        )}

        <HolidayLeft>
          <HolidayLeftHeader type="5" style={{ marginBottom: '0.5rem' }}>
            {holiday.left + ' days of holiday left'}
          </HolidayLeftHeader>
          <ProgressBar
            showValue={holiday.left}
            maxValue={holiday.total}
            heightBar={6}
            pointToChangeColorValue={5}
            colorMinBar={getColorByTheme(colors.dark.tertiary, colors.tertiary)}
            colorBar={getColorByTheme(colors.dark.fail, colors.fail)}
          />
        </HolidayLeft>
      </Container>

      <CalendarMonthNavigation
        prevIcon="ArrowLeftIcon"
        prevIconWidth={25}
        nextIcon="ArrowRightIcon"
        nextIconWidth={25}
        onClickPrevIcon={decreaseMonthIndex}
        onClickNextIcon={increaseMonthIndex}
        isFormatDate
        formatDate={dayjs(new Date(year, monthIndex)).format('MMMM YYYY')}
        isButtonToday
        handleReset={handleReset}
        isHeaderUserCalendar={true}
      />
    </CalendarHeaderWrapper>
  );
};
