import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'components/Inputs';
import Text from 'components/Text';
import { HolidayStatuses, TeamHoliday } from 'services/HolidaysService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { filterByDepartment, searchByEmployee, searchByStatus } from 'utils/search';
import { Spinner } from '../../../components/Spinner';
import { useDashboard } from '../../../hooks/useDashboard';
import { FetchDashboardParams } from '../../../services/DashboardService';
import { HolidayProps } from '../types';
import { HolidayRow } from './HolidayRow';
import {
  FilterButton,
  FiltersBar,
  HolidaysFilters,
  HolidaysWrapper,
  Wrap,
  DepartmentWithoutHoliday,
} from './styled';

export const Holidays = ({ activeDepartment }: HolidayProps) => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const [statusFilter, setStatusFilter] = useState<string>(HolidayStatuses.Pending);
  const [searchQuery, setSearchQuery] = useState<string>();

  const [params, setParams] = useState<FetchDashboardParams>({
    by_status: statusFilter,
    query: searchQuery,
  });
  const { dashboardHolidays, refetch, meta, metaData } = useDashboard(params);
  const [holidays, setHolidays] = useState<TeamHoliday[]>([]);

  useEffect(() => {
    const filterHolidays = dashboardHolidays.filter((teamHoliday) => {
      return (
        filterByDepartment(teamHoliday.user.department, activeDepartment?.name) &&
        searchByStatus(statusFilter as HolidayStatuses, teamHoliday.status) &&
        searchByEmployee(searchQuery, teamHoliday.user)
      );
    });

    setHolidays(filterHolidays);
  }, [dashboardHolidays, statusFilter, searchQuery, activeDepartment]);

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  const handleSearchingChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchQuery(e.currentTarget.value);
  };

  useEffect(() => {
    setParams((currentParams) => ({ ...currentParams, query: searchQuery }));
  }, [searchQuery]);

  const clearSearching = () => setSearchQuery(undefined);

  const handleFilterClick = (status: HolidayStatuses) => {
    setParams((currentParams) => ({ ...currentParams, by_status: status, page: undefined }));
    setStatusFilter(status);
  };

  const group = useMemo(
    () => _.groupBy(holidays, (h) => dayjs(h.dateFrom).format('YYYY-MM-DD')),
    [holidays],
  );

  const handleNextPage = () => {
    if (meta?.nextPage) {
      setParams((currentParams) => ({ ...currentParams, page: meta.nextPage as number }));
    }
  };

  return (
    <HolidaysWrapper
      title={activeDepartment?.name ? activeDepartment?.name + ' Holiday' : 'Holiday'}>
      <FiltersBar>
        <Input
          placeholder={'Search for employee'}
          value={searchQuery || ''}
          onChange={handleSearchingChange}
          crossMarkIconOnClick={clearSearching}
          maxLength={50}
          dataCY="dashboard-holidays-search-input"
        />
        <HolidaysFilters data-cy="dashboard-holidays-filters">
          {[HolidayStatuses.Pending, HolidayStatuses.Accepted, HolidayStatuses.Declined].map(
            (status) => (
              <FilterButton
                active={statusFilter === status}
                onClick={() => {
                  handleFilterClick(status);
                }}
                key={status}>
                {status === HolidayStatuses.Pending ? 'Requested' : status}
              </FilterButton>
            ),
          )}
        </HolidaysFilters>
      </FiltersBar>
      {holidays.length > 0 ? (
        <InfiniteScroll
          dataLength={holidays.length}
          next={handleNextPage}
          hasMore={!!meta?.nextPage && !!metaData.length}
          loader={<Spinner />}
          style={{ overflow: 'initial' }}>
          {Object.keys(group).map((key, i) => (
            <Wrap key={i}>
              <Text.Paragraphs type="caption" color={colors.gray[600]}>
                {dayjs(key).format('dddd DD MMMM YYYY')}
              </Text.Paragraphs>
              {group[key].map((holiday) => (
                <HolidayRow
                  key={holiday.id}
                  holiday={holiday}
                  employmentTypes={employmentTypesStore.listData}
                  refetch={refetch}
                />
              ))}
            </Wrap>
          ))}
        </InfiniteScroll>
      ) : (
        <DepartmentWithoutHoliday>
          {activeDepartment?.name
            ? `${activeDepartment.name} - no holidays`
            : 'Everyone - no holidays'}
        </DepartmentWithoutHoliday>
      )}
    </HolidaysWrapper>
  );
};
