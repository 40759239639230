import { Everyone } from 'const';
import { Department } from 'services/DepartmentsService';
import { getSortedDepartamentList } from './getSortedDepartamentList';

export const getSortedDepartamentListWithEveryone = (departments?: Department[]) => {
  if (!departments) {
    return [];
  }

  const sortedDeps = getSortedDepartamentList(departments);

  return [Everyone, ...sortedDeps];
};
