import { colors } from 'styles/colors';
import { useThemeContext } from '../ThemeWrapper/ThemeWrapper';
import { Wrapper, Background, Progress } from './styled';
import { ProgressBarProps } from './types';

export const ProgressBar = ({
  showValue,
  maxValue = 100,
  colorBar,
  colorMinBar,
  pointToChangeColorValue = 20,
  heightBar = 12,
}: ProgressBarProps) => {
  const { getColorByTheme } = useThemeContext();

  const colorBarByTheme = getColorByTheme(colors.dark.tertiary, colors.tertiary);
  const colorMinBarByTheme = getColorByTheme(colors.dark.fail, colors.fail);

  return (
    <Wrapper>
      <Background heightBar={heightBar} />
      <Progress
        showValue={showValue}
        maxValue={maxValue}
        colorBar={colorBar ? colorBar : colorBarByTheme}
        colorMinBar={colorMinBar ? colorMinBar : colorMinBarByTheme}
        pointToChangeColorValue={pointToChangeColorValue}
        heightBar={heightBar}
      />
    </Wrapper>
  );
};
