import { Dayjs } from 'dayjs';
import { useHolidaysContext } from 'contexts/HolidaysContext';
import { useUserContext } from 'contexts/UserContext';
import { usePolishNameHolidayType } from 'hooks';
import { HolidayType, TeamHoliday } from 'services/HolidaysService';
import { foundHolidayContainingDay } from 'utils';
import { EmployeeDayContent } from './EmployeeDayContent';
import { EmployeeTeamDayContent } from './EmployeeTeamDayContent';
import { TeamDayContent } from './TeamDayContent';

export interface DayProps {
  day: Dayjs;
  withDaysNames: boolean;
  firstColumn: boolean;
  teamCalendar?: boolean;
  weekRows: Record<number, (number | null)[]>;
  holidayType?: HolidayType;
  filterTeamHolidays: (holiday: TeamHoliday) => boolean;
  weekHasHoliday: boolean;
  setWeekHasHoliday: (param: boolean) => void;
}

export type EmployeeDayProps = Omit<DayProps, 'filterTeamHolidays'>;

export const Day = ({
  day,
  withDaysNames,
  firstColumn,
  teamCalendar,
  weekRows,
  filterTeamHolidays,
  setWeekHasHoliday,
  weekHasHoliday,
}: DayProps) => {
  const { isManager } = useUserContext();
  const { holidays } = useHolidaysContext();
  const holiday = foundHolidayContainingDay(day, holidays);
  const holidayType = usePolishNameHolidayType(holiday);

  return (
    <>
      {isManager && teamCalendar && (
        <TeamDayContent
          day={day}
          withDaysNames={withDaysNames}
          firstColumn={firstColumn}
          weekRows={weekRows}
          filterTeamHolidays={filterTeamHolidays}
          setWeekHasHoliday={setWeekHasHoliday}
          weekHasHoliday={weekHasHoliday}
        />
      )}

      {!isManager && teamCalendar && (
        <EmployeeTeamDayContent
          day={day}
          withDaysNames={withDaysNames}
          firstColumn={firstColumn}
          weekRows={weekRows}
          filterTeamHolidays={filterTeamHolidays}
          setWeekHasHoliday={setWeekHasHoliday}
          weekHasHoliday={weekHasHoliday}
        />
      )}

      {!teamCalendar && (
        <EmployeeDayContent
          day={day}
          withDaysNames={withDaysNames}
          firstColumn={firstColumn}
          weekRows={weekRows}
          holidayType={holidayType}
          setWeekHasHoliday={setWeekHasHoliday}
          weekHasHoliday={weekHasHoliday}
        />
      )}
    </>
  );
};
