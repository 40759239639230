import { useState, useRef, useCallback, useEffect } from 'react';
import { StyledInput } from '../EmployeeSelect/styled';
import { DropDownListContainer, DropDownList, ListItem } from '../styled';
import { SelectProps } from '../types';
import { DropdownFunctions } from './types';

export function SelectDropdown<T>({
  options,
  onOptionClicked,
  labelSelector,
  searchInputProps,
  toggleDropdown,
  selectedOption,
  setShowEnteredValue,
}: SelectProps<T> & DropdownFunctions) {
  const initCursor = selectedOption ? options.indexOf(selectedOption) : undefined;

  const [cursor, setCursor] = useState<number | undefined>(initCursor);
  const dropdown = useRef<HTMLUListElement>(null);

  const onUpPress = useCallback(() => {
    if (options.length === 0) return;
    setCursor((currentIndex) => {
      if (currentIndex == null) {
        return 0;
      }
      if (currentIndex === 0) {
        return currentIndex;
      } else {
        const nextIndex = currentIndex - 1;
        const scrollHeight = dropdown.current?.scrollHeight || 0;
        const scrollTo = (scrollHeight / options.length) * nextIndex;
        dropdown.current?.scrollTo({ top: scrollTo });

        return nextIndex;
      }
    });
  }, [options.length]);

  const onDownPress = useCallback(() => {
    if (options.length === 0) return;
    setCursor((currentIndex) => {
      if (currentIndex == null) {
        return 0;
      }

      const maxIndex = options.length - 1;
      if (currentIndex === maxIndex) {
        return currentIndex;
      } else {
        const nextIndex = currentIndex + 1;

        const scrollHeight = dropdown.current?.scrollHeight || 0;
        const scrollTo = (scrollHeight / options.length) * nextIndex;
        dropdown.current?.scrollTo({ top: scrollTo });

        return nextIndex;
      }
    });
  }, [options.length]);

  const onEnterPress = useCallback(() => {
    if (cursor != null && options[cursor] != null) {
      onOptionClicked && onOptionClicked(options[cursor]);
      toggleDropdown(false);
    }
  }, [cursor, onOptionClicked, toggleDropdown, options]);

  useEffect(() => {
    const handleEnterPress = (ev: KeyboardEvent) => {
      if (ev.key === 'Enter') {
        onEnterPress();
      }
    };

    const handleArrowPress = (ev: KeyboardEvent) => {
      if (ev.key === 'ArrowDown') {
        ev.preventDefault();
        onDownPress();
      } else if (ev.key === 'ArrowUp') {
        ev.preventDefault();
        onUpPress();
      }
    };

    document.addEventListener('keydown', handleArrowPress);
    document.addEventListener('keypress', handleEnterPress);

    return () => {
      document.removeEventListener('keydown', handleArrowPress);
      document.removeEventListener('keypress', handleEnterPress);
    };
  }, [onUpPress, onDownPress, onEnterPress]);

  return (
    <DropDownListContainer data-cy="select-dropdown">
      <DropDownList itemRef="" ref={dropdown}>
        {searchInputProps && (
          <StyledInput
            {...searchInputProps}
            placeholder="Search for employee"
            autoFocus={true}
            dataCY="select-employee-search-input"
          />
        )}
        {options &&
          options.map((option, i) => (
            <ListItem
              active={i === cursor}
              onClick={() => {
                onOptionClicked && onOptionClicked(option);
                toggleDropdown(false);
                setShowEnteredValue(false);
              }}
              key={i}
              data-cy="select-dropdown-option">
              {labelSelector ? labelSelector(option) : option}
            </ListItem>
          ))}
      </DropDownList>
    </DropDownListContainer>
  );
}
