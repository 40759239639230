import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useUserContext } from 'contexts/UserContext';
import { TeamHoliday } from 'services/HolidaysService';
import { foundTeamHolidaysContainingDay, getDayIndex } from 'utils';
import { Day } from './Day';

interface WeekProps {
  week: Dayjs[];
  weekIdx: number;
  teamCalendar?: boolean;
  employeeTeamCalendar?: boolean;
  weekTeamHolidays: TeamHoliday[];
  filterTeamHolidays: (holiday: TeamHoliday) => boolean;
}

export const Week = ({
  week,
  weekIdx,
  teamCalendar,
  weekTeamHolidays,
  filterTeamHolidays,
}: WeekProps) => {
  const weekRows: Record<number, (number | null)[]> = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
  };
  const [weekHasHoliday, setWeekHasHoliday] = useState(false);

  const { isManager } = useUserContext();

  const hasNextDayHoliday = (day: Dayjs, holidayId: number) => {
    const nextDayHoliday = foundTeamHolidaysContainingDay(
      dayjs(day).add(1, 'day'),
      weekTeamHolidays,
      isManager,
    ).find((h) => h.id === holidayId);
    return !!nextDayHoliday;
  };

  week.forEach((day) => {
    const dayHolidays = foundTeamHolidaysContainingDay(day, weekTeamHolidays, isManager);
    const dayIndex = getDayIndex(day);

    dayHolidays.forEach((holiday) => {
      for (const row in weekRows) {
        if (!weekRows[row].length || weekRows[row].includes(holiday.id)) {
          weekRows[row][dayIndex] = holiday.id;
          if (hasNextDayHoliday(day, holiday.id)) {
            weekRows[row][dayIndex + 1] = holiday.id;
          }
          break;
        }

        if (
          Object.values(weekRows).some((r) => {
            return r.some((item) => item === holiday.id);
          })
        ) {
          continue;
        }

        if (!weekRows[row][dayIndex]) {
          weekRows[row][dayIndex] = holiday.id;
          break;
        }
      }
    });
  });

  return (
    <>
      {week.map((day, idx) => (
        <Day
          day={day}
          key={day.format('DD-MM-YY')}
          withDaysNames={weekIdx === 0}
          firstColumn={idx === 0}
          teamCalendar={teamCalendar}
          weekRows={weekRows}
          filterTeamHolidays={filterTeamHolidays}
          setWeekHasHoliday={setWeekHasHoliday}
          weekHasHoliday={weekHasHoliday}
        />
      ))}
    </>
  );
};
