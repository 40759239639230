export const DEFAULT_ICON_SIZE = 16;

export const Everyone = {
  id: 999,
  name: 'Everyone',
  parentId: null,
};

export const All = {
  key: 'all',
  value: 'All',
  holiday_types: [],
  additional_holiday_types: [],
  settlements: [],
};
