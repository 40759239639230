import { Navbar } from '../Navbar';
import { Content, Wrapper } from './styled';
import { LayoutProps } from './types';

export const Layout = ({ children, className }: LayoutProps) => {
  return (
    <>
      <Navbar />
      <Wrapper>
        <Content className={className}>{children}</Content>
      </Wrapper>
    </>
  );
};
