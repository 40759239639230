import { EmploymentTypesActionTypesEnum as CONSTANTS } from 'store/actionTypes';
import { EmploymentTypeInterfaceTypes } from 'types';

const {
  EMPLOYMENT_TYPES_ACTION_REQUEST,
  EMPLOYMENT_TYPES_ACTION_RESPONSE,
  EMPLOYMENT_TYPES_ACTION_ERROR,
} = CONSTANTS;

export interface GetEmploymentTypesRequestAction {
  type: CONSTANTS.EMPLOYMENT_TYPES_ACTION_REQUEST;
}

export const getEmploymentTypesRequest = (): GetEmploymentTypesRequestAction => ({
  type: EMPLOYMENT_TYPES_ACTION_REQUEST,
});

export interface GetEmploymentTypesResponseAction {
  type: CONSTANTS.EMPLOYMENT_TYPES_ACTION_RESPONSE;
  payload: EmploymentTypeInterfaceTypes[];
}

export const getEmploymentTypesResponse = (
  payload: EmploymentTypeInterfaceTypes[],
): GetEmploymentTypesResponseAction => ({
  type: EMPLOYMENT_TYPES_ACTION_RESPONSE,
  payload,
});

export interface GetEmploymentTypesErrorAction {
  type: CONSTANTS.EMPLOYMENT_TYPES_ACTION_ERROR;
}

export const getEmploymentTypesError = (): GetEmploymentTypesErrorAction => ({
  type: EMPLOYMENT_TYPES_ACTION_ERROR,
});

export type GetEmploymentTypesActions =
  | GetEmploymentTypesRequestAction
  | GetEmploymentTypesResponseAction
  | GetEmploymentTypesErrorAction;
