import styled, { css } from 'styled-components';
import { Button } from 'components/Button/ButtonBase';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { DepartmentButtonStyleProps, SubdepartmentWrapperProps } from './types';

export const StyledButton = styled(Button)<DepartmentButtonStyleProps>`
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
  margin: 0.25rem 0;
  justify-content: flex-start;
  color: ${({ theme }) => theme.department.text};
  background-color: transparent;
  position: relative;
  ${typography.buttonFontSize.large};

  svg {
    color: ${({ theme }) => theme.department.text};
  }

  &:hover {
    border-radius: ${borderRadius.xs};
    color: ${({ theme }) => theme.department.activeText};
    background-color: ${({ theme }) => theme.department.activeBg};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-radius: ${borderRadius.xs};
      color: ${({ theme }) => theme.department.activeText};
      background-color: ${({ theme }) => theme.department.activeBg};

      svg {
        color: ${({ theme }) => theme.department.activeText};
      }
    `};
`;

export const SubDepartmentWrapper = styled.div<SubdepartmentWrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ isChild }) =>
    isChild &&
    css`
      margin-left: 2.5rem;
      :last-child {
        ::after {
          content: '';
          position: absolute;
          left: -28px;
          border-left: 2px solid ${({ theme }) => theme.department.border};
          height: 10px;
          width: 0;
          top: 20px;
        }
      }

      ::before {
        content: '';
        position: absolute;
        left: -28px;
        border-bottom: 2px solid ${({ theme }) => theme.department.border};
        border-left: 2px solid ${({ theme }) => theme.department.border};
        top: 7px;
        width: 16px;
        height: 26px;
        border-end-start-radius: 8px;
      }

      ::after {
        content: '';
        position: absolute;
        left: -28px;
        border-left: 2px solid ${({ theme }) => theme.department.border};
        height: 100%;
        width: 0;
        top: 20px;
      }
    `};
`;
