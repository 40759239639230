import { useUserContext } from 'contexts/UserContext';
import { ShowForProps } from '../../types';
import { DepartmentSelect } from '../DepartmentSelect';
import { EmployeeSearch } from '../EmployeeSearch';
import { EmploymentTypeSelect } from '../EmploymentTypeSelect';
import { HolidayBalanceHeader } from '../styled';
import { ShowForWrapper } from './styled';

export const ShowFor = ({
  departmentIdState,
  employmentTypeState,
  searchPhraseState,
}: ShowForProps) => {
  const { isManager } = useUserContext();

  return (
    <ShowForWrapper data-cy="team-calendar-show-for">
      <HolidayBalanceHeader type="small" weight={700} margin="1rem 0 0 0">
        Show for
      </HolidayBalanceHeader>
      {isManager && (
        <>
          <DepartmentSelect selectedDepartmentIdState={departmentIdState} />
          <EmploymentTypeSelect selectedEmploymentTypeState={employmentTypeState} />
        </>
      )}
      <EmployeeSearch searchPhraseState={searchPhraseState} />
    </ShowForWrapper>
  );
};
