import { AxiosResponse } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, Observable, of, catchError, map, switchMap } from 'rxjs';
import { departmerntRolesService } from 'services';
import {
  getDepartmentRolesResponse,
  getDepartmentRolesError,
  GetDepartmentRolesRequestAction,
} from 'store/actions';
import { DepartmentRolesActionTypesEnum } from 'store/actionTypes';
import { DepartmentRolesServiceResponseInterfaceTypes } from 'types';

export const departmentRolesEpic: Epic = (action$: Observable<GetDepartmentRolesRequestAction>) =>
  action$.pipe(
    ofType(DepartmentRolesActionTypesEnum.DEPARTMENT_ROLES_ACTION_REQUEST),
    switchMap(() =>
      from(departmerntRolesService()).pipe(
        map((response: AxiosResponse<DepartmentRolesServiceResponseInterfaceTypes, unknown>) =>
          getDepartmentRolesResponse(response.data.data),
        ),
        catchError(() => of(getDepartmentRolesError())),
      ),
    ),
  );
