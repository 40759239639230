import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';
import { Checkbox } from 'components/Switches/Checkbox';
import Text from 'components/Text';
import { useCalendarContext } from 'contexts/CalendarContext';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';
import { EnumEmploymentType, HolidayStatuses, HolidayType } from 'services/HolidaysService';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { createAllHolidayTypesArray } from 'utils';
import { useThemeContext } from '../../../../components/ThemeWrapper/ThemeWrapper';
import { useSelectedHolidayStatuses, useSelectedHolidayTypes } from '../../hooks';
import { HolidayBalanceProps } from '../../types';
import {
  HolidayBalanceWrapper,
  HolidayBalanceList,
  HolidayBalanceListItem,
  WrapSpinner,
  HolidayBalanceHeader,
  HolidayBalanceParagraph,
} from '../styled';

export const HolidayBalance = ({
  holidayTypesState,
  holidayStatusesState,
  loadingHolidayTypes,
}: HolidayBalanceProps) => {
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);

  const { getColorByTheme } = useThemeContext();
  const { teamHolidays } = useTeamHolidaysContext();
  const { monthIndex } = useCalendarContext();

  const selectedHolidayStatuses = holidayStatusesState.state;
  const statusesListItems = useSelectedHolidayStatuses(
    selectedHolidayStatuses,
    teamHolidays,
    monthIndex,
  );

  const holidayTypes = useMemo(
    () => createAllHolidayTypesArray(employmentTypesStore.listData),
    [employmentTypesStore.listData],
  );
  const selectedHolidayTypes = holidayTypesState.state;
  const typesListItems = useSelectedHolidayTypes({
    holidayTypes,
    selectedHolidayTypes,
    teamHolidays,
    monthIndex,
  });

  const showArray = `${EnumEmploymentType.Vacation},${EnumEmploymentType.Sick},${EnumEmploymentType.On_demand},`;
  const other = typesListItems.filter((e) => !showArray.includes(e.holidayType.key));

  const handleHolidayStatusCheckbox = (holidayStatus: HolidayStatuses) => {
    if (selectedHolidayStatuses.some((status) => status === holidayStatus)) {
      holidayStatusesState.setState(
        selectedHolidayStatuses.filter((status) => status !== holidayStatus),
      );
    } else {
      holidayStatusesState.setState([...selectedHolidayStatuses, holidayStatus]);
    }
  };

  const handleHolidayTypeCheckbox = (holidayType: HolidayType) => {
    if (selectedHolidayTypes.some((type) => type.key === holidayType.key)) {
      holidayTypesState.setState(
        selectedHolidayTypes.filter((type) => type.key !== holidayType.key),
      );
    } else {
      holidayTypesState.setState([...selectedHolidayTypes, holidayType]);
    }
  };

  const otherHolidayTypes: HolidayType[] = other.map((holiday) => holiday.holidayType);
  const flatSelectedHolidayTypes = selectedHolidayTypes.map((holiday) => holiday.key);
  const flatOtherHolidayTypes = otherHolidayTypes.map((holiday) => holiday.key);

  const handleOtherHolidayTypeCheckbox = () => {
    if (flatSelectedHolidayTypes.some((key) => flatOtherHolidayTypes.includes(key))) {
      holidayTypesState.setState(
        selectedHolidayTypes.filter(({ key }) => !flatOtherHolidayTypes.includes(key)),
      );
    } else {
      holidayTypesState.setState([...selectedHolidayTypes, ...otherHolidayTypes]);
    }
  };

  const onCheckboxAction = (holidayType?: HolidayType) => {
    if (holidayType && showArray.includes(holidayType.key)) {
      handleHolidayTypeCheckbox(holidayType);
    } else {
      handleOtherHolidayTypeCheckbox();
    }
  };

  if (loadingHolidayTypes) {
    return (
      <WrapSpinner>
        <Spinner />
      </WrapSpinner>
    );
  }

  const filteredTypesList = typesListItems.filter((el) => showArray.includes(el.holidayType.key));

  const otherTypesList = typesListItems
    .filter((el) => !showArray.includes(el.holidayType.key))
    .reduce((prev, curr) => ({ ...prev, days: prev.days + curr.days, checked: curr.checked }), {
      label: 'Inny',
      days: 0,
      color: getColorByTheme(colors.gray[700], colors.gray[600]),
      checked: false,
    });

  const showType = filteredTypesList.length > 0 || otherTypesList.days > 0;
  const showHolidayBalanceTitle = statusesListItems.length > 0 || showType;

  return (
    <HolidayBalanceWrapper data-cy="holiday-balance">
      {showHolidayBalanceTitle && (
        <HolidayBalanceHeader type="small" weight={700}>
          Holiday Balance
        </HolidayBalanceHeader>
      )}
      <HolidayBalanceList>
        {statusesListItems.length > 0 && (
          <HolidayBalanceParagraph type="caption">State</HolidayBalanceParagraph>
        )}

        {statusesListItems.map(({ status, label, days, color, checked }) => (
          <HolidayBalanceListItem key={label} data-cy="holiday-balance-item">
            <Checkbox
              label={label}
              isChecked={checked}
              stateCheckbox={true}
              checkboxAction={() => handleHolidayStatusCheckbox(status)}
              holidayBalanceVariant
              color={color}
              dataCY={`holiday-balance-checkbox${checked ? '-checked' : ''}`}
            />
            <HolidayBalanceParagraph type="xSmall">{days}</HolidayBalanceParagraph>
          </HolidayBalanceListItem>
        ))}

        {showType && <HolidayBalanceParagraph type="caption">Type</HolidayBalanceParagraph>}
        {filteredTypesList.map((el) => (
          <HolidayBalanceListItem key={el.label} data-cy="holiday-balance-item">
            <Checkbox
              label={el.label}
              isChecked={el.checked}
              checkboxAction={() => onCheckboxAction(el.holidayType)}
              holidayBalanceVariant
              color={el.color}
              dataCY={`holiday-balance-checkbox${el.checked ? '-checked' : ''}`}
            />
            <Text.Paragraphs type="xSmall">{el.days}</Text.Paragraphs>
          </HolidayBalanceListItem>
        ))}
        {otherTypesList.days > 0 && (
          <HolidayBalanceListItem key={otherTypesList.label} data-cy="holiday-balance-item">
            <Checkbox
              label={otherTypesList.label}
              isChecked={otherTypesList.checked}
              checkboxAction={() => onCheckboxAction()}
              holidayBalanceVariant
              color={otherTypesList.color}
              dataCY={`holiday-balance-checkbox${otherTypesList.checked ? '-checked' : ''}`}
            />
            <Text.Paragraphs type="xSmall">{otherTypesList.days}</Text.Paragraphs>
          </HolidayBalanceListItem>
        )}
      </HolidayBalanceList>
    </HolidayBalanceWrapper>
  );
};
