import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { typography } from 'styles/typography';

export const Wrapper = styled.div`
  margin-top: -24px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.profile.link};
  ${typography.heading[6]};
  display: flex;
  align-items: center;
  transform: translateY(100%);

  svg {
    color: ${({ theme }) => theme.profile.link};
  }

  span {
    margin-left: 0.5rem;
  }
`;

export const UserHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
`;

export const WelcomeSection = styled.div`
  ${typography.heading[3]};
  margin: 0.5rem 0 0.425rem 0;
  color: ${({ theme }) => theme.profile.header};
`;

export const UserProfileLink = styled(Link)`
  ${typography.heading[3]};
  margin: 0.5rem 0 0.425rem 0;
  color: ${({ theme }) => theme.profile.header};
  text-decoration: none;
`;

export const TagSection = styled.div`
  ${typography.heading[6]};
  display: flex;
  list-style-type: none;
  align-items: center;
`;

export const Element = styled.div`
  color: ${({ theme }) => theme.profile.subHeader};
  padding: 0;
`;

export const Dot = styled.div`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.profile.subHeader};
  margin: 0 0.5em;
`;
