import styled from 'styled-components';
import { Button } from 'components/Button/ButtonBase';
import Text from '../../../components/Text';

export const Container = styled.div`
  display: flex;
`;

export const Header = styled.div`
  top: 3.7rem;
  position: fixed;
  padding: 2.5em 0 1.5em 0;
  z-index: 999;
  width: 98%;
  background-color: ${({ theme }) => theme.background};
`;

export const ButtonOpenModal = styled(Button)`
  margin-right: 1.5rem;
`;

export const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  margin-right: 0.5em;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.background};
`;

export const HolidayBalanceWrapper = styled.div`
  margin-top: 2rem;
`;

export const HolidayBalanceList = styled.ul`
  padding: 0;
  display: grid;
  flex-direction: column;
  gap: 1rem;
`;

export const HolidayBalanceListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapSpinner = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const HolidayBalanceHeader = styled(Text.Paragraphs)`
  color: ${({ theme }) => theme.text[900]};
`;

export const HolidayBalanceParagraph = styled(Text.Paragraphs)`
  color: ${({ theme }) => theme.text[600]};
`;
