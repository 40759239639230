import { Department } from 'services/DepartmentsService';

interface SortedDepartaments extends Department {
  children?: Department[];
}

export const getSortedDepartamentList = (departments: Department[]) => {
  if (!departments) {
    return [];
  }

  const companyName = departments.find((dep) => dep.parentId === null) || null;

  const departamentsInCompany = departments.filter((dep) => dep.parentId === companyName?.id) || [];

  const departamentsWithTribes: Record<number, SortedDepartaments> = {};

  departamentsInCompany.forEach((dt) => {
    if (companyName && dt.parentId === companyName.id) {
      departamentsWithTribes[dt.id] = dt;
    }
  });

  departments?.forEach((dt) => {
    if (Object.keys(departamentsWithTribes).find((depart) => depart === dt.parentId?.toString())) {
      if (
        dt.parentId &&
        !departamentsWithTribes[dt.parentId].children?.find((child) => child.id === dt.id)
      ) {
        departamentsWithTribes[dt.parentId].children = [
          ...(departamentsWithTribes[dt.parentId].children ?? []),
          dt,
        ];
      }
    }
  });

  const listDepartaments: Department[] = [];

  Object.values(departamentsWithTribes).forEach((dep) => {
    if (dep.children) {
      const { children, ...rest } = dep;
      listDepartaments.push(rest, ...children);
    } else {
      listDepartaments.push(dep);
    }
  });

  if (!companyName) {
    return [...listDepartaments];
  }

  return [companyName, ...listDepartaments];
};
