import { useCallback, useEffect, useState } from 'react';
import HolidaysService, { FetchHolidaysParams, Holiday } from 'services/HolidaysService';
import { useCalendarContext } from './CalendarContext';
import { makeContext } from './ContextCreator';
import { useToastContext } from './ToastContext';
import { useUserContext } from './UserContext';

export const [useHolidaysContext, HolidaysProvider] = makeContext(() => {
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [holidaysLoading, setHolidaysLoading] = useState<boolean>(false);
  const [smallCalendarUserHolidays, setSmallCalendarUserHolidays] = useState<Holiday[]>([]);
  const [yearHolidays, setYearHolidays] = useState<Holiday[]>([]);
  const { isAuthenticated } = useUserContext();
  const { handleError } = useToastContext();
  const { currentYear } = useCalendarContext();

  const getUserHolidays = useCallback(
    async (params?: FetchHolidaysParams) => {
      setHolidaysLoading(true);
      const response = await HolidaysService.fetchUserHolidays(params);
      const { data, error } = response;

      if (error) {
        return handleError(error);
      }

      setHolidays(data);
      setHolidaysLoading(false);
    },
    [handleError],
  );

  const getSmallCalendarUserHolidays = useCallback(
    async (params?: FetchHolidaysParams) => {
      const response = await HolidaysService.fetchUserHolidays(params);
      const { data, error } = response;

      if (error) {
        return handleError(error);
      }

      setSmallCalendarUserHolidays(data);
    },
    [handleError],
  );

  const getHolidaysForYear = useCallback(async () => {
    const params = {
      'date[from]': `${currentYear.toString()}-01-01`,
      'date[to]': `${currentYear.toString()}-12-31`,
    };

    const response = await HolidaysService.fetchUserHolidays(params);
    const { data, error } = response;

    if (error) {
      return handleError(error);
    }

    setYearHolidays(data);
  }, [handleError, currentYear]);

  useEffect(() => {
    if (isAuthenticated) {
      getUserHolidays();
      getSmallCalendarUserHolidays();
    }
  }, [isAuthenticated, getUserHolidays, getSmallCalendarUserHolidays]);

  return {
    holidays,
    holidaysLoading,
    smallCalendarUserHolidays,
    getUserHolidays,
    getSmallCalendarUserHolidays,
    yearHolidays,
    getHolidaysForYear,
  };
});
