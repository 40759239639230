import React from 'react';
import dayjs from 'dayjs';
import Text from '../../Text';
import { CalendarMonthNavigation } from '../CalendarMonthNavigation';
import { SmallCalendarHeader } from './styled';
import { DateFormat, SmallCalendarNavigationProps } from './types';

export const SmallCalendarNavigation = ({
  currentYear,
  currentMonthIdx,
  setCurrentMonthIdx,
  setCurrentYear,
  showNavigation,
}: SmallCalendarNavigationProps) => {
  const decrease = () => {
    if (currentMonthIdx <= 1) {
      setCurrentMonthIdx(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonthIdx(currentMonthIdx - 1);
    }
  };

  const increase = () => {
    if (currentMonthIdx >= 11) {
      setCurrentMonthIdx(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonthIdx(currentMonthIdx + 1);
    }
  };

  return (
    <SmallCalendarHeader>
      <Text.Paragraphs type="small" weight={700}>
        {dayjs(new Date(currentYear, currentMonthIdx)).format(DateFormat.MONTH)}
      </Text.Paragraphs>
      {showNavigation && (
        <CalendarMonthNavigation
          prevIcon={'ArrowUpIcon'}
          prevIconWidth={15}
          nextIcon={'ArrowDownIcon'}
          nextIconWidth={15}
          onClickPrevIcon={decrease}
          onClickNextIcon={increase}
          smallCalendar
        />
      )}
    </SmallCalendarHeader>
  );
};
