import { useSelector } from 'react-redux';
import { Holiday } from 'services/HolidaysService';
import { RootReducer } from 'store/reducers';
import { createAllHolidayTypesArray } from 'utils';

export const usePolishNameHolidayType = (holiday: Holiday | undefined) => {
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);

  const holidayTypes = createAllHolidayTypesArray(employmentTypesStore.listData);
  const holidayType = holidayTypes.find((type) => type.key === holiday?.holidayType);

  return holidayType;
};
