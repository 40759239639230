import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { borderRadius } from 'styles/variables';
import Text from '../../components/Text';

export const Wrapper = styled.div`
  margin: 2.5rem 1.5rem 0 1.5rem;
  display: flex;
`;

export const UserCalendarHeader = styled(Text.Heading)`
  color: ${({ theme }) => theme.text[900]};
`;

export const Header = styled.div`
  top: 3.7rem;
  position: fixed;
  padding: 2.5em 0 1.5em 0;
  z-index: 999;
  width: 98%;
  background-color: ${({ theme }) => theme.background};
`;

export const CalendarLayout = styled.div`
  display: flex;
  min-height: 100%;
  margin-top: 4.5rem;
  flex: 1;
`;

export const SidebarWrapper = styled.aside<{
  isCalendarMonth?: boolean;
}>`
  position: sticky;
  top: 10rem;
  background-color: ${({ theme }) => theme.backgroundElement};
  border-radius: ${borderRadius.sm};
  margin: 0 1rem 1rem 0;
  padding: 1.5rem 1.5rem 4rem 1.5rem;
  max-width: 16.75rem;
  scrollbar-width: thin;
  scrollbar-color: #258cf3 #e6e6e6;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 1rem;
    margin: 0.5rem;
    scroll-padding: 20rem;
  }

  ::-webkit-scrollbar-corner {
    margin: 20rem;
    border-right: 0.5rem solid ${colors.white};
    padding-right: 2rem;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.secondary};
    margin: 0.5rem;
    border-right: 0.5rem solid ${({ theme }) => theme.backgroundElement};
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.text.highlight};
    border-right: 0.5rem solid ${({ theme }) => theme.backgroundElement};
    border-radius: 0;
  }

  height: content;

  ${({ isCalendarMonth }) =>
    isCalendarMonth &&
    css`
      height: 65vh;
    `}

  z-index: 1;
`;
