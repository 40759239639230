import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Variant, Type } from 'components/AlertComponent/types';
import { SvgIconComponent } from 'components/Icons';
import { RejectHolidayModal } from 'components/Modals';
import Text from 'components/Text';
import { Ellipsis } from 'components/Text/Ellipsis';
import { ShortUserWithHolidays, TeamHolidayWithoutUser } from 'contexts/TeamHolidaysContext';
import { useToastContext } from 'contexts/ToastContext';
import { usePolishNameHolidayType } from 'hooks';
import { routes } from 'routes';
import { ApiError } from 'services/AuthService';
import HolidayService from 'services/HolidayService';
import { HolidayStatuses } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { getHolidayBorderByStatus, getHolidayBorderByType, getHolidayEmojiIcon } from 'utils';
import { getHolidayTimeSpan } from 'views/Dashboard/components/helpers';
import { useCalendarContext } from '../../../../../contexts';
import { useFetchHolidaysForCurrentWeek } from '../../../hooks';
import {
  DecisionIconsWrapper,
  DecisionIconWrapper,
  EmojiIconWrapper,
  OnClickContainer,
  TextWrapper,
  VacationPanelWrapper,
} from './styled';

interface VacationPanelProps {
  holiday: TeamHolidayWithoutUser;
  user: ShortUserWithHolidays;
  dayIndex: number;
  duration: number;
  startsBeforeCurrentWeek: boolean;
  endsAfterCurrentWeek: boolean;
  weekCalendar: boolean;
  moreHolidayTypesInTheSameDay: boolean;
}

export const VacationPanel = ({
  holiday,
  user,
  dayIndex,
  duration,
  startsBeforeCurrentWeek,
  endsAfterCurrentWeek,
  weekCalendar,
  moreHolidayTypesInTheSameDay,
}: VacationPanelProps) => {
  const { getHolidayColorByType, getHolidayColorByStatus } = useCalendarContext();

  const getBackgroundColor = () => {
    const backgroundColor =
      holiday.status === HolidayStatuses.Accepted
        ? getHolidayColorByType(holiday.holidayType)
        : getHolidayColorByStatus(holiday.status);

    return backgroundColor;
  };

  const getBorderColor = () => {
    const borderColor =
      holiday.status === HolidayStatuses.Accepted
        ? getHolidayBorderByType(holiday.holidayType)
        : getHolidayBorderByStatus(holiday.status);

    return borderColor;
  };

  const getPanelTheme = (): string => {
    const backgroundColor = getBackgroundColor();

    const color = holiday.status === HolidayStatuses.Pending ? colors.gray['800'] : colors.white;

    const borderColor = getBorderColor();

    return `background-color: ${backgroundColor}; color: ${color}; border: 1px solid ${borderColor};`;
  };

  const history = useHistory();

  const { handleError, setToast } = useToastContext();
  const refetchHolidays = useFetchHolidaysForCurrentWeek();

  const holidayType = usePolishNameHolidayType(holiday);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [messageToReject, setMessageToReject] = useState('');

  const handleAccept = async () => {
    try {
      await HolidayService.acceptHolidayById(holiday.id);
      refetchHolidays();
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been accepted.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  const handleReject = async () => {
    try {
      await HolidayService.rejectHolidayById(holiday.id, { reason: messageToReject });
      refetchHolidays();
      setShowRejectModal(false);
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been rejected.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  const handleRedirectToEdit = () => {
    history.push(`${routes.teamCalendar}/${holiday.id}`);
  };

  const isPending = holiday.status === HolidayStatuses.Pending;

  const dateTextColor = isPending ? colors.gray[600] : colors.white;

  const iconsCommon = {
    width: 12,
    height: 12,
    color: colors.gray[700],
  };

  const userFullName = `${user.lastName} ${user.firstName}`;

  return (
    <VacationPanelWrapper
      vacationBorderColor={getBorderColor()}
      vacationTheme={getPanelTheme()}
      weekCalendar={weekCalendar}
      status={holiday.status}
      moreHolidayTypesInTheSameDay={moreHolidayTypesInTheSameDay}
      type={holiday.holidayType}
      dayIndex={dayIndex}
      duration={duration}
      startsBeforeCurrentWeek={startsBeforeCurrentWeek}
      endsAfterCurrentWeek={endsAfterCurrentWeek}
      title={holidayType?.value}>
      <EmojiIconWrapper>{getHolidayEmojiIcon(holiday.holidayType)}</EmojiIconWrapper>

      <TextWrapper>
        <Text.Paragraphs type={'xSmall'}>
          {duration === 1 ? <Ellipsis>{holidayType?.value}</Ellipsis> : holidayType?.value}
        </Text.Paragraphs>
        <Text.Paragraphs type={'xxSmall'} color={dateTextColor}>
          {getHolidayTimeSpan(holiday.dateFrom, holiday.dateTo, 'MMM DD')}
        </Text.Paragraphs>
      </TextWrapper>

      {isPending && (
        <DecisionIconsWrapper>
          <DecisionIconWrapper onClick={handleAccept}>
            <SvgIconComponent icon="CheckIcon" {...iconsCommon} />
          </DecisionIconWrapper>
          <DecisionIconWrapper onClick={() => setShowRejectModal(true)}>
            <SvgIconComponent icon="CrossMarkIcon" {...iconsCommon} />
          </DecisionIconWrapper>
        </DecisionIconsWrapper>
      )}

      <OnClickContainer onClick={handleRedirectToEdit} />

      {showRejectModal && (
        <RejectHolidayModal
          isShowing={showRejectModal}
          rightButtonAction={handleReject}
          leftButtonAction={() => setShowRejectModal(false)}
          closeIcon={() => setShowRejectModal(false)}
          setMessage={(message) => setMessageToReject(message)}
          message={messageToReject}
          subtitleWithProps={{
            fullName: userFullName,
            startDate: holiday.dateFrom,
            endDate: holiday.dateTo,
            action: 'reject',
          }}
        />
      )}
    </VacationPanelWrapper>
  );
};
