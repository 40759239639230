import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { Type, Variant } from 'components/AlertComponent/types';
import { RejectHolidayModal } from 'components/Modals';
import { CancelHolidayModal } from 'components/Modals/CancelHolidayModal';
import { MessagePosition } from 'components/Select/styled';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { useToastContext } from 'contexts/ToastContext';
import { countWorkingDays } from 'helpers/CountWorkoutDays';
import { ApiError } from 'services/AuthService';
import HolidayService from 'services/HolidayService';
import { EnumEmploymentType } from 'services/HolidaysService';
import { colors } from 'styles/colors';
import { TeamURLParams } from '../types';
import { Buttons } from './components/Buttons';
import { Form } from './components/Form';
import { Header } from './components/Header';
import { HolidaySummary } from './components/HolidaySummary';
import { Container, HolidaySummaryContainer } from './styled';
import { HolidayManagerFormProps } from './types';

export const HolidayManagerForm = ({
  holiday,
  employee,
  holidayId,
  setHoliday,
  holidayTypes,
  additionalComment,
}: HolidayManagerFormProps & TeamURLParams) => {
  const [startDate, setStartDate] = useState<Date>(new Date(holiday.dateFrom));
  const [endDate, setEndDate] = useState<Date>(new Date(holiday.dateTo));
  const { setToast, handleError } = useToastContext();
  const history = useHistory();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [messageToRejectHolidayRequest, setMessageToRejectHolidayRequest] = useState('');
  const [rightDemandDate, setRightDemandDate] = useState(true);

  const { getColorByTheme } = useThemeContext();
  const subtitleWithPropsByTheme = getColorByTheme(colors.gray[700], colors.gray[500]);

  const employeeHolidayType =
    holiday.status === 'pending'
      ? holidayTypes?.find((empHT) => empHT.key === employee.employmentType?.key)?.holiday_types
      : holidayTypes[1].holiday_types || [];

  const leaveSelectedByEmployee = employeeHolidayType?.find((e) => e.key === holiday.holidayType);

  const [selectedHoliday, setSelectedHoliday] = useState<{ key: string; value: string }>({
    key: leaveSelectedByEmployee?.key || '',
    value: leaveSelectedByEmployee?.value || '',
  });
  const [buttons, setButtons] = useState<boolean>();

  useEffect(() => {
    if (holiday.status === 'accepted') {
      return setButtons(
        startDate.setHours(0, 0, 0, 0) - endDate.setHours(0, 0, 0, 0) <= 0 &&
          (new Date(holiday.dateFrom).setHours(0, 0, 0, 0) !== startDate.setHours(0, 0, 0, 0) ||
            new Date(holiday.dateTo).setHours(0, 0, 0, 0) !== endDate.setHours(0, 0, 0, 0) ||
            holiday.holidayType !== selectedHoliday.key),
      );
    }
    if (holiday.status === 'rejected') {
      return setButtons(false);
    }
    if (holiday.status === 'pending') {
      return setButtons(true);
    }
  }, [holiday, startDate, endDate, selectedHoliday]);

  useEffect(() => {
    if (startDate) {
      const dateCompare =
        startDate.setHours(0, 0, 0, 0) - new Date(holiday.createdAt).setHours(0, 0, 0, 0);
      return setRightDemandDate(dateCompare >= 172800000 || dateCompare < 0 ? true : false);
    }
  }, [startDate]);

  const requestingHoliday = countWorkingDays(startDate, endDate) || 0;

  const tooManyOnDemand =
    !!selectedHoliday &&
    selectedHoliday.key === EnumEmploymentType.On_demand &&
    (requestingHoliday > 1 || rightDemandDate) &&
    holiday.status === 'pending'
      ? requestingHoliday
      : null;

  if (!holiday) throw Error('Holiday not found');

  const rejectHoliday = async () => {
    try {
      await HolidayService.rejectHolidayById(holidayId, { reason: messageToRejectHolidayRequest });
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been rejected',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      setShowRejectModal(false);
      history.goBack();
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  const cancelHandler = () => {
    setStartDate(new Date(holiday.dateFrom));
    setEndDate(new Date(holiday.dateTo));
    setSelectedHoliday({
      key: leaveSelectedByEmployee?.key || '',
      value: leaveSelectedByEmployee?.value || '',
    });
  };

  const acceptHoliday = async () => {
    try {
      if (showButtons) {
        await HolidayService.updateHolidayById(holidayId, {
          ...holiday,
          dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
          dateTo: dayjs(endDate).format('YYYY-MM-DD'),
          holidayType: selectedHoliday.key || holiday.holidayType,
          additionalComment: additionalComment,
        });

        const redownloadHolidayAfterAccept = await HolidayService.fetchHolidayById(holidayId);
        setHoliday(redownloadHolidayAfterAccept);
      }

      await HolidayService.acceptHolidayById(holidayId);

      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: !selectedHoliday
          ? 'Holiday request has been edited!'
          : 'Holiday request has been accepted!',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });

      history.goBack();
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  const deleteHoliday = async () => {
    if (!holiday) throw Error('Holiday not found');

    try {
      await HolidayService.deleteHolidayById(holidayId);
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been deleted!',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      history.goBack();
    } catch (err) {
      handleError(err as ApiError);
    }
  };

  const showButtons =
    leaveSelectedByEmployee?.key !== selectedHoliday.key ||
    new Date(holiday.dateFrom).toDateString() !== startDate.toDateString() ||
    new Date(holiday.dateTo).toString() !== endDate.toString();

  const fullName = `${employee.lastName} ${employee.firstName}`;
  const formatStartDate = `${dayjs(holiday?.dateFrom).format('DD.MM.YYYY')}`;
  const formatEndDate = `${dayjs(holiday?.dateTo).format('DD.MM.YYYY')}`;

  const requestedDates =
    holiday?.dateFrom === holiday?.dateTo
      ? `${dayjs(holiday?.dateFrom).format('dddd, MMMM D')}`
      : `${dayjs(holiday?.dateFrom).format('dddd, MMMM D')} - ${dayjs(holiday?.dateTo).format(
          'dddd, MMMM D',
        )}` || '';

  return (
    <Container data-cy="holiday-manager-form">
      <Header
        leaveSelectedByEmployee={leaveSelectedByEmployee?.value}
        holiday={holiday}
        employeeFullName={fullName}
        employeePosition={employee.position}
      />

      {employeeHolidayType && (
        <Form
          holiday={holiday}
          leaveSelectedByEmployee={leaveSelectedByEmployee}
          employee={employee}
          employeeHolidayType={employeeHolidayType}
          startDate={startDate}
          endDate={endDate}
          handleStartDateSelect={setStartDate}
          handleEndDateSelect={setEndDate}
          selectedHoliday={selectedHoliday}
          setSelectedHoliday={setSelectedHoliday}
          additionalComment={additionalComment}
        />
      )}

      <HolidaySummary employee={employee} startDate={startDate} endDate={endDate} />

      {tooManyOnDemand && holiday.status === 'pending' && (
        <HolidaySummaryContainer>
          <MessagePosition>
            "On demand holiday" can only be taken for 1 day, for today or the next working day.
          </MessagePosition>
        </HolidaySummaryContainer>
      )}

      {!tooManyOnDemand && (
        <Buttons
          holiday={holiday}
          setShowRejectModal={() => setShowRejectModal(true)}
          setShowCancelModal={() => (buttons ? cancelHandler() : setShowCancelModal(true))}
          acceptHoliday={acceptHoliday}
          showButtons={!!showButtons}
          onDemandHolidays={!!(!tooManyOnDemand && !buttons)}
          makingChanges={buttons}
        />
      )}

      {showRejectModal && (
        <RejectHolidayModal
          isShowing={showRejectModal}
          subtitleWithProps={{
            fullName: fullName,
            startDate: formatStartDate,
            endDate: formatEndDate,
            action: 'reject',
          }}
          setMessage={(message) => setMessageToRejectHolidayRequest(message)}
          message={messageToRejectHolidayRequest}
          rightButtonAction={rejectHoliday}
          leftButtonAction={() => setShowRejectModal(false)}
          closeIcon={() => setShowRejectModal(false)}
        />
      )}

      {showCancelModal && (
        <CancelHolidayModal
          leftButtonAction={deleteHoliday}
          closeIcon={() => setShowCancelModal(false)}
          isShowing={showCancelModal}
          subtitleWithProps={{
            fullName: fullName,
            action: 'cancel',
            startDate: formatStartDate,
            endDate: formatEndDate,
            color: subtitleWithPropsByTheme,
          }}
          holidayType={leaveSelectedByEmployee}
          requestedDates={requestedDates}
          fullName={fullName}
          marginBottomEmpDetails="69px"
          additionalComment={additionalComment}
        />
      )}
    </Container>
  );
};
