import styled from 'styled-components';
import { Navbar } from 'components/Navbar';

export const Wrapper = styled.div`
  margin: 1rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-template-areas: 'Sidebar  Main';
  column-gap: 2rem;
`;

export const Main = styled.div`
  grid-area: Main;
  float: right;
  display: flex;
  flex-direction: column;
`;

export const StyledNavbar = styled(Navbar)`
  position: sticky;
  z-index: 1001;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
