export enum AvailableCalendarPath {
  TEAM_CALENDAR = 'team-calendar',
  CALENDAR = 'calendar',
  SINGLE_EMPLOYEE_YEAR_CALENDAR = 'singleEmployeeYearCalendar',
  HOLIDAY_FORM = '/team-calendar/',
}

export enum CalendarType {
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}
