import { useEffect } from 'react';
import { DismissWrapperProps } from './types';

export const DismissWrapper = ({ children, close }: DismissWrapperProps) => {
  useEffect(() => {
    const closeModalEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close && close();
      }
    };

    document.addEventListener('keydown', closeModalEsc);

    return () => {
      document.removeEventListener('keydown', closeModalEsc);
    };
  }, [close]);

  return <div>{children}</div>;
};
