import { Fragment } from 'react';
import { Button, SvgIconComponent } from 'components';
import { Type, Variant } from 'components/AlertComponent/types';
import Modal from 'components/Modal/Modal';
import Text from 'components/Text';
import { useThemeContext } from 'components/ThemeWrapper/ThemeWrapper';
import { useToastContext } from 'contexts';
import { remCalc } from 'helpers';
import { ApiError } from 'services/AuthService';
import EmployeesService, { AdditionalHolidaUpdateTypes } from 'services/EmployeesService';
import { colors } from 'styles/colors';
import {
  AdditionalHolidaysButtonsWrapper,
  AdditionalHolidaysContainer,
  AdditionalHolidaysIconWrapper,
  AdditionalHolidaysInfoWrapper,
  DividerModalBorder,
} from './styled';

interface Props {
  toggle: VoidFunction;
  isVisible: boolean;
  dataForUpdate: AdditionalHolidaUpdateTypes[];
  employeeFirstName: string;
  employeeLastName: string;
  employeeId: number;
}

export const AdditionalHolidayRemoveModal = ({
  toggle,
  isVisible,
  employeeFirstName,
  employeeLastName,
  dataForUpdate,
  employeeId,
}: Props) => {
  const { setToast, handleError } = useToastContext();
  const { getColorByTheme } = useThemeContext();

  const handleSaveAdditionalHolidays = async () => {
    const newUserData = dataForUpdate.map(({ localId, ...rest }) => rest);
    try {
      await EmployeesService.updateUserData(employeeId, {
        additionalHolidaysAttributes: newUserData,
      });
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Additional holiday successfully removed',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
      toggle();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      handleError(err as ApiError);
      toggle();
    }
  };

  const colorTitle = getColorByTheme(colors.gray[600], colors.gray[600]);
  const colorUserData = getColorByTheme(colors.gray[800], colors.gray[200]);

  return (
    <Modal
      label="Changing additional holidays"
      visible={isVisible}
      onClose={toggle}
      crossMarkIcon
      dataCY="preview-additional-holiday-modal"
      hideButton>
      <Text.Paragraphs style={{ marginBottom: 25, marginTop: 11 }} type="xSmall">
        Are you sure you want to remove additional holiday?
      </Text.Paragraphs>
      <AdditionalHolidaysInfoWrapper>
        <DividerModalBorder />
        <AdditionalHolidaysContainer>
          <Text.Paragraphs type="small" color={colorTitle} weight={700}>
            <AdditionalHolidaysIconWrapper>
              <SvgIconComponent icon={'UserGreyIcon'} width={12} height={12} />
            </AdditionalHolidaysIconWrapper>
            Employee
          </Text.Paragraphs>
          <Text.Paragraphs
            type="small"
            color={colorUserData}
            margin={`0 0 ${remCalc(24)}`}
            weight={700}>
            {employeeLastName} {employeeFirstName}
          </Text.Paragraphs>
          {dataForUpdate.map((item: AdditionalHolidaUpdateTypes) => {
            return (
              <Fragment key={`${item.id}-${item.localId}`}>
                <Text.Paragraphs type="small" color={colorTitle} weight={700}>
                  <AdditionalHolidaysIconWrapper>
                    <SvgIconComponent icon={'SuitcaseIcon'} width={12} height={12} />
                  </AdditionalHolidaysIconWrapper>
                  Remove Holiday type
                </Text.Paragraphs>
                <Text.Paragraphs
                  type="small"
                  color={colorUserData}
                  margin={`0 0 ${remCalc(24)}`}
                  weight={700}>
                  {item.name} - {item.days} days for {item.year} year
                </Text.Paragraphs>
              </Fragment>
            );
          })}
        </AdditionalHolidaysContainer>

        <DividerModalBorder />
      </AdditionalHolidaysInfoWrapper>
      <AdditionalHolidaysButtonsWrapper>
        <Button label="Don't remove" variant="basic" size="regular" onClick={toggle} />
        <Button
          label="Remove additional holidays"
          variant="strongFail"
          size="regular"
          onClick={handleSaveAdditionalHolidays}
        />
      </AdditionalHolidaysButtonsWrapper>
    </Modal>
  );
};
