import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHolidayCapacity } from '../../../hooks';
import { HolidayCapacitiesResponseType } from '../../../services/HolidayCapacitiesService';
import { SvgIconComponent } from '../../Icons';
import { ProgressBar } from '../../ProgressBar';
import { CapacityInfo, CapacitySubtitle, CapacityTitle, MainPercent, TextPercent } from './styled';
import { CapacityHolidayOnWeekProps } from './types';

export const CapacityHolidayOnWeek = ({ capacityParams }: CapacityHolidayOnWeekProps) => {
  const { capacity } = useHolidayCapacity({ capacityParams });
  const [holidayCapacitiesOnWeek, setHolidayCapacitiesOnWeek] =
    useState<HolidayCapacitiesResponseType>();

  useEffect(() => {
    setHolidayCapacitiesOnWeek(capacity);
  }, [capacity]);

  if (!holidayCapacitiesOnWeek) return null;

  return (
    <>
      <CapacityTitle>
        {`Capacity ${dayjs(holidayCapacitiesOnWeek.date_from).format('D')} - ${dayjs(
          holidayCapacitiesOnWeek.date_to,
        ).format('D')} ${dayjs(holidayCapacitiesOnWeek.date_to).format('MMMM')}`}
      </CapacityTitle>
      <MainPercent>
        {holidayCapacitiesOnWeek && (
          <>
            <CapacitySubtitle>Percentage on vacation this week</CapacitySubtitle>
            <CapacityInfo>
              <ProgressBar showValue={holidayCapacitiesOnWeek.occupancy_percentage} />
              <TextPercent value={holidayCapacitiesOnWeek.occupancy_percentage}>
                {`${holidayCapacitiesOnWeek.occupancy_percentage?.toFixed(0)}%`}
              </TextPercent>
              <SvgIconComponent icon="UserIcon" width={16} />
            </CapacityInfo>
          </>
        )}
      </MainPercent>
    </>
  );
};
