import { useCallback, useMemo, useState } from 'react';
import HolidaysService, { FetchHolidaysParams, TeamHoliday } from 'services/HolidaysService';
import { makeContext } from './ContextCreator';
import { useToastContext } from './ToastContext';

/** This type is just inversion of type TeamHoliday, in sense model hierarchy
 * TeamHoliday.User is now User.TeamHoliday[]
 * so instead of
 * teamHoliday = { ...holidayData, user } we hame user = { ...userData, holidays }
 */
export type ShortUserWithHolidays = TeamHoliday['user'] & {
  holidays: TeamHolidayWithoutUser[];
};

export type TeamHolidayWithoutUser = Omit<TeamHoliday, 'user'>;

function inverseHolidaysAndUsers(holidays: TeamHoliday[]): ShortUserWithHolidays[] {
  let users: TeamHoliday['user'][] = [];

  holidays.forEach((holiday) => {
    const userAlreadyAdded = users.find((user) => user.id === holiday.user.id);
    if (!userAlreadyAdded) {
      users.push(holiday.user);
    }
  });

  return users.map((user) => {
    const userHolidays = holidays.filter((holiday) => holiday.user.id === user.id);
    const narrowedHolidays = userHolidays.map(({ user: _user, ...holiday }) => holiday);

    return {
      ...user,
      holidays: narrowedHolidays,
    };
  });
}

export const [useTeamHolidaysContext, TeamHolidaysProvider] = makeContext(() => {
  const [teamHolidays, setTeamHolidays] = useState<TeamHoliday[]>([]);
  const [teamHolidaysLoading, setTeamHolidaysLoading] = useState<boolean>(false);
  const [smallCalendarTeamHolidays, setSmallCalendarTeamHolidays] = useState<TeamHoliday[]>([]);
  const { handleError } = useToastContext();

  const getTeamHolidays = useCallback(
    async (params?: FetchHolidaysParams) => {
      setTeamHolidaysLoading(true);
      const response = await HolidaysService.fetchTeamHolidays(params);
      const { data, error } = response;

      if (error) {
        return handleError(error);
      }

      setTeamHolidays(data);
      setTeamHolidaysLoading(false);
    },
    [handleError],
  );

  const getSmallCalendarTeamHolidays = useCallback(
    async (params?: FetchHolidaysParams) => {
      const response = await HolidaysService.fetchTeamHolidays(params);
      const { data, error } = response;

      if (error) {
        return handleError(error);
      }

      setSmallCalendarTeamHolidays(data);
    },
    [handleError],
  );

  const usersWithHolidays: ShortUserWithHolidays[] = useMemo(
    () => inverseHolidaysAndUsers(teamHolidays),
    [teamHolidays],
  );

  return {
    teamHolidays,
    teamHolidaysLoading,
    smallCalendarTeamHolidays,
    getTeamHolidays,
    getSmallCalendarTeamHolidays,
    usersWithHolidays,
  };
});
