import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
`;

export const Background = styled.div<{ heightBar: number }>`
  position: absolute;
  width: 100%;
  background: ${colors.gray[200]};
  border-radius: 50px;
  z-index: 0;

  ${({ heightBar }) => css`
    height: ${heightBar}px;
  `}
`;

export const Progress = styled.div<{
  showValue: number;
  maxValue: number;
  colorBar: string;
  colorMinBar: string;
  pointToChangeColorValue: number;
  heightBar: number;
}>`
  position: absolute;
  height: 0.75rem;
  border-radius: 50px;
  z-index: 10;
  background: ${({ showValue, pointToChangeColorValue, colorBar, colorMinBar }) => {
    return showValue <= pointToChangeColorValue ? `${colorBar}` : `${colorMinBar}`;
  }};

  ${({ showValue, maxValue, heightBar }) =>
    css`
      width: ${(showValue * 100) / maxValue}%;
      height: ${heightBar}px;
    `};
`;
