import { useState } from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { HolidayStatuses } from '../services/HolidaysService';
import { colors } from '../styles/colors';
import { makeContext } from './ContextCreator';
import { updateRequestHeader } from './CurrentYearContext';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
const getCurrentDate = () => dayjs().startOf('day');

export const [useCalendarContext, CalendarProvider] = makeContext(() => {
  const [date, setDate] = useState(getCurrentDate());

  const monthIndex = date.month();
  const weekIndex = date.week();

  const increaseWeekIndex = () => setDate((prevDate) => prevDate.add(7, 'days'));
  const decreaseWeekIndex = () => setDate((prevDate) => prevDate.subtract(7, 'days'));

  const handleIncreaseDate = (value: string) => {
    setDate((prevDate) => {
      const newDate = prevDate.add(1, value);
      updateRequestHeader(newDate.year().toString());
      return newDate;
    });
  };

  const handleDecreaseDate = (value: string) => {
    setDate((prevDate) => {
      const newDate = prevDate.subtract(1, value);
      updateRequestHeader(newDate.year().toString());
      return newDate;
    });
  };

  const increaseMonthIndex = () => handleIncreaseDate('month');
  const decreaseMonthIndex = () => handleDecreaseDate('month');

  const increaseYearIndex = () => handleIncreaseDate('year');
  const decreaseYearIndex = () => handleDecreaseDate('year');

  const element = document.getElementById('isTodayOnLangeCalendar');

  const handleReset = () => {
    element && element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    setDate(() => {
      const newDate = getCurrentDate();
      updateRequestHeader(newDate.year().toString());
      return newDate;
    });
  };

  const firstDayOfWeek = date.weekday(1);
  const lastDayOfWeek = date.weekday(7);
  const currentYear = date.year();

  const getHolidayColorByType = (holidayType: string | undefined): string => {
    switch (holidayType) {
      case 'vacation':
      case 'holiday':
      case 'kid':
      case 'occasional':
      case 'overdue':
      case 'increase':
        return colors.dark.tertiary;
      case 'sick':
        return colors.warning;
      case 'on_demand':
        return colors.primary;
      case 'maternity':
      case 'faternity':
      case 'paternity':
      case 'parental':
        return colors.dark.fail;
      default:
        return 'transparent';
    }
  };

  const getHolidayColorByStatus = (holidayStatus: HolidayStatuses): string => {
    switch (holidayStatus) {
      case HolidayStatuses.Pending:
        return colors.light.tertiary;
      case HolidayStatuses.Declined:
        return colors.dark.fail;
      default:
        return 'transparent';
    }
  };

  return {
    date,
    monthIndex,
    currentYear,
    increaseMonthIndex,
    decreaseMonthIndex,
    weekIndex,
    increaseWeekIndex,
    decreaseWeekIndex,
    increaseYearIndex,
    decreaseYearIndex,
    handleReset,
    firstDayOfWeek,
    lastDayOfWeek,
    setDate,
    getHolidayColorByType,
    getHolidayColorByStatus,
  };
});
