import styled, { css } from 'styled-components';
import Text from 'components/Text';
import { HolidayStatuses, HolidayType } from 'services/HolidaysService';
import { borderRadius } from 'styles/variables';

export const UserRowWrapper = styled.div`
  width: 100%;
  min-height: 80px;

  display: flex;
  flex-direction: row;

  border: 1px solid ${({ theme }) => theme.weekCalendar.user.border};

  &:not(:last-child) {
    border-bottom: none;
  }
`;

export const UserInfoWrapper = styled.div`
  flex: 1;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 4fr 1fr;
  background-color: ${({ theme }) => theme.weekCalendar.user.background};
  position: relative;
  min-width: 19rem;
  border-color: ${({ theme }) => theme.weekCalendar.user.background};
`;

export const AvatarWrapper = styled.div`
  margin: auto 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  img {
    object-fit: cover;
  }
`;

export const TextInfoWrapper = styled.div`
  margin: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

export const SubInfoWrapper = styled(Text.Paragraphs)`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

export const UserGridWrapper = styled.div`
  position: relative;
  flex: 4;

  display: grid;
  grid-template-columns: repeat(7, minmax(5rem, 1fr));

  align-items: center;
`;

export const ActionArea = styled.div<{ notDisplay: boolean; numberColumn: number }>`
  grid-row: 1;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  background-color: ${({ theme }) => theme.backgroundElement};
  border: 1px solid ${({ theme }) => theme.smallCalendar.border};

  cursor: pointer;

  ${({ numberColumn }) =>
    numberColumn &&
    css`
      grid-column: ${numberColumn};
    `}

  ${({ notDisplay }) =>
    notDisplay &&
    css`
      z-index: -10;
    `}
`;

export const EmojiIconWrapper = styled.div`
  margin: 10px;
`;

export const TextWrapper = styled.div`
  margin-right: auto;

  display: flex;
  flex-direction: column;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 1rem;
`;

export const DecisionIconsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: 100%;
  padding: 0 0.5em;

  z-index: 100;

  background-color: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 100%;

  opacity: 0;
  transition: 200ms ease all;
`;

export const DecisionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5em;

  cursor: pointer;
  user-select: none;
`;

export const OnClickContainer = styled.div`
  z-index: 10;

  cursor: pointer;
  user-select: none;

  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

interface VacationPanelWrapperProps {
  dayIndex: number;
  duration: number;
  type: HolidayType['key'];
  status: HolidayStatuses;
  startsBeforeCurrentWeek: boolean;
  endsAfterCurrentWeek: boolean;
  weekCalendar: boolean;
  moreHolidayTypesInTheSameDay: boolean;
  vacationBorderColor: string;
  vacationTheme: string;
}

const getPositionHolidayBarsInWeekCalendar = (weekCalendar: boolean, status: HolidayStatuses) => {
  switch (weekCalendar && status) {
    case HolidayStatuses.Pending:
    case HolidayStatuses.Accepted:
      return 'height: 55%; margin-bottom: 16px;';
    case HolidayStatuses.Declined:
      return 'height: 55%; margin-top: 16px;';
  }
};

export const VacationPanelWrapper = styled.div<VacationPanelWrapperProps>`
  grid-row: 1;
  position: relative;
  height: 50px;
  margin: 5px 20px;
  padding-left: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  user-select: none;

  border-bottom-right-radius: 0;

  ${({ vacationTheme }) => vacationTheme};

  grid-column: ${({ dayIndex, duration }: VacationPanelWrapperProps) =>
    `${dayIndex} / span ${duration}`};

  border-radius: ${borderRadius.xs};

  ${({ endsAfterCurrentWeek }) =>
    endsAfterCurrentWeek &&
    'border-top-right-radius: 0; border-bottom-right-radius: 0; border-right: none;'};

  ${({ startsBeforeCurrentWeek }) =>
    startsBeforeCurrentWeek &&
    'border-top-left-radius: 0; border-bottom-left-radius: 0; border-left: none;'};

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;

    ${({ startsBeforeCurrentWeek }) => !startsBeforeCurrentWeek && 'content: ""'};

    background-color: ${({ vacationBorderColor }) => vacationBorderColor};
    border-radius: ${borderRadius.xs};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover > ${DecisionIconsWrapper} {
    opacity: 1;
    border-radius: ${borderRadius.lg};
  }

  ${({ startsBeforeCurrentWeek }) => startsBeforeCurrentWeek && 'margin-left: 0'};
  ${({ endsAfterCurrentWeek }) => endsAfterCurrentWeek && 'margin-right: 0'};
  ${({ weekCalendar, status, moreHolidayTypesInTheSameDay }) =>
    weekCalendar &&
    status &&
    moreHolidayTypesInTheSameDay &&
    getPositionHolidayBarsInWeekCalendar(weekCalendar, status)}
`;
