import { DepartmentItem } from 'components/DepartmentItem';
import { Everyone } from 'const';
import { useEmployeeContext } from 'contexts/EmployeesContext';
import { Container, Wrap } from './styled';
import { DepartmentTileProps } from './types';

export const DepartmentTile = ({
  departments,
  className,
  activeDepartmentId,
  isChild,
}: DepartmentTileProps) => {
  const { selectDepartment, selectedDepartment } = useEmployeeContext();
  const deps = [Everyone, ...departments];

  return (
    <Container className={className}>
      <Wrap>
        {deps
          .filter(({ parentId }) => parentId === null)
          .map((department) => {
            const hasChildren = !!departments.find((d) => department.id === d.parentId);

            return (
              <DepartmentItem
                key={department.name}
                isChild={isChild}
                activeDepartmentId={activeDepartmentId}
                selectedDepartment={selectedDepartment}
                departments={departments}
                item={department}
                hasChildren={hasChildren}
                setActiveDepartment={selectDepartment}
              />
            );
          })}
      </Wrap>
    </Container>
  );
};
