import { Dayjs } from 'dayjs';
import { TeamHolidayWithoutUser } from 'contexts/TeamHolidaysContext';
import { dateOverlapsHoliday } from 'utils';

export const calculateHolidaysPerDay = (
  holidays: TeamHolidayWithoutUser[],
  days: Dayjs[],
): number[] => {
  return [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
    const day = days[dayIndex];
    const holidaysOverlappingCurrentDay = holidays.filter((holiday) =>
      dateOverlapsHoliday(day, holiday),
    );

    return holidaysOverlappingCurrentDay.length;
  });
};
