import { SvgIconComponent } from 'components/Icons';
import Text from 'components/Text';
import { colors } from 'styles/colors';
import { useThemeContext } from '../../ThemeWrapper/ThemeWrapper';
import { Row } from '../styled';

interface EmployeeDetailsProps {
  holidayTypeValue?: string;
  requestedDates: string;
  fullName?: string;
  marginBottomEmpDetails?: string;
  additionalComment?: string | null;
}

export const EmployeeDetails = ({
  holidayTypeValue,
  requestedDates,
  fullName,
  marginBottomEmpDetails,
  additionalComment,
}: EmployeeDetailsProps) => {
  const { getColorByTheme } = useThemeContext();

  const lightGrayParagraph = getColorByTheme(colors.gray[600], colors.gray[500]);
  const darkGrayParagraph = getColorByTheme(colors.gray[800], colors.gray[200]);

  const additionalCommentAfterTrim = additionalComment?.trim();

  const showAdditionalComment =
    !!additionalCommentAfterTrim &&
    additionalCommentAfterTrim !== 'null' &&
    additionalCommentAfterTrim !== null &&
    additionalCommentAfterTrim.length > 0;

  return (
    <>
      {fullName && (
        <>
          <Row style={{ marginTop: 48, marginBottom: 10 }}>
            <SvgIconComponent height={14} icon="UserIcon" color={lightGrayParagraph} />
            <Text.Paragraphs style={{ marginLeft: 11 }} type="caption" color={lightGrayParagraph}>
              Employee
            </Text.Paragraphs>
          </Row>
          <Text.Paragraphs style={{ marginLeft: 2 }} type="caption" color={darkGrayParagraph}>
            {fullName}
          </Text.Paragraphs>
        </>
      )}

      <Row style={{ marginTop: 27, marginBottom: 10 }}>
        <SvgIconComponent color={'rgb(135, 145, 157)'} height={14} icon="SuitcaseIcon" />
        <Text.Paragraphs style={{ marginLeft: 11 }} type="caption" color={lightGrayParagraph}>
          Holiday Type
        </Text.Paragraphs>
      </Row>

      <Text.Paragraphs style={{ marginLeft: 2 }} type="caption" color={darkGrayParagraph}>
        {holidayTypeValue}
      </Text.Paragraphs>

      <Row style={{ marginTop: 27, marginBottom: 10 }}>
        <SvgIconComponent color={'rgb(135, 145, 157)'} height={14} icon="RequestedDateIcon" />
        <Text.Paragraphs style={{ marginLeft: 11 }} type="caption" color={lightGrayParagraph}>
          Requested dates
        </Text.Paragraphs>
      </Row>

      <Text.Paragraphs
        style={{ marginLeft: 2, marginBottom: `${marginBottomEmpDetails}` }}
        type="caption"
        color={darkGrayParagraph}>
        {requestedDates}
      </Text.Paragraphs>

      {showAdditionalComment && (
        <>
          <Row style={{ marginTop: 27, marginBottom: 10 }}>
            <SvgIconComponent color={'rgb(135, 145, 157)'} height={14} icon="InfoIcon" />
            <Text.Paragraphs style={{ marginLeft: 10 }} type="caption" color={lightGrayParagraph}>
              Additional comment
            </Text.Paragraphs>
          </Row>

          <Text.Paragraphs
            style={{ marginLeft: 2, marginBottom: `${marginBottomEmpDetails}` }}
            type="caption"
            color={darkGrayParagraph}>
            {additionalCommentAfterTrim}
          </Text.Paragraphs>
        </>
      )}
    </>
  );
};
