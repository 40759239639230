// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import axios from 'axios';
import ApiBaseService, { PaginationMeta } from './ApiBaseService';
import { TeamHoliday } from './HolidaysService';

export interface FetchDashboardParams {
  by_status: string;
  query?: string;
  page?: number;
  per?: number;
}

export interface FetchDashboardResponse {
  data: TeamHoliday[];
  meta: PaginationMeta;
}

export default class DashboardService extends ApiBaseService {
  static readonly API_URL = `${this.API_BASE_URL}/dashboard`;

  static fetchDashboardService = async (params?: FetchDashboardParams) => {
    const { data } = await axios.get<FetchDashboardResponse>(`${this.API_URL}/holiday_requests/`, {
      params,
    });
    return data;
  };
}
