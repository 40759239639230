export const colors = {
  primary: '#FFB500',
  secondary: '#E6E6E6',
  tertiary: '#57ABFF',
  success: '#66CC91',
  fail: '#EB5757',
  strongFail: '#D72638',
  warning: '#FF9505',
  background: '#202327',

  black: '#000000',
  white: '#ffffff',

  boxShadowColor: 'rgba(46, 51, 56, 0.08)',
  boxShadowModalColor: 'rgba(12, 26, 75, 0.08)',
  borderButton: '#e5e8ec',

  modalAlert: '#D72638',
  modalColor: '#f6f7f9d1',
  modalColorFirefox: '#f6f7f9',

  modalBackgroundLight: 'rgba(246, 247, 249, 0.82)',
  modalBorderLight: 'rgb(237, 239, 241)',

  modalBackgroundDark: 'rgba(55, 60, 65, 0.82)',
  modalBorderDark: 'rgb(46, 51, 56)',

  toastBorderLigth: 'rgba(0, 0, 0, 0.005)',
  toastBorderDark: 'rgba(255, 255, 255, 0.005)',

  shadowToast: {
    light: {
      firstValue: 'rgba(12, 26, 75, 0.24)',
      secondValue: 'rgba(50, 50, 71, 0.05)',
    },
    dark: {
      firstValue: 'rgba(255, 255, 255, 0.5)',
      secondValue: 'rgba(255, 255, 255, 0.5)',
    },
  },
  light: {
    primary: '#FFF0CC',
    secondary: '#F6F7F9',
    tertiary: '#D0E9FB',
    success: '#B2E6C2',
    fail: '#E69898',
    warning: '#FFDDAD',
    black: '#808080',
  },

  dark: {
    primary: '#E09900',
    secondary: '#C4CBD4',
    tertiary: '#258CF3',
    success: '#219653',
    fail: '#D72638',
    warning: '#E08300',
    black: '#000000',
  },

  gray: {
    '50': '#F6F7F9',
    '100': '#F2F4F7',
    '200': '#EDEFF1',
    '300': '#DDE0E4',
    '400': '#C6CBD2',
    '500': '#ABB2BA',
    '600': '#87919D',
    '700': '#505862',
    '800': '#2E3338',
    '900': '#17191C',
  },

  surface: {
    primary: '#FFFFFF',
    secondary: '#F6F7F9',
    light: '#E6E6E6',
    dark: '#17191C',
  },

  brandColors: {
    bootstrap: '#7952B3',
    facebook: '#3B5999',
    twitter: '#1DA1F2',
    pinterest: '#BD081C',
    youtube: '#CD201F',
    dribbble: '#EA4C89',
    github: '#222222',
    reddit: '#FF4301',
  },

  chart: {
    available: '#d2d3d4',
    requested: '#FF9505',
    used: '#258CF3',
  },
};
