import { SingleDatePicker } from 'components/Inputs/DatePicker/SingleDatePicker';
import { Switch } from 'components/Switches/Switch/Switch';
import Text from '../../../Text';
import { Wrapper } from './styled';
import { KidsFormProps } from './types';

export const KidsForm = ({
  label,
  initialDate,
  onChange,
  changeSwitch,
  isTurnedOn,
}: KidsFormProps) => {
  return (
    <>
      <Wrapper>
        <Text.Heading type="5">{label}</Text.Heading>
        <Switch value={isTurnedOn} onChange={changeSwitch} />
      </Wrapper>
      {isTurnedOn && (
        <SingleDatePicker
          label="Date of birth"
          onChange={onChange}
          initialDate={initialDate}
          dataCY="kids-date-picker"
        />
      )}
    </>
  );
};
