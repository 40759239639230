import { FC } from 'react';
import ReactDOM from 'react-dom';
import { SvgIconComponent } from 'components/Icons';
import { EnumEmploymentType } from 'services/HolidaysService';
import { DismissWrapper } from './DismissWrapper/DismissWrapper';
import {
  ButtonRequest,
  ModalHeader,
  ModalIconButton,
  ModalContainer,
  ModalOverlay,
  ModalWrapper,
  WrapLabel,
} from './styled';
import { ModalProps } from './types';

const Modal: FC<ModalProps> = ({
  visible,
  onClose,
  buttonProps = {
    label: 'Request Holiday',
    size: 'regular',
    variant: 'tertiary',
  },
  disabled,
  additionalButtonProps,
  trashIcon,
  crossMarkIcon,
  children,
  label,
  editIcon,
  onEdit,
  onDelete,
  onClickButton,
  onClickAdditionalButton,
  dataCY,
  hideButton,
  holidayType,
  startDate,
  endDate,
}) => {
  if (!visible) {
    return null;
  }

  const typeAreaWithoutDisableButton = [
    EnumEmploymentType.Holiday,
    EnumEmploymentType.Sick,
    EnumEmploymentType.Occasional,
  ].toString();

  if (
    holidayType &&
    typeAreaWithoutDisableButton.includes(holidayType.key) &&
    !!startDate &&
    !!endDate
  ) {
    disabled = false;
  }

  return ReactDOM.createPortal(
    <DismissWrapper close={onClose}>
      <ModalWrapper aria-modal tabIndex={-1} role="dialog" data-cy={dataCY}>
        <ModalOverlay onClick={onClose} />
        <ModalContainer>
          <ModalHeader>
            <WrapLabel type="4">{label}</WrapLabel>
            <div style={{ display: 'flex' }}>
              {holidayType?.key !== EnumEmploymentType.Sick && editIcon && (
                <ModalIconButton onClick={onEdit} data-cy="modal-edit-button">
                  <SvgIconComponent width={16} icon={'PenIcon'} />
                </ModalIconButton>
              )}
              {holidayType?.key !== EnumEmploymentType.Sick && trashIcon && (
                <ModalIconButton onClick={onDelete} data-cy="modal-delete-button">
                  <SvgIconComponent width={17} icon={'TrashIcon'} />
                </ModalIconButton>
              )}
              {crossMarkIcon && (
                <ModalIconButton onClick={onClose} data-cy="modal-close-button">
                  <SvgIconComponent width={14} icon={'CrossMarkIcon'} />
                </ModalIconButton>
              )}
            </div>
          </ModalHeader>
          {children}
          {!hideButton && buttonProps && (
            <ButtonRequest
              disabled={disabled}
              onClick={onClickButton}
              dataCY="modal-action-button"
              {...buttonProps}
            />
          )}
          {additionalButtonProps && (
            <ButtonRequest
              onClick={onClickAdditionalButton}
              dataCY="modal-additional-button"
              {...additionalButtonProps}
            />
          )}
        </ModalContainer>
      </ModalWrapper>
    </DismissWrapper>,
    document.body,
  );
};

export default Modal;
