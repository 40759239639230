import dayjs from 'dayjs';

interface OnDemandIsPossibilityProps {
  statusIsOnDemand: boolean;
  startDate: Date | undefined;
}

export const onDemandIsPossibility = ({
  statusIsOnDemand,
  startDate,
}: OnDemandIsPossibilityProps) => {
  const today = dayjs();

  const tomorrow = dayjs().add(1, 'day');

  const checkToday =
    today.day() === dayjs(startDate).day() &&
    today.week() === dayjs(startDate).week() &&
    today.month() === dayjs(startDate).month() &&
    today.year() === dayjs(startDate).year();

  const checkTomorow =
    tomorrow.day() === dayjs(startDate).day() &&
    tomorrow.week() === dayjs(startDate).week() &&
    tomorrow.month() === dayjs(startDate).month() &&
    tomorrow.year() === dayjs(startDate).year();

  return statusIsOnDemand && ![checkToday, checkTomorow].includes(true);
};
