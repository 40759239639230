import axios from 'axios';
import { getAuth } from 'firebase/auth';
import Cookies from 'js-cookie';
import { CONFIG } from 'const/config';
import { firebaseApp } from 'firebaseApp';
import { routes } from 'routes';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { UserResponse } from './AccountService';
import ApiBaseService from './ApiBaseService';

export enum Access {
  Manager = 'manager',
  Employee = 'employee',
  Admin = 'admin',
}

export interface ApiError {
  data: {
    error: string;
  };
  status: number;
  statusText: string;
}

const auth = getAuth(firebaseApp);

axios.interceptors.response.use(
  (response) => response,
  (error: { response: ApiError }) => {
    if (error.response.status === 401) {
      Cookies.remove(CONFIG.SSO_COOKIE_KEY, {
        path: '/',
        domain: CONFIG.SSO_COOKIE_DOMAIN,
      });
      auth.signOut();
      !window.location.pathname.includes(routes.login) && window.location.reload();
    }
    console.error(error.response.data.error);
    return Promise.reject(error.response);
  },
);

axios.defaults.headers.common = { Authorization: Cookies.get(CONFIG.SSO_COOKIE_KEY) || '' };

export default class AuthService extends ApiBaseService {
  static login = async (idToken: string, accessToken: string, refreshToken: string) => {
    const { data } = await axios.post<{ data: UserResponse }>(`${this.API_BASE_URL}/login`, {
      idToken: idToken,
      accessToken: accessToken,
      refreshToken: refreshToken,
    });

    AuthService.setTokenInCookies(accessToken);
    return data.data;
  };

  static setAxiosToken = (token: string) => {
    axios.defaults.headers.common.Authorization = token;
  };

  private static setTokenInCookies = (token: string) => {
    this.setAxiosToken(token);
  };

  static logout = () => {
    return axios.delete(`${this.API_BASE_URL}/logout`);
  };
}
