import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Spinner } from 'components/Spinner';
import { useHolidaysContext } from 'contexts/HolidaysContext';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';
import { useToastContext } from 'contexts/ToastContext';
import { FetchHolidaysParams, TeamHoliday } from 'services/HolidaysService';
import {
  filterHolidaysBySearchPhrase,
  filterHolidaysByStatuses,
  filterHolidaysByTypes,
  filterHolidaysByDepartment,
  filterHolidaysByEmploymentTypes,
} from 'utils';
import { MonthWrapper, WrapSpinner } from './styled';
import { MonthProps } from './types';
import { Week } from './Week';

export const MonthCalendar = ({
  month,
  teamCalendar = false,
  departmentId,
  searchPhrase,
  holidayTypes,
  employmentType,
  holidayStatuses,
  loadingHolidayTypes,
}: MonthProps) => {
  const { holidaysLoading, getUserHolidays } = useHolidaysContext();
  const { teamHolidaysLoading, teamHolidays, getTeamHolidays } = useTeamHolidaysContext();
  const { dataToast } = useToastContext();

  useEffect(() => {
    const dateFrom = month[0][0].format('YYYY-MM-DD') as `${number}-${number}-${number}`;
    const lastWeek = month[month.length - 1];
    const dateTo = lastWeek[lastWeek.length - 1].format(
      'YYYY-MM-DD',
    ) as `${number}-${number}-${number}`;

    const params: FetchHolidaysParams = { 'date[from]': dateFrom, 'date[to]': dateTo };

    if (teamCalendar && month) {
      getTeamHolidays(params);
    }

    if (!teamCalendar && month) {
      getUserHolidays(params);
    }
  }, [getTeamHolidays, month, teamCalendar, dataToast, getUserHolidays]);

  const filterHolidays = (holiday: TeamHoliday) => {
    const byDepartment = departmentId ? filterHolidaysByDepartment(holiday, departmentId) : true;
    const byPhrase = searchPhrase ? filterHolidaysBySearchPhrase(holiday, searchPhrase) : true;
    const byTypes = holidayTypes ? filterHolidaysByTypes(holiday, holidayTypes) : true;
    const byStatuses = holidayStatuses ? filterHolidaysByStatuses(holiday, holidayStatuses) : true;
    const byEmploymentType = employmentType
      ? filterHolidaysByEmploymentTypes(holiday, employmentType)
      : true;

    if (!byStatuses || !byTypes) {
      return byDepartment && byPhrase && byTypes && byStatuses;
    } else {
      return byDepartment && byEmploymentType && byPhrase && byTypes && byStatuses;
    }
  };

  if (holidaysLoading || teamHolidaysLoading || loadingHolidayTypes) {
    return (
      <WrapSpinner>
        <Spinner />
      </WrapSpinner>
    );
  }

  const filteredHolidays = teamHolidays.filter(filterHolidays);

  return (
    <MonthWrapper weeksCount={month.length} data-cy="month-calendar">
      {!holidaysLoading && !teamHolidaysLoading && (
        <>
          {month.map((week, i) => {
            const holidaysCurrentWeek = filteredHolidays.filter(
              (holiday) => dayjs(holiday.dateFrom) <= dayjs(holiday.dateTo),
            );

            return (
              <Week
                week={week}
                weekIdx={i}
                teamCalendar={teamCalendar}
                key={i}
                weekTeamHolidays={holidaysCurrentWeek}
                filterTeamHolidays={filterHolidays}
              />
            );
          })}
        </>
      )}
    </MonthWrapper>
  );
};
