import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Type, Variant } from 'components/AlertComponent/types';
import { HolidaySummaryRow } from 'components/HolidaySummary/HolidaySummaryRow';
import { RangeDatePicker } from 'components/Inputs/DatePicker/RangeDatePicker';
import Modal from 'components/Modal/Modal';
import { BalanceComponent } from 'components/Modals/BalanceComponent';
import { Select } from 'components/Select';
import { WrapperForSelectComponents } from 'components/Select/styled';
import { useToastContext } from 'contexts/ToastContext';
import { useUserContext } from 'contexts/UserContext';
import { countWorkingDays } from 'helpers/CountWorkoutDays';
import AccountService from 'services/AccountService';
import { ApiError } from 'services/AuthService';
import EmployeesService, { Employee } from 'services/EmployeesService';
import HolidayService from 'services/HolidayService';
import { EnumEmploymentType, HolidayStatuses, HolidayType } from 'services/HolidaysService';
import { actionCreators } from 'store';
import { RootReducer } from 'store/reducers';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { HolidayTypesEnums } from 'types';
import Text from '../Text/index';
import { onDemandIsPossibility } from './helpers';
import { EditModalProps } from './types';

const AdditionalInfoOnHolidayRequest = styled.textarea`
  width: 21.375rem;
  height: 3rem;
  padding: 1em 1em;
  resize: none;
  outline: none;
  border-radius: ${borderRadius.xs};
  ${typography.paragraphs.xSmall};
  color: ${({ theme }) => theme.holidayForm.messegaOnRejectModal};
  border: 1px solid ${({ theme }) => theme.input.border};
  background-color: ${({ theme }) => theme.input.background};
  opacity: 0.82;

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }
`;

const AdditionalInfoTitle = styled.div`
  color: ${({ theme }) => theme.input.label};
  margin-left: 0;
  margin-bottom: 0.5rem;
`;

export const EditHolidayModal = ({
  close,
  onTrashPress,
  isVisible,
  editStartDate,
  editEndDate,
  holiday,
  currentHolidayType,
  displayUserName,
  employeeId,
  additionalComment,
}: EditModalProps) => {
  const dispatch = useDispatch();
  const { getEmploymentTypesRequest } = bindActionCreators(actionCreators, dispatch);
  const employmentTypesStore = useSelector((state: RootReducer) => state.employmentTypes);
  const [startDate, handleStartDateSelect] = useState<Date | null>(editStartDate);
  const todayDate = new Date().setHours(0, 0, 0, 0);
  const [rightDemandDate, setRightDemandDate] = useState(true);
  const [endDate, handleEndDateSelect] = useState<Date | null>(editEndDate);

  const [newAdditionalComment, setNewAdditionalComment] = useState<string>(additionalComment || '');

  const { isManager } = useUserContext();
  const { fetchAuthenticatedUser, getUser } = useUserContext();
  const { handleError, setToast } = useToastContext();

  const { data: employee, refetch } = useQuery<Employee>('single_employee', () => {
    return EmployeesService.fetchEmployeeById(employeeId || getUser().id);
  });

  useEffect(() => {
    if (!employmentTypesStore.listData.length) {
      getEmploymentTypesRequest();
    }
  }, []);

  useEffect(() => {
    if (startDate) {
      const dateCompare = startDate.setHours(0, 0, 0, 0) - todayDate;
      setRightDemandDate(dateCompare >= 172800000 || dateCompare < 0 ? true : false);
    }
  }, [startDate]);

  const holidayTypes = employee
    ? employmentTypesStore.listData?.find(
        (empType) => empType.value === employee.employmentType?.value,
      )?.holiday_types || []
    : [];

  const individualHolidayTypes =
    employee?.kidDateOfBirth === null
      ? holidayTypes.filter((holType) => holType.key !== HolidayTypesEnums.Kid)
      : holidayTypes;

  const initialHolidayType =
    typeof currentHolidayType == 'string'
      ? holidayTypes.find((s) => s.key === currentHolidayType)
      : currentHolidayType;

  const [selectedHolidayTypes, setSelectedHolidayTypes] = useState<HolidayType | undefined>(
    initialHolidayType,
  );

  useEffect(() => {
    refetch();
  }, [selectedHolidayTypes, startDate, endDate, employeeId]);

  const onSubmit = async () => {
    try {
      if (!selectedHolidayTypes) {
        return;
      }
      if (isManager) {
        await HolidayService.updateHolidayById(holiday.id, {
          dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
          dateTo: dayjs(endDate).format('YYYY-MM-DD'),
          holidayType: selectedHolidayTypes.key!,
          additionalComment: newAdditionalComment.trim(),
        });
      } else {
        await AccountService.upDateCurrentUserHolidayRequest(holiday.id, {
          dateFrom: dayjs(startDate).format('YYYY-MM-DD'),
          dateTo: dayjs(endDate).format('YYYY-MM-DD'),
          holidayType: selectedHolidayTypes.key!,
          additionalComment: newAdditionalComment.trim(),
        });
      }
      fetchAuthenticatedUser();
      close();
      setToast({
        variant: Variant.success,
        type: Type.outline,
        label: 'Holiday request has been changed.',
        iconInfo: 'CheckIcon',
        actionIcon: 'CrossMarkIcon',
      });
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  // Balance
  const requestingHoliday = countWorkingDays(startDate, endDate) || 0;
  const oryginalRequestedDays = countWorkingDays(editStartDate, editEndDate) || 0;
  const diffDays = requestingHoliday - oryginalRequestedDays;
  const correctDates = startDate && endDate && startDate > endDate;

  const tooManyOnDemand =
    !!selectedHolidayTypes &&
    selectedHolidayTypes.key === EnumEmploymentType.On_demand &&
    (requestingHoliday > 1 || rightDemandDate)
      ? requestingHoliday
      : null;

  const forUseOnDemand = employee?.holiday?.onDemand || 0;

  const forUseOnDemandDynamic =
    employee?.holiday?.onDemand === employee?.holiday?.onDemandTotal
      ? forUseOnDemand
      : forUseOnDemand + diffDays;

  const forUseLeftDynamic =
    (employee?.holiday?.total || 0) -
    (employee?.holiday?.used || 0) -
    (employee?.holiday?.requested || 0) -
    diffDays;

  const idOnDemand =
    selectedHolidayTypes && selectedHolidayTypes?.key === EnumEmploymentType.On_demand;

  const label =
    selectedHolidayTypes?.key === HolidayTypesEnums.Sick
      ? `Edit Sick ${displayUserName ? `- ${displayUserName}` : ''}`
      : `Edit ${displayUserName ? `- ${displayUserName}` : ''}`;

  const disabledButton =
    !selectedHolidayTypes ||
    !endDate ||
    !startDate ||
    correctDates ||
    forUseLeftDynamic < 0 ||
    (!!tooManyOnDemand && tooManyOnDemand > 1) ||
    onDemandIsPossibility({
      statusIsOnDemand: !!idOnDemand,
      startDate: startDate,
    }) ||
    (idOnDemand && employee?.holiday?.onDemand === employee?.holiday?.onDemandTotal);

  return (
    <Modal
      label={label}
      visible={isVisible}
      onClose={close}
      onClickButton={onSubmit}
      disabled={disabledButton}
      buttonProps={{ label: 'Apply', variant: 'tertiary' }}
      crossMarkIcon
      trashIcon
      onDelete={onTrashPress}
      dataCY="edit-holiday-modal">
      <WrapperForSelectComponents gridRowGap={'1.5rem'} margin={'1rem 0'}>
        <Select
          label="Holiday type"
          placeholder="choose"
          options={individualHolidayTypes}
          selectedOption={selectedHolidayTypes || initialHolidayType}
          onOptionClicked={setSelectedHolidayTypes}
          labelSelector={(holidType) => holidType.value}
          dataCY="edit-holiday-modal-holiday-type-select"
          disabled={holiday.status === HolidayStatuses.Accepted ? true : false}
        />
        <RangeDatePicker
          label="Holiday Time"
          startDate={startDate}
          endDate={endDate}
          setStartDateSelect={handleStartDateSelect}
          setEndDateSelect={handleEndDateSelect}
          minDate={holiday.status === HolidayStatuses.Accepted ? new Date() : undefined}
          dataCY="edit-holiday-modal-holiday-time-datepicker"
        />

        {(selectedHolidayTypes?.key === HolidayTypesEnums.Sick ||
          selectedHolidayTypes?.key === HolidayTypesEnums.Holiday ||
          selectedHolidayTypes?.key === HolidayTypesEnums.Kid ||
          selectedHolidayTypes?.key === HolidayTypesEnums.Occasional) && (
          <HolidaySummaryRow
            left="Requesting"
            right={countWorkingDays(startDate, endDate) || 0}
            color={colors.dark.tertiary}
            weight={600}
          />
        )}
      </WrapperForSelectComponents>

      <div style={{ marginBottom: 8 }}>
        <AdditionalInfoTitle>
          <Text.Paragraphs type="caption">Additional comment</Text.Paragraphs>
        </AdditionalInfoTitle>

        <AdditionalInfoOnHolidayRequest
          placeholder={'More information about holiday request...'}
          value={newAdditionalComment}
          onChange={(e) => setNewAdditionalComment(e.currentTarget.value)}
          data-cy="additional-info-on-holiday-request"
        />
      </div>

      {!!employee?.holiday && selectedHolidayTypes?.key === HolidayTypesEnums.OnDemand && (
        <BalanceComponent
          title={'Balance'}
          requestingHoliday={requestingHoliday}
          onDemand={forUseOnDemandDynamic}
          onDemandTotal={employee.holiday.onDemandTotal || 0}
          useLeft={forUseLeftDynamic}
          total={employee.holiday.total || 0}
        />
      )}

      {!!employee?.holiday && selectedHolidayTypes?.key === HolidayTypesEnums.Vacation && (
        <BalanceComponent
          title={'Balance'}
          requestingHoliday={requestingHoliday}
          onDemand={employee.holiday.onDemand}
          onDemandTotal={employee.holiday.onDemandTotal || 0}
          useLeft={forUseLeftDynamic}
          total={employee.holiday.total || 0}
        />
      )}
    </Modal>
  );
};
