import { SvgIconName } from '../Icons/types';

export enum Variant {
  main = 'main',
  success = 'success',
  fail = 'fail',
  alert = 'alert',
  info = 'info',
}

export enum Type {
  outline = 'outline',
  outlineAlt = 'outlineAlt',
  solid = 'solid',
}

export interface AlertComponentProps {
  variant: Variant;
  type: Type;
  label: string | number;
  subLabel?: string;
  iconInfo: SvgIconName;
  actionIcon?: SvgIconName;
  onClick?: VoidFunction;
}
