import { Layout } from 'components/Layout';
import Text from 'components/Text';
import { routes } from 'routes';
import { colors } from 'styles/colors';
import {
  NotFoundWrapper,
  NotFoundContent,
  StyledLink,
  StyledButton,
  NotFoundImage,
} from './styled';

export const NotFound = () => {
  return (
    <Layout>
      <NotFoundWrapper>
        <NotFoundContent>
          <Text.Display style={{ marginTop: 0 }}>Wrrrrrr!</Text.Display>
          <Text.Paragraphs type="large" color={colors.gray[900]}>
            Apparently lion was really hungry ...
          </Text.Paragraphs>
          <StyledLink to={routes.dashboard}>
            <StyledButton
              label="Go Home"
              variant="secondary"
              size="large"
              iconNameLeft="EmojiLionIcon"
              hideBorder
            />
          </StyledLink>
          <NotFoundImage src="images/404Image.png" />
        </NotFoundContent>
      </NotFoundWrapper>
    </Layout>
  );
};
