import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SmallCalendar, MonthCalendar } from 'components/Calendar';
import { AvailableCalendarPath, CalendarType } from 'components/Calendar/types';
import { useCalendarContext, useUserContext, useHolidaysContext } from 'contexts';
import { useTeamHolidaysContext } from 'contexts/TeamHolidaysContext';
import { usePersistedString } from 'hooks';
import { getMonthMatrix } from 'utils';
import { StyledNavbar } from 'views/Dashboard/styled';
import { HeaderComponent, WeekCalendar, ShowFor, HolidayBalance } from './components';
import { useFiltersWeekCalendar } from './helpers';
import { useFilters } from './hooks';
import { CalendarLayout, SidebarWrapper, Wrapper } from './styled';
import { TeamCalendarLocationState } from './types';

export const TeamCalendar = () => {
  const { isManager } = useUserContext();
  const location = useLocation<TeamCalendarLocationState>();
  const { smallCalendarUserHolidays } = useHolidaysContext();

  const [lastCalendarType, setCalendarType] = usePersistedString(
    'calendar_period',
    location.state?.type || CalendarType.Month,
  );

  const calendarType = useMemo(
    () => (isManager ? lastCalendarType : CalendarType.Month),
    [isManager, lastCalendarType],
  );

  const isMonth = useMemo(() => calendarType === CalendarType.Month, [calendarType]); // wrapped in memo in order to use in useEffect

  const { monthIndex, date } = useCalendarContext();
  const year = useMemo(() => date.year(), [date]);

  const currentMonthMatrixMemoized = useMemo(
    () => getMonthMatrix(monthIndex, year),
    [monthIndex, year],
  );

  const {
    departmentIdState,
    searchPhraseState,
    holidayTypesState,
    employmentTypeState,
    holidayStatusesState,
    loadingHolidayTypes,
  } = useFilters();

  const { usersWithHolidays } = useTeamHolidaysContext();
  const {
    filteredUsersWeekCalendar,
    searchPhraseStateWeekCalendar,
    selectedDayStateWeekCalendar,
    selectedDepartmentIdStateWeekCalendar,
  } = useFiltersWeekCalendar(usersWithHolidays, location.state?.userName);

  return (
    <>
      <StyledNavbar />
      <Wrapper>
        <HeaderComponent
          calendarType={calendarType}
          setCalendarType={setCalendarType}
          isManager={isManager}
          selectedDayStateWeekCalendar={selectedDayStateWeekCalendar}
        />
        <CalendarLayout>
          {isMonth && (
            <SidebarWrapper
              isCalendarMonth={window.location.pathname.includes(
                AvailableCalendarPath.TEAM_CALENDAR,
              )}>
              <SmallCalendar
                holidaysData={smallCalendarUserHolidays}
                customMonthIndex={monthIndex}
              />
              <ShowFor
                departmentIdState={departmentIdState}
                employmentTypeState={employmentTypeState}
                searchPhraseState={searchPhraseState}
              />
              {isManager && (
                <HolidayBalance
                  loadingHolidayTypes={loadingHolidayTypes}
                  holidayTypesState={holidayTypesState}
                  holidayStatusesState={holidayStatusesState}
                />
              )}
            </SidebarWrapper>
          )}

          {isMonth ? (
            <MonthCalendar
              departmentId={departmentIdState.state}
              searchPhrase={searchPhraseState.state}
              employmentType={employmentTypeState.state}
              holidayTypes={holidayTypesState.state}
              loadingHolidayTypes={loadingHolidayTypes}
              holidayStatuses={holidayStatusesState.state}
              month={currentMonthMatrixMemoized}
              teamCalendar
            />
          ) : (
            <WeekCalendar
              filteredUsers={filteredUsersWeekCalendar}
              searchPhraseState={searchPhraseStateWeekCalendar}
              selectedDayState={selectedDayStateWeekCalendar}
              selectedDepartmentIdState={selectedDepartmentIdStateWeekCalendar}
            />
          )}
        </CalendarLayout>
      </Wrapper>
    </>
  );
};
