import styled, { css } from 'styled-components';
import { remCalc } from 'helpers';
import { colors } from 'styles/colors';
import { typography } from 'styles/typography';
import { borderRadius } from 'styles/variables';
import { RequestManagerModal } from '.';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRejectModal = styled(RequestManagerModal)`
  width: 40rem;
`;

export const StyledCancelModal = styled(RequestManagerModal)`
  width: 31rem;
`;

export const ModalRejectTextArea = styled.textarea`
  width: calc(100% - 2rem);
  height: 11.625rem;
  padding: 0.5em 1em;
  margin-top: 0.5rem;
  margin-bottom: 2.25rem;
  resize: none;
  outline: none;
  border-radius: ${borderRadius.xs};
  ${typography.paragraphs.xSmall};
  color: ${({ theme }) => theme.holidayForm.messegaOnRejectModal};
  border-color: ${({ theme }) => theme.input.border};
  background-color: ${({ theme }) => theme.input.background};

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
  }
`;

export const InfoText = styled.div`
  ${typography.paragraphs.caption};
  color: ${colors.modalAlert};
`;

export const DividerModalBorder = styled.div`
  position: absolute;
  border-bottom: 1px solid ${colors.gray[400]};
  width: calc(100% + 56px);
  left: -${remCalc(28)};
  right: -${remCalc(28)};
`;

export const AdditionalHolidaysInfoWrapper = styled.div`
  position: relative;
`;

export const AdditionalHolidaysContainer = styled.div<{ showAdditionalHolidayElement?: boolean }>`
  ${({ showAdditionalHolidayElement }) =>
    (showAdditionalHolidayElement &&
      css`
        padding: 1.5em 0 1.25em;
      `) ||
    css`
      padding: 1.5em 0 0;
    `}
`;

export const AdditionalHolidaysButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: ${remCalc(30)};
  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const AdditionalHolidaysIconWrapper = styled.span`
  margin-right: ${remCalc(10)};
`;
